import {
  Avatar,
  ButtonGroup,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  Icon,
  AvatarBadge,
} from 'components/design/next';
import { FieldType, TaskTemplate } from '@process-street/subgrade/process';
import * as React from 'react';
import { AssignmentPickerWrapper } from 'app/pages/templates/_id/components/assignment-picker/assignment-picker-wrapper';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { useSelector } from '@xstate/react';
import { useTaskAssignments } from './use-task-assignments';
import {
  TaskAssignmentRule,
  TaskAssignments,
  TaskAssignmentUser,
} from 'app/pages/templates/_id/components/task-assignments';
import { match, P } from 'ts-pattern';
import { getAvatar } from 'components/common/Avatar';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';

export type BulkAssignTaskButtonProps = {
  taskTemplates: TaskTemplate[];
  isEditable: boolean;
  isDisabled: boolean;
};

export const BulkAssignTaskButton = ({ taskTemplates, isEditable, isDisabled }: BulkAssignTaskButtonProps) => {
  const editorActor = useFormEditorPageActorRef();
  const templateRevision = useSelector(editorActor, FormEditorPageActorSelectors.getTemplateRevision);
  const { assignees, uniqueRules } = useTaskAssignments({ taskTemplates, editorActor });
  const hasAssignments = assignees.length + uniqueRules.length > 0;

  const [firstUser] = assignees
    .slice()
    .sort((a, b) => a.username.toLowerCase().localeCompare(b.username.toLowerCase()));
  const total = assignees.length + uniqueRules.length;
  const coverUser = (firstUser ?? uniqueRules[0]) as typeof firstUser | undefined | typeof uniqueRules[number];
  const taskTempaltesIds = taskTemplates.map(t => t.id);

  if (!templateRevision) return null;
  return (
    <ButtonGroup
      isAttached
      sx={{
        '> span:first-of-type:not(:nth-last-of-type(2)) > button': { borderEndRadius: 0 },
        '> button:not(:only-child)': { borderStartRadius: 0, ml: '-px' },
      }}
    >
      {isEditable && (
        <AssignmentPickerWrapper
          taskTemplates={taskTemplates}
          templateRevision={templateRevision}
          hasAssignments={hasAssignments}
          disabled={isDisabled}
          isBulk
          buttonProps={{ borderRight: hasAssignments ? '0' : '1', minW: hasAssignments ? '123px' : '163px' }}
        />
      )}
      {hasAssignments && (
        <Popover isLazy>
          <PopoverTrigger>
            <IconButton
              aria-label="View assignees"
              borderLeft="0"
              bgColor="white"
              icon={
                <Avatar
                  size="sm"
                  bg="gray.300"
                  {...match(coverUser)
                    .with({ widget: { fieldType: FieldType.Members } }, () => ({
                      icon: <Icon icon="users" size="4" />,
                    }))
                    .with({ widget: { fieldType: FieldType.Email } }, () => ({
                      icon: <Icon icon="envelope" size="4" />,
                    }))
                    .with({ avatarUrl: P.string }, { avatarFile: { id: P.string } }, { username: P.string }, user => {
                      const avatar = getAvatar(user);
                      return {
                        src: avatar.url,
                        name: user.username,
                      };
                    })
                    .otherwise(() => ({}))}
                >
                  {total > 1 && (
                    <AvatarBadge fontSize="8px" h="1em" minW="1em" px="0.5" bg="gray.400" border="none">
                      {total}
                    </AvatarBadge>
                  )}
                </Avatar>
              }
              {...{
                iconSpacing: '0',
                variant: 'outline',
                size: 'md',
                borderWidth: 'px',
                borderColor: isEditable ? 'gray.300' : 'transparent',
                justifyContent: 'center',
              }}
              sx={{
                '&': { px: 1 },
              }}
            />
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverHeader>
                <Text variant="-1" color="gray.700" fontWeight="medium">
                  Assignees
                </Text>

                <PopoverCloseButton
                  padding="0"
                  textTransform="none"
                  borderStyle="none"
                  outline="initial"
                  margin="0"
                  marginTop={1}
                  cursor="pointer"
                  backgroundColor="transparent"
                  fontSize="xs"
                  fontWeight="md"
                  color="gray.500"
                  w="24px!important"
                />
              </PopoverHeader>
              <PopoverArrow />
              <PopoverBody pb="4">
                <TaskAssignments>
                  {assignees.map(assignee => (
                    <TaskAssignmentUser
                      key={assignee.id}
                      user={assignee}
                      taskTemplatesIds={taskTempaltesIds}
                      templateRevisionId={templateRevision.id}
                      isReadOnly={!isEditable}
                    />
                  ))}
                  {uniqueRules.map(rule => (
                    <TaskAssignmentRule
                      key={rule.id}
                      rule={rule}
                      templateRevisionId={templateRevision.id}
                      isReadOnly={!isEditable}
                    />
                  ))}
                </TaskAssignments>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      )}
    </ButtonGroup>
  );
};
