import * as React from 'react';
import {
  HStack,
  StackProps,
  Text,
  Icon,
  VStack,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  ComponentWithAs,
  SkeletonText,
  SkeletonCircle,
  Skeleton,
  SkeletonTextProps,
  SkeletonProps,
  Flex,
  Square,
  Spacer,
  IconProps,
  useBreakpointValue,
  ThemingProps,
} from 'components/design/next';
import { Template, TemplateShareLevel, TemplateType } from '@process-street/subgrade/process';
import { DateFormat, DateUtils } from '@process-street/subgrade/util';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { RunWorkflowButton as RunButtonWithPaywall } from 'components/run-workflow-button';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { useIsCurrentUserFreeMember } from 'hooks/use-is-current-user-free-member';
import { useInjector } from 'components/injection-provider';
import { useGetTemplateReadPermissionsQuery } from 'features/template/query-builder';
import { Option } from 'space-monad';
import { match, P } from 'ts-pattern';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import { NgLink } from 'features/app/components/ng-link';
import { AppModalName, AppModalQueryParam } from 'app/app.constants';
import { useTemplateTypeContext } from 'utils/template/template-type-context';
import { useFeatureFlag } from 'app/features/feature-flags';

export type CrossLinkCardProps = StackProps;

const SQUARE_SIZE = [16, 20];
export const CrossLinkCard: ComponentWithAs<'div', CrossLinkCardProps> = ({ children, ...props }) => {
  let leftElement: React.ReactElement | undefined = undefined;
  let body: React.ReactElement | undefined = undefined;
  let rightElement: React.ReactElement | undefined = undefined;

  const { templateType } = useTemplateTypeContext();

  React.Children.forEach(children, child => {
    if (React.isValidElement(child) && child.type === CrossLinkLeftElement) {
      leftElement = child;
    }
    if (React.isValidElement(child) && child.type === CrossLinkBody) {
      body = child;
    }
    if (React.isValidElement(child) && child.type === CrossLinkRightElement) {
      rightElement = child;
    }
  });

  return (
    match({ templateType })
      // If searching for pages, we know we're in the template editor
      .with({ templateType: TemplateType.Page }, () => (
        <Flex
          position="relative"
          borderColor="gray.200"
          borderWidth="px"
          borderStyle="solid"
          borderRadius="base"
          alignItems="center"
          justifyContent="flex-start"
          py="2"
          pl="4"
          pr="3"
          h={leftElement ? SQUARE_SIZE : undefined}
          {...props}
        >
          {leftElement ? (
            <>
              <Square
                {...{
                  bg: 'teal.500',
                  borderLeftRadius: 'base',
                  size: SQUARE_SIZE,
                  position: 'absolute',
                  // -px to offset parent border
                  left: '-px',
                  top: '-px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {React.cloneElement(leftElement, { color: 'white' })}
              </Square>
              {/* match square width */}
              <Box w={SQUARE_SIZE} flexShrink={0} />
            </>
          ) : null}

          <Box minW={0} w="full" pr="2">
            {body}
          </Box>

          {rightElement ? (
            <>
              <Spacer />

              <Box flexShrink={0}>{rightElement}</Box>
            </>
          ) : null}
        </Flex>
      ))
      .otherwise(() => (
        <Box position="relative">
          <HStack bg="gray.100" borderRadius="lg" p="4" spacing="4" {...props}>
            {leftElement}
            {body}
            {rightElement}
          </HStack>
        </Box>
      ))
  );
};

export const TemplateIconSkeleton: React.FC<React.PropsWithChildren<SkeletonProps>> = props => (
  <SkeletonCircle size="4" borderRadius="base" {...props} />
);

export type TemplateIconProps = { templateId: Template['id'] };
export const TemplateIcon: React.FC<React.PropsWithChildren<TemplateIconProps>> = ({ templateId }) => {
  const templateQuery = useGetTemplateReadPermissionsQuery({ templateId });
  return match(templateQuery)
    .with({ status: 'loading' }, () => <TemplateIconSkeleton />)
    .with(
      { status: 'success' },
      ({
        data: {
          template: { templateType },
        },
      }) => (
        <Icon
          size="4"
          variant="fas"
          {...match<{ templateType: TemplateType }, Pick<IconProps, 'icon' | 'color'>>({
            templateType,
          })
            .with({ templateType: TemplateType.Page }, () => ({ icon: 'file-alt', color: 'white' }))
            .with({ templateType: TemplateType.Playbook }, () => ({ icon: 'workflow', color: 'indigo.500' }))
            .run()}
        />
      ),
    )
    .otherwise(() => null);
};

export const CrossLinkBody: React.FC<React.PropsWithChildren<BoxProps>> = props => {
  return <Box minW="0" flex="1" {...props} />;
};

export const CrossLinkLeftElement: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <>{children}</>;
};

export const CrossLinkRightElement: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <Box>{children}</Box>;
};

export const TemplateInfoSkeleton: React.FC<React.PropsWithChildren<SkeletonTextProps>> = props => (
  <SkeletonText noOfLines={3} spacing="1" skeletonHeight="2" {...props} />
);

export type TemplateInfoProps = { templateId: Template['id'] };
export const TemplateInfo: React.FC<React.PropsWithChildren<TemplateInfoProps>> = ({ templateId }) => {
  const templateQuery = useGetTemplateReadPermissionsQuery({ templateId });
  // It's possible for this query to 403 if the user doesn't have permissions but the workflow has shared access
  // so we use it if it's there otherwise don't worry about it
  const latestTemplateRevisionQuery = useNewestTemplateRevisionQuery({ templateId, editable: false }, { retry: false });
  const isLoading = templateQuery.isLoading && latestTemplateRevisionQuery.isLoading;

  const templateUpdatedDate = templateQuery.data?.template.audit.updatedDate ?? 0;
  const templateRevisionUpdatedDate = latestTemplateRevisionQuery.data?.audit.updatedDate ?? 0;
  const updatedDate = Math.max(templateUpdatedDate, templateRevisionUpdatedDate);

  const userQuery = useGetCurrentUserInfoQuery();
  const timezone = userQuery.data?.user.timeZone;
  const formatDate = (updatedDate: number) =>
    DateUtils.formatDateTime(updatedDate, DateFormat.DateMonthDayYearAtTime, timezone);

  const nameVariant = useBreakpointValue({ base: '-1', sm: '1' }) ?? '1';
  const descriptionVariant = useBreakpointValue({ base: '-2', sm: '-1' }) ?? '-1';
  return match({ isLoading, updatedDate, template: templateQuery.data?.template, latestTemplateRevisionQuery })
    .with({ isLoading: true }, () => <TemplateInfoSkeleton />)
    .with({ template: P.not(undefined) }, ({ template: { name, description }, updatedDate }) => (
      <VStack alignItems="start" spacing="px">
        <Text w="full" variant={nameVariant} fontWeight="bold" color="gray.600" noOfLines={1}>
          {name}
        </Text>
        {description ? (
          <Text w="full" variant={descriptionVariant} color="gray.500" noOfLines={1}>
            {description}
          </Text>
        ) : null}
        <Text w="full" variant={descriptionVariant} color="gray.400" fontStyle="italic" noOfLines={1}>
          Last modified {formatDate(updatedDate)}
        </Text>
      </VStack>
    ))
    .otherwise(() => null);
};

export const TemplateNotAllowed: React.FC<React.PropsWithChildren<unknown>> = () => {
  const variant = useBreakpointValue({ base: '-2', sm: '1' }) ?? '1';
  return (
    <Text variant={variant} color="gray.600" noOfLines={3}>
      You don&apos;t have permission to view this content. Please contact an administrator.
    </Text>
  );
};

export const RunButtonSkeleton: React.FC<React.PropsWithChildren<SkeletonProps>> = props => (
  <Skeleton {...props}>
    {props.children ?? (
      <Button size="lg" iconSpacing="2" leftIcon={<Icon icon="workflow-run" variant="far" size="4" />}>
        Run
      </Button>
    )}
  </Skeleton>
);

export const RunButton: React.FC<React.PropsWithChildren<{ templateId: Template['id'] }>> = ({ templateId }) => {
  const { $state } = useInjector('$state');
  const permissionsQuery = useGetConsolidatedTemplatePermissionsQuery(templateId, { enabled: !!templateId });
  const templateQuery = useGetTemplateReadPermissionsQuery({ templateId });

  const isFree = useIsCurrentUserFreeMember();

  // show to free members so they get the paywall
  const isVisible =
    !!permissionsQuery.data?.permissionMap.checklistCreate ||
    templateQuery.data?.template.shareLevel === TemplateShareLevel.Run ||
    isFree;

  const runUrlOptions = {
    to: $state.current,
    params: {
      ...$state.params,
      [AppModalQueryParam.Modal]: AppModalName.RunChecklist,
      [AppModalQueryParam.ModalTemplateId]: templateId,
    },
    options: { inherit: false },
  };

  const buttonSize = useBreakpointValue<ThemingProps['size']>({ base: 'sm', sm: 'lg' }) ?? 'lg';
  return (
    <RunButtonSkeleton isLoaded={permissionsQuery.isSuccess}>
      <RunButtonWithPaywall isVisible={isVisible || permissionsQuery.isLoading}>
        <Button
          as={NgLink}
          {...runUrlOptions}
          size={buttonSize}
          iconSpacing="2"
          leftIcon={<Icon icon="workflow-run" variant="far" size="4" />}
          // annoying to have to override a global `a` css selector
          _hover={{ bgColor: 'brand.600', color: 'white', textDecoration: 'none' }}
        >
          Run
        </Button>
      </RunButtonWithPaywall>
    </RunButtonSkeleton>
  );
};

export const ViewButton: React.FC<React.PropsWithChildren<ButtonProps & { templateId: Template['id'] }>> = ({
  templateId,
  ...props
}) => {
  const { $state } = useInjector('$state');
  const templateQuery = useGetTemplateReadPermissionsQuery({ templateId });
  const isNewViewModeEnabled = useFeatureFlag('pagesEditorV2');
  const pageView = isNewViewModeEnabled ? 'pageViewV2' : 'pageView';
  const templateView = isNewViewModeEnabled ? 'templateViewV2' : 'templateDashboard';
  const href = Option(templateQuery.data?.template)
    .map(({ templateType }) => (templateType === TemplateType.Page ? pageView : templateView))
    .map(stateName => $state.href(stateName, { id: templateId }, { inherit: false }))
    .getOrElse('#');
  const buttonSize = useBreakpointValue<ThemingProps['size']>({ base: 'sm', sm: 'lg' }) ?? 'lg';
  return (
    <Skeleton isLoaded={templateQuery.isSuccess}>
      <Button
        as="a"
        variant="secondary"
        // Upping from bg gray.100 because the card background is gray.100
        bgColor="gray.200"
        _hover={{ bgColor: 'gray.300', color: 'gray.700' }}
        size={buttonSize}
        href={href}
        target="_blank"
        {...props}
      >
        View
      </Button>
    </Skeleton>
  );
};
