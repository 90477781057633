import * as React from 'react';
import { useField, useFormikContext } from 'formik';
import {
  Checkbox,
  FormControl,
  HStack,
  InputProps,
  Link,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from 'components/design/next';
import { formikMetaToFormControlProps } from '../common/utils';
import { EmailFormat, SendRichEmailFormFieldWidget } from '@process-street/subgrade/process';
import { SwitchModeAlert } from 'app/components/widgets/form-field/settings/send-rich-email-settings/switch-mode-alert';
import { bodyIsEmpty, getEmailBody } from 'app/features/widgets/components/send-rich-email/util';
import { useConfirmationContext } from '../../context';

export const EmailSettingsFields: React.FC<
  React.PropsWithChildren<InputProps & { widget: SendRichEmailFormFieldWidget }>
> = ({ widget }) => {
  const [emailFormatField, formatFieldMeta] = useField<string>(EmailFormatFieldName);
  const [editAllowedField, editAllowedFieldMeta] = useField<string>(EditAllowedFieldName);
  const { setFieldValue, handleSubmit } = useFormikContext();

  const { setConfirmationContent, setShouldShowConfirmation, onConfirm, setHasAcceptedConfirmation } =
    useConfirmationContext();

  const handleClose = () => {
    setShouldShowConfirmation(false);
  };
  const handleEditorChange = (emailFormat: EmailFormat) => {
    const isDirty = !bodyIsEmpty(getEmailBody(widget.config));
    setFieldValue(EmailFormatFieldName, emailFormat);
    setFieldValue(EmailEditorFieldName, emailFormat === EmailFormat.RichTextOrHtml ? 'RichEditor' : 'PlainTextEditor');
    if (isDirty && emailFormat !== emailFormatField.value) {
      setConfirmationContent(
        <SwitchModeAlert
          isOpen={true}
          onClose={handleClose}
          onConfirm={() => {
            setHasAcceptedConfirmation(true);
            onConfirm(handleSubmit);
          }}
          targetEmailFormat={emailFormat as EmailFormat}
        />,
      );
    } else {
      setConfirmationContent(null);
      setShouldShowConfirmation(false);
      setHasAcceptedConfirmation(false);
    }
  };
  return (
    <VStack w="full" gap={4}>
      <FormControl {...formikMetaToFormControlProps(formatFieldMeta)}>
        <RadioGroup
          {...emailFormatField}
          onChange={handleEditorChange}
          value={emailFormatField.value ?? EmailFormat.RichTextOrHtml}
        >
          <VStack alignItems="flex-start" gap={4}>
            <VStack alignItems="flex-start" gap={2}>
              <Radio value={EmailFormat.RichTextOrHtml}>Rich text or HTML</Radio>
              <VStack alignItems="flex-start" gap={2} ml={8}>
                <Text fontSize="sm">Add flourish to your workflow&apos;s email content.</Text>
                <Text fontSize="sm">
                  Email is sent from&nbsp;
                  <Text fontSize="sm" as="b">
                    process-street-mail.com
                  </Text>
                  &nbsp;or a&nbsp;
                  <Link
                    href="https://www.process.st/help/docs/email-widget/#custom-sender-domain"
                    color="brand.300"
                    isExternal
                  >
                    custom sender domain
                  </Link>
                  .
                </Text>
              </VStack>
            </VStack>
            {emailFormatField.value === EmailFormat.RichTextOrHtml && (
              <FormControl {...formikMetaToFormControlProps(editAllowedFieldMeta)} ml={8}>
                <HStack>
                  <Checkbox {...editAllowedField} defaultChecked={Boolean(editAllowedField.value)} />
                  <Text>Allow workflow runners to edit email prior to sending</Text>
                </HStack>
              </FormControl>
            )}
            <VStack alignItems="flex-start" gap={2}>
              <Radio value={EmailFormat.PlainText}>Plain text</Radio>
              <VStack alignItems="flex-start" gap={2} ml={8}>
                <Text fontSize="sm">Simple, no frills workflow email content.</Text>
                <Text fontSize="sm">
                  Email is sent from the current user's email client (e.g. Outlook or Gmail) with their email address.
                </Text>
              </VStack>
            </VStack>
          </VStack>
        </RadioGroup>
      </FormControl>
    </VStack>
  );
};

export const EmailFormatFieldName = 'config.emailFormat';
export const EmailEditorFieldName = 'config.editor';
export const EditAllowedFieldName = 'config.editAllowed';
