import { TaskWithTaskTemplate } from '@process-street/subgrade/process';
import * as React from 'react';
import { FormResponsePageMachineHooks } from 'app/pages/responses/_id/form-response-page-hooks';
import { AvatarBadge, Button, Tooltip } from '@chakra-ui/react';
import { toModelRef } from '@process-street/subgrade/core';
import { Option } from 'space-monad';
import { getAvatar } from 'app/components/common/Avatar';
import { TaskListItemAssigneeIndicatorPopover as Popover } from './task-list-item-assignee-indicator-popover';
import { TaskListItemAssigneeIndicatorAvatar as Avatar } from './task-list-item-assignee-indicator-avatar';
import get from 'lodash/fp/get';

export type TaskListItemAssigneeIndicatorProps = {
  task: TaskWithTaskTemplate;
};

export const TaskListItemAssigneeIndicator = ({ task }: TaskListItemAssigneeIndicatorProps) => {
  const taskAssignments = FormResponsePageMachineHooks.useTaskAssignments(task.id);
  const [firstAssignment] = taskAssignments;

  if (!firstAssignment) return null;

  const user = Option(firstAssignment)
    .map(get('organizationMembership'))
    .map(toModelRef)
    .map(get('user'))
    .map(toModelRef)
    .get();

  if (!user) return null;
  const { title } = getAvatar(user);
  const count = taskAssignments.length - 1;

  const tooltipLabel =
    count > 0 ? `Assigned to ${title} and ${count} ${count > 1 ? 'others' : 'other'}` : `Assigned to ${title}`;

  return (
    <Popover taskAssignments={taskAssignments}>
      <Button variant="unstyled">
        <Tooltip label={tooltipLabel}>
          <Avatar user={user}>
            {count > 0 && (
              <AvatarBadge borderColor="white" borderWidth="thin" bg="gray.500" boxSize="1.25em" fontSize="xs">
                {count + 1}
              </AvatarBadge>
            )}
          </Avatar>
        </Tooltip>
      </Button>
    </Popover>
  );
};
