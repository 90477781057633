import { Option, User } from '@process-street/subgrade/core';
import { InboxItemType } from '@process-street/subgrade/inbox';
import angular, { IChangesObject } from 'angular';
import templateUrl from './type-filter.component.html';
import './type-filter.component.scss';

export enum FilterItemTypeOption {
  All = 'All',
  Approvals = 'Approvals',
  Tasks = 'Tasks',
  WorkflowRuns = 'Runs',
}

export class InboxFilterBarItemTypeFilterController {
  public selectedOption = FilterItemTypeOption.All;
  public opened = false;

  public options = Object.values(FilterItemTypeOption).filter(o => o !== FilterItemTypeOption.Approvals);

  public $onChanges(changes: { selected: IChangesObject<InboxItemType>; user: IChangesObject<User> }) {
    const { selected, user } = changes;

    if (selected && selected.currentValue) {
      switch (selected.currentValue) {
        case InboxItemType.ApprovalTask:
          this.selectedOption = FilterItemTypeOption.Approvals;
          break;
        case InboxItemType.StandardTask:
        case InboxItemType.OneOffTask:
          this.selectedOption = FilterItemTypeOption.Tasks;
          break;
        case InboxItemType.Checklist:
          this.selectedOption = FilterItemTypeOption.WorkflowRuns;
          break;
      }
    }

    if (user && user.currentValue) {
      // @ts-expect-error The angular inbox will be deprecated soon
      this.options = Object.values(FilterItemTypeOption);
    }
  }

  public select(selectedOption: FilterItemTypeOption) {
    this.selectedOption = selectedOption;
    this.opened = false;

    let itemType: Option<InboxItemType>;
    switch (selectedOption) {
      case FilterItemTypeOption.All:
        itemType = undefined;
        break;
      case FilterItemTypeOption.Approvals:
        itemType = InboxItemType.ApprovalTask;
        break;
      case FilterItemTypeOption.Tasks:
        itemType = InboxItemType.StandardTask;
        break;
      case FilterItemTypeOption.WorkflowRuns:
        itemType = InboxItemType.Checklist;
        break;
    }

    // @ts-expect-error -- TODO
    this.onSelect({ itemType });
  }
}

export const InboxFilterBarItemTypeFilter: angular.IComponentOptions = {
  bindings: {
    onSelect: '&',
    selected: '<',
    user: '<',
  },
  controller: InboxFilterBarItemTypeFilterController,
  templateUrl,
};
