import angular from 'angular';
import templateUrl from './inbox.component.html';

angular.module('frontStreetApp.directives').component('psInbox', {
  bindings: {
    user: '<',
    organization: '<',
    expandedItemType: '<',
    expandedItemId: '<',
  },
  templateUrl,
  // eslint-disable-next-line object-shorthand
  controller: function () {
    const ctrl = this;

    ctrl.registerInboxListCtrl = function (inboxListCtrl) {
      ctrl.inboxListCtrl = inboxListCtrl;
    };

    ctrl.applyFilter = function (filters, isInitialLoad = false) {
      ctrl.inboxListCtrl.applyFilters(filters, isInitialLoad);
    };

    ctrl.setSearchBoxFocused = function (focused) {
      return ctrl.inboxListCtrl && ctrl.inboxListCtrl.setSearchBoxFocused(focused);
    };

    ctrl.countSelected = function () {
      return ctrl.inboxListCtrl && ctrl.inboxListCtrl.countSelected();
    };

    ctrl.areAllSelected = function () {
      return ctrl.inboxListCtrl && ctrl.inboxListCtrl.areAllSelected();
    };

    ctrl.selectAll = function () {
      return ctrl.inboxListCtrl && ctrl.inboxListCtrl.selectAll();
    };

    ctrl.unselectAll = function () {
      return ctrl.inboxListCtrl && ctrl.inboxListCtrl.unselectAll();
    };

    ctrl.completeAll = function () {
      return ctrl.inboxListCtrl && ctrl.inboxListCtrl.completeAll();
    };

    ctrl.updateAllDueDates = function (dueDate) {
      return ctrl.inboxListCtrl && ctrl.inboxListCtrl.updateAllDueDates(dueDate);
    };

    ctrl.removeAllDueDates = function () {
      return ctrl.inboxListCtrl && ctrl.inboxListCtrl.updateAllDueDates(null);
    };
  },
});
