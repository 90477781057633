import * as React from 'react';
import { Box, Center, GridItem, Show, SimpleGrid, Spinner, useBreakpointValue, VStack } from 'components/design/next';
import { InsertWidget, InsertWidgetDrawer, InsertWidgetDrawerButton, WidgetsList } from './components';
import { useMachine, useSelector } from '@xstate/react';
import { makeFormEditorPageMachine } from './form-editor-page-machine';
import { match } from 'ts-pattern';
import { useStateParam } from 'hooks/use-state-param';
import { FormEditorTopBar, TOP_BAR_HEIGHT_CSS_VAR, UIActorProvider, useSharedContext } from '../shared';
import { useBackgroundCdnImage } from 'features/forms/hooks/use-background-cdn-image';
import { EditorModal } from 'pages/forms/_id/shared/editor-modal/editor-modal';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { NewWidgetItem } from './types';
import { FormEditorBottomBar } from './components/form-editor-bottom-bar';
import { FormEditorPageActorRefProvider } from './form-editor-page-machine/form-editor-page-machine-context';
import { useInjector } from 'components/injection-provider';
import { StepHeader } from './components/step-header';
import { MoveToStepModal } from './components/forms-widget-menu/move-to-step/move-to-step-modal';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { abbreviateForTitle } from '@process-street/subgrade/util';
import { useSwitchOrganizationIfNeeded } from 'hooks/use-switch-organization-if-needed';
import { FormEditorPageActorSelectors } from './form-editor-page-machine/form-editor-page-machine-selectors';
import { useReorderAutoScroll } from 'app/pages/workflows/_id/edit-v2/hooks/use-reorder-auto-scroll';
import { ReorderAutoScrollContext } from 'app/pages/workflows/_id/edit-v2/providers/reorder-scroll-context';

export type FormEditorProps = {};

const WIDGETS_AUTOSCROLL_THRESHOLD_PX = 100;
const WIDGETS_AUTOSCROLL_AMOUNT_PX = 20;

export const FormsEditor: React.FC<React.PropsWithChildren<FormEditorProps>> = () => {
  const { $state } = useInjector('$state');

  const templateId = useStateParam({ key: 'id' })!;
  const sharedContext = useSharedContext();

  const machine = React.useRef(makeFormEditorPageMachine({ sharedContext })).current;
  const [state, send, actor] = useMachine(machine);

  const template = useSelector(actor, FormEditorPageActorSelectors.getTemplate);
  const permissionsQuery = useGetConsolidatedTemplatePermissionsQuery(templateId);

  useSwitchOrganizationIfNeeded({ organizationId: template?.organization?.id });

  React.useEffect(() => {
    if (template && permissionsQuery.data && !permissionsQuery.data.permissionMap.templateUpdate) {
      $state.go('formResponses', { id: template.id, title: `${abbreviateForTitle(template.name)}-` });
    }
  }, [$state, template, permissionsQuery.data]);

  React.useEffect(() => {
    if (template?.name) {
      document.title = `${template?.name} | Process Street`;
    }
  }, [template?.name]);

  const handleWidgetClick = (payload: NewWidgetItem) => {
    send({ type: 'CREATE_WIDGET', payload });
  };

  const cdnImage = useBackgroundCdnImage({ templateId });
  const backgroundSize = useBreakpointValue({ base: 'contain', lg: 'cover' });
  const backgroundPosition = useBreakpointValue({ base: 'center top', lg: 'center center' });

  const isLoading = useSelector(actor, FormEditorPageActorSelectors.isLoading);
  const isPublishing = useSelector(actor, FormEditorPageActorSelectors.isPublishing);

  const widgetAutoScrollProps = useReorderAutoScroll({
    scrollThresholdPx: WIDGETS_AUTOSCROLL_THRESHOLD_PX,
    scrollAmountPx: WIDGETS_AUTOSCROLL_AMOUNT_PX,
  });

  return (
    <DndProvider backend={HTML5Backend} context={window}>
      <UIActorProvider uiActorRef={state.context.uiActorRef}>
        <FormEditorPageActorRefProvider actor={actor}>
          <ReorderAutoScrollContext.Provider value={{ widgetAutoScroll: widgetAutoScrollProps }}>
            <VStack spacing="0" alignItems="stretch" h="full" overflow="hidden">
              <FormEditorTopBar actor={state.context.topBarActorRef} />

              <SimpleGrid
                // left col is 0 on mobile for now until we add the tasks functionality
                gridTemplateColumns={{ base: '0 1fr', lg: '0 1fr' }}
                flex="1"
                h={`calc(100vh - ${TOP_BAR_HEIGHT_CSS_VAR})`}
                alignItems="flex-start"
                justifyContent="flex-start"
                position="relative"
              >
                <GridItem
                  pt="10"
                  h="100%"
                  borderRightWidth="1px"
                  borderColor="gray.200"
                  borderRightStyle="solid"
                  bgColor="white"
                />

                <GridItem
                  pt={{ base: '32', lg: '10' }}
                  overflowY="auto"
                  h="full"
                  position="relative"
                  display="flex"
                  flexDirection="column"
                  w="full"
                  {...{
                    ...match(cdnImage)
                      .with({ status: 'loaded' }, ({ src }) => ({
                        backgroundImage: `url(${src})`,
                      }))
                      .with({ lqipStatus: 'loaded' }, ({ lqipSrc }) => ({
                        backgroundImage: `url(${lqipSrc}) `,
                      }))
                      .otherwise(() => {}),
                    backgroundAttachment: 'fixed',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition,
                    backgroundSize,
                  }}
                  bgColor="brand.100"
                  ref={widgetAutoScrollProps.scrollContainerProps.scrollContainerRef}
                  onScroll={widgetAutoScrollProps.scrollContainerProps.onScroll}
                >
                  <Center w="full" pb="8">
                    <Box
                      bgColor="white"
                      borderRadius={{ base: '0', lg: 'lg' }}
                      px={{ base: '10', lg: '11' }}
                      w="full"
                      maxW={{ base: 'full', lg: '788px' }}
                    >
                      <StepHeader />
                      <WidgetsList />
                    </Box>
                  </Center>

                  <FormEditorBottomBar position="sticky" bottom={4} minH={BOTTOM_BAR_HEIGHT_TOKEN} zIndex="1" />
                </GridItem>

                <Show above="md">
                  <InsertWidgetDrawerButton />
                </Show>

                {isPublishing ? (
                  <>
                    <Box
                      zIndex="2"
                      opacity={0.5}
                      position="absolute"
                      top="0"
                      left="0"
                      w="full"
                      h="full"
                      bg="gray.300"
                    ></Box>
                    <Center pt="12" position="fixed" left="0" w="full" top="50%" transform="translateY(-50%)">
                      <Spinner h="30" w="30" />
                    </Center>
                  </>
                ) : null}
              </SimpleGrid>
            </VStack>

            <InsertWidgetDrawer
              contentProps={{
                h: `calc(100vh - ${TOP_BAR_HEIGHT_CSS_VAR})`,
                top: `${TOP_BAR_HEIGHT_CSS_VAR} !important`,
                bottom: 0,
              }}
              body={<InsertWidget isLoading={isLoading} onWidgetClick={handleWidgetClick} />}
            />
            <MoveToStepModal />
            <EditorModal />
          </ReorderAutoScrollContext.Provider>
        </FormEditorPageActorRefProvider>
      </UIActorProvider>
    </DndProvider>
  );
};

const BOTTOM_BAR_HEIGHT_TOKEN = '11';
