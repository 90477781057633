import { Icon, IconSize, IconType } from 'components/design/Icon';
import React, { ReactNode } from 'react';
import { components, GroupBase, OptionProps } from 'react-select';

export const createSingleOption =
  <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
    label: (props: OptionProps<Option, IsMulti, Group>) => ReactNode,
  ) =>
  (props: OptionProps<Option, IsMulti, Group>) => {
    const { isSelected, data } = props;
    const icon = props.selectProps.getOptionIcon?.<Option, IconType>(data);

    return (
      <div className="blvd-select__option__wrapper" title={props.label}>
        <components.Option<Option, IsMulti, Group> {...props}>
          <span className="blvd-select__option__label">
            <>
              {icon && <Icon size={IconSize.Normal} iconType={icon} className={'blvd-select__option__icon'} />}
              {label(props)}
              {data && typeof data === 'object' && 'suffix' in data ? data.suffix : null}
            </>
          </span>
          {isSelected && <Icon size={IconSize.Normal} iconType={IconType.Check} className={''} />}
        </components.Option>
      </div>
    );
  };
