import { useFunctionRef } from 'hooks/use-function-ref';
import * as React from 'react';

export const COMMA = ', ';

/** A form variable might have a comma in the name (e.g., "Name, username, or email") */
const COMMA_NOT_IN_VARIABLE_PATTERN = /[,]+\s*(?![^{]*})/;

const SEMICOLON_NOT_IN_VARIABLE_PATTERN = /[;]+(?![^{]*})/;

// e.g., "a@b.c,c@b.a," -> ["a@b.c", "c@b.a"]
export const sanitizeEmails = (value: string) =>
  value
    .replace(SEMICOLON_NOT_IN_VARIABLE_PATTERN, COMMA)
    .split(COMMA_NOT_IN_VARIABLE_PATTERN)
    .filter(Boolean)
    .map(s => s.trim());

export function useEmailField({
  initialValue,
  onChange: onChangeProp,
}: {
  initialValue?: string[];
  onChange: (emails: string[]) => void;
}) {
  const onChangeRef = useFunctionRef(onChangeProp);
  const [value, setValue] = React.useState(initialValue?.join(COMMA) ?? '');

  const onBlur = React.useCallback<React.FocusEventHandler>(() => {
    setValue(sanitizeEmails(value).join(COMMA));
  }, [value]);

  const onChange = React.useCallback(
    (value: string) => {
      setValue(value);
      onChangeRef.current(sanitizeEmails(value));
    },
    [onChangeRef],
  );

  return React.useMemo(() => ({ onBlur, onChange, value, setValue }), [onBlur, onChange, value]);
}
