import {
  Box,
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useBreakpointValue,
  useDisclosure,
  useToast,
  VStack,
} from 'components/design/next';
import * as React from 'react';
import { Breadcrumbs } from 'components/breadcrumbs';
import { TemplateMenuContext } from 'app/features/template/components/template-menu/template-menu';

import { useQueryClient } from 'react-query';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { useGetFocusBarColor } from './use-get-focus-bar-color';
import {
  GetTemplateQuery,
  UpdateTemplateStatusMutation,
  UpdateTemplateStatusMutationParams,
  useGetTemplateQuery,
  useUpdateTemplateStatusMutation,
} from 'features/template/query-builder';
import { useIsPrivateTemplate } from 'hooks/use-is-private-template';
import { useWorkflowState } from './use-workflow-state';
import { useReactQueryReduxSync } from './use-react-query-redux-sync';

import { TemplateMemberList } from 'components/template/membership/components/TemplateMemberList/TemplateMemberList';
import { TemplateStatus } from '@process-street/subgrade/process';
import { match } from 'ts-pattern';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { ViewButtonGroup } from './right-button-group';
import {
  GetNewestTemplateRevisionsByTemplateIdQuery,
  useTemplateRevisionQuery,
} from 'features/template-revisions/query-builder';
import { useTemplateSettingsModalContext } from 'pages/templates/_id/components/template-settings-modal/template-settings-modal-context';
import { FocusBarHStack } from './focus-bar-hstack';
import { ConditionalLogicModalWrapper } from 'pages/templates/_id/components/conditional-logic-modal-wrapper';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { TemplateCoverIcon } from 'features/cover-icon/components/template';
import { GetCoverIconByTemplateId } from 'features/cover-icon/query-builder';
import { BoxProps, Show } from '@chakra-ui/react';
import { TemplateBackButton, useBackButtonContext } from 'components/back-button-provider';
import { TemplateNameEditor } from './template-name-editor';
import { AnalyticsService } from 'components/analytics/analytics.service';
import { EditMiddleButtonGroup } from './middle-button-group/edit';
import { EditButtonGroupV2 } from './right-button-group/edit/edit-button-group-v2';
import { ViewMiddleButtonGroupV2 } from './middle-button-group/view-v2';
import { RunWorkflowButton } from './run-workflow-button';
import { ImportTemplateButton } from './import-template-button';
import { Global } from '@emotion/react';
import { useIsAnonymousUser } from 'app/hooks/use-is-anonymous-user';
import { DeleteTemplateButton } from './delete-template-button';

export const baseCoverIconProps: BoxProps = {
  height: {
    base: 9,
    md: '46px',
  },
  width: {
    base: 9,
    md: '46px',
  },
};

export const FocusBarV2: React.FC<React.PropsWithChildren<unknown>> = () => {
  useReactQueryReduxSync();

  const workflowState = useWorkflowState();
  const editable = workflowState === 'edit';
  const { templateId, templateRevisionId } = useTemplateSettingsModalContext();
  if (!templateRevisionId) {
    throw new Error('Expecting template revision ID for this component.');
  }
  const templateRevisionQuery = useTemplateRevisionQuery({ templateRevisionId });
  const isDashboard = workflowState === 'dashboard';
  const { shouldShowBackButton } = useBackButtonContext();

  const isPrivate = useIsPrivateTemplate(templateId);
  const isGuest = useSelector(SessionSelector.isUserGuestOfSelectedOrganization);

  const isAnonymousUser = useIsAnonymousUser();

  const templateQuery = useGetTemplateQuery({ templateId });
  const template = templateQuery.data;

  const { data: { permissionMap } = {} } = useGetConsolidatedTemplatePermissionsQuery(templateId);

  const getFocusBarColor = useGetFocusBarColor();
  const queryClient = useQueryClient();

  const templateStatus = template?.status ?? TemplateStatus.Active;

  const unarchiveTemplateParams = React.useMemo<UpdateTemplateStatusMutationParams>(
    () => ({ templateId, status: 'Active' }),
    [templateId],
  );

  const toast = useToast();

  const { mutate: unarchiveTemplate } = useUpdateTemplateStatusMutation({
    mutationKey: UpdateTemplateStatusMutation.getKey(unarchiveTemplateParams),
    onSuccess: res => {
      AnalyticsService.trackEvent('template unarchived', {});
      queryClient.setQueryData(GetTemplateQuery.getKey({ templateId }), res);
      void queryClient.refetchQueries(GetNewestTemplateRevisionsByTemplateIdQuery.getKey({ templateId }));
      toast({
        status: 'success',
        title: `This workflow is now active`,
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: `We're having problems activating this workflow`,
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const canArchiveTemplate = permissionMap?.templateUpdate;

  const handleUnarchive = React.useCallback(() => {
    if (canArchiveTemplate) {
      unarchiveTemplate(unarchiveTemplateParams);
    }
  }, [canArchiveTemplate, unarchiveTemplate, unarchiveTemplateParams]);

  const [closeOnBlur, setCloseOnBlur] = React.useState(true);

  const isTemplateMembershipViewable = !(isPrivate || isGuest || editable);

  const templateMenuDisclosure = useDisclosure();
  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  const coverIconQuery = GetCoverIconByTemplateId.useQuery(
    { templateId },
    {
      enabled: Boolean(templateId),
    },
  );

  return (
    <>
      {isAnonymousUser && (
        <Global
          styles={`
          ps-topbar {
            display: none;
          }

          .navbar-offset {
            padding-top: 0 !important;
          }
        `}
        />
      )}

      <FocusBarHStack>
        <HStack w="ful" spacing="4" flex="1">
          {shouldShowBackButton && (
            <HStack>
              <TemplateBackButton />

              <Divider h="10" orientation="vertical" />
            </HStack>
          )}

          <TemplateCoverIcon
            editable={editable}
            templateId={templateId}
            icon={coverIconQuery.data}
            borderWidth="2px"
            imageHeight={isSmallScreen ? 36 : 46}
            imageWidth={isSmallScreen ? 36 : 46}
            emojiFontSize={isSmallScreen ? 'lg' : '26px'}
            ml={0}
            border="none"
            components={{
              triggerButton: (
                <IconButton
                  aria-label="Add icon"
                  borderRadius="full"
                  variant="outline"
                  icon={<Icon icon="plus" color="gray.200" size="4" />}
                  colorScheme="gray"
                  borderWidth="thin"
                  borderColor="gray.200"
                  _hover={{
                    bgColor: 'gray.800',
                  }}
                />
              ),
            }}
            {...baseCoverIconProps}
          />

          {isDashboard && (
            <>
              {coverIconQuery.data ? (
                <TemplateCoverIcon
                  templateId={templateId}
                  icon={coverIconQuery.data}
                  borderWidth="2px"
                  imageHeight={isSmallScreen ? 36 : 46}
                  imageWidth={isSmallScreen ? 36 : 46}
                  emojiFontSize={isSmallScreen ? 'lg' : '26px'}
                  {...baseCoverIconProps}
                />
              ) : (
                <Box
                  borderWidth="0.5"
                  borderColor="gray.100"
                  borderStyle="solid"
                  borderRadius="full"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  {...baseCoverIconProps}
                >
                  {!coverIconQuery.isLoading && (
                    <Icon icon="workflow" variant="fas" size={isSmallScreen ? '3' : '5'} color="indigo.500" />
                  )}
                </Box>
              )}
            </>
          )}

          <VStack minWidth="0" alignItems="flex-start" flex="1" spacing={1}>
            <HStack color={getFocusBarColor({ light: 'gray.600', dark: 'white' })} spacing="2" maxW="90%">
              {!isDashboard && <Icon icon="workflow" variant="fas" size="3" color="indigo.500" />}
              {templateQuery.data && <TemplateNameEditor isReadOnly={!editable} template={templateQuery.data} />}
            </HStack>

            {!isAnonymousUser && <Breadcrumbs getColor={getFocusBarColor} templateId={templateId} />}
          </VStack>
        </HStack>
        {templateRevisionQuery.data && !isSmallScreen && !isAnonymousUser && (
          <Show breakpoint="(min-width: 850px)">
            {editable ? (
              <EditMiddleButtonGroup templateRevision={templateRevisionQuery.data} />
            ) : (
              <ViewMiddleButtonGroupV2 templateRevision={templateRevisionQuery.data} />
            )}
          </Show>
        )}

        {match({ templateStatus, isTemplateMembershipViewable, isAnonymous: isAnonymousUser })
          .with({ isAnonymous: true }, () => {
            const runAriaLabel = `run workflow ${template ? template.name : ''}`;

            if (!template) return null;

            return (
              <HStack spacing="5" justifyContent="flex-end">
                <ImportTemplateButton templateId={template.id} />

                <RunWorkflowButton>
                  <Button aria-label={runAriaLabel} w="full">
                    Run
                  </Button>
                </RunWorkflowButton>
              </HStack>
            );
          })
          .with({ templateStatus: TemplateStatus.Active }, () => (
            <>
              <HStack minWidth="0" justifyContent="flex-end" flex="1">
                <Show breakpoint="(min-width: 950px)">
                  {isTemplateMembershipViewable && <TemplateMemberList templateId={templateId} />}
                </Show>
                {editable ? <EditButtonGroupV2 /> : <ViewButtonGroup templateId={templateId} />}
              </HStack>
            </>
          ))
          .with({ templateStatus: TemplateStatus.Archived }, () => (
            <TemplateMenuContext.Provider value={{ setCloseOnBlur, closeOnBlur, templateId, view: 'show' }}>
              <Menu>
                <MenuButton
                  as={IconButton}
                  icon={<Icon size="4" variant="far" icon="ellipsis-h" />}
                  variant="outline"
                  colorScheme="gray"
                  aria-label="actions"
                />
                <MenuList>
                  <DeleteTemplateButton
                    templateId={templateId}
                    view={'show'}
                    disclosure={{ onOpen: () => setCloseOnBlur(false), onClose: () => setCloseOnBlur(true) }}
                    onDeleted={() => templateMenuDisclosure.onClose()}
                  >
                    {({ isMutating }) => {
                      const icon = isMutating ? (
                        <Icon icon="spinner-third" animation="spin" size="4" variant="far" color="gray.500" />
                      ) : (
                        <Icon icon="trash-alt" size="4" variant="far" color="red.500" />
                      );
                      return (
                        <MenuItem isDisabled={isMutating} color="red.500" icon={icon} iconSpacing="2">
                          Delete
                        </MenuItem>
                      );
                    }}
                  </DeleteTemplateButton>
                </MenuList>
              </Menu>
              <Button variant="secondary" onClick={handleUnarchive}>
                Unarchive
              </Button>
            </TemplateMenuContext.Provider>
          ))
          .otherwise(() => null)}
      </FocusBarHStack>

      {templateRevisionQuery.data && !isDashboard && !isAnonymousUser ? (
        <ConditionalLogicModalWrapper templateRevision={templateRevisionQuery.data} />
      ) : null}
    </>
  );
};
