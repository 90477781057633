import React from 'react';
import { match, P } from 'ts-pattern';
import { useSelector } from 'react-redux';

import { Badge, Text, HStack } from 'components/design/next';
import { SessionSelector } from 'reducers/session/session.selectors';

import { useGetInboxStatsByOrganizationQuery } from 'features/inbox/query-builder';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { useFeatureFlag } from 'app/features/feature-flags';

export const Title = () => {
  const isMyWorkGAEnabled = useFeatureFlag('myWorkGA');
  const selectedOrganization = useSelector(SessionSelector.getSelectedOrganization);

  const userQuery = useGetCurrentUserInfoQuery({
    select: data => data.user,
  });

  const user = userQuery.data;
  const inboxStatsQuery = useGetInboxStatsByOrganizationQuery(
    {
      organizationId: selectedOrganization?.id!,
      userId: user?.id!,
    },
    {
      select: data => ({ count: data.inbox + data.upcoming }),
      enabled: Boolean(user?.id) && Boolean(selectedOrganization?.id),
    },
  );

  return (
    <HStack spacing="2.5">
      <Text fontSize="lg" fontWeight="bold">
        {isMyWorkGAEnabled ? 'My Work' : 'Inbox'}
      </Text>
      {match(inboxStatsQuery)
        .with({ status: 'success', data: { count: P.when(count => count > 0) } }, ({ data }) => (
          <Badge
            color="white"
            bg="brand.500"
            px="1"
            py="0.5"
            borderRadius="base"
            fontWeight="bold"
            lineHeight="short"
            fontSize="xs"
            aria-label="pending tasks"
          >
            {data.count}
          </Badge>
        ))
        .otherwise(() => null)}
    </HStack>
  );
};
