import { match } from 'ts-pattern';

/**
 * Different from the library qs, because it parses into boolean, number, null and undefined, not just string.
 * It doesn't support stringifying nested objects or arrays.
 * */
function stringify(object: Record<string, unknown>): string {
  const searchParams = new URLSearchParams();

  Object.entries(object).forEach(([key, value]) => {
    if (value === undefined) return;

    searchParams.append(key, value === null ? 'null' : value.toString());
  });

  return searchParams.toString();
}

/**
 * Different from the library qs, because it parses into boolean, number, null and undefined, not just string.
 * It doesn't support parsing nested objects or arrays.
 * */
function parse(queryString?: string): Record<string, string | boolean | null | undefined | number> {
  const entries = Array.from(new URLSearchParams(queryString).entries()).map(([key, value]) => {
    const parsedValue = match(value)
      // parse boolean
      .with('true', () => true)
      .with('false', () => false)
      // parse undefined
      .with('undefined', () => undefined)
      // parse null
      .with('null', () => null)
      .otherwise(v => {
        return Number.isNaN(Number(v))
          ? v //parse numbers
          : Number(v);
      });

    return [key, parsedValue];
  });

  return Object.fromEntries(entries);
}

export const queryString = {
  stringify,
  parse,
};
