import angular from 'angular';
import { AnalyticsConstants } from '@process-street/subgrade/analytics';
import { MSTeamsUtils } from 'features/microsoft-teams/utils';
import { CookieService } from 'features/cookies/cookie-service';
import { GoNativeService } from 'features/go-native/go-native-service';
import { IntercomService } from 'services/interop/intercom-service';
import { trace } from 'components/trace';
import { LocalStorageService } from 'features/storage/local-storage-service';
import { SignUpStateService } from 'services/sign-up-state-service';
import { ToastServiceImpl } from 'services/toast-service.impl';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'LoginCtrl',
    function (
      $location,
      $scope,
      $state,
      $timeout,
      $rootScope,
      appBootService,
      Auth0Service,
      auth,
      focusById,
      SessionService,
    ) {
      const ctrl = this;
      const logger = trace({ name: 'LoginCtrl' });
      logger.info('loading ctrl');

      ctrl.$onInit = () => {
        $scope.forms = {};
        $scope.loading = false;

        $scope.info = {
          email: $state.params.email,
        };

        $scope.msTeamsApp = MSTeamsUtils.isEmbeddedInMsTeams();
        if ($scope.msTeamsApp) {
          IntercomService.update({
            hide_default_launcher: true,
          });
        }

        $scope.goNativeApp = GoNativeService.isGoNativeApp();
        if ($scope.goNativeApp) {
          // Clear the tabs just in case they somehow got logged out without hiding them
          GoNativeService.setDefaultTabs({ enabled: false });
        }

        SignUpStateService.saveSource($state.params.source);

        $timeout($scope._autoLoginIfPossible);

        if (!$scope.info.email) {
          focusById('email');
        } else {
          focusById('password');
        }
      };

      $scope.login = function (info) {
        if ($scope.forms.form?.$valid) {
          $scope.saveUrl();

          $scope.submitting = true;

          Auth0Service.login(info.email, info.password, errorMessage => {
            $timeout(() => {
              ToastServiceImpl.openToast({
                status: 'error',
                title: errorMessage,
              });
              $scope.submitting = false;
              $scope.message = 'Ooops. There was an issue logging you in. Please, try again later.';
            });
          });
        } else {
          const messages = [];

          if ($scope.forms.form?.email.$error.required) {
            messages.push('You must enter an email address.');
          }
          if ($scope.forms.form?.email.$error.email) {
            messages.push('You must enter a valid email address.');
          }
          if ($scope.forms.form?.password.$error.required) {
            messages.push('You must enter a password.');
          }
          if ($scope.forms.form?.password.$error.minlength) {
            messages.push('Your password must be at least 6 characters long.');
          }

          const message = messages.join('<br>');
          ToastServiceImpl.openToast({
            status: 'error',
            title: message,
          });
        }
      };

      $scope.loginUsingIDP = providerName => async () => {
        $scope.saveUrl();

        if ($scope.msTeamsApp) {
          const teamsSdk = await import('@microsoft/teams-js');

          teamsSdk.initialize();
          teamsSdk.getContext(context => {
            // We can't call the authentication popup if we are already inside the authentication popup context.
            // This case happens in the Teams mobile app.
            if (context.frameContext === teamsSdk.FrameContexts.authentication) {
              Auth0Service.loginUsingIDP(providerName);
            } else {
              $scope.loading = true;
              $scope.$apply();

              Auth0Service.loginUsingIDPPopup(providerName)
                .then(token => {
                  $scope._bootAppWithToken(token);
                })
                .catch(e => {
                  logger.error('Login via Teams SDK failed.', e);
                  const errorMessage = MSTeamsUtils.getAuthErrorMessage(e);

                  $scope.loading = false;
                  ToastServiceImpl.openToast({
                    status: 'error',
                    title: errorMessage,
                  });
                  $scope.$apply();
                });
            }
          });
        } else {
          Auth0Service.loginUsingIDP(providerName);
        }
      };

      $scope.loginUsingMicrosoft = $scope.loginUsingIDP('microsoft-login');
      $scope.loginUsingGoogle = $scope.loginUsingIDP('google-oauth2');

      $scope.loginUsingSSO = function (info) {
        $scope.saveUrl();

        $state.go('loginSSO', { email: info.email });
      };

      $scope._autoLoginIfPossible = function () {
        if (auth.isLoggedIn()) {
          $scope._redirect();
        } else if (CookieService.hasTokenCookie()) {
          $scope.submitting = true;
          appBootService.setupSessionAndBootUsingCookie().then(
            () => {
              const { templateId } = $state.params;
              const templateIdIsMuid = templateId && templateId.length === 22;
              if (templateIdIsMuid) {
                $state.go('templateAdd', { templateId });
              } else {
                $scope._redirect();
              }
            },
            () => {
              $scope.submitting = false;
            },
          );
        }
      };

      $scope._redirect = function () {
        const { url } = $state.params;
        if (url) {
          logger.info('redirecting to url "%s"', url);
          $location.url(url);
        } else {
          logger.info('redirecting to home');
          if (SessionService.getDefaultHomepage() === 'inbox') {
            $state.go('inbox');
          } else {
            $scope.msTeamsApp ? $state.go('microsoftTeamsInbox') : $state.go('reports');
          }
        }
      };

      $scope._bootAppWithToken = function (token) {
        appBootService.setupSessionAndBootUsingToken(token).then(() => {
          $rootScope.$broadcast(
            AnalyticsConstants.Event.USER_LOGGED_IN,
            SessionService.getUser(),
            SessionService.getProfile(),
          );
          Auth0Service.redirectToStoredUrl();
        });
      };

      $scope.saveUrl = () => {
        if ($state.params.url) {
          LocalStorageService.setItem('url', $state.params.url);
        }
      };
    },
  );
