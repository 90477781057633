import { OrderTreeUtils, TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import {
  QueryKey,
  QueryClient,
  QueryObserver,
  QueryObserverOptions,
  QueryObserverResult,
  useQuery,
  UseQueryOptions,
} from 'react-query';
import { axiosService } from 'services/axios-service';

export type TaskTemplatesByTemplateRevisionIdQueryParams = { templateRevisionId?: TemplateRevision['id'] };

export type TaskTemplatesByTemplateRevisionIdQueryResponse = TaskTemplate[];

export const TaskTemplatesByTemplateRevisionIdQuery = {
  key: ['template-revisions', 'task-templates'],
  getKey: (params: TaskTemplatesByTemplateRevisionIdQueryParams): QueryKey => [
    ...TaskTemplatesByTemplateRevisionIdQuery.key,
    params,
  ],
  queryFn: ({ templateRevisionId }: TaskTemplatesByTemplateRevisionIdQueryParams) =>
    axiosService
      .getAxios()
      .get<TaskTemplatesByTemplateRevisionIdQueryResponse>(`/1/template-revisions/${templateRevisionId}/task-templates`)
      .then(res => res.data),
};

export const useTaskTemplatesByTemplateRevisionIdQuery = <Select = TaskTemplatesByTemplateRevisionIdQueryResponse>(
  params: TaskTemplatesByTemplateRevisionIdQueryParams,
  options: UseQueryOptions<TaskTemplatesByTemplateRevisionIdQueryResponse, AxiosError, Select> = {},
) => {
  return useQuery(
    TaskTemplatesByTemplateRevisionIdQuery.getKey(params),
    () => TaskTemplatesByTemplateRevisionIdQuery.queryFn(params),
    {
      ...options,
      enabled: Boolean(params.templateRevisionId) && options.enabled !== false,
    },
  );
};

export const makeGetTaskTemplatesByTemplateRevisionIdQueryObserver = <
  Select = TaskTemplatesByTemplateRevisionIdQueryResponse,
>({
  queryClient,
  options,
  ...params
}: TaskTemplatesByTemplateRevisionIdQueryParams & {
  queryClient: QueryClient;
  options?: QueryObserverOptions<TaskTemplatesByTemplateRevisionIdQueryResponse, AxiosError, Select>;
}) => {
  return new QueryObserver<TaskTemplatesByTemplateRevisionIdQueryResponse, AxiosError, Select>(queryClient, {
    queryKey: TaskTemplatesByTemplateRevisionIdQuery.getKey(params),
    queryFn: () =>
      TaskTemplatesByTemplateRevisionIdQuery.queryFn(params).then(res =>
        res.sort((a, b) => OrderTreeUtils.compare(a.orderTree, b.orderTree)),
      ),
    enabled: Boolean(params.templateRevisionId) && options?.enabled !== false,
    ...options,
  });
};

export type GetTaskTemplatesByTemplateRevisionIdQueryObserver = QueryObserverResult<
  TaskTemplatesByTemplateRevisionIdQueryResponse,
  AxiosError
>;
