import * as React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Textarea,
  VStack,
  Text,
  Spinner,
} from 'components/design/next';
import { TextareaFormFieldValue, TextareaFormFieldWidget, WidgetUtils } from '@process-street/subgrade/process';
import { UnsavedChangesIcon } from 'components/widgets/form-field/common/unsaved-changes-icon/unsaved-changes-icons';
import { useChecklistTextareaFormFieldWidget } from './use-checklist-textarea-form-field-widget';
import { MaskedInput, MaskedInputParsers } from 'features/widgets/components/masked-input';
import { ChecklistFormFieldWidgetProps } from 'components/widgets/form-field/common/types';
import { FormFieldValueHelperText } from '../form-field-value-helper-text';
import { useWorkflowRunStore } from '../../workflow-run-store';
import { WidgetProvider } from '../../hooks/use-widget-context';
import { match } from 'ts-pattern';
import { TextAreaRichEditor } from 'pages/runs/_id/components/checklist-textarea-form-field-widget/textarea-rich-editor';

export interface ChecklistTextareaFormFieldWidgetProps
  extends ChecklistFormFieldWidgetProps<TextareaFormFieldWidget, TextareaFormFieldValue> {
  onKeyUp?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

export const ChecklistTextareaFormFieldWidget: React.FC<
  React.PropsWithChildren<ChecklistTextareaFormFieldWidgetProps>
> = props => {
  const { formFieldValue, widget, isInvalid, readOnly } = props;
  const { errorMessage, handleReset, value, setValue, textareaProps, aiAutomationStatus, onFocus, onBlur } =
    useChecklistTextareaFormFieldWidget(props);

  const hasDefaultValue = WidgetUtils.hasDefaultValue({ formFieldValue, widget });
  const hasVariables = WidgetUtils.hasVariables(widget.config.defaultValue);
  const maskedInputParser = React.useMemo(
    () =>
      hasDefaultValue && hasVariables
        ? MaskedInputParsers.makeParser('urls', 'merge-tags')
        : MaskedInputParsers.makeParser('urls'),
    [hasDefaultValue, hasVariables],
  );
  const html = React.useMemo(
    () => maskedInputParser(String(textareaProps.value)),
    [maskedInputParser, textareaProps.value],
  );
  const addOutputWidgetClickedDuringAiTask = useWorkflowRunStore(state => state.addOutputWidgetClickedDuringAiTask);

  return (
    <WidgetProvider widget={widget}>
      <FormControl
        as={VStack}
        alignItems="stretch"
        isInvalid={isInvalid || Boolean(errorMessage)}
        isRequired={widget.required}
        isDisabled={aiAutomationStatus === 'AutomationRunning' || readOnly}
      >
        <HStack lineHeight="normal">
          {errorMessage ? <UnsavedChangesIcon /> : null}
          <FormLabel m={0}>{widget.label || 'Untitled Long Text'}</FormLabel>
          {aiAutomationStatus === 'AutomationRunning' && (
            <Spinner color="gray.300" data-testid="native-automation-running-spinner" />
          )}
        </HStack>

        {widget.config.format === 'RichText' ? (
          <TextAreaRichEditor
            hasDefaultValue={hasDefaultValue}
            setValue={setValue}
            editable={props.editable}
            readOnly={readOnly}
            placeholder={textareaProps.placeholder}
            onBlur={onBlur}
            onFocus={onFocus}
            markdownValue={value}
          />
        ) : (
          <MaskedInput
            html={html}
            color={readOnly ? 'gray.400' : undefined}
            onClick={() => {
              if (aiAutomationStatus === 'AutomationRunning') {
                addOutputWidgetClickedDuringAiTask(widget.id);
              }
            }}
            sx={{
              '& textarea': {
                borderColor: readOnly ? 'gray.400' : 'inherit',
              },
            }}
          >
            <Textarea
              {...textareaProps}
              bgColor={match({ hasDefaultValue, readOnly })
                .with({ readOnly: true }, () => 'gray.200')
                .with({ hasDefaultValue: true }, () => 'yellow.100')
                .otherwise(() => undefined)}
            />
          </MaskedInput>
        )}

        <FormFieldValueHelperText
          defaultValue={widget.config.defaultValue}
          formFieldValue={formFieldValue}
          onResetDefaultValue={handleReset}
        />

        {errorMessage ? (
          <FormErrorMessage>
            <Text variant="-2">{errorMessage}</Text>
          </FormErrorMessage>
        ) : null}
      </FormControl>
    </WidgetProvider>
  );
};
