import * as React from 'react';

import { useSelector } from '@xstate/react';
import { TaskListItem, TaskListItemProps } from '../task-list-item';
import { DueDateRuleDefinition, TemplateTaskAssignment } from '@process-street/subgrade/process';
import _groupBy from 'lodash/groupBy';
import _keyBy from 'lodash/keyBy';
import _isEqual from 'lodash/isEqual';
import { TaskTemplateListActorSelectors } from './task-template-list-machine';
import { Option } from 'space-monad';
import _map from 'lodash/fp/map';
import { OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { useTaskTemplateListActorRef } from '../../hooks/use-task-templates-list-actor';
import { Dictionary } from 'lodash';
import { TaskAssignmentRule } from '@process-street/subgrade/role-assignment';
import { Box, MotionWrapper } from 'components/design/next';
import { Reorder, useInView } from 'framer-motion';
import { ReorderAutoScrollContext } from '../../providers/reorder-scroll-context';

type TaskListItemWrapperProps = Omit<
  TaskListItemProps,
  'assignmentRules' | 'organizationMemberships' | 'isSelected' | 'isMultiSelecting'
> & {
  isSelected: boolean;
  dueDateRulesMap: Dictionary<DueDateRuleDefinition>;
  taskAssignmentsMap: Dictionary<TemplateTaskAssignment[]>;
  organizationMembershipsMap: Dictionary<OrganizationMembershipWithUser>;
  taskAssignmentRulesMap: Dictionary<TaskAssignmentRule[]>;
};

const listItemAnimationVariants = {
  initial: {
    opacity: 0,
    y: -10,
  },
  animate: ({ index, skipDelay }: { index: number; skipDelay: boolean }) => {
    return {
      opacity: 1,
      y: 0,
      transition: {
        delay: skipDelay ? 0 : 0.08 * Math.min(index, 10),
      },
    };
  },
};

export const TaskListItemWrapper = React.memo(function TaskListItemWrapper({
  dueDateRulesMap,
  taskAssignmentsMap,
  organizationMembershipsMap,
  taskAssignmentRulesMap,
  ...props
}: TaskListItemWrapperProps) {
  const taskTemplateListActor = useTaskTemplateListActorRef();
  const taskItemRef = React.useRef(null);
  const isVisible = useInView(taskItemRef);
  // Don't apply the animation delay when it's a fresh task template or is generating
  const skipAnimationDelay = Date.now() - props.taskTemplate.audit.createdDate < 1000 || props.isGenerating;

  const dueDateRuleDefinition = React.useMemo(
    () => dueDateRulesMap[props.taskTemplate.group.id],
    [dueDateRulesMap, props.taskTemplate.group.id],
  );

  const organizationMemberships = React.useMemo(
    () =>
      Option(taskAssignmentsMap[props.taskTemplate.id])
        .map(_map(taskAssignment => organizationMembershipsMap[taskAssignment.organizationMembership.id]))
        .getOrElse([]),
    [organizationMembershipsMap, props.taskTemplate.id, taskAssignmentsMap],
  );

  const assignmentRules = React.useMemo(() => {
    return taskAssignmentRulesMap[props.taskTemplate.group.id] ?? [];
  }, [props.taskTemplate.group.id, taskAssignmentRulesMap]);

  const isMultiSelecting = useSelector(taskTemplateListActor, TaskTemplateListActorSelectors.isMultiSelecting);
  const reorderItemProps = React.useContext(ReorderAutoScrollContext)?.taskAutoScroll?.reorderItemProps;

  const isReorderEnabled = !props.isReadOnly && !props.isGenerating;

  return (
    <Box ref={taskItemRef} width="full" height={10}>
      <MotionWrapper
        key={props.taskTemplate.id}
        variants={listItemAnimationVariants}
        initial="initial"
        animate="animate"
        custom={{ index: props.stepNumber - 1, skipDelay: skipAnimationDelay }}
      >
        <Box
          // if it was HStack, we'd get typing issues about onDrag
          display="flex"
          value={props.taskTemplate}
          w="full"
          {...(isReorderEnabled
            ? {
                as: Reorder.Item,
                ...reorderItemProps,
              }
            : {})}
        >
          <>
            {isVisible && (
              <TaskListItem
                assignmentRules={assignmentRules}
                dueDateRuleDefinition={dueDateRuleDefinition}
                organizationMemberships={organizationMemberships}
                isMultiSelecting={isMultiSelecting}
                {...props}
              />
            )}
          </>
        </Box>
      </MotionWrapper>
    </Box>
  );
});
