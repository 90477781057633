import * as React from 'react';
import { Box, Checkbox, Divider, Flex, HStack, Heading, Icon, Text } from 'components/design/next';
import { useFeatureFlag } from 'features/feature-flags';
import { TaskStatus } from '@process-street/subgrade/process';
import { DateUtils, DateFormat } from '@process-street/subgrade/util';
import { useCurrentUser } from 'hooks/use-current-user';
import { AssigneeList } from './assignee-list';
import { AttachmentsList } from '../shared/attachments';
import { PrintComments } from './print-comments';
import DOMPurify from 'dompurify';
import { SubTasks } from '../sub-tasks';
import { OneOffTask } from '@process-street/subgrade/one-off-task';
import { PrintActivity } from './print-activity';
import { PrintOneOffTaskAudit } from './print-one-off-task-audit';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';

type OneOffTaskItemProps = {
  task: OneOffTask;
};

export const OneOffTaskItem = ({ task }: OneOffTaskItemProps) => {
  const currentOrganizationId = useSelector(SessionSelector.getSelectedOrganizationId);
  const currentUser = useCurrentUser();
  const isTasksGAEnabled = useFeatureFlag('tasksGA');

  const usersMapQuery = useGetAllOrganizationMembershipsQuery(
    {
      organizationId: currentOrganizationId,
    },
    {
      enabled: Boolean(currentOrganizationId),
      select: data => {
        return Object.fromEntries(data.map(item => [item.user.id, item.user]));
      },
    },
  );

  const getSanitizedDescription = (description: string) => {
    return DOMPurify.sanitize(description);
  };

  return (
    <Box mb={10}>
      <HStack mb={4} justifyContent="space-between">
        <HStack w="full" gap={1}>
          <Checkbox defaultChecked={task.status === TaskStatus.Completed} isReadOnly />
          <Heading fontSize="lg" fontWeight="normal">
            {task.name}
          </Heading>
        </HStack>

        <PrintOneOffTaskAudit task={task} usersMap={usersMapQuery.data ?? {}} />
      </HStack>
      <Divider />
      {task.dueDate && (
        <Flex alignItems="center" mt={4}>
          <Icon icon="clock" size="4" />
          <Text as="span" fontSize="xs" ml="2">
            {DateUtils.formatDateTime(
              task.dueDate,
              DateFormat.DateTimeLongWeekdayShortMonthWithZone,
              currentUser?.timeZone,
            )}
          </Text>
        </Flex>
      )}
      {task.assignments && <AssigneeList assignments={task.assignments} />}
      {isTasksGAEnabled ? (
        <>
          <Box mt={8} dangerouslySetInnerHTML={{ __html: getSanitizedDescription(task.description) }} />
          <SubTasks task={task} isPrintView />
          <PrintActivity task={task} />
        </>
      ) : (
        <Text mt="8" mb={`var(--ps-space-8) !important`} fontSize="sm">
          {task.description}
        </Text>
      )}
      <AttachmentsList task={task} />
      <PrintComments task={task} />
    </Box>
  );
};
