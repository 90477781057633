import { Box, HStack, Icon, Switch, Text, Tooltip } from 'components/design/next';
import * as React from 'react';
import { TemplateSettingsSchema } from '../schema';

export type ChecklistRequestApprovalProps = {
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: TemplateSettingsSchema;
};

const ARROW_SIZE = 10;

export const ChecklistRequestApproval: React.FC<React.PropsWithChildren<ChecklistRequestApprovalProps>> = ({
  handleChange,
  values,
}) => {
  return (
    <HStack>
      <Box position="relative">
        <Switch
          name="checklistRequestApprovalEnabled"
          isChecked={values.checklistRequestApprovalEnabled}
          onChange={handleChange}
          size="lg"
          spacing="2"
          display="flex"
          alignItems="center"
        >
          <Text fontWeight="normal" color="gray.600" as="span">
            Require review for workflow changes
          </Text>
        </Switch>
      </Box>
      <Tooltip label="All workflow changes will need approval." hasArrow arrowSize={ARROW_SIZE} shouldWrapChildren>
        <Icon icon="circle-info" variant="far" size="4" color="gray.500" />
      </Tooltip>
    </HStack>
  );
};
