import angular from 'angular';
import { InboxConstants } from '@process-street/subgrade/inbox/inbox-constants';
import { PseudoGroup } from 'services/group-constants';
import { htmlEscaped } from '@process-street/subgrade/util';
import { inboxStatsSelector } from 'reducers/stats/stats.selectors';
import { createSelector } from 'reselect';
import { bindActionCreatorsToActions } from 'reducers/util';
import { isGroupUserOm, isStandardUserOrStandardGroupOm } from '@process-street/subgrade/util/membership-utils';
import templateUrl from './filter-bar.component.html';
import './filter-bar.scss';
import './hamburger-menu.scss';
import { queryClient } from 'components/react-root';
import { GetUnreadCommentsCountQuery } from 'features/comments/query-builder';
import { QueryObserver } from 'react-query';

angular.module('frontStreetApp.directives').component('psInboxFilterBar', {
  bindings: {
    user: '<',
    organization: '<',
  },
  require: {
    inboxCtrl: '^psInbox',
  },
  templateUrl,
  controller(
    $ngRedux,
    $scope,
    $state,
    GroupService,
    OrganizationMembershipService,
    SessionService,
    SyncService,
    InboxActions,
  ) {
    const ctrl = this;
    ctrl.initialized = false;

    ctrl.mobileFilterMenuOpen = false;
    ctrl.hiddenPseudoGroups = [PseudoGroup.EVERYONE, PseudoGroup.UNASSIGNED];

    ctrl.Group = InboxConstants.Group;
    ctrl.Filter = InboxConstants.Filter;

    ctrl.availableGroups = {};
    ctrl.availableGroups[ctrl.Group.DUE_DATE] = 'Due Date';
    ctrl.availableGroups[ctrl.Group.PROCESS] = 'Process';

    ctrl.availableFilters = {};
    ctrl.availableFilters[ctrl.Filter.INBOX] = 'Inbox';
    ctrl.availableFilters[ctrl.Filter.UPCOMING] = 'Upcoming';
    ctrl.availableFilters[ctrl.Filter.COMMENTS] = 'Comments';

    ctrl.popBoxComponent = undefined;

    ctrl.$scope = $scope;

    ctrl.unreadCommentsCount = 0;

    ctrl.subscribeToInboxStats = () => {
      if (ctrl.unsubscribeFromInboxStats) {
        ctrl.unsubscribeFromInboxStats();
      }
      const organizationId = ctrl.organization.id;
      const userId = ctrl.selectedUser.id;

      const mapStateToCtrl = createSelector([inboxStatsSelector], inbox => {
        const allUserStats = inbox[userId] || {};
        const orgUserStats = allUserStats[organizationId] || {};

        return {
          stats: orgUserStats,
          shouldShowComments: ctrl.selectedUser.id === ctrl.user.id,
        };
      });
      const mapDispatchToCtrl = bindActionCreatorsToActions(InboxActions);

      ctrl.unsubscribeFromInboxStats = $ngRedux.connect(mapStateToCtrl, mapDispatchToCtrl)(ctrl);

      ctrl.actions.getInboxStatsByOrganization(organizationId, userId, ctrl.itemsType, true /* flushCache */);

      const observer = new QueryObserver(queryClient, {
        queryKey: GetUnreadCommentsCountQuery.getKey(),
        queryFn: () => GetUnreadCommentsCountQuery.queryFn(),
      });

      ctrl.unsubscribeFromReactQuery = observer.subscribe(result => {
        if (result.status === 'success' && result.data?.count !== ctrl.unreadCommentsCount) {
          $scope.$evalAsync(() => {
            ctrl.unreadCommentsCount = result.data.count;
          });
        }
      });
    };

    ctrl.$onInit = function () {
      initializeFilterObject();
      loadOrganizationUsers();
      initializePermissions().then(() => {
        ctrl.initialized = true;
        ctrl.applyFilters(true);
      });

      ctrl.subscribeToInboxStats();
    };

    ctrl.$onDestroy = function () {
      if (ctrl.unsubscribeFromInboxStats) {
        ctrl.unsubscribeFromInboxStats();
      }

      if (ctrl.unsubscribeFromReactQuery) {
        ctrl.unsubscribeFromReactQuery();
      }
    };

    ctrl.toggleMobileFilterMenu = function () {
      ctrl.mobileFilterMenuOpen = !ctrl.mobileFilterMenuOpen;
    };

    ctrl.getGroupStatusTitle = function () {
      return `By ${ctrl.availableGroups[ctrl.filterMap.group]}`;
    };

    ctrl.getFilterTitle = function () {
      return ctrl.availableFilters[ctrl.filterMap.filter];
    };

    ctrl.selectGroup = function (group) {
      ctrl.filterMap.group = group;
      ctrl.applyFilters();
    };

    ctrl.selectFilter = function (filter) {
      ctrl.toggleMobileFilterMenu();
      $state.go('inbox', { tab: filter });
    };

    $scope.$on('$stateChangeSuccess', (__event, __toState, toParams) => {
      if ($state.includes('inbox')) {
        ctrl.filterMap.filter = toParams.tab;
        ctrl.applyFilters();
      }
    });

    ctrl.getAssigneeTitle = function () {
      if (ctrl.filterMap.pseudoGroup) {
        return htmlEscaped`Assigned to ${ctrl.filterMap.pseudoGroup}`;
      } else {
        const name = ctrl.selectedUser.id === ctrl.user.id ? 'Me' : ctrl.selectedUser.username;
        return htmlEscaped`Assigned to ${name}`;
      }
    };

    ctrl.onItemTypeSelect = function (type) {
      SessionService.setInboxProperty('itemTypeFilter', type);
      SyncService.pushInboxProperties();
      ctrl.itemsType = type;
      ctrl.filterMap.itemsType = type;
      ctrl.applyFilters();
      ctrl.subscribeToInboxStats();
      ctrl.toggleMobileFilterMenu();
    };

    ctrl.onSelectUser = function (user, pseudoGroup) {
      ctrl.selectedUser = user;
      ctrl.selectedEmails = ctrl.selectedUser ? [ctrl.selectedUser.email] : [];
      ctrl.selectedPseudoGroup = pseudoGroup;
      ctrl.filterMap.userId = ctrl.selectedUser ? ctrl.selectedUser.id : null;
      ctrl.filterMap.pseudoGroup = pseudoGroup;
      ctrl.applyFilters();
      ctrl.subscribeToInboxStats();
      ctrl.toggleMobileFilterMenu();
      ctrl.setShowMobileAssigneesPopBox(false);
    };

    ctrl.clearSearch = function () {
      ctrl.searchTerm = '';
      ctrl.updateSearchTerm('');
    };

    ctrl.updateSearchTerm = function (term) {
      ctrl.filterMap.search = term;
      ctrl.applyFilters();
    };

    ctrl.applyFilters = function (isInitialLoad = false) {
      ctrl.inboxCtrl.applyFilter(ctrl.filterMap, isInitialLoad);
    };

    ctrl.extractUsersAndMergeStats = function (memberships, groupStats) {
      ctrl.users = memberships
        .filter(om => isStandardUserOrStandardGroupOm(om))
        .map(membership => {
          const { user } = membership;
          user.guest = membership.guest;

          if (isGroupUserOm(membership) && groupStats) {
            user.groupStats = groupStats.find(item => item.id === user.id);
          }
          return user;
        });
    };

    ctrl.getInboxStatsTitle = function () {
      return ctrl.stats && ctrl.stats.inbox > 0 ? ` (${ctrl.stats.inbox})` : '';
    };

    ctrl.getUpcomingStatsTitle = function () {
      return ctrl.stats && ctrl.stats.upcoming > 0 ? ` (${ctrl.stats.upcoming})` : '';
    };

    ctrl.getUnreadCommentsCount = function () {
      return ctrl.unreadCommentsCount > 0 ? ` (${ctrl.unreadCommentsCount})` : '';
    };

    ctrl.shouldShowAssigneesSelector = function () {
      return ctrl.initialized && !ctrl.isGuest;
    };

    ctrl.registerPopBox = function (popBox) {
      ctrl.popBoxComponent = popBox;
    };

    ctrl.setAssigneesPopBoxInitialized = function () {
      ctrl.assigneesPopBoxInitialized = true;
    };

    ctrl.setShowMobileAssigneesPopBox = function (state) {
      ctrl.showMobileAssigneesPopBox = state;
    };

    ctrl.shouldShowFilters = () => ctrl.filterMap.filter !== ctrl.Filter.COMMENTS;

    function initializePermissions() {
      return OrganizationMembershipService.isGuestByUserIdAndOrganizationId(ctrl.user.id, ctrl.organization.id).then(
        isGuest => {
          ctrl.isGuest = isGuest;
        },
      );
    }

    function initializeFilterObject() {
      ctrl.searchTerm = $state.params.searchTerm ?? '';
      ctrl.selectedUser = ctrl.user;
      ctrl.selectedEmails = [ctrl.selectedUser.email];

      ctrl.itemsType = SessionService.getInboxProperty('itemTypeFilter');

      const filter = $state.params.tab || Object.keys(ctrl.availableFilters)[0];

      ctrl.filterMap = {
        search: ctrl.searchTerm,
        group: Object.keys(ctrl.availableGroups)[0],
        filter,
        userId: ctrl.selectedUser.id,
        itemsType: ctrl.itemsType,
      };
    }

    function loadOrganizationUsers() {
      OrganizationMembershipService.getAllByOrganizationId(ctrl.organization.id).then(memberships => {
        GroupService.getGroupStatsByOrganizationId(ctrl.organization.id)
          .then(({ payload: groupStats }) => ctrl.extractUsersAndMergeStats(memberships, groupStats))
          .catch(() => ctrl.extractUsersAndMergeStats(memberships));
      });
    }
  },
});
