import * as React from 'react';
import { Box } from 'components/design/next';
import { TableContentActor } from './table-content-widget-machine';
import { useActor } from '@xstate/react';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { WidgetActorProvider } from 'pages/forms/_id/shared/widget-context';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { ContentFieldRecentlyMovedIndicator } from '../common/content-field-recently-moved-indicator';
import { TableEditor } from './components/table-editor';

export const DEFAULT_TABLE_CONTENT =
  '<table><thead><tr><th><p><strong></strong></p></th><th><p><strong></strong></p></th></tr></thead><tbody><tr><td><p></p></td><td><p></p></td></tr><tr><td><p></p></td><td><p></p></td></tr></tbody></table>';
export interface TableContentWidgetProps {
  actor: TableContentActor;
  isFirst?: boolean;
  isLast?: boolean;
}

export const TableContentWidget: React.FC<React.PropsWithChildren<TableContentWidgetProps>> = ({
  actor,
  isFirst = false,
  isLast = false,
}) => {
  const [state, send] = useActor(actor);
  const { widget, recentlyMovedFrom, isReadOnly } = state.context;

  if (!widget) return null;
  const initialValue = state.context.widget.content;
  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer alignItems="flex-start" fontSize="md">
        {recentlyMovedFrom && <ContentFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
        {isReadOnly ? (
          <Box
            dangerouslySetInnerHTML={{ __html: initialValue ?? '' }}
            sx={{
              'table': { width: 'full' },
              'th': { backgroundColor: 'brand.50' },
              'th, td': { border: '1px solid var(--ps-colors-gray-200)', padding: '2!important' },
            }}
          />
        ) : (
          <>
            <Box w="full">
              <TableEditor
                widgetId={widget.id}
                value={initialValue}
                onChange={content => send({ type: 'CHANGE', content })}
                onFocus={() => send('FOCUS')}
              />
            </Box>
            <WidgetListItemDragIcon />
            <FormsWidgetMenu>
              <FormsWidgetMenuItems.Duplicate />
              <FormsWidgetMenuItems.MoveToStep widget={widget} />
              <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
              <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
              <FormsWidgetMenuItems.Delete />
            </FormsWidgetMenu>
          </>
        )}
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};
