import * as React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Text,
} from 'components/design/next';
import { useField, useFormikContext } from 'formik';
import { formikMetaToFormControlProps } from '../common/utils';
import { FieldType, FormFieldWidget, TemplateRevision, WidgetUtils } from '@process-street/subgrade/process';
import { MergeTagsMenu, MergeTagsMenuButton } from 'app/features/merge-tags/components/merge-tags-menu';
import { useMergeTaggableInput } from 'app/hooks/use-merge-taggable-input';
import { MergeTagsConstants, MergeTagTarget } from '@process-street/subgrade/form';
import { TextSettingsSchema } from '../../../../text-form-field/text-form-field-schema';
import { urlService } from 'app/services/url-service';

export type DefaultValueFieldProps = React.PropsWithChildren<
  InputProps & {
    templateRevisionId: TemplateRevision['id'];
    widget?: FormFieldWidget;
    label?: string;
    mergeTagTarget?: MergeTagTarget;
  }
>;

export const DefaultValueField = ({
  templateRevisionId,
  widget,
  label = 'Default answer',
  mergeTagTarget,
  ...props
}: DefaultValueFieldProps) => {
  const [{ onChange: _, ...field }, meta] = useField<string | undefined>(DefaultValueFieldName);
  const { values, setValues } = useFormikContext<TextSettingsSchema>();
  const hasVariables = WidgetUtils.hasVariables(field.value);

  const { ref: defaultValueRef, insertMergeTag: insertVariable } = useMergeTaggableInput<HTMLInputElement>({
    get: () => field.value ?? '',
    set: value => {
      setValues({
        ...values,
        config: {
          ...values.config,
          defaultValue: value,
        },
        hasVariables: WidgetUtils.hasVariables(value),
      });
    },
  });

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (widget?.fieldType === FieldType.Url) {
      const value = e.target.value.trim();
      const hasVariables = WidgetUtils.hasVariables(value);
      const normalizedValue = hasVariables ? value : urlService.addHttp(value);
      setValues({
        ...values,
        config: {
          ...values.config,
          defaultValue: normalizedValue,
        },
      });
    }

    field.onBlur(e);
  };

  return (
    <FormControl {...formikMetaToFormControlProps(meta)}>
      <FormLabel>
        <Text color="gray.700">{label}</Text>
      </FormLabel>
      <InputGroup>
        <Input
          {...field}
          value={field.value ?? ''}
          ref={defaultValueRef}
          aria-label="optional default answer"
          placeholder="Type default answer here"
          variant="outline"
          {...props}
          onChange={e => {
            const { value } = e.target;
            setValues({
              ...values,
              config: {
                ...values.config,
                defaultValue: value,
              },
              hasVariables,
            });
            props.onChange?.(e);
          }}
          onBlur={handleOnBlur}
        />
        <InputRightElement _focusWithin={{ zIndex: 3 }}>
          <MergeTagsMenu
            {...{
              templateRevisionId,
              onSelect: (key, _fieldId, fallback) => insertVariable(key, fallback),
              mergeTagTarget: mergeTagTarget ?? MergeTagsConstants.Target.GENERAL,
              menuButton: <MergeTagsMenuButton size="sm" bg="white" />,
            }}
          />
        </InputRightElement>
      </InputGroup>
      {hasVariables ? <FormHelperText>Validation disabled due to {'{{ variables }}'}</FormHelperText> : null}
      {meta.error ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
    </FormControl>
  );
};

export const DefaultValueFieldName = 'config.defaultValue';
export const DefaultValueHasVariablesFieldName = 'config.hasVariables';
