import * as React from 'react';
import { AutomationEmptyState } from 'pages/templates/_id/automation/components/empty-state';
import { AnimatePresence, motion } from 'framer-motion';
import { Box } from 'components/design/next';
import { ConfigWizardModal } from '../../_id/config-wizard/modal';
import { useConfigWizardDisclosure } from 'pages/templates/_id/automation/_id/config-wizard/context';
import { AutomationEditor } from '../editor';
import { AutomationInstanceUtils, isTemplateSolutionInstance } from '@process-street/subgrade/automation';
import { useGetAutomationInstanceQuery } from '../../utils/use-get-automation-instance';
import { useAutomationSelector } from '../selector/context';

const slider = {
  style: {
    width: '100%',
  },
  initial: {
    x: '-10%',
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
  },
  exit: { x: '-10%', opacity: 0 },
  transition: { mass: 0.5, damping: 10, stiffness: 100, type: 'spring' },
};

export const AutomationDetailsContainer: React.VFC = () => {
  const [state] = useAutomationSelector();
  const {
    solutionTypeTag: selectedSolutionType,
    automationInstanceId: selectedAutomationInstanceId,
    automationType: selectedAutomationType,
  } = state.context;
  const selectedAutomationInstanceQuery = useGetAutomationInstanceQuery({
    automationInstanceId: selectedAutomationInstanceId,
    automationInstanceType: selectedAutomationType,
  });
  const selectedAutomationInstance = selectedAutomationInstanceQuery.data;

  const configWizardModalDisclosure = useConfigWizardDisclosure();

  if (!selectedSolutionType && !selectedAutomationInstance) {
    return <AutomationEmptyState />;
  }

  return (
    <Box height="100%">
      <AnimatePresence exitBeforeEnter={true}>
        <motion.div
          {...slider}
          key={selectedAutomationInstance && AutomationInstanceUtils.getInstanceId(selectedAutomationInstance)}
        >
          <AutomationEditor />
        </motion.div>
      </AnimatePresence>
      {isTemplateSolutionInstance(selectedAutomationInstance) ? (
        <ConfigWizardModal {...selectedAutomationInstance} {...configWizardModalDisclosure} />
      ) : null}
    </Box>
  );
};
