import * as React from 'react';
import { Box, BoxProps } from 'components/design/next';
import { FormatBlockType } from 'directives/text-widget-toolbar/toolbar-blocks';
import 'tinymce-6/tinymce';
import 'tinymce-6/icons/default';
import 'tinymce-6/models/dom';
import 'tinymce-6/themes/silver';
import 'tinymce-6/plugins/autolink';
import 'tinymce-6/plugins/link';
import 'tinymce-6/plugins/lists';
import 'tinymce-6/plugins/table';
import 'tinymce-6/skins/ui/oxide/skin.min.css';
import 'tinymce-6/skins/ui/oxide/content.min.css';
import 'tinymce-6/skins/content/default/content.min.css';
import tinyMCE from 'tinymce-6/tinymce';
import { Editor, IAllProps } from '@tinymce/tinymce-react';
import { useEffectOnce, useToggle } from 'react-use';

export interface RichTextEditorProps {
  editorProps?: Omit<IAllProps, 'init'>;
  init?: IAllProps['init'];
  editorWrapperProps?: Partial<BoxProps>;
}

export type TinyMCEEditor = NonNullable<InstanceType<typeof Editor>['editor']>;

export const PASTE_FROM_WORD_PLUGIN_URL =
  'https://unpkg.com/@pangaeatech/tinymce-paste-from-word-plugin@latest/index.js';

export const RichTextEditor: React.FC<React.PropsWithChildren<RichTextEditorProps>> = ({
  editorProps,
  init,
  editorWrapperProps,
}) => {
  const [loaded, toggle] = useToggle(false);
  useEffectOnce(() => {
    try {
      tinyMCE.PluginManager.load('paste_from_word', PASTE_FROM_WORD_PLUGIN_URL);
      tinyMCE.addI18n('en', {
        'Advanced.LinkTitle': 'Tooltip',
        'LinkTitle': 'Tooltip',
        'Title': 'Tooltip',
        'link.title': 'Tooltip',
        'link.form.title.label': 'Tooltip',
      });
      toggle(true);
    } catch (_error) {
      console.error(`Url for plugin not found: ${PASTE_FROM_WORD_PLUGIN_URL}`);
    }
  });

  if (!loaded) return null; // needed since we're calling the plugin manager in the useEffectOnce before using the plugin

  return (
    <Box
      {...editorWrapperProps}
      sx={{
        '.mce-content-body': {
          border: 'none',
          outline: 'none',
          // a little trick to make sure the cursor is visible at the beginning of the line
          mx: -1,
          px: 1,
          py: 2,
        },
        '.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before': {
          fontStyle: 'italic',
          pl: '1',
        },
        ...editorWrapperProps?.sx,
      }}
    >
      <Editor inline={true} init={{ ...INIT, ...init }} {...editorProps} />
    </Box>
  );
};

const INIT: IAllProps['init'] = {
  menubar: false,
  toolbar: [
    ['bold', 'underline', 'italic'],
    ['blocks'],
    ['alignleft', 'aligncenter', 'alignright', 'alignjustify'],
    ['bullist', 'numlist'],
  ]
    .map(section => section.join(' '))
    .join(' '),
  browser_spellcheck: true,
  entity_encoding: 'raw',
  plugins: 'autolink link lists table paste_from_word',
  link_default_target: '_blank',
  valid_elements:
    'a[href|rel|target|title],strong/b,em/i,u,p[class|style],ul,ol,li[style],s,' +
    'br,hr,h1[style],h2[style],h3[style],h4[style],h5[style],h6[style],pre,code,span[style],' +
    'table[border|cellpadding|cellspacing|style],thead,tbody,tr,td[colspan|rowspan|style],th[colspan|rowspan|style]',
  valid_classes: Object.values(FormatBlockType).join(' '),
  valid_styles: {
    li: 'text-align',
    p: 'padding-left,text-align',
    span: 'background-color,color,text-decoration',
    h1: 'text-align',
    h2: 'text-align',
    h3: 'text-align',
    h4: 'text-align',
    h5: 'text-align',
    h6: 'text-align',
    table: 'width,text-align,text-decoration',
  },
  paste_auto_cleanup_on_paste: true,
  fix_list_elements: true,
  convert_urls: false,
  link_assume_external_targets: true,
  placeholder: 'Write something here...',
  toolbar_mode: 'scrolling', // https://www.tiny.cloud/docs/tinymce/6/toolbar-configuration-options/#toolbar
  paste_webkit_styles: 'all',
  paste_remove_styles_if_webkit: false,
};
