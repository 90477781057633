import angular from 'angular';
import { isStandardUserOrStandardGroupOm } from '@process-street/subgrade/util/membership-utils';
import templateUrl from './toolbar.component.html';
import './toolbar.scss';

angular.module('frontStreetApp.directives').component('psInboxToolbar', {
  bindings: {
    user: '<',
    organization: '<',
  },
  require: {
    inboxCtrl: '^psInbox',
  },
  templateUrl,
  controller(OrganizationMembershipService) {
    const ctrl = this;

    ctrl.$onInit = function () {
      ctrl.loadOrganizationUsers();
      ctrl.defaultDueDate = Date.now();
    };

    ctrl.areAllSelected = function () {
      return ctrl.inboxCtrl.areAllSelected();
    };

    ctrl.toggleSelectAll = function () {
      if (ctrl.areAllSelected()) {
        ctrl.inboxCtrl.unselectAll();
      } else {
        ctrl.inboxCtrl.selectAll();
      }
    };

    ctrl.countSelected = function () {
      return ctrl.inboxCtrl.countSelected();
    };

    ctrl.completeAll = function () {
      return ctrl.inboxCtrl.completeAll();
    };

    ctrl.updateAllDueDates = function (dueDate) {
      return ctrl.inboxCtrl.updateAllDueDates(dueDate);
    };

    ctrl.removeAllDueDates = function () {
      return ctrl.inboxCtrl.removeAllDueDates();
    };

    ctrl.closeToolbar = function () {
      ctrl.inboxCtrl.unselectAll();
    };

    ctrl.loadOrganizationUsers = () => {
      OrganizationMembershipService.getAllByOrganizationId(ctrl.organization.id).then(memberships => {
        ctrl.users = memberships
          .filter(membership => isStandardUserOrStandardGroupOm(membership))
          .map(membership => membership.user);
      });
    };
  },
});
