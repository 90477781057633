import { Button, Flex, Toast } from '@chakra-ui/react';
import { useSelector } from '@xstate/react';
import { FormResponseMachineSelectors } from 'app/pages/responses/_id/components/form-response-body/form-response-machine-selectors';
import { FormMachineUtils } from 'app/pages/responses/_id/components/form-response-body/form-response-machine-utils';
import * as React from 'react';
import { FormResponsePageProviders } from '../../providers';

export const TaskFooter = () => {
  const formResponseActor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
  const taskActorsMap = useSelector(formResponseActor, FormResponseMachineSelectors.getTaskActorsMap);
  const currentTaskActor = useSelector(formResponseActor, FormResponseMachineSelectors.getCurrentTaskActor);

  const isCompletingCurrentTask = useSelector(
    formResponseActor,
    FormResponseMachineSelectors.getIsCompletingCurrentTask,
  );
  const isComplete = useSelector(formResponseActor, FormResponseMachineSelectors.getIsComplete);

  const taskIsInvalid = currentTaskActor?.getSnapshot()?.matches('validation.invalid.visible');

  const hasMoreTasks = FormMachineUtils.hasMoreTasks(taskActorsMap, currentTaskActor);

  const isNextButtonVisible = hasMoreTasks || !isComplete;
  const isSkipButtonVisible = hasMoreTasks && !isComplete;
  const nextButtonText = hasMoreTasks ? 'Complete' : 'Finish';

  const handleNextClick = React.useCallback(() => {
    const isInvalid = formResponseActor
      .getSnapshot()
      ?.context.currentTaskActor?.getSnapshot()
      ?.matches('validation.invalid.hidden');

    if (isInvalid) {
      Toast({
        title: `We couldn't complete the step`,
        description: `Oops! Some form fields still need to be completed.`,
        status: 'warning',
        isClosable: true,
      });
    }
    formResponseActor.send({ type: 'NEXT_TASK' });
  }, [formResponseActor]);

  const handleSkip = React.useCallback(() => {
    formResponseActor.send({ type: 'SKIP_TASK' });
  }, [formResponseActor]);

  return (
    <Flex direction="row" w="full" justifyContent="flex-end" gap={2} pt="6">
      {isSkipButtonVisible && (
        <Button
          variant="ghost"
          fontWeight="500"
          colorScheme="gray"
          onClick={handleSkip}
          _hover={{ bgColor: 'transparent' }}
        >
          Skip
        </Button>
      )}
      {isNextButtonVisible ? (
        <Button
          isLoading={isCompletingCurrentTask}
          loadingText={nextButtonText}
          onClick={handleNextClick}
          isDisabled={taskIsInvalid}
        >
          {nextButtonText}
        </Button>
      ) : null}
    </Flex>
  );
};
