import { Box, HStack, Icon, Switch, Text, Tooltip } from 'components/design/next';
import { PaidPlanRequiredPopover } from 'features/ui/popovers/paid-plan-required/paid-plan-required-popover';
import * as React from 'react';
import { TemplateSettingsSchema } from '../schema';
import { useGetIsPaidAccount } from 'utils/plans/get-is-paid-account/use-get-is-paid-account';

export type ChecklistShareLinksProps = {
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: TemplateSettingsSchema;
};

const ARROW_SIZE = 10;

export const ChecklistShareLinks: React.FC<React.PropsWithChildren<ChecklistShareLinksProps>> = ({
  handleChange,
  values,
}) => {
  const isPaidAccount = useGetIsPaidAccount();

  return (
    <HStack>
      <Box position="relative">
        <Switch
          isDisabled={!isPaidAccount}
          name="checklistShareLinkEnabled"
          isChecked={values.checklistShareLinkEnabled}
          onChange={handleChange}
          size="lg"
          spacing="2"
          display="flex"
          alignItems="center"
        >
          <Text fontWeight="normal" color="gray.600" as="span">
            Workflow Run Share Links
          </Text>
        </Switch>
        {!isPaidAccount && <PaidPlanRequiredPopover needPaidFor="modifyPermission" />}
      </Box>
      <Tooltip label="Turn on share links for new workflow runs" hasArrow arrowSize={ARROW_SIZE} shouldWrapChildren>
        <Icon icon="circle-info" variant="far" size="4" color="gray.500" />
      </Tooltip>
    </HStack>
  );
};
