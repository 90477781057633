import { FormResponsePageProviders } from 'app/pages/checklists/_id/providers';
import { FormResponsePageMachineSelectors } from './form-response-page-selectors';
import { useSelector } from '@xstate/react';
import { Muid } from '@process-street/subgrade/core';
import { useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { TaskTemplate } from '@process-street/subgrade/process';

export namespace FormResponsePageMachineHooks {
  export const useTasks = () => {
    const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();
    const tasks = useSelector(actor, FormResponsePageMachineSelectors.getTasks);

    return tasks;
  };

  export const useOneOffTasks = () => {
    const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();
    const oneOffTasks = useSelector(actor, FormResponsePageMachineSelectors.getOneOffTasks);

    return oneOffTasks;
  };

  export const useChecklist = () => {
    const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();
    const checklist = useSelector(actor, FormResponsePageMachineSelectors.getChecklist);

    return checklist;
  };

  export const useChecklistRevision = () => {
    const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();
    const checklistRevision = useSelector(actor, FormResponsePageMachineSelectors.getChecklistRevision);

    return checklistRevision;
  };

  export const useTaskAssignments = (taskId: Muid) => {
    const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();
    const selector = useMemo(() => FormResponsePageMachineSelectors.getTaskAssignments(taskId), [taskId]);

    return useSelector(actor, selector, isEqual);
  };

  export const useDueDateRules = () => {
    const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();

    return useSelector(actor, FormResponsePageMachineSelectors.getDueDateRules);
  };

  export const useTaskTemplates = () => {
    const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();

    return useSelector(actor, FormResponsePageMachineSelectors.getTaskTemplates);
  };

  export const useDateWidgets = () => {
    const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();

    return useSelector(actor, FormResponsePageMachineSelectors.getDateWidgets, isEqual);
  };

  export const useDueDateRuleForTaskTemplate = (taskTemplate: TaskTemplate) => {
    const actor = FormResponsePageProviders.FormResponsePageActorRef.useActorRef();
    const selector = useMemo(
      () => FormResponsePageMachineSelectors.getDueDateRuleByTaskTemplate(taskTemplate),
      [taskTemplate],
    );

    return useSelector(actor, selector, isEqual);
  };
}
