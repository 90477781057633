import angular from 'angular';
import { dayjs as moment } from '@process-street/subgrade/util';
import { InboxItemType } from '@process-street/subgrade/inbox';
import templateUrl from './item-ctrl-bar.component.html';
import './item-ctrl-bar.scss';

angular.module('frontStreetApp.directives').component('psInboxItemControls', {
  bindings: {
    user: '<',
    item: '<',
    permissionMap: '<',
    taskPermissionMap: '<',
  },
  require: {
    inboxListCtrl: '^psInboxList',
  },
  templateUrl,
  controller($q, InboxService) {
    const ctrl = this;
    ctrl.readOnly = false;

    ctrl.$onChanges = changes => {
      if (changes.taskPermissionMap) {
        ctrl.setIsReadOnly();
      }
    };

    ctrl.getOverdueClassName = () => InboxService.getOverdueClassName(ctrl.item.task.dueDate);

    ctrl.complete = function (event) {
      event.preventDefault();
      event.stopPropagation();

      ctrl.inboxListCtrl.completeItem(ctrl.item);
    };

    ctrl.isChecklist = function () {
      return ctrl.item.itemType === InboxItemType.Checklist;
    };

    ctrl.isTask = () => InboxService.isTask(ctrl.item.itemType);

    ctrl.isApprovalTask = () => InboxService.isApprovalTask(ctrl.item.itemType);

    ctrl.getOverdueClassName = () => InboxService.getOverdueClassName(ctrl.getItemDueDate());

    ctrl.resolveDueDate = () => InboxService.resolveDueDate(ctrl.getItemDueDate());

    ctrl.isOverdue = function () {
      const dueDate = ctrl.getItemDueDate();

      return dueDate && moment(dueDate, 'x').isBefore(moment());
    };

    ctrl.isDueDateSet = function () {
      return !!ctrl.getItemDueDate();
    };

    ctrl.getItemDueDate = function () {
      return ctrl.isChecklist() ? ctrl.item.checklist.dueDate : ctrl.item.task.dueDate;
    };

    ctrl.canCompleteItem = function () {
      return (
        ctrl.permissionMap &&
        !ctrl.isApprovalTask() &&
        (ctrl.isChecklist() ? ctrl.permissionMap.checklistComplete : ctrl.permissionMap.checklistUpdate)
      );
    };

    ctrl.canReadItem = function () {
      return ctrl.permissionMap && ctrl.permissionMap.checklistRead;
    };

    ctrl.canSnoozeItem = function () {
      return ctrl.permissionMap && ctrl.permissionMap.checklistUpdate;
    };

    ctrl.updateDueDate = function (dueDate) {
      ctrl.inboxListCtrl.updateDueDate(ctrl.item, dueDate);
    };

    ctrl.removeDueDate = function () {
      ctrl.inboxListCtrl.updateDueDate(ctrl.item, null);
    };

    ctrl.setIsReadOnly = () => {
      if (!ctrl.isTask()) return;

      ctrl.readOnly = !ctrl.taskPermissionMap[ctrl.item.task.id];
    };
  },
});
