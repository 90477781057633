import { Badge } from '@chakra-ui/react';
import { DEFAULT_TIME_ZONE } from '@process-street/subgrade/core/date-context';
import { TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { DateFormat, DateUtils } from '@process-street/subgrade/util';
import { useTimezone } from 'app/hooks/use-timezone';
import * as React from 'react';
import { dayjs } from '@process-street/subgrade/util';

export type TaskListItemDueDateIndicatorProps = {
  task: TaskWithTaskTemplate;
};

const UPCOMING_DUE_DATE_THRESHOLD_IN_HOURS = 24;

export const TaskListItemDueDateIndicator = ({ task }: TaskListItemDueDateIndicatorProps) => {
  const timeZone = useTimezone() ?? DEFAULT_TIME_ZONE;

  const colorScheme = React.useMemo(() => {
    const dueDate = dayjs(task.dueDate);
    const now = dayjs(Date.now());

    if (dueDate.isBefore(now)) {
      return 'red';
    } else if (dueDate.diff(now, 'h') <= UPCOMING_DUE_DATE_THRESHOLD_IN_HOURS) {
      return 'yellow';
    } else {
      return 'blue';
    }
  }, [task.dueDate]);

  if (!task.dueDate) return null;

  const formattedDate = DateUtils.formatDateTime(task.dueDate, DateFormat.DateMonthDay, timeZone);

  return (
    <Badge
      h={5}
      minH={5}
      borderRadius="4px"
      colorScheme={colorScheme}
      fontSize="11px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      fontWeight={600}
      bgColor={`${colorScheme}.100`}
      color={`${colorScheme}.500`}
    >
      {formattedDate}
    </Badge>
  );
};
