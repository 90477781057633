import { TemplateType } from '@process-street/subgrade/process';
import angular from 'angular';
import { HttpStatus } from '@process-street/subgrade/util';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';
import { AnalyticsService } from 'components/analytics/analytics.service';
import { AnalyticsConstants } from '@process-street/subgrade/analytics';

const lazyLoadTinymce = () => import('directives/template-widgets/tinymce-lazy-load');

angular
  .module('frontStreetApp.controllers')
  .controller(
    'TemplateEditCtrl',
    function (
      $anchorScroll,
      $rootScope,
      $scope,
      $state,
      $timeout,
      $q,
      $window,
      FeatureFlagService,
      FeatureGateService,
      MessageBox,
      OrganizationService,
      SecurityService,
      SessionService,
      TaskTemplateListMenuEvent,
      TaskTemplateService,
      TemplateRevisionService,
      TemplateService,
      ToastService,
      UserSettingsService,
      WidgetService,
    ) {
      const ctrl = this;
      const logger = trace({ name: 'TemplateEditCtrl' });
      logger.info('loading ctrl');

      $scope.$on('$destroy', () => {
        // Save the editor properties
        UserSettingsService.updateTemplateEditor(SessionService.getTemplateEditorProperties());
      });

      ctrl.$onInit = () => {
        $scope.isLoading = true;
        $scope.templateId = $state.params.id;
        $scope.taskTemplateGroupId = $state.params.groupId;
        $scope.templateType = TemplateType.Playbook;

        initUser();

        if (
          $scope.taskTemplateGroupId ||
          SessionService.getTemplateEditorProperty(`template:${$scope.templateId}:activeStep`)
        ) {
          showWidgets(true);
        }

        // Used in template-widgets-container.component.html
        $scope.editable = true;

        $scope.sidebarHidden = SessionService.getTemplateEditorProperty('sidebarHidden');

        //We flush the cache here because another user may have created a new revision in the meantime
        TemplateRevisionService.getAllNewestByTemplateId($scope.templateId, true /* flushCache */).then(
          revisions => {
            const [finishedRevision, draftRevision] = revisions;

            if (finishedRevision) {
              const { template } = finishedRevision;
              if (template.organization.id !== SecurityService.getSelectedOrganizationIdByUser($scope.user)) {
                OrganizationService.switchById(template.organization.id);
                return;
              }
              if (TemplateService.isPageByTemplateType(template.templateType)) {
                $state.go('page', { id: template.id });
                return;
              }

              //set page title on initialization
              $scope.updatePageTitle(finishedRevision.template.name);

              initialize(finishedRevision, draftRevision);
            } else {
              ToastService.openToast({
                status: 'error',
                title: `We're having problems loading the workflow`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });

              if ($state.includes('template')) {
                $state.go('dashboard');
              }
            }
          },
          response => {
            let message;

            switch (response.status) {
              case HttpStatus.FORBIDDEN:
                message = "Sorry, you don't have permission to see that workflow.";
                break;
              case HttpStatus.NOT_FOUND:
                message = "Sorry, we couldn't find that workflow.";
                break;
              default:
                message = 'Failed to load workflow. Please try again later.';
            }

            ToastService.openToast({
              status: 'error',
              title: `We're having problems loading the workflow`,
              description: message,
            });

            if ($state.includes('template')) {
              $state.go('dashboard');
            }
          },
        );
      };

      /**
       * Initializes user and redirects to login if user is not fully authenticated
       */
      function initUser() {
        if (SecurityService.isAuthenticatedFully()) {
          $scope.user = SessionService.getUser();
          return true;
        } else {
          $state.go('login', { url: $state.href('inbox') });
          return false;
        }
      }

      $scope.updatePageTitle = function (title) {
        $window.document.title = `${title} | Process Street`;
      };

      async function initialize(finishedRevision, draftRevision) {
        const shouldRedirectToNewEditor = (await FeatureFlagService.getFeatureFlagsAsync()).reactWorkflowEditor;

        if (shouldRedirectToNewEditor) {
          $state.go('templateV2', $state.params);

          return;
        } else if (draftRevision) {
          // We only load tinyMCE v4 in case the new version of the editor is not enabled.
          // The new editor uses TinyMCE V6, so if we load v4 before, it will brake the
          // text content component.
          await lazyLoadTinymce();
          initializeTemplateRevisionAndPermission(draftRevision);
        } else {
          await lazyLoadTinymce();

          TemplateRevisionService.create(finishedRevision.template.id).then(
            createdDraftRevision => {
              // Show a message warning the user that the template is no longer free
              if (finishedRevision.template.free) {
                TemplateService.showPaidTemplateMessage(finishedRevision.template);
              }

              initializeTemplateRevisionAndPermission(createdDraftRevision);

              AnalyticsService.trackEvent(AnalyticsConstants.Event.TEMPLATE_REVISION_DRAFT_CREATED);
            },
            response => {
              if (response.status === HttpStatus.PAYMENT_REQUIRED) {
                TemplateService.showLimitReachedMessageAndRedirect();
              } else {
                ToastService.openToast({
                  status: 'error',
                  title: `We're having problems creating a new revision of the workflow`,
                  description: DefaultErrorMessages.unexpectedErrorDescription,
                });

                if ($state.includes('template')) {
                  $state.go('dashboard');
                }
              }
            },
          );
        }
      }

      function initializeTemplateRevisionAndPermission(templateRevision) {
        $scope.templateRevision = templateRevision;

        SecurityService.canUpdateTemplateByTemplate(templateRevision.template).then(userCanUpdateTemplate => {
          $scope.userCanUpdateTemplate = userCanUpdateTemplate;
        });
      }

      $scope.selectTaskTemplate = function (taskTemplateGroupId, replace) {
        if ($state.is('template') || $state.is('template.task')) {
          if (taskTemplateGroupId) {
            $state.go('template.task', { groupId: taskTemplateGroupId }, { location: replace ? 'replace' : true });
          } else {
            $state.go('template');
          }
        }
      };

      $scope.setActiveTaskTemplate = function (taskTemplate, assignees, __canMoveUp, canMoveDown) {
        $scope.activeStep = { id: taskTemplate.group.id, name: taskTemplate.name, taskType: taskTemplate.taskType };
        $scope.activeStepTaskTemplate = taskTemplate;
        $scope.activeStepAssignees = assignees;
        $scope.activeStepHasAssignees = assignees && assignees.length > 0;
        $scope.activeStepHasDueDateOffset = taskTemplate.dueOffset;
        $scope.activeStepIsLastTask = !canMoveDown;
      };

      $scope.taskTemplateNameChanged = function (taskTemplate, name) {
        if ($scope.activeStep.id === taskTemplate.group.id) {
          $scope.activeStep.name = name;
          $scope.activeStepTaskTemplate = { ...$scope.activeStepTaskTemplate, name };
        }
      };

      $scope.selectStepBelow = function (task) {
        $rootScope.$broadcast(TaskTemplateListMenuEvent.MOVE_DOWN_REQUEST_FROM_MENU, task);
      };

      $scope.editStep = function (taskTemplate, step) {
        MessageBox.prompt({
          title: 'What should the name of this task be?',
          defaultValue: taskTemplate.name,
          okButton: {
            text: 'Save',
            action(name) {
              if (taskTemplate.name !== name) {
                taskTemplate.name = name;
                $scope.updateTaskTemplate(taskTemplate, step);
              }
            },
          },
        });
      };

      $scope.updateTaskTemplate = function (taskTemplate, step) {
        return TaskTemplateService.update(taskTemplate).then(
          response => {
            step.name = taskTemplate.name;

            return response;
          },
          () => {
            taskTemplate.name = step.name;

            ToastService.openToast({
              status: 'error',
              title: `We're having problems updating the task name`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
          },
        );
      };

      // Template View Tab

      const Tab = {
        OVERVIEW: 'overview',
        TEMPLATE: 'template',
      };

      function setSelectedTab(templateId) {
        const key = `template:${templateId}:selectedTab`;
        SessionService.setTemplateEditorProperty(key, Tab.TEMPLATE);

        let templateRequest;
        if ($scope.templateRevision && $scope.templateRevision.template) {
          templateRequest = $q.resolve($scope.templateRevision.template);
        } else {
          templateRequest = TemplateService.get(templateId);
        }
        templateRequest.then(template => {
          $rootScope.$broadcast(EventName.TEMPLATE_VIEWED, { template });
        });
      }

      $scope.$on('$stateChangeSuccess', (__event, toState, toParams) => {
        if (toState.name.split('.')[0] === 'template') {
          switch (toState.name) {
            case 'template':
              // So, if we get sent to the template viewer without a specific task,
              // then we should redirect to the last tab we were on
              setSelectedTab(toParams.id);
              break;
            case 'template.task':
              setSelectedTab(toParams.id);
              if (toParams.groupId) {
                $scope.taskTemplateGroupId = toParams.groupId;
              }
              break;
            default:
              logger.error('unexpected state: %s', toState.name);
          }
        }
      });

      $scope.$on(EventName.TEMPLATE_MOVED, (__event, __movedTemplate, updatedTemplate) => {
        $scope.templateRevision.template = updatedTemplate;
      });

      /**
       * Returns true if the user can assign users to tasks in this template.
       *
       * @param templateRevision
       * @returns {*}
       */
      $scope.canManageTasks = function (templateRevision) {
        const revisionIsDraft = templateRevision && templateRevision.status === 'Draft';

        const templateIsActive =
          templateRevision && templateRevision.template && templateRevision.template.status === 'Active';

        return revisionIsDraft && templateIsActive && $scope.userCanUpdateTemplate;
      };

      $scope.shouldShowDueOffset = function () {
        // Used in template-widgets-container.component.html
        return true;
      };

      $scope.shouldShowStopTask = function () {
        // Used in template-widgets-container.component.html
        return true;
      };

      // Used in template-widgets-container.component.html
      $scope.shouldShowTaskPermissions = () => FeatureGateService.isTaskPermissionsFeatureEnabled($scope.user);

      // Widgets
      // Used to toggle widgets

      $scope.widgetsMap = {};

      $scope.setWidgetsVisibility = function (visible, instantly) {
        if (visible) {
          showWidgets(instantly);
        } else {
          hideWidgets();
        }
      };

      $scope.toggleWidgets = function () {
        if ($scope.widgetsShown) {
          hideWidgets();
        } else {
          showWidgets();
        }
      };

      function showWidgets(instantly) {
        $scope.instantly = instantly;
        $scope.widgetsShown = true;
        $scope.isLoading = false;

        $timeout(() => {
          $anchorScroll('widgets-top');
        });
      }

      function hideWidgets() {
        $scope.widgetsShown = false;
      }
      $scope.hideWidgetsAsync = () => {
        $timeout(() => {
          hideWidgets();
        });
      };

      $scope.initTaskTemplateWidgets = function (taskTemplateGroupId) {
        $scope.widgetsMap[taskTemplateGroupId] = $scope.widgetsMap[taskTemplateGroupId] || [];
      };

      $scope.cleanUpTaskTemplateWidgets = function (taskTemplateGroupId) {
        delete $scope.widgetsMap[taskTemplateGroupId];
      };

      $scope.getDuplicateWidgets = function (duplicatedTaskTemplate, newTaskTemplate) {
        const { widgetsMap } = $scope;

        WidgetService.getAllByTaskTemplateId(duplicatedTaskTemplate.id)
          .then(
            duplicatedWidgets => {
              ToastService.openToast({
                status: 'success',
                title: `Task duplicated`,
              });

              widgetsMap[newTaskTemplate.group.id] = duplicatedWidgets;
            },
            response => {
              ToastService.openToast({
                status: 'error',
                title: `We're having problems duplicating the widgets for this task`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });

              return $q.reject(response);
            },
          )
          .finally(() => {
            $rootScope.$broadcast(EventName.GETTING_DUPLICATED_WIDGETS_FINISHED, newTaskTemplate);
          });
      };
    },
  );
