import * as React from 'react';
import {
  Box,
  Icon,
  Popover,
  Portal,
  PopoverAnchor,
  PopoverTrigger,
  Show,
  Spinner,
  useDisclosure,
} from 'components/design/next';
import { SelectionBar } from 'components/selection-bar-actions';
import { IdentifiableTaskRow, useTasksRowSelectionProvider } from 'pages/tasks/row-selection-provider';
import { UpdateInboxItemsStatusMutation } from 'features/inbox/query-builder/update-status-mutation';
import { useTasksFilters } from 'pages/tasks/tasks-filters-provider';
import { GetInboxItemsQuery } from 'features/microsoft-teams/query-builder';
import { ToastService } from 'services/toast-service.impl';
import { theme2024 } from 'components/design/next/theme';
import pluralize from 'pluralize';
import { useQueryClient } from 'react-query';
import { TasksTableUtils } from '../tasks-table/tasks-table-utils';
import { AssignmentBulkSnoozeMutation } from '../../query-builder/assignment-bulk-snooze-mutation';
import { match, P } from 'ts-pattern';
import { SnoozeButtonPopoverContent } from '../snooze-button/popover-content';
import { useFeatureFlag } from 'app/features/feature-flags';
import {
  AssignmentBulkDeleteSnoozedMutation,
  GetAllTasksByChecklistRevisionIdQuery,
} from 'app/features/task/query-builder';
import { GetSnoozeAssignmentCountQuery } from '../../query-builder/get-snooze-assignment-count-query';
import { isFunction } from 'lodash';
import { InboxItemType, InboxItemUtils, TaskInboxItem } from '@process-street/subgrade/inbox';

type SelectionBarWrapperProps = {
  onSuccessUpdateItemsStatus?: (response: UpdateInboxItemsStatusMutation.Response) => void;
  onSuccessBulkSnooze?: () => void;
  onSuccessBulkDeleteSnoozed?: () => void;
};

export const SelectionBarWrapper = ({
  onSuccessUpdateItemsStatus,
  onSuccessBulkSnooze,
  onSuccessBulkDeleteSnoozed,
}: SelectionBarWrapperProps) => {
  const tasksFilters = useTasksFilters();
  const queryClient = useQueryClient();
  const [selectedRows, setSelectedRows] = useTasksRowSelectionProvider();
  const bulkSnoozePopoverDisclosure = useDisclosure();
  const isMyWorkGAEnabled = useFeatureFlag('myWorkGA');

  const isSnoozedView = tasksFilters.snoozeStatus === GetInboxItemsQuery.SnoozeStatus.Snoozed;

  const updateInboxItemsStatusMutation = UpdateInboxItemsStatusMutation.useMutation({
    onSuccess: async (response, variables) => {
      onSuccessUpdateItemsStatus?.(response);

      await GetInboxItemsQuery.invalidate(queryClient, tasksFilters);

      const toast = new ToastService(theme2024);

      const completedTasks = response.filter(task => task.response === 'Ok');
      const completedTasksCount = completedTasks.length;
      const uncompletedTasksCount = variables.todos.length - completedTasksCount;

      if (!uncompletedTasksCount) {
        setSelectedRows([]);
      } else {
        const uncompletedRows = selectedRows.filter(({ data }) => {
          if (!TasksTableUtils.isInboxItemRow(data)) return false;

          const itemId = InboxItemUtils.getId(data);

          const isCompleted = completedTasks.find(({ todo }) => todo.id === itemId);

          return !isCompleted;
        });
        setSelectedRows(uncompletedRows);
      }

      if (completedTasksCount > 0) {
        toast.openToast({
          status: 'success',
          title: `${completedTasksCount} ${pluralize('assignment', completedTasksCount)} marked as completed.`,
        });
      }

      if (uncompletedTasksCount > 0) {
        toast.openToast({
          status: 'warning',
          title: `We couldn't complete ${uncompletedTasksCount} ${pluralize('task', uncompletedTasksCount)}.`,
          description: `Some of the fields are invalid or required and missing.`,
        });
      }
    },
  });

  const assignmentBulkSnoozeMutation = AssignmentBulkSnoozeMutation.useMutation({
    onMutate: variables => {
      const rollbackSnoozeCount = GetSnoozeAssignmentCountQuery.incrementCount(
        queryClient,
        variables.assignmentIds.length,
      );
      const rollbackInboxItems = GetInboxItemsQuery.removeItem(queryClient, inboxItem =>
        variables.assignmentIds.includes(inboxItem.assignmentId),
      );

      return () => {
        rollbackSnoozeCount();
        rollbackInboxItems();
      };
    },
    onError: (_error, _variables, context) => {
      if (isFunction(context)) {
        // Rollback the optimistic update
        context();
      }
    },
    onSuccess: async () => {
      setSelectedRows([]);
      await GetInboxItemsQuery.invalidate(queryClient);
      await GetSnoozeAssignmentCountQuery.invalidate(queryClient);
      onSuccessBulkSnooze?.();
    },
  });

  const handleBulkComplete = async (selectedItems: IdentifiableTaskRow[]) => {
    const inboxItems = selectedItems.map(item => item.data).filter(TasksTableUtils.isInboxItemRow);
    return updateInboxItemsStatusMutation
      .mutateAsync({
        newStatus: 'Completed',
        todos: inboxItems
          .map(item => ({
            id: InboxItemUtils.getId(item),
            todoType: item.itemType,
          }))
          .filter((item): item is UpdateInboxItemsStatusMutation.Todo => Boolean(item.id)),
      })
      .then(response => {
        const checklistRevisionIdsToInvalidate = new Set(
          inboxItems
            .filter((item): item is TaskInboxItem => item.itemType !== InboxItemType.Checklist)
            .map(item => item.task.checklistRevision.id),
        );

        checklistRevisionIdsToInvalidate.forEach(async checklistRevisionId => {
          await queryClient.invalidateQueries(GetAllTasksByChecklistRevisionIdQuery.getKey({ checklistRevisionId }));
        });

        return response;
      });
  };

  const [itemsToBulkSnooze, setItemsToBulkSnooze] = React.useState<Array<IdentifiableTaskRow>>([]);
  const handleBulkSnooze = (items: Array<IdentifiableTaskRow>) => {
    setItemsToBulkSnooze(items);
    return Promise.resolve();
  };

  const assignmentBulkDeleteSnoozedMutation = AssignmentBulkDeleteSnoozedMutation.useMutation({
    onMutate: variables => {
      queryClient.setQueryData<GetSnoozeAssignmentCountQuery.Response>(GetSnoozeAssignmentCountQuery.key, prev => {
        return {
          organizationMembershipId: '',
          ...prev,
          count: (prev?.count ?? 0) - variables.assignmentIds.length,
        };
      });
    },
    onSuccess: async () => {
      setSelectedRows([]);
      await queryClient.invalidateQueries(GetSnoozeAssignmentCountQuery.key);
      await GetInboxItemsQuery.invalidate(queryClient);
      onSuccessBulkDeleteSnoozed?.();
    },
  });

  const handleConfirmBulkSnooze = (untilDate: number) => {
    bulkSnoozePopoverDisclosure.onClose();

    if (!itemsToBulkSnooze.length) {
      return;
    }

    const assignmentIds = itemsToBulkSnooze
      .map(item =>
        match(item.data)
          .with({ assignmentId: P.string }, ({ assignmentId }) => assignmentId)
          .otherwise(() => null),
      )
      .filter((maybeId): maybeId is string => Boolean(maybeId));

    setItemsToBulkSnooze([]);

    return assignmentBulkSnoozeMutation.mutateAsync({
      assignmentIds,
      untilDate,
    });
  };

  const handleConfirmBulkDeleteSnoozed = (items: Array<IdentifiableTaskRow>) => {
    if (!items.length) {
      return Promise.resolve();
    }

    const assignmentIds = items
      .map(item =>
        match(item.data)
          .with({ assignmentId: P.string }, ({ assignmentId }) => assignmentId)
          .otherwise(() => null),
      )
      .filter((maybeId): maybeId is string => Boolean(maybeId));

    return assignmentBulkDeleteSnoozedMutation.mutateAsync({ assignmentIds });
  };

  return (
    <SelectionBar.Actions<IdentifiableTaskRow> selectedItems={selectedRows}>
      <SelectionBar.Button
        onConfirm={handleBulkComplete}
        leftIcon={updateInboxItemsStatusMutation.isLoading ? <Spinner color="white" /> : <Icon icon="check" size="3" />}
        isDisabled={updateInboxItemsStatusMutation.isLoading}
        skipConfirmation
      >
        <Show above="md">Mark as completed</Show>
        <Show below="md">Complete</Show>
      </SelectionBar.Button>

      {isMyWorkGAEnabled && !isSnoozedView && (
        <Popover {...bulkSnoozePopoverDisclosure}>
          <PopoverTrigger>
            <SelectionBar.Button
              onConfirm={handleBulkSnooze}
              leftIcon={
                assignmentBulkSnoozeMutation.isLoading ? <Spinner color="white" /> : <Icon icon="snooze" size="3" />
              }
              isDisabled={assignmentBulkSnoozeMutation.isLoading}
              skipConfirmation
              position="relative"
            >
              Snooze
              <PopoverAnchor>
                <Box position="absolute" top="0" right="50%" />
              </PopoverAnchor>
            </SelectionBar.Button>
          </PopoverTrigger>

          <Portal>
            <SnoozeButtonPopoverContent
              onSnooze={handleConfirmBulkSnooze}
              isOpen={bulkSnoozePopoverDisclosure.isOpen}
            />
          </Portal>
        </Popover>
      )}

      {isMyWorkGAEnabled && isSnoozedView && (
        <SelectionBar.Button
          onConfirm={handleConfirmBulkDeleteSnoozed}
          leftIcon={
            assignmentBulkDeleteSnoozedMutation.isLoading ? <Spinner color="white" /> : <Icon icon="snooze" size="3" />
          }
          isDisabled={assignmentBulkDeleteSnoozedMutation.isLoading}
          position="relative"
          skipConfirmation
        >
          Unsnooze
        </SelectionBar.Button>
      )}
    </SelectionBar.Actions>
  );
};
