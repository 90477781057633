import * as React from 'react';
import { Box, Center, Checkbox, HStack, Text, VStack } from '@chakra-ui/react';
import { isApproval, TaskStatus, TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { TaskListItemAssigneeIndicator } from './task-list-item-assignee-indicator';
import { match } from 'ts-pattern';
import { Icon } from 'app/components/design/next';
import { TaskListItemDueDateIndicator } from './task-list-item-due-date-indicator';
import { TaskListItemDynamicDueDateIndicator } from './task-list-item-dynamic-due-date-indicator';
import { TaskListItemAutomationIndicator } from './task-list-item-automation-indicator';

export type TaskListItemProps = {
  task: TaskWithTaskTemplate;
  isLast: boolean;
  isFirst: boolean;
  isSelected: boolean;
  stepNumber: number;
  onSelect: (task: TaskWithTaskTemplate) => void;
};

export const TaskListItem = ({ task, stepNumber, onSelect, isLast, isFirst, isSelected }: TaskListItemProps) => {
  // TODO: implement this once approval tasks are ready
  const shouldHighlight = false;
  const isCompleted = task.status === TaskStatus.Completed;

  const handleSelect = React.useCallback(() => {
    onSelect(task);
  }, [onSelect, task]);

  return (
    <Box w="full">
      <HStack w="full" spacing="0">
        <Box p={2} pl={0} pr={3}>
          <VStack position="relative">
            <Center
              minW={5}
              w={5}
              h={5}
              bgColor={isSelected ? 'brand.500' : 'transparent'}
              borderRadius="full"
              role="group"
              _hover={{ bgColor: 'transparent' }}
              position="relative"
            >
              {match(task.taskTemplate)
                .when(isApproval, () => (
                  <Icon
                    aria-label="Approval task"
                    color={isSelected ? 'white' : 'gray.400'}
                    size="3"
                    icon="thumbs-up"
                  />
                ))
                .otherwise(() => (
                  <Text
                    fontSize="sm"
                    fontWeight={isSelected ? '700' : '400'}
                    color={isSelected ? 'white' : 'gray.400'}
                    position="absolute"
                    cursor="default"
                  >
                    {stepNumber}
                  </Text>
                ))}
            </Center>
            {task.taskTemplate.stop && (
              <Box position="absolute" bottom="-4">
                <Icon aria-label="Stop task" color={isSelected ? 'purple.500' : 'gray.400'} size="3" icon="hand" />
              </Box>
            )}
          </VStack>
        </Box>
        <Box
          w="full"
          justifyContent="flex-start"
          fontWeight={isSelected ? '600' : task.taskTemplate.name?.endsWith(':') ? '700' : '400'}
          noOfLines={1}
          borderStyle="solid"
          borderWidth="thin"
          borderColor={isSelected ? 'brand.500' : 'gray.200'}
          borderRadius={0}
          h={10}
          color={isSelected ? 'brand.600' : 'gray.600'}
          borderTopLeftRadius={isFirst ? '8px' : undefined}
          borderTopRightRadius={isFirst ? '8px' : undefined}
          borderBottomLeftRadius={isLast ? '8px' : undefined}
          borderBottomRightRadius={isLast ? '8px' : undefined}
          borderTopColor={isSelected || isFirst ? undefined : 'transparent'}
          aria-selected={isSelected}
          bgColor={shouldHighlight ? 'brand.200' : 'white'}
          onClick={handleSelect}
        >
          <HStack spacing={2} px={3}>
            <Checkbox
              isChecked={isCompleted}
              sx={{
                '.chakra-checkbox__control': {
                  borderWidth: '1px',
                  borderRadius: '4px',
                  mt: '-2px',
                },
              }}
            />

            <Text
              role="button"
              aria-label={task.taskTemplate.name}
              flex="1"
              w="full"
              h={10}
              lineHeight="40px"
              mt="-1px"
              textAlign="left"
              fontSize="md"
              noOfLines={1}
              textDecoration={isCompleted ? 'line-through' : undefined}
              color={isCompleted ? 'gray.500' : 'gray.600'}
              fontStyle={isCompleted ? 'italic' : undefined}
            >
              {task.taskTemplate.name}
            </Text>

            <HStack>
              <TaskListItemAssigneeIndicator task={task} />
              <TaskListItemDueDateIndicator task={task} />
              <TaskListItemDynamicDueDateIndicator task={task} />
              <TaskListItemAutomationIndicator task={task} />
            </HStack>
          </HStack>
        </Box>
      </HStack>
    </Box>
  );
};
