import { useToast } from 'components/design/next';
import * as React from 'react';
import { useGetFinishedTmplRevisionByTmplIdQuery } from 'features/template-revisions/query-builder/get-finished-template-revision-by-template-id';
import { useInjector } from 'components/injection-provider';
import { HttpStatus } from '@process-street/subgrade/util';
import { AppModalName, AppModalQueryParam, GlobalSearchVariant } from 'app/app.constants';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { Muid } from '@process-street/subgrade/core';

export function useCanRunWorkflowCheck({ templateId }: { templateId: Muid }) {
  const toast = useToast();
  const { $state } = useInjector('$state');

  const permissionsQuery = useGetConsolidatedTemplatePermissionsQuery(templateId);
  const templateRevisionsQuery = useGetFinishedTmplRevisionByTmplIdQuery({ templateId }, { retry: 0 });

  const tmplRevsNotFound = templateRevisionsQuery.error?.response?.status === HttpStatus.NOT_FOUND;
  const cannotCreateChecklist = permissionsQuery.data?.permissionMap.checklistCreate === false;
  const shouldRedirect = tmplRevsNotFound || cannotCreateChecklist;

  React.useEffect(() => {
    if (shouldRedirect) {
      toast({
        status: 'warning',
        title: "We couldn't run the workflow",
        description: "It may have been deleted or you don't have permission to run it.",
      });
      $state.go($state.current, {
        [AppModalQueryParam.Modal]: AppModalName.GlobalSearch,
        [AppModalQueryParam.ModalVariant]: GlobalSearchVariant.Run,
        [AppModalQueryParam.ModalTemplateId]: undefined,
      });
    }
  }, [$state, shouldRedirect, toast]);
}
