import React from 'react';
import { match, P } from 'ts-pattern';
import { UseQueryResult } from 'react-query';

import { Badge, BadgeProps, Box, BoxProps, Flex } from 'components/design/next';
import { InboxStats } from '@process-street/subgrade/inbox';

import { useGetInboxStatsByOrganizationQuery } from 'features/inbox/query-builder';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { GetUnreadCommentsCountQueryResponse, useGetUnreadCommentsCountQuery } from 'features/comments/query-builder';
import { UserType } from '@process-street/subgrade/core';

const InboxBadge: React.FC<React.PropsWithChildren<BadgeProps>> = ({ children, ...props }) => (
  <Badge
    colorScheme="gray"
    ml="1"
    size="md"
    padding={1}
    borderRadius="base"
    fontWeight="medium"
    aria-label="pending inbox items"
    {...props}
  >
    {children}
  </Badge>
);

export const UnreadCommentsBadge: React.FC<React.PropsWithChildren<BoxProps>> = props => (
  // looks the same as Chakra's AvatarBadge
  <Box
    pointerEvents="none"
    position="absolute"
    boxSize={2.5}
    bg="red.500"
    top={-1}
    right={-1}
    borderRadius="full"
    border="1px solid white"
    aria-label="unread comments"
    {...props}
  />
);

export const InboxItem = () => {
  const userQuery = useGetCurrentUserInfoQuery();

  const currentUser = userQuery.data?.user;
  const organizationId = userQuery.data?.organizationMembership.organization.id;

  const inboxQuery = useGetInboxStatsByOrganizationQuery(
    {
      organizationId: organizationId!,
      userId: currentUser?.id!,
    },
    {
      enabled: Boolean(currentUser) && Boolean(organizationId) && currentUser?.userType === UserType.Standard,
    },
  );

  const unreadCommentsQuery = useGetUnreadCommentsCountQuery({
    enabled: Boolean(currentUser) && currentUser?.userType === UserType.Standard,
  });

  return (
    match<[UseQueryResult<InboxStats | undefined>, UseQueryResult<GetUnreadCommentsCountQueryResponse | undefined>]>([
      inboxQuery,
      unreadCommentsQuery,
    ])
      // inbox elements / unread comments
      .with(
        [
          { status: 'success', data: { inbox: P.when(count => count > 0) } },
          { status: 'success', data: { count: P.when(count => count > 0) } },
        ],
        ([
          {
            data: { inbox },
          },
          _,
        ]) => (
          <Flex alignItems="center">
            Inbox
            <Box position="relative">
              <InboxBadge>{inbox}</InboxBadge>
              <UnreadCommentsBadge />
            </Box>
          </Flex>
        ),
      )
      // unread comments
      .with(
        [
          { status: 'success', data: { inbox: P.when(count => count === 0) } },
          { status: 'success', data: { count: P.when(count => count > 0) } },
        ],
        () => (
          <Flex alignItems="center">
            Inbox
            <UnreadCommentsBadge position="relative" />
          </Flex>
        ),
      )
      // inbox items
      .with(
        [
          { status: 'success', data: { inbox: P.when(count => count > 0) } },
          { status: 'success', data: { count: P.when(count => count === 0) } },
        ],
        ([
          {
            data: { inbox },
          },
          _,
        ]) => (
          <Flex alignItems="center">
            Inbox
            <InboxBadge>{inbox}</InboxBadge>
          </Flex>
        ),
      )
      .otherwise(() => <>Inbox</>)
  );
};
