import * as React from 'react';
import { useInjector } from 'components/injection-provider';
import { Muid } from '@process-street/subgrade/core';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { isWorkflow as tmplIsWorkflow, TemplateType } from '@process-street/subgrade/process';
import {
  Button,
  ButtonProps,
  Icon,
  IconButton,
  IconProps,
  MenuItem,
  Skeleton,
  Text,
  Tooltip,
  TooltipProps,
} from 'components/design/next';
import { EditTemplateButton } from 'components/edit-template-button';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { abbreviateForTitle } from '@process-street/subgrade/util';
import { useIsCurrentUserFreeMember } from 'hooks/use-is-current-user-free-member';
import { TemplateMenuItem } from 'app/features/template/components/template-menu/template-menu';
import { useGetDraftTmplRevisionByTmplIdQuery } from 'features/template-revisions/query-builder';
import { SatisMeterEvent } from 'services/interop/satis-meter-event';
import { TextProps } from '@chakra-ui/react';
import { MobileActions } from 'pages/library/components/template-library/mobile-actions';

export interface Props {
  templateId: Muid;
  children?: React.ReactElement<ButtonProps>;
  renderLabel?: (draftExists?: boolean) => React.ReactElement;
  textProps?: TextProps;
  iconProps?: Partial<IconProps>;
  tooltipProps?: Partial<TooltipProps>;
}

const TEMPLATE_LABELS = {
  [TemplateType.Page]: 'page',
  [TemplateType.Form]: 'form',
  [TemplateType.Playbook]: 'workflow',
  [TemplateType.Task]: 'task', // added just for type safety
  [TemplateType.WorkflowSandbox]: 'sandbox', // also added just for type safety
} as const;

export const EditButton: React.FC<React.PropsWithChildren<Props>> = ({
  templateId,
  children,
  textProps,
  renderLabel,
  tooltipProps,
  iconProps,
}) => {
  const isFreeMember = useIsCurrentUserFreeMember();

  const { $state, $rootScope } = useInjector('$state', '$rootScope');
  const templateQuery = useGetTemplateQuery({ templateId });
  const template = templateQuery.data;

  const draftTmplRevisionQuery = useGetDraftTmplRevisionByTmplIdQuery({ templateId });
  const draftTmplRevision = draftTmplRevisionQuery.data;

  const draftExists = !!draftTmplRevision;

  const isWorkflow = template && tmplIsWorkflow(template);

  const { data } = useGetConsolidatedTemplatePermissionsQuery(templateId);

  const hasPermission = Boolean(isWorkflow ? data?.permissionMap.templateUpdate : data?.permissionMap.pageUpdate);
  // free members always see the edit button on a workflow (because it has a paywall). But they shouldn't
  // see the edit button on a page if they don't have privileges (as other members)
  const shouldShowEditButton = (isFreeMember && isWorkflow) || hasPermission;

  const handleEdit = React.useCallback(() => {
    if (!template?.id || !template?.name || !template?.templateType) return;

    if (template.templateType === TemplateType.Page) {
      $state.go('page', { id: template.id, title: `${abbreviateForTitle(template.name)}-`, verb: 'edit' });
    } else if (template.templateType === TemplateType.Form) {
      $state.go('form', { id: template.id, title: `${abbreviateForTitle(template.name)}-` });
    } else if ($state.params.groupId) {
      sendFirstEditSatisMeterEvent(template.id);
      $state.go('template.task', { id: template.id, groupId: $state.params.groupId });
    } else {
      sendFirstEditSatisMeterEvent(template.id);
      $state.go('template', { id: template.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- callback
  }, [$state, template?.id, template?.name, template?.templateType]);

  const sendFirstEditSatisMeterEvent = (templateId: Muid) => {
    const key = `ai-generated-workflow-${templateId}`;
    if (localStorage.getItem(key) === 'true') {
      localStorage.removeItem(key);
      $rootScope.$broadcast(SatisMeterEvent.AiGeneratedTemplateFirstEdit);
    }
  };

  if (
    !!children &&
    (!React.isValidElement(children) ||
      ![MenuItem, Button, IconButton, TemplateMenuItem, MobileActions.Item].some(type => children.type === type))
  ) {
    throw new Error('EditButton children must be a MenuItem or Button');
  }

  return template ? (
    <Tooltip
      isDisabled={!draftExists}
      mt={1}
      label={`This ${TEMPLATE_LABELS[template.templateType]} has unpublished changes.`}
      placement="bottom"
      shouldWrapChildren
      hasArrow
      {...tooltipProps}
    >
      {shouldShowEditButton ? (
        <EditTemplateButton templateType={template?.templateType}>
          {children && React.isValidElement(children) ? (
            React.cloneElement(children, {
              children: renderLabel ? renderLabel() : <Text {...textProps}>{draftExists ? 'Edit Draft' : 'Edit'}</Text>,
              onClick: handleEdit,
              ...(children.props.icon
                ? {}
                : {
                    [children.type === MenuItem || children.type === TemplateMenuItem || children.type === IconButton
                      ? 'icon'
                      : 'leftIcon']: draftExists ? (
                      <Icon
                        icon="exclamation-circle"
                        variant="far"
                        size="4"
                        title={`This ${TEMPLATE_LABELS[template.templateType]} has unpublished changes.`}
                        {...iconProps}
                      />
                    ) : (
                      <Icon icon="edit" variant="far" size="4" {...iconProps} />
                    ),
                  }),
            })
          ) : (
            <Button
              variant="secondary"
              onClick={handleEdit}
              leftIcon={draftExists ? <Icon icon="exclamation-circle" variant="far" size="4" /> : undefined}
            >
              {/* Push the right icon to the right */}
              <Text>{draftExists ? 'Edit Draft' : 'Edit'}</Text>
            </Button>
          )}
        </EditTemplateButton>
      ) : null}
    </Tooltip>
  ) : (
    <Skeleton>
      <Button aria-label="edit">Edit</Button>
    </Skeleton>
  );
};
