import * as React from 'react';
import {
  Button,
  Divider,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  HStack,
  Icon,
  Text,
} from 'components/design/next';
import { ChooseTemplateTriggers } from '../../../choose-template-triggers';

export interface PreselectTriggersProps {
  onNext: () => void;
}

export const PreselectTriggers: React.FC<React.PropsWithChildren<PreselectTriggersProps>> = ({ onNext }) => {
  return (
    <>
      <ModalHeader>
        <HStack>
          <Icon icon="bolt" variant="far" size="4" color="gray.400" />
          <Text>Select a Trigger to Run this Workflow</Text>
        </HStack>
      </ModalHeader>
      <ModalCloseButton />
      <Divider />
      <ModalBody py="6">
        <ChooseTemplateTriggers onSelect={onNext} />
      </ModalBody>
      <ModalFooter justifyContent="center" pt={0}>
        <Button variant="primary" type="submit" onClick={onNext}>
          I'll decide later
        </Button>
      </ModalFooter>
    </>
  );
};
