import { trace } from 'app/components/trace';
import { useAiGenerationSlice } from 'app/pages/templates/_id/components/ai-generated-workflow-settings-modal/store';
import { AblyEvent } from 'app/pusher/ably-event';
import { ablyService } from 'app/pusher/ably.service';
import * as React from 'react';
import { useFormEditorPageActorRef } from '../../form-editor-page-machine';
import { useSelector } from '@xstate/react';
import _isEqual from 'lodash/isEqual';
import { isTemplateRevisionEqual } from 'app/pages/workflows/_id/edit-v2/helpers/is-template-revision-equal';
import { FormEditorPageActorSelectors } from '../../form-editor-page-machine/form-editor-page-machine-selectors';

const logger = trace({ name: 'useSyncAiGenerationAblyMessages' });

export function useSyncAiGenerationAblyMessages() {
  const { isGenerating } = useAiGenerationSlice();

  const editorRef = useFormEditorPageActorRef();

  const templateRevision = useSelector(
    editorRef,
    FormEditorPageActorSelectors.getTemplateRevision,
    isTemplateRevisionEqual,
  );
  const templateRevisionId = templateRevision?.id;
  const taskTemplates = useSelector(editorRef, FormEditorPageActorSelectors.getGeneratedTaskTemplates);
  const aiWorkflowGenerationActor = useSelector(
    editorRef,
    FormEditorPageActorSelectors.getAiWorkflowGeneratorActorRef,
    _isEqual,
  );
  const generationStatus = useSelector(aiWorkflowGenerationActor, state => state.value);

  React.useEffect(() => {
    if (generationStatus === 'done') {
      // TODO: show modal
    }
  }, [generationStatus]);

  React.useEffect(
    function syncAiGenerationAblyMessages() {
      if (!templateRevisionId) return;
      const channelName = ablyService.getChannelNameForTemplateRevision(templateRevisionId);
      const channel = ablyService.getChannel(channelName);

      const listener = (message: any) => {
        const { taskTemplateId } = JSON.parse(message.data);
        logger.info(`message from ${AblyEvent.EventType.TaskTemplateUpdated}`, taskTemplateId);

        const taskTemplate = taskTemplates.find(t => t.id === taskTemplateId);
        if (taskTemplate && taskTemplate.templateRevision.id === templateRevisionId) {
          setTimeout(() => {
            aiWorkflowGenerationActor.send({ type: 'ANIMATE_TASK_TEMPLATE_WIDGETS', taskTemplate });
          }, 5000);
        }
      };

      if (isGenerating) {
        logger.info(`subscribing to channel ${AblyEvent.EventType.TaskTemplateUpdated}`);
        channel.subscribe(AblyEvent.EventType.TaskTemplateUpdated, listener);
      }

      return () => {
        logger.info(`unsubscribing from channel ${AblyEvent.EventType.TaskTemplateUpdated}`);
        channel.unsubscribe(AblyEvent.EventType.TaskTemplateUpdated, listener);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO
    [isGenerating, taskTemplates.length],
  );
}
