import { isAi, isHeading, TaskStatus, TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { StateFrom } from 'xstate';
import { FormResponseMachine } from './form-response-machine';
import { FormMachineUtils } from './form-response-machine-utils';

export type State = StateFrom<FormResponseMachine>;

export const FormResponseMachineSelectors = {
  getTotalTaskCount: (state: State) =>
    Object.values(state.context.taskActorsMap).filter(actor => !FormMachineUtils.taskActorIsHiddenByRule(actor)).length,
  getCurrentTaskIndex: (state: State) =>
    FormMachineUtils.getCurrentTaskIndex(state.context.taskActorsMap, state.context.currentTaskActor),
  getTaskActorsMap: (state: State) => state.context.taskActorsMap,
  getCurrentTaskActor: (state: State) => state.context.currentTaskActor,
  getIsCompletingCurrentTask: (state: State) => state.matches('response.active.completingCurrentTask'),
  getIsUncompletingCurrentTask: (state: State) => state.matches('response.active.uncompletingPreviousTask'),
  getIsComplete: (state: State) => state.matches('response.complete'),
};

export const FormResponseMachineChecklistSelectors = {
  getChecklist: (state: State) => state.context.checklist,
  getCompletionPercentage: (state: State) => {
    const tasks = Object.values(state.context.taskActorsMap)
      .map(actor => actor.getSnapshot()?.context.task)
      .filter((task): task is TaskWithTaskTemplate => Boolean(task))
      .filter(task => !isHeading(task.taskTemplate) && !isAi(task.taskTemplate) && !task.hidden);

    const completedCount = tasks.filter(task => task.status === TaskStatus.Completed).length;

    return (completedCount / tasks.length) * 100;
  },
  getShouldHideCompletedTasks: (state: State) => state.context.shouldHideCompletedTasks,
};
