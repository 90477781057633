import * as React from 'react';
import { TaskTemplate, TemplateRevision, Widget } from '@process-street/subgrade/process';
import { useInjector } from 'components/injection-provider';
import { ConditionalsButtonService } from 'directives/rules/template/conditionals-button/conditionals-button.service';
import { useEffectOnce, usePromise } from 'react-use';
import { ButtonProps } from 'components/design/next';

export interface ConditionalLogicButtonProps {
  templateRevisionId: TemplateRevision['id'];
  widget?: Widget;
  taskTemplate?: TaskTemplate;
  children: React.ReactElement<ButtonProps>;
}

export const ConditionalLogicButtonWrapper: React.FC<React.PropsWithChildren<ConditionalLogicButtonProps>> = ({
  templateRevisionId,
  children,
  widget,
  taskTemplate,
}) => {
  const { ConditionalsButtonService } = useInjector('ConditionalsButtonService', '$rootScope');
  const [rulesModalIsOpen, setRulesModalIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [featureData, setFeatureData] =
    React.useState<Awaited<ReturnType<ConditionalsButtonService['initializeFeatures']>>>();

  const mounted = usePromise();
  useEffectOnce(() => {
    mounted(ConditionalsButtonService.initializeFeatures()).then(result => {
      if (!result) {
        setFeatureData(undefined);
      }
      setFeatureData(result);
      setIsLoading(false);
    });
  });

  if (!React.isValidElement(children)) {
    throw new Error('ConditionalLogicButton children must be a valid React element');
  }
  const isDisabled =
    !featureData ||
    ConditionalsButtonService.shouldDisableFeature({
      templateRevisionId,
      initialized: Boolean(featureData),
    });

  return (
    <>
      {React.cloneElement(children, {
        onClick: () => {
          children.props.onClick?.();
          const { user, organization, rulesFeatureIsAvailable, userIsAdmin } = featureData || {};
          if (user && organization) {
            ConditionalsButtonService.openRulesManagerModal({
              templateRevisionId,
              organization,
              user,
              rulesFeatureIsAvailable,
              rulesModalIsOpen,
              setRulesModalIsOpen,
              userIsAdmin: Boolean(userIsAdmin),
              widget,
              taskTemplate,
            });
          }
        },
        isDisabled: children.props.isDisabled || isDisabled,
        isLoading: isLoading || children.props.isLoading,
      })}
    </>
  );
};
