import * as React from 'react';
import { Plate, PlatePluginComponent, PlateProvider } from '@udecode/plate-core';
import { Box, Text } from 'components/design/next';
import {
  createPSFragment,
  createPSHeaders,
  createPSInline,
  createPSInsertBreak,
  createPSLink,
  createPSMarks,
  createPSRenderer,
  createPSVoid,
  ELEMENT_PS_FRAGMENT,
  ELEMENT_PS_IMAGE,
  ELEMENT_PS_SOFT_BREAK,
} from 'pages/pages/_id/edit/page/plugins';
import { BaseRange } from 'slate';
import { RenderPlaceholderProps } from 'slate-react';
import { Break, Fragment, HeaderElement, ImageElement, LinkElement } from 'pages/pages/_id/edit/page/components';
import { Paragraph } from './paragraph';
import { ErrorBoundary } from 'pages/pages/_id/edit/page/error-boundary';
import { EditorParagraph } from 'pages/pages/_id/edit/page/components/elements/paragraph';
import { ForcedState } from 'pages/pages/_id/edit/page/components/balloon-toolbar/context';
import {
  SendRichEmailFormFieldConfig,
  SendRichEmailFormFieldWidget,
  TaskTemplate,
} from '@process-street/subgrade/process';
import { IsEditableProvider } from 'features/rich-text';
import { useFunctionRef } from 'hooks/use-function-ref';
import { Muid } from '@process-street/subgrade/core';
import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3 } from '@udecode/plate-heading';
import { createParagraphPlugin, ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import { createAutoformatPlugin } from '@udecode/plate-autoformat';
import { ELEMENT_LINK } from '@udecode/plate-link';
import { createSoftBreakPlugin } from './plugins/soft-break';
import { EditableRenderer } from 'app/features/widgets/components/send-rich-email/template/editor/send-rich-email-editable-renderer';
import { MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';
import { match, P } from 'ts-pattern';
import { SlateLeaf } from 'pages/pages/_id/edit/page/components/slate-leaf';
import { MutationStatus } from 'react-query';
import { EditorUtils } from 'features/widgets/components/send-rich-email/template/editor/editor-utils';
import { useDebouncedCallback } from 'use-debounce';
import { useWidgetUpdateOverrideListener } from 'hooks/use-widget-update-override-listener';
import {
  createSendRichEmailPlateEditor,
  createSendRichEmailPlugins,
  SendRichEmailEditableProps,
  SendRichEmailNodeEntry,
  SendRichEmailPlatePlugin,
  SendRichEmailValue,
} from './send-rich-email-plate-types';
import { RenderLeafFn } from '@udecode/slate';
import { useFeatureFlag } from 'features/feature-flags';

const elementComponents: Record<string, PlatePluginComponent> = {
  [ELEMENT_PARAGRAPH]: Paragraph,
  [ELEMENT_PS_IMAGE]: ImageElement,
  [ELEMENT_H1]: HeaderElement,
  [ELEMENT_H2]: HeaderElement,
  [ELEMENT_H3]: HeaderElement,
};

const inlineComponents: Record<string, PlatePluginComponent> = {
  [ELEMENT_PS_SOFT_BREAK]: Break,
  [ELEMENT_PS_FRAGMENT]: Fragment,
  [ELEMENT_LINK]: LinkElement,
};

const components = { ...inlineComponents, ...elementComponents } as Record<string, PlatePluginComponent>;

export type SendRichEmailEditorMode = 'Template' | 'Checklist';
type SendRichEmailEditorProps = {
  id: Muid;
  widget: SendRichEmailFormFieldWidget;
  body: string;
  templateRevisionId: Muid;
  onUpdate?: (widget: Pick<SendRichEmailFormFieldWidget['config'], 'richEditorBody'>) => void;
  mode: SendRichEmailEditorMode;
  taskTemplate?: TaskTemplate;
} & SendRichEmailEditableProps;

const decorate = ([node, path]: SendRichEmailNodeEntry): BaseRange[] => {
  const nodeText: string | null = match(node)
    .with({ text: P.string }, ({ text }) => text)
    .otherwise(() => null);

  if (!nodeText) return [];

  // Find all the merge tags
  const matches = [...nodeText.matchAll(MergeTagStringReplacementUtils.TAGS_REGEXP)];

  const mergeTagsWithIndexes: [string, number][] = matches ? matches.map(match => [match[0], match.index ?? 0]) : [];

  const res = mergeTagsWithIndexes.map(([mergeTag, index]) => ({
    anchor: {
      path,
      // start of the range to be highlighted
      offset: index,
    },
    focus: {
      path,
      // end of the range to be highlighted
      offset: index + mergeTag.length,
    },
    decoration: 'mergeTag',
  }));

  return res;
};

const renderLeaf: RenderLeafFn<SendRichEmailValue> = ({ attributes, children, leaf, text }) => {
  if (leaf.decoration === 'mergeTag') {
    children = (
      <Text as="span" bgColor="brand.100" {...attributes}>
        {children}
      </Text>
    );
  }

  return <SlateLeaf {...{ attributes, children, leaf, text }} />;
};

export const SendRichEmailEditorV1: React.FC<React.PropsWithChildren<SendRichEmailEditorProps>> = ({
  mode,
  onUpdate,
  body,
  id,
  widget,
  templateRevisionId,
  taskTemplate,
  ...props
}) => {
  const onUpdateRef = useFunctionRef(onUpdate);
  const [isAiGeneratingContent, setIsAiGeneratingContent] = React.useState(false);

  const [elements, setElements] = React.useState<SendRichEmailValue>(EditorUtils.convertBodyToElements(body));
  const [keyCounter, setKeyCounter] = React.useState(0);

  const handleAiContentGeneration = (config: SendRichEmailFormFieldConfig) =>
    setTimeout(() => {
      handleChange(EditorUtils.convertBodyToElements(config.richEditorBody ?? ''));
      // Force Plate to rerender after AI generation.
      // Try to remove this after a Plate upgrade from version 22.
      setKeyCounter(c => c + 1);
    });

  useWidgetUpdateOverrideListener(widget, updated => {
    setElements(EditorUtils.convertBodyToElements(updated.config.richEditorBody ?? ''));
    setKeyCounter(c => c + 1); // Force Plate to rerender
  });

  const isTableFormFieldEnabled = useFeatureFlag('tableFormField');
  const update = React.useCallback(() => {
    onUpdateRef.current?.({
      richEditorBody: EditorUtils.serializeElementsWithStyleString(elements, isTableFormFieldEnabled),
    });
  }, [elements, isTableFormFieldEnabled, onUpdateRef]);

  const debouncedUpdate = useDebouncedCallback(update, 500);

  const handleChange = React.useCallback(
    (newElements: SendRichEmailValue) => {
      if (newElements === elements) return;
      setElements(newElements);
      debouncedUpdate();
    },
    [debouncedUpdate, elements],
  );

  const plugins = React.useMemo(() => {
    const p = createSendRichEmailPlugins([
      createPSInsertBreak(),
      createPSInline(),
      createPSVoid(),
      createPSRenderer(),
      createPSFragment(),
      createPSHeaders() as SendRichEmailPlatePlugin,
      createPSLink(),
      createPSMarks(),
      createSoftBreakPlugin(),
      createParagraphPlugin(),
      createAutoformatPlugin({
        options: {
          rules: [],
        },
      }),
    ]);

    // override plugins with components
    return p;
  }, []);

  // For some reason useRef causes a rerender loop, while useMemo doesn't
  // Instantiating the editor outside of Plate so we have a handle on it for other effects.
  const editor = React.useMemo(() => {
    return createSendRichEmailPlateEditor({ plugins, components, id });
  }, [id, plugins]);

  const editablePropsBase = React.useMemo(
    (): SendRichEmailEditableProps => ({
      ...props,
      'placeholder': props.readOnly ? 'Empty' : 'Start writing something…',
      // this rendered within a <p> so need to as="span" everything
      'renderPlaceholder': ({ children, attributes: { style: _, ...attributes } }: RenderPlaceholderProps) => (
        <Box
          as="span"
          display="block"
          pointerEvents="none"
          userSelect="none"
          opacity="0.3"
          width="100%"
          position="relative"
          {...attributes}
        >
          <Box position="absolute" as="span">
            <EditorParagraph as="span" variant="widget">
              {children}
            </EditorParagraph>
          </Box>
        </Box>
      ),
      'aria-label': 'editor',
      decorate,
      renderLeaf,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- regenerate on editor change
    [editor, props, isAiGeneratingContent],
  );

  const handleAiContentGenerationStatusChange = (status: MutationStatus) => {
    setIsAiGeneratingContent(status === 'loading');
  };

  const [forcedBalloonToolbarState, setForcedBalloonToolbarState] = React.useState<ForcedState>('none');
  const balloonToolbarContext = React.useMemo(() => {
    return { setForcedState: setForcedBalloonToolbarState, forcedState: forcedBalloonToolbarState };
  }, [setForcedBalloonToolbarState, forcedBalloonToolbarState]);

  const recoverFromException = () => {
    editor.selection = null;
    setForcedBalloonToolbarState('none');
  };

  return (
    <IsEditableProvider isEditable={!props.readOnly}>
      <Box position="relative" w="full" h="full">
        <ErrorBoundary recover={recoverFromException} resetKeys={[editor.selection]}>
          <PlateProvider id={id} onChange={handleChange} editor={editor} value={elements} key={keyCounter}>
            <Plate
              id={id}
              editableProps={editablePropsBase}
              renderEditable={editableNode => (
                <EditableRenderer
                  {...{
                    balloonToolbarContext,
                    editableNode,
                    mode,
                    readOnly: props.readOnly,
                    templateRevisionId,
                    widget,
                    isAiGeneratingContent,
                    editor,
                    taskTemplate,
                    onContentGeneration: handleAiContentGeneration,
                    onContentGenerationStatusChange: handleAiContentGenerationStatusChange,
                  }}
                />
              )}
            ></Plate>
          </PlateProvider>
        </ErrorBoundary>
      </Box>
    </IsEditableProvider>
  );
};
