import * as React from 'react';
import { Drawer, DrawerBody, DrawerContent, DrawerCloseButton, DrawerProps, Icon } from 'components/design/next';
import { useUiDisclosure } from 'pages/forms/_id/shared';
import { DrawerContentProps } from '@chakra-ui/modal';

type WidgetSidebarDrawerProps = Omit<DrawerProps, 'children' | 'isOpen' | 'onClose'> & {
  body: React.ReactElement;
  contentProps: DrawerContentProps;
  isOpen?: boolean;
};

export function InsertWidgetDrawer({ body, contentProps, ...rest }: WidgetSidebarDrawerProps) {
  const disclosure = useUiDisclosure('insertWidget');

  return (
    <Drawer
      variant="aside"
      trapFocus={false}
      blockScrollOnMount={false}
      {...disclosure}
      {...rest}
      portalProps={{ appendToParentPortal: true }}
    >
      <DrawerContent maxW={{ base: 'full', md: '180px' }} {...contentProps} zIndex={1}>
        <DrawerCloseButton>
          <Icon icon="times" size="4" color="gray.400" />
        </DrawerCloseButton>
        <DrawerBody px="0" py="4">
          {body}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
