import * as React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Text,
  HStack,
  Icon,
  FormControl,
  FormLabel,
  Tooltip,
  VStack,
  Spacer,
  Box,
  Center,
  Skeleton,
  IconButton,
  useBreakpointValue,
  Divider,
} from 'components/design/next';
import { CoverImageByTemplateIdQuery } from 'features/cover-image/query-builder';
import { CDNImage } from 'components/cdn-image';
import { match, P } from 'ts-pattern';
import { DeleteBackgroundImageAlertDialog } from './delete-background-image-alert-dialog';
import { AddBackgroundImagePopover } from './add-background-image-popover';
import { TOP_BAR_HEIGHT_CSS_VAR } from '../form-editor-top-bar';
import { useUiDisclosure } from '../ui-context';
import { BrandingSwitch } from 'pages/forms/_id/shared/design-drawer/branding-switch';
import { Template, TemplateRevision } from '@process-street/subgrade/process';
import { PermissionsSwitch } from 'pages/forms/_id/shared/design-drawer/permissions-switch';
import { ActivityButton } from 'pages/forms/_id/shared/design-drawer/activity-button';
import { FormDescription } from 'pages/forms/_id/shared/design-drawer/form-description';
import { DeleteTemplateButton } from 'app/components/focus-bar/workflow/delete-template-button';

export type DesignDrawerProps = {
  template: Template;
  templateRevision: TemplateRevision;
};

export const DesignDrawer: React.FC<React.PropsWithChildren<DesignDrawerProps>> = ({ template, templateRevision }) => {
  const templateId = templateRevision.template.id;
  const drawerDisclosure = useUiDisclosure('design', {
    onClose: () => {
      coverImageDisclosure.onClose();
    },
  });
  const coverImageDisclosure = useDisclosure();
  const deleteDisclosure = useDisclosure();

  const coverImageQuery = CoverImageByTemplateIdQuery.useQuery({ templateId }, { enabled: drawerDisclosure.isOpen });
  const drawerRef = React.useRef<HTMLDivElement>(null);
  const size = useBreakpointValue({ base: 'full', md: 'xs' });

  return (
    <>
      <Drawer {...drawerDisclosure} size={size}>
        <DrawerContent
          bg="gray.50"
          h={`calc(100vh - ${TOP_BAR_HEIGHT_CSS_VAR})`}
          top={`${TOP_BAR_HEIGHT_CSS_VAR} !important`}
          bottom={0}
          ref={drawerRef}
        >
          <DrawerCloseButton>
            <Icon icon="times" size="4" color="gray.400" />
          </DrawerCloseButton>

          <DrawerHeader>
            <HStack>
              <Icon icon="cog" size="4" variant="far" color="gray.400" />

              <Text variant="1" textTransform="uppercase" fontWeight="bold" color="gray.500">
                Settings
              </Text>
            </HStack>
          </DrawerHeader>

          <DrawerBody flex="unset">
            <VStack alignItems="flex-start" spacing="6" divider={<Divider />}>
              <FormDescription template={template} />

              <VStack alignItems="flex-start" spacing="4">
                <Text variant="-2u" color="gray.400">
                  Design
                </Text>
                <FormControl>
                  <HStack>
                    <FormLabel mb="0" mr="0">
                      Background Image
                    </FormLabel>
                    <Tooltip label="Images wider than 1920px work best" shouldWrapChildren hasArrow>
                      <Icon icon="info-circle" size="4" variant="far" color="gray.400" />
                    </Tooltip>

                    <Spacer />

                    {match(coverImageQuery)
                      .with({ status: 'loading' }, () => (
                        <Skeleton>
                          <Button>Add</Button>
                        </Skeleton>
                      ))
                      .with({ status: 'success', data: P.not(undefined) }, () => (
                        <IconButton
                          aria-label="delete background image"
                          variant="tertiary"
                          icon={<Icon icon="trash-alt" size="4" variant="far" />}
                          onClick={() => deleteDisclosure.onOpen()}
                        />
                      ))
                      .otherwise(() => (
                        <AddBackgroundImagePopover {...coverImageDisclosure} containerRef={drawerRef} />
                      ))}
                  </HStack>
                </FormControl>

                {match(coverImageQuery)
                  .with({ status: 'loading' }, () => <Skeleton w="full" h="124px" />)
                  .with({ status: 'success', data: P.not(undefined) }, ({ data: coverImage }) => (
                    <Box
                      w="full"
                      h="33"
                      py="1"
                      px="2"
                      borderRadius="base"
                      borderColor="gray.300"
                      borderWidth="1px"
                      borderStyle="solid"
                    >
                      <Center h="full" position="relative">
                        <CDNImage
                          alt={coverImage.s3File.originalName}
                          s3File={coverImage.s3File}
                          {...{
                            transformation: {
                              width: 'auto',
                              height: 124,
                            },
                            maxHeight: '124px',
                          }}
                        />
                      </Center>
                    </Box>
                  ))
                  .otherwise(() => null)}

                {<BrandingSwitch templateRevision={templateRevision} />}
              </VStack>

              <VStack alignItems="flex-start" spacing="4">
                <Text variant="-2u" color="gray.400">
                  Permissions
                </Text>
                <PermissionsSwitch template={template} />
              </VStack>

              <VStack alignItems="flex-start" spacing="6">
                <ActivityButton templateId={template.id} />

                <DeleteTemplateButton templateId={templateId} view="show">
                  <Button
                    colorScheme="red"
                    variant="link"
                    leftIcon={<Icon size="4" icon="trash-alt" />}
                    fontWeight="400"
                    color="red.500"
                  >
                    Delete form
                  </Button>
                </DeleteTemplateButton>
              </VStack>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <DeleteBackgroundImageAlertDialog {...deleteDisclosure} />
    </>
  );
};
