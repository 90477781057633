import { createSelector } from 'reselect';

export const inboxStatsSelector = state => state.stats.inbox;

export const selectInboxUserStatsInOrganization = (organizationId, userId) =>
  createSelector(inboxStatsSelector, inbox => {
    const allUserStats = inbox[userId] || {};
    const orgUserStats = allUserStats[organizationId] || {};
    return { stats: orgUserStats };
  });
