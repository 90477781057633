import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import '@uirouter/angularjs/release/stateEvents'; // Transitional, provides $state* events
import 'angular-animate';
import 'angular-elastic';
import 'angular-sanitize';
import 'angular-ui-bootstrap';
import 'angular-ui-event';
import 'angular-ui-sortable';
import 'angular-ui-tinymce';
import 'angularjs-stripe-elements';
import 'focus-visible';
import 'ng-infinite-scroll';
import 'ng-redux';
import 'ng-tags-input';
import 'ngclipboard';
import '@process-street/ui-select';
import 'ui-select-infinity';
import { locationChanged } from 'reducers/location/location.actions';
import { ReducersModule } from 'reducers/reducers.module';
import { SessionSelector } from 'reducers/session/session.selectors';
import { ComponentsModule } from 'components/components.module';
import { ServicesModule } from 'services/services.module';
import { GtmService } from 'features/google/gtm-service';
import { SentryService } from 'components/sentry/sentry.service';
import { trace } from 'components/trace';
import KonamiCode from 'konami-code.js';
import '@process-street/jquery-ui/ui/version';
import '@process-street/jquery-ui/ui/widget';
import '@process-street/jquery-ui/ui/data';
import '@process-street/jquery-ui/ui/scroll-parent';
import '@process-street/jquery-ui/ui/plugin';
import '@process-street/jquery-ui/ui/widgets/mouse';
import '@process-street/jquery-ui/ui/widgets/draggable';
import '@process-street/jquery-ui/ui/widgets/sortable';
import { store } from 'features/redux/store';
import { SessionSyncListener } from './startup/session-sync-listener';
import './styles/boulevard-theme/boulevard-theme-main.scss';
import * as Sentry from '@sentry/browser';
import { sentryConfigAngular } from './sentry.config';
import { enableMapSet } from 'immer';
import { AngularInjectorContext, ReactToAngularPortals } from 'angulareact';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { ReactRoot } from 'components/react-root';
import { IntercomService } from 'services/interop/intercom-service';
import { RootPagesModule } from 'pages/pages.module';
import { env, getEnv } from 'components/common/env';
import { GoNativeService } from 'features/go-native/go-native-service';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { WatchdogService } from 'services/boot/watchdog-service';
import { EventEmitterService } from '@process-street/subgrade/util/event-emitter-service';
import { HubSpotService } from 'features/hub-spot/hub-spot-service';
import { SessionService } from 'app/services/session-service';

enableMapSet();

// disable Sentry in unit tests
if (sentryConfigAngular.dsn !== 'LOCAL') {
  Sentry.init(sentryConfigAngular);
}

const { AG_GRID_LICENSE_KEY } = getEnv();
if (AG_GRID_LICENSE_KEY) {
  LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);
}

WatchdogService.init();

angular.module('frontStreetApp.controllers', [
  'angularjs-stripe-elements',
  'monospaced.elastic',
  'ngAnimate',
  'ngSanitize',
  'ui.bootstrap',
  'ui.event',
  'ui.sortable',
  'ui.tinymce',
]);

angular.module('frontStreetApp.directives', [
  'infinite-scroll',
  'ngclipboard',
  'ngSanitize',
  'ngTagsInput',
  'ui.select',
  'ui-select-infinity',
]);
angular.module('frontStreetApp.filters', []);

angular
  .module('frontStreetApp.services', [
    ComponentsModule,
    RootPagesModule,
    ReducersModule,
    uiRouter,
    'ui.router.state.events', // Transitional, provides $state* events
    'ngSanitize',
    'ui.bootstrap',
    'frontStreetApp.dao',
    'ngRedux',
    ServicesModule,
  ])
  .config($ngReduxProvider => {
    $ngReduxProvider.provideStore(store);
  });

angular.module('frontStreetApp.dao', []);

angular
  .module('frontStreetApp', [
    'frontStreetApp.exceptionOverwrite',
    'frontStreetApp.dao',
    'frontStreetApp.states',
    'frontStreetApp.controllers',
    'frontStreetApp.directives',
    'frontStreetApp.filters',
    'frontStreetApp.services',
    uiRouter,
  ])
  .config(($locationProvider, $httpProvider, $sceDelegateProvider, StripeElementsProvider) => {
    // This gets rid of the '#' in the URL
    $locationProvider.html5Mode(true);

    $sceDelegateProvider.trustedResourceUrlList([
      'self',
      'https://st-process-localhost.s3.amazonaws.com/**',
      'https://st-process-test.s3.amazonaws.com/**',
      'https://st-process-production.s3.amazonaws.com/**',
      'https://www.youtube.com/embed/**',
      'https://player.vimeo.com/video/**',
      'https://www.loom.com/embed/**',
      'https://www.google.com/m8/feeds/contacts/default/full/**',
    ]);

    // Stripe
    StripeElementsProvider.setAPIKey(env.STRIPE_PUB_KEY);
  })
  .run(
    (
      $location,
      $ngRedux,
      $rootScope,
      $state,
      $stateParams,
      $timeout,
      $transitions,
      $window,
      appBootService,
      auth,
      FacebookService,
      FileUploadService,
      PromiseQueueService,
      RouteService,
      TempDataService,
      ToastService,
    ) => {
      const logger = trace({ name: 'run' });

      // PS-5576 - we get the time from server to make sure token is refreshed correctly
      $ngRedux.dispatch(auth.setTimeOffset());

      // Konami
      new KonamiCode(() => $state.go('developer'));

      let firstLoad = true;

      $transitions.onStart({}, _trans => {
        if (firstLoad) {
          firstLoad = false;
          if (auth.isLoggedIn()) {
            return appBootService.setupSessionAndBootLoggedIn();
          }
        }
      });

      $transitions.onStart({}, trans => {
        trans.promise.then(state => {
          const location = {
            url: state.url,
            name: state.name,
            params: { ...$stateParams },
          };
          $ngRedux.dispatch(locationChanged(location));
        });
      });

      if (GoNativeService.isGoNativeApp()) {
        $window.gonativeTabs = {
          selectMyWork: () => {
            $state.go('myWork');
          },
          selectLibrary: () => {
            $state.go('dashboard');
          },
          selectReports: () => {
            $state.go('reports');
          },
          selectComments: () => {
            $state.go('comments');
          },
        };
        $transitions.onStart({}, trans => {
          const names = ['myWork', 'dashboard', 'reports', 'comments'];
          if (
            names.some(name => trans.from().name.startsWith(name)) &&
            !names.some(name => trans.to().name.startsWith(name))
          ) {
            GoNativeService.deselectTab();
          }
        });
      }

      // This was suggested on Stack Overflow: https://stackoverflow.com/q/44935174
      // It's needed so that Intercom will check if a user has a new message
      $transitions.onSuccess({}, () => {
        IntercomService.ping();
      });

      // Facebook boot
      FacebookService.boot();

      // To expose state for pageTitle
      $rootScope.$state = $state;

      $rootScope.$on('$stateChangeStart', (event, toState, toParams) => {
        return RouteService.onStateChangeStart(event, toState, toParams);
      });

      $rootScope.$on('$stateChangeSuccess', (__event, toState, __toParams, fromState, fromParams) => {
        TempDataService.pushState({
          state: fromState,
          params: fromParams,
        });

        if (toState.name !== 'loader') {
          // We use a timeout so that if a dependent script calls location.href, it'll be updated to the current URL
          const profile = SessionService.getProfile();
          $timeout(() => {
            const path = $state.href($state.$current);
            GtmService.pushEvent({
              event: 'pageview',
              fields: {
                page: path,
                userId: profile?.userId,
                userUsername: profile?.username,
                userEmail: profile?.email,
              },
            });
            HubSpotService.setPath(path);
            HubSpotService.trackPageView();
          });
        }
      });

      // Listen to EventEmitter relay event
      EventEmitterService.on(EventEmitterService.ANGULAR_RELAY_EVENT, (relayedEventName, ...args) => {
        $rootScope.$broadcast(relayedEventName, ...args);
      });

      // Suppress backspace
      angular.element($window.document).on('keydown', event => {
        if (event.keyCode === 8) {
          const selector =
            'input:not([type]),' +
            '[type=email],' +
            '[type=number],' +
            '[type=tel],' +
            '[type=text],' +
            '[type=password],' +
            '[type=url],' +
            '[type=search],' +
            'textarea,' +
            '[contenteditable=true]';
          const text = angular.element(event.target).is(selector);
          const editable = !(event.target.readOnly || event.target.disabled);
          if (!(text && editable)) {
            logger.info('backspace suppressed');
            event.preventDefault();
          }
        }
      });

      $window.ononline = function () {
        // replaced $rootScope.$apply with $timeout to avoid $digest in progress error
        $timeout(() => {
          ToastService.openToast({
            status: 'success',
            title: `Re-connected to the internet. Nothing to worry about.`,
          });
        });
      };

      $window.onoffline = function () {
        // replaced $rootScope.$apply with $timeout to avoid $digest in progress error
        $timeout(() => {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems connecting`,
            description: 'Looks like we lost contact with the internet, some of your data might not save.',
          });
        });
      };

      $window.onbeforeunload = function () {
        // Don't show this if we're logged out
        if (!auth.isLoggedIn()) {
          return undefined;
        }
        if (PromiseQueueService.hasAnyInProgress()) {
          return 'Are you sure you want to go? Some of your data is still saving.';
        }
        if (FileUploadService.hasAnyInProgress()) {
          return 'Are you sure you want to go? Some files are still uploading.';
        }

        return undefined;
      };
    },
  )
  .run($ngRedux => {
    const selectedOrganizationId = SessionSelector.getSelectedOrganizationId($ngRedux.getState());
    if (selectedOrganizationId) {
      SentryService.setOrganizationId(selectedOrganizationId);
    }
  })
  .run(SessionSyncListener)
  .run($injector => {
    const container = document.getElementById('react-root');
    const root = createRoot(container);

    root.render(
      <React.StrictMode>
        <AngularInjectorContext.Provider value={$injector}>
          <ReactRoot>
            <ReactToAngularPortals />
          </ReactRoot>
        </AngularInjectorContext.Provider>
      </React.StrictMode>,
    );
  });
