import * as React from 'react';
import { Icon, MenuItem, MenuItemProps } from 'components/design/next';
import { FieldType, FormFieldWidgetOfType, TemplateRevision, TemplateType } from '@process-street/subgrade/process';
import { useUIActorRef } from 'pages/forms/_id/shared';
import { useConditionalLogicModalStore } from 'features/conditional-logic/components/modal/store';
import { useConditionalLogicButton } from 'pages/templates/_id/components/widget-menu/use-conditional-logic-button';
import { ConditionalLogicButtonWrapper } from 'app/pages/templates/_id/components/conditional-logic-button-wrapper';
import { match } from 'ts-pattern';

export type ConditionalLogicMenuItemProps<Type extends FieldType> = MenuItemProps & {
  widget: FormFieldWidgetOfType<Type>;
  templateRevisionId: TemplateRevision['id'];
  templateType: TemplateType;
};

export const ConditionalLogicMenuItem = <Type extends FieldType>({
  widget,
  templateRevisionId,
  templateType,
  ...props
}: ConditionalLogicMenuItemProps<Type>) => {
  const { dispatch } = useConditionalLogicModalStore();
  const { uiActorRef } = useUIActorRef();
  const { shouldShowConditionalLogicButton, widgetHasConditionalLogic } = useConditionalLogicButton(widget);

  if (!shouldShowConditionalLogicButton) return null;

  return (
    <>
      {match(templateType)
        .with(TemplateType.Form, () => (
          <MenuItem
            iconSpacing="2"
            aria-label="Open conditional logic"
            icon={<Icon icon="shuffle" size="4" />}
            {...(widgetHasConditionalLogic
              ? { 'color': 'purple.500', 'aria-description': 'This widget has conditional logic' }
              : {})}
            onClick={() => {
              uiActorRef.send({ type: 'OPEN_DISCLOSURE', name: 'conditionalLogic' });
              dispatch({ type: 'SET_SELECTED_WIDGET', payload: widget, shouldAutoCreateNewRule: true });
            }}
            {...props}
          >
            Conditional Logic
          </MenuItem>
        ))
        .with(TemplateType.Playbook, () => (
          <ConditionalLogicButtonWrapper templateRevisionId={templateRevisionId} widget={widget}>
            <MenuItem
              iconSpacing="2"
              aria-label="Open conditional logic"
              icon={<Icon icon="shuffle" size="4" />}
              {...(widgetHasConditionalLogic
                ? { 'color': 'purple.500', 'aria-description': 'This widget has conditional logic' }
                : {})}
              {...props}
            >
              Conditional Logic
            </MenuItem>
          </ConditionalLogicButtonWrapper>
        ))
        .otherwise(() => null)}
    </>
  );
};
