import { entitiesSelector } from 'reducers/entities/entities.selectors';
import { lookupsSelector } from 'reducers/lookups/lookups.selectors';
import { FieldType } from '@process-street/subgrade/process';
import { WidgetSelector } from 'components/widgets/store/widget.selector';
import { Entities } from 'reducers/entities/entities';

import { createSelector } from 'reselect';
import { safeEntityMapToArrayByIds } from '../../../reducers/entities/safe-entity-map-to-array-by-ids';

const getEntityMap = state => entitiesSelector(state)[Entities.DYNAMIC_DUE_DATE];

const getById = id => state => getEntityMap(state)[id];

const getLookups = state => lookupsSelector(state)[Entities.DYNAMIC_DUE_DATE];

const getLookupByTemplateRevisionId = state => getLookups(state).byTemplateRevisionId;

const getLookupByChecklistRevisionId = state => getLookups(state).byChecklistRevisionId;

const getAllIdsByTemplateRevisionId = templateRevisionId =>
  createSelector(getLookupByTemplateRevisionId, lookupMap => lookupMap[templateRevisionId] || []);

const getAllIdsByChecklistRevisionId = checklistRevisionId =>
  createSelector(getLookupByChecklistRevisionId, lookupMap => lookupMap[checklistRevisionId] || []);

const getAllByTemplateRevisionId = templateRevisionId =>
  createSelector([getAllIdsByTemplateRevisionId(templateRevisionId), getEntityMap], (ids, entities) =>
    safeEntityMapToArrayByIds(entities, ids),
  );

const getAllByChecklistRevisionId = checklistRevisionId =>
  createSelector([getAllIdsByChecklistRevisionId(checklistRevisionId), getEntityMap], (ids, entities) =>
    safeEntityMapToArrayByIds(entities, ids),
  );

const getDateWidgetsByTemplateRevisionId = templateRevisionId => state =>
  WidgetSelector.getAllByTemplateRevisionId(templateRevisionId)(state)
    .filter(widget => widget.fieldType === FieldType.Date)
    .sort((a, b) => (a.label || '').localeCompare(b.label || ''));

const getDateWidgetsByChecklistRevisionId = templateRevisionId => state =>
  WidgetSelector.getAllByChecklistRevisionId(templateRevisionId)(state)
    .filter(widget => widget.fieldType === FieldType.Date)
    .sort((a, b) => (a.label || '').localeCompare(b.label || ''));

const getAllByTemplateRevisionIdAndWidget = (templateRevisionId, widget) => state => {
  if (widget.fieldType !== FieldType.Date) {
    return [];
  }

  const formFieldWidgetGroupId = widget.header && widget.header.group && widget.header.group.id;
  return getAllByTemplateRevisionId(templateRevisionId)(state).filter(
    rule =>
      rule.formFieldWidgetGroup &&
      rule.formFieldWidgetGroup.id &&
      rule.formFieldWidgetGroup.id === formFieldWidgetGroupId,
  );
};

export const DynamicDueDatesSelector = {
  getAllByChecklistRevisionId,
  getAllByTemplateRevisionId,
  getAllByTemplateRevisionIdAndWidget,
  getById,
  getDateWidgetsByTemplateRevisionId,
  getDateWidgetsByChecklistRevisionId,
};
