import * as React from 'react';
import { Button, HStack, Icon, Text, VStack } from 'components/design/next';
import { ButtonProps, TextProps } from '@chakra-ui/react';
import { useInjector } from 'components/injection-provider';
import { Template } from '@process-street/subgrade/process';
import { useGetInboxStatsByOrganizationQuery } from 'features/inbox/query-builder';
import { InboxConstants } from '@process-street/subgrade/inbox';
import { InboxListUtils } from 'directives/inbox/list/list-utils';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { DelayedSpinner } from 'components/design/next/delayed-spinner';
import { useFeatureFlag } from 'features/feature-flags';
import { useNavigate } from 'app/adapters/navigation';
import qs from 'qs';
import { TasksTableUtils } from 'pages/tasks/components/tasks-table/tasks-table-utils';

export interface AssignmentsProps {
  template?: Template;
}

type AssignmentButtonProps = {
  children: React.ReactNode;
} & ButtonProps;

const AssignmentButton = ({ children, ...rest }: AssignmentButtonProps) => (
  <Button
    variant="tertiary"
    borderColor="gray.100"
    borderWidth="thin"
    borderRadius="8px"
    bgColor="white"
    h={21}
    w={21}
    {...rest}
  >
    <HStack>
      <VStack>{children}</VStack>
    </HStack>
  </Button>
);

const assignmentButtonCountProps: TextProps = {
  fontWeight: 'normal',
  fontSize: '18px',
};

const assignmentButtonCopyProps: TextProps = {
  color: 'gray.500',
  fontWeight: 'medium',
  fontSize: 'xs',
};

export const Assignments: React.FC<React.PropsWithChildren<AssignmentsProps>> = ({ template }) => {
  const userInfoQuery = useGetCurrentUserInfoQuery();
  const userId = userInfoQuery.data?.user.id;
  const organizationId = userInfoQuery.data?.organizationMembership.organization.id;

  const isInboxRevampEnabled = useFeatureFlag('inboxRevamp');

  const inboxQuery = useGetInboxStatsByOrganizationQuery(
    {
      templateId: template?.id,
      organizationId: organizationId!,
      userId: userId!,
      includeOverdue: true,
    },
    { enabled: Boolean(userId) && Boolean(organizationId) && Boolean(template) },
  );

  const upcoming = inboxQuery.data?.upcoming ?? 0;
  // total overdue count can be obtained from checklist-analytics endpoint
  const overdue = inboxQuery.data?.overdue ?? 0;
  // no due date count can be derived from total inbox and overdue count
  const noDueDate = (inboxQuery.data?.inbox ?? 0) - overdue;

  const isEmpty = upcoming === 0 && overdue === 0 && noDueDate === 0;

  const { $state } = useInjector('$state');
  const navigate = useNavigate();

  const isLoading = userInfoQuery.isLoading || inboxQuery.isLoading;

  const goToOldInbox = () => $state.go('inbox', { searchTerm: template?.name });
  const goToInbox = () => navigate({ pathname: 'myWork', search: qs.stringify({ templateId: template?.id }) });
  const goToOldNoDueDate = () =>
    $state.go('inbox', {
      'searchTerm': template?.name,
      '#': InboxListUtils.resolveGroupId(InboxConstants.StdGroupKey.NO_DUE_DATE),
    });
  const goToNoDueDate = () =>
    navigate({
      pathname: 'myWork',
      search: qs.stringify({ templateId: template?.id }),
      hash: TasksTableUtils.TasksTableHash.NoDueDate,
    });
  const goToOldUpcoming = () => $state.go('inbox', { tab: 'upcoming', searchTerm: template?.name });
  const goToUpcoming = () =>
    navigate({
      pathname: 'myWork',
      search: qs.stringify({ templateId: template?.id }),
      hash: TasksTableUtils.TasksTableHash.Upcoming,
    });

  return (
    <VStack spacing={3} alignItems="flex-start">
      <HStack spacing={2}>
        <Icon icon="user" variant="far" size="3" />
        <Text fontWeight="bold" fontSize="sm" color="gray.600">
          Assigned to me
        </Text>
      </HStack>
      <DelayedSpinner isLoaded={Boolean(template) && !isLoading} isCentered={true}>
        {isEmpty && !isLoading && (
          <Text color="gray.500" fontSize="sm">
            You don’t have assignments for this workflow.
          </Text>
        )}
        {!isEmpty && !isLoading && (
          <HStack spacing={3}>
            {overdue > 0 && (
              <AssignmentButton onClick={isInboxRevampEnabled ? goToInbox : goToOldInbox}>
                <Text {...assignmentButtonCountProps} color="red.500">
                  {overdue}
                </Text>
                <Text {...assignmentButtonCopyProps}>Overdue</Text>
              </AssignmentButton>
            )}
            {upcoming > 0 && (
              <AssignmentButton onClick={isInboxRevampEnabled ? goToUpcoming : goToOldUpcoming}>
                <Text {...assignmentButtonCountProps} color="orange.500">
                  {upcoming}
                </Text>
                <Text {...assignmentButtonCopyProps}>Upcoming</Text>
              </AssignmentButton>
            )}
            {noDueDate > 0 && (
              <AssignmentButton onClick={isInboxRevampEnabled ? goToNoDueDate : goToOldNoDueDate}>
                <Text {...assignmentButtonCountProps} color="gray.600">
                  {noDueDate}
                </Text>
                <Text {...assignmentButtonCopyProps}>No due date</Text>
              </AssignmentButton>
            )}
          </HStack>
        )}
      </DelayedSpinner>
    </VStack>
  );
};
