import { Checklist, ChecklistUtils } from '@process-street/subgrade/process';
import { StringUtils } from '@process-street/subgrade/util';
import { DefaultErrorMessages } from 'app/components/utils/error-messages';
import { GetChecklistQuery, UpdateChecklistNameMutation } from 'app/features/checklists/query-builder';
import { MaskedChevrons } from 'app/features/widgets/components/masked-input/masked-chevrons';
import {
  Box,
  Editable,
  EditablePreviewProps,
  EditableTextarea,
  useEditableContext,
  useToast,
} from 'components/design/next';
import * as React from 'react';
import { useQueryClient } from 'react-query';

type TaskListChecklistNameProps = {
  checklist: Checklist;
};

const CONTENT_MARGIN_PX = 10;
// textarea content is 6px smaller than preview content
const CONTENT_MARGIN_PX_EDITING = CONTENT_MARGIN_PX + 6;
const TEXTAREA_BORDER_WIDTH = 1;
const ROUNDING_PX = 5;

export const TaskListChecklistName = ({ checklist }: TaskListChecklistNameProps) => {
  const queryClient = useQueryClient();
  const [name, setName] = React.useState(checklist.name);
  const toast = useToast();
  const mutation = UpdateChecklistNameMutation.useMutation({
    onSuccess: (result: Checklist) => {
      queryClient.setQueryData(GetChecklistQuery.getKey({ checklistId: result.id }), result);
      toast({ title: 'Workflow run name updated', status: 'success', position: 'top' });
    },
    onError: () => {
      // revert
      setName(checklist.name);
      toast({
        title: "We're having problems updating the workflow run name",
        description: DefaultErrorMessages.unexpectedErrorDescription,
        status: 'error',
        position: 'top',
      });
    },
  });
  const handleUpdateName = (name: string) => {
    if (name === checklist.name) {
      return;
    } else if (StringUtils.isBlank(name)) {
      setName(checklist.name);
      return;
    } else {
      mutation.mutate({ checklistId: checklist.id, name });
    }
  };

  React.useEffect(
    function syncChecklistName() {
      setName(name => (checklist.name === name ? name : checklist.name));
    },
    [checklist.name],
  );

  // either the preview or the textarea is visible at a time
  // so we need both to assess content size
  const previewRef = React.useRef<HTMLDivElement>(null);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  /** Grow/shrink textarea as text line count changes. */
  const setTextareaHeight = () => {
    if (!textareaRef.current || !previewRef.current) return;
    // force the browser to update scrollHeight
    textareaRef.current.style.height = '';
    const suggestedTextareaHeight = (textareaRef.current?.scrollHeight ?? 0) + TEXTAREA_BORDER_WIDTH * 2;
    if (textareaRef.current.offsetHeight !== suggestedTextareaHeight) {
      textareaRef.current.style.height = suggestedTextareaHeight + 'px';
    }
  };

  const isEditEnabled = ChecklistUtils.isActive(checklist);

  const contentHeight = Math.max(previewRef.current?.offsetHeight ?? 0, textareaRef.current?.offsetHeight ?? 0);
  // round up min height to prevent 1-2px shifts due to rounding errors
  const minHeight =
    Math.ceil(
      (contentHeight + (textareaRef.current?.hasAttribute('hidden') ? CONTENT_MARGIN_PX : CONTENT_MARGIN_PX_EDITING)) /
        ROUNDING_PX,
    ) * ROUNDING_PX;

  return (
    <Editable
      // vertically align content inside
      display="flex"
      flexDirection="column"
      justifyContent="center"
      isDisabled={!isEditEnabled}
      fontSize="18px"
      fontWeight={500}
      color="gray.600"
      m={0}
      // editable + preview paddings added together, so text appears vertically aligned
      ml="-10px"
      w="full"
      as="h1"
      // wrap text when cover image is used (flex context)
      overflowX="auto"
      // allows textarea focus border without overflow
      p={1}
      lineHeight="normal"
      defaultValue={checklist.name}
      onSubmit={handleUpdateName}
      value={name}
      onChange={setName}
      // prevent 1-2px shifts when resizing textarea
      minH={minHeight + 'px'}
      onEdit={setTextareaHeight}
    >
      <MaskedEditablePreview
        _hover={
          isEditEnabled
            ? {
                backgroundColor: 'brand.100',
              }
            : undefined
        }
        cursor={isEditEnabled ? 'pointer' : 'default'}
        borderRadius="md"
        overflowWrap="break-word"
        ref={previewRef}
        as={Box}
        w="full"
        px="6px"
        py="10px"
        whiteSpace="preserve"
      />
      <EditableTextarea
        rows={1}
        px="5px"
        py="6px"
        ref={textareaRef}
        resize="none"
        overflow="hidden"
        onKeyDown={e => {
          // don't allow multiline WFR names
          if (e.key === 'Enter') {
            e.preventDefault();
            textareaRef.current?.blur();
          }
          setTextareaHeight();
        }}
      />
    </Editable>
  );
};

const MaskedEditablePreview = React.forwardRef<HTMLDivElement, EditablePreviewProps>(function EditablePreview(
  props,
  ref,
) {
  const { getPreviewProps, value } = useEditableContext();
  const previewProps = getPreviewProps(props, ref);

  return (
    <Box as={EditablePreview} ref={ref} {...previewProps} {...props}>
      <MaskedChevrons text={value} />
    </Box>
  );
});
