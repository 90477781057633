import { useMatch } from '@process-street/adapters/navigation';
import { getRandomInt, Muid } from '@process-street/subgrade/core';
import { MergeTagTarget } from '@process-street/subgrade/form';
import {
  Box,
  HStack,
  Icon,
  Image,
  InputGroup,
  InputRightElement,
  Link,
  Menu,
  MenuGroup,
  MenuItem,
  MenuList,
  MenuProps,
  Portal,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanelProps,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from 'components/design/next';
import { CustomIconName } from 'components/design/next/icon/icon-name';
import { useFeatureFlag } from 'features/feature-flags';
import {
  MergeTagMenuGroupTitle,
  MergeTagsByTemplateRevisionIdQuery,
  useMergeTagsByTemplateRevisionIdQuery,
} from 'features/merge-tags/query-builder';
import { useTaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { Option } from 'space-monad';
import { match } from 'ts-pattern';
import { LearnMorePopover } from './learn-more-popover';
import { MenuInput } from './menu-input';
import { MergeTagsMenuButton, MergeTagsMenuButtonProps } from './merge-tags-menu-button';
import { VariableFallbackForm, VariableFallbackTagOption } from './variable-fallback-form';

export interface MergeTagsMenuProps extends Omit<MenuProps, 'children'>, Pick<MergeTagsMenuButtonProps, 'ngProps'> {
  menuButton?: React.ReactElement;
  templateRevisionId?: Muid;
  mergeTagTarget: MergeTagTarget;
  onSelect: (key: string, fieldId?: string, fallback?: string) => void;
  isDisabled?: boolean;
  fieldId?: string;
  disclosure?: ReturnType<typeof useDisclosure>;
}

export interface MergeTagsMenuWithTemplateRevisionIdProps extends MergeTagsMenuProps {
  menuButton: React.ReactElement;
  templateRevisionId: Muid;
}

const getMenuGroupTitleIcon = (title: MergeTagMenuGroupTitle | MergeTagTabTitle): CustomIconName | undefined => {
  return match<MergeTagMenuGroupTitle | MergeTagTabTitle, CustomIconName | undefined>(title)
    .with(MergeTagsByTemplateRevisionIdQuery.WORKFLOW_VARIABLES, () => 'workflow')
    .with(WORKFLOW_TAB, () => 'workflow')
    .with(GLOBAL_TAB, () => 'globe')
    .with(MergeTagsByTemplateRevisionIdQuery.GLOBAL_VARIABLES, () => undefined)
    .otherwise(() => 'database');
};

export const MergeTagsMenu = React.forwardRef<HTMLDivElement, MergeTagsMenuProps>((props, ref) => {
  const { templateRevisionId, ngProps = {}, menuButton = <MergeTagsMenuButton ngProps={ngProps} /> } = props;
  // A wrapper that shows a disabled magic wand button if there is no template revision ID
  // (Without calling query hooks)
  return templateRevisionId ? (
    <MergeTagsMenuWithTemplateRevisionId ref={ref} {...{ menuButton, templateRevisionId, ...props }} />
  ) : (
    <Menu>{React.cloneElement(menuButton, { isDisabled: true })}</Menu>
  );
});

export type MergeTagTabTitle = 'Workflow' | 'Data Sets' | 'Global';
export const WORKFLOW_TAB: MergeTagTabTitle = 'Workflow';
export const DATASETS_TAB: MergeTagTabTitle = 'Data Sets';
export const GLOBAL_TAB: MergeTagTabTitle = 'Global';
const FALLBACK_FORM_HEIGHT = 140;
const TAB_PANEL_HEIGHT = 224;

type MergeTagsMenuWithTemplateRevisionIdContentProps = MergeTagsMenuWithTemplateRevisionIdProps & {
  query: string;
  setQuery: (value: string) => void;
  selectedTag: VariableFallbackTagOption | null;
  setSelectedTag: (value: VariableFallbackTagOption | null) => void;
};

const MergeTagsMenuWithTemplateRevisionIdContent = React.memo(
  React.forwardRef<HTMLDivElement, MergeTagsMenuWithTemplateRevisionIdContentProps>(
    (
      {
        templateRevisionId,
        fieldId,
        mergeTagTarget,
        onSelect,
        disclosure,
        query,
        setQuery,
        selectedTag,
        setSelectedTag,
      },
      ref,
    ) => {
      const internalDisclosure = useDisclosure();
      const menuDisclosure = disclosure ?? internalDisclosure;
      const isMergeTagImprovementsEnabled = useFeatureFlag('mergeTagImprovements');
      const isEditorV2 = useMatch('templateV2');
      const lastFocusedInputRef = React.useRef<HTMLInputElement | null>(null);

      MergeTagsByTemplateRevisionIdQuery.useWatchWidgetChanges({
        templateRevisionId,
        mergeTagTarget,
        includeLegacyTags: false,
      });

      const taskTemplatesQuery = useTaskTemplatesByTemplateRevisionIdQuery({ templateRevisionId });

      const isTableFormFieldEnabled = useFeatureFlag('tableFormField');
      const tagsQuery = useMergeTagsByTemplateRevisionIdQuery(
        { templateRevisionId, mergeTagTarget, includeLegacyTags: false },
        {
          staleTime: Infinity,
          refetchOnReconnect: 'always',
          refetchOnWindowFocus: 'always',
          refetchOnMount: 'always',
          enabled: taskTemplatesQuery.status === 'success' && Boolean(taskTemplatesQuery.data),
          select: data => ({
            workflowVariablesTab: MergeTagsByTemplateRevisionIdQuery.getWorkflowMergeTagsTab(
              taskTemplatesQuery.data ?? [],
              isTableFormFieldEnabled,
            )(data),
            dataSetVariablesTab: MergeTagsByTemplateRevisionIdQuery.getDataSetsMergeTagsTab()(data),
            globalVariablesTab: MergeTagsByTemplateRevisionIdQuery.getGlobalMergeTagsTab()(data),
            groupedTags: MergeTagsByTemplateRevisionIdQuery.groupMergeTagsTabs(query)(data),
          }),
        },
      );

      const handleSelect = (tag: { key: string; value: string }, fieldId: string | undefined) => {
        if (!isMergeTagImprovementsEnabled) return onSelect(tag.key, fieldId);

        // If the user clicks on the same option again, we will insert the variable,
        // instead of requiring the user to click on the "Insert variable" button.
        if (selectedTag?.key === tag.key) {
          setSelectedTag(null);
          menuDisclosure.onClose();
          onSelect(tag.key, fieldId);

          return;
        }

        setSelectedTag(tag);
      };

      const handleInsertVariable = (fallback: string | undefined) => {
        if (!selectedTag) throw new Error('There is not `selection` defined.');

        setSelectedTag(null);
        onSelect(selectedTag.key, fieldId, fallback);
        menuDisclosure.onClose();
      };

      const clearLastFocusedInputRef = () => {
        lastFocusedInputRef.current = null;
      };

      const handleMenuFocusCapture: React.FocusEventHandler<HTMLInputElement> = e => {
        const focusIsNotAtTheInput =
          e.target.nodeName !== 'INPUT' || (document.activeElement && document.activeElement.nodeName !== 'INPUT');

        if (lastFocusedInputRef.current && focusIsNotAtTheInput) {
          // We need to make it async so the focused element is updated after Chakra focusing on the menu item.
          return setTimeout(() => {
            // Force the focus on the last focused input.
            lastFocusedInputRef.current?.focus();
          });
        }

        if (e.target.nodeName === 'INPUT') {
          // When an input is focused, we save it to the ref, so we can manage its focus
          // when the mouse leaves the input.
          lastFocusedInputRef.current = e.target as HTMLInputElement;
        }
      };

      const handleMenuItemClick = (tag: VariableFallbackTagOption) => {
        clearLastFocusedInputRef();
        handleSelect(tag, fieldId);
      };

      const getMenuItemStyles = (key: string) =>
        key === selectedTag?.key
          ? {
              backgroundColor: 'brand.100',
            }
          : {};

      const tabPanelProps: Partial<TabPanelProps> = {
        overflowY: 'auto',
        // Make the TabPanel occupy the height o the normal tab panel + the fallback form height when a tag is not selected.
        // When a tag is selected, we remove the height of the fallback form, this way, the menu won't change its height when
        // the fallback form is shown.
        maxH: selectedTag ? `${TAB_PANEL_HEIGHT}px` : `${TAB_PANEL_HEIGHT + FALLBACK_FORM_HEIGHT}px`,
        p: '0',
      };

      return (
        <MenuList cursor="auto" zIndex="modal" w="sm" onFocusCapture={handleMenuFocusCapture} ref={ref}>
          <MenuGroup>
            <Box px="3">
              <InputGroup>
                <MenuInput
                  {...{
                    placeholder: 'Search variables',
                    value: query,
                    fontSize: isEditorV2 ? 'sm' : 'inherit',
                    onChange: e => setQuery(e.target.value),
                  }}
                />
                <InputRightElement>
                  <LearnMorePopover />
                </InputRightElement>
              </InputGroup>
            </Box>
          </MenuGroup>
          {query.trim() === '' ? (
            <Tabs pt="2" isFitted>
              <TabList color="gray.500">
                <Tab>
                  <HStack>
                    <Icon icon="workflow" variant="far" size="3" />
                    <Text fontWeight="medium" fontSize="sm" aria-label={WORKFLOW_TAB}>
                      {WORKFLOW_TAB}
                    </Text>
                  </HStack>
                </Tab>
                <Tab>
                  <HStack>
                    <Icon icon="database" variant="far" size="3" />
                    <Text fontWeight="medium" fontSize="sm" aria-label={DATASETS_TAB}>
                      {DATASETS_TAB}
                    </Text>
                  </HStack>
                </Tab>

                <Tab>
                  <HStack>
                    <Icon icon="globe" variant="far" size="3" />
                    <Text fontWeight="medium" fontSize="sm" aria-label={GLOBAL_TAB}>
                      {GLOBAL_TAB}
                    </Text>
                  </HStack>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel {...tabPanelProps}>
                  {match(tagsQuery)
                    .with({ status: 'loading' }, () => {
                      return Array.from({ length: 5 }, (_, i) => (
                        <MenuItem key={i}>
                          <Skeleton w={`${getRandomInt({ max: 100, min: 50 })}%`}>Loading...</Skeleton>
                        </MenuItem>
                      ));
                    })
                    .with({ status: 'success' }, ({ data: { workflowVariablesTab } }) =>
                      workflowVariablesTab.map(([group, tags]) => (
                        <MenuGroup
                          px="0"
                          pl="4"
                          position="sticky"
                          bg="white"
                          top="0"
                          key={group}
                          // @ts-expect-error -- TODO
                          title={
                            <HStack as="span">
                              <Text as="span" variant="-2u" fontWeight="bold" color="gray.400">
                                {group}
                              </Text>
                            </HStack>
                          }
                          pt="2"
                          margin="0"
                        >
                          {tags.map(tag => (
                            <MenuItem
                              closeOnSelect={!isMergeTagImprovementsEnabled}
                              key={tag.key}
                              py="2"
                              pl="4"
                              fontWeight="normal"
                              fontSize={isEditorV2 ? 'sm' : 'inherit'}
                              _hover={{
                                bgColor: 'blue.100',
                                transitionProperty: 'background-color',
                                transitionDuration: 'ultra-fast',
                                transitionTimingFunction: 'ease-in-out',
                              }}
                              onClick={() => handleMenuItemClick(tag)}
                              {...getMenuItemStyles(tag.key)}
                            >
                              {tag.value || tag.key}
                            </MenuItem>
                          ))}
                        </MenuGroup>
                      )),
                    )
                    .otherwise(() => null)}
                </TabPanel>

                <TabPanel {...tabPanelProps}>
                  {match(tagsQuery)
                    .with({ status: 'loading' }, () => {
                      return Array.from({ length: 5 }, (_, i) => (
                        <MenuItem key={i}>
                          <Skeleton w={`${getRandomInt({ max: 100, min: 50 })}%`}>Loading...</Skeleton>
                        </MenuItem>
                      ));
                    })
                    .with({ status: 'success' }, ({ data: { dataSetVariablesTab } }) =>
                      dataSetVariablesTab.length > 0 ? (
                        dataSetVariablesTab.map(([group, tags]) => (
                          <MenuGroup
                            px="0"
                            pl="4"
                            position="sticky"
                            bg="white"
                            top="0"
                            key={group}
                            // @ts-expect-error -- TODO
                            title={
                              <HStack as="span">
                                <Text as="span" variant="-2u" fontWeight="bold" color="gray.400">
                                  {group}
                                </Text>
                              </HStack>
                            }
                            margin="0"
                            pt="2"
                          >
                            {tags.map(tag => {
                              const displayValue = tag.value || tag.key;
                              const [savedViewName, columnName] = displayValue.split(' - ');

                              return (
                                <MenuItem
                                  closeOnSelect={!isMergeTagImprovementsEnabled}
                                  key={tag.key}
                                  py={isEditorV2 ? '1' : '2'}
                                  pl="4"
                                  fontWeight="normal"
                                  fontSize={isEditorV2 ? 'sm' : 'inherit'}
                                  _hover={{
                                    bgColor: 'blue.100',
                                    transitionProperty: 'background-color',
                                    transitionDuration: 'ultra-fast',
                                    transitionTimingFunction: 'ease-in-out',
                                  }}
                                  onClick={() => handleMenuItemClick(tag)}
                                  {...getMenuItemStyles(tag.key)}
                                >
                                  {savedViewName && columnName ? (
                                    <VStack alignItems="flex-start" w="full" spacing="0">
                                      <Text color="gray.600" fontSize={isEditorV2 ? 'sm' : 'inherit'}>
                                        {columnName}
                                      </Text>
                                      <Text color="gray.400" fontSize={isEditorV2 ? 'sm' : 'inherit'} variant="-1">
                                        {savedViewName}
                                      </Text>
                                    </VStack>
                                  ) : (
                                    displayValue
                                  )}
                                </MenuItem>
                              );
                            })}
                          </MenuGroup>
                        ))
                      ) : (
                        <VStack justifyContent="center" p="4" minH="3xs">
                          <Image src={require('app/images/ps-datasets-empty-gray.png')} pt="2" alt="Empty Data Sets" />
                          <HStack>
                            <Text
                              fontWeight="medium"
                              lineHeight="base"
                              color="gray.500"
                              fontSize={isEditorV2 ? 'sm' : 'inherit'}
                            >
                              No Data Set variables
                            </Text>
                            <Link
                              href="https://www.process.st/help/docs/data-sets/#using-data-sets-as-variables"
                              isExternal
                            >
                              <Icon icon="info-circle" variant="far" size="4" color="gray.400" pt="1" />
                            </Link>
                          </HStack>
                          <Text textAlign="center" color="gray.400" fontSize={isEditorV2 ? 'sm' : 'inherit'}>
                            Connect your Data Set to a dropdown form field to access them.
                          </Text>
                        </VStack>
                      ),
                    )
                    .otherwise(() => null)}
                </TabPanel>

                <TabPanel {...tabPanelProps}>
                  {match(tagsQuery)
                    .with({ status: 'loading' }, () =>
                      Array.from({ length: 5 }, (_, i) => (
                        <MenuItem key={i}>
                          <Skeleton w={`${getRandomInt({ max: 100, min: 50 })}%`}>Loading...</Skeleton>
                        </MenuItem>
                      )),
                    )
                    .with({ status: 'success' }, ({ data: { globalVariablesTab } }) =>
                      globalVariablesTab.map(tag => (
                        <MenuItem
                          key={tag.key}
                          closeOnSelect={!isMergeTagImprovementsEnabled}
                          py="2"
                          pl="4"
                          fontWeight="normal"
                          fontSize={isEditorV2 ? 'sm' : 'inherit'}
                          _hover={{
                            bgColor: 'blue.100',
                            transitionProperty: 'background-color',
                            transitionDuration: 'ultra-fast',
                            transitionTimingFunction: 'ease-in-out',
                          }}
                          onClick={() => handleMenuItemClick(tag)}
                          {...getMenuItemStyles(tag.key)}
                        >
                          {tag.value || tag.key}
                        </MenuItem>
                      )),
                    )
                    .otherwise(() => null)}
                </TabPanel>
              </TabPanels>
            </Tabs>
          ) : (
            // When user starts typing hide tabs and show results of all tabs in same view.
            <Box overflowY="auto" maxH="3xs" mt="2">
              {match(tagsQuery)
                .with({ status: 'loading' }, () => (
                  <MenuGroup title={MergeTagsByTemplateRevisionIdQuery.GLOBAL_VARIABLES}>
                    {Array.from({ length: 5 }, (_, i) => (
                      <MenuItem key={i}>
                        <Skeleton w={`${getRandomInt({ max: 100, min: 50 })}%`}>Loading...</Skeleton>
                      </MenuItem>
                    ))}
                  </MenuGroup>
                ))
                .with({ status: 'success' }, ({ data: { groupedTags } }) =>
                  groupedTags.length === 0 ? (
                    <Box p="4">
                      {' '}
                      No results found for{' '}
                      <Text as="strong" color="gray.600" fontSize="inherit">
                        "{query}"
                      </Text>{' '}
                    </Box>
                  ) : (
                    groupedTags.map(([group, tags]) => (
                      <MenuGroup
                        position="sticky"
                        bg="white"
                        top="0"
                        key={group}
                        // @ts-expect-error -- TODO
                        title={
                          <HStack as="span">
                            {Option(getMenuGroupTitleIcon(group))
                              .map(icon => <Icon icon={icon} size="3" variant="far" />)
                              .getOrElse(<></>)}
                            <Text as="span" variant="-2u" fontWeight="bold">
                              {group}
                            </Text>
                          </HStack>
                        }
                        margin={0}
                        py="2"
                        px="4"
                      >
                        {tags.map(tag => (
                          <MenuItem
                            key={tag.key}
                            closeOnSelect={!isMergeTagImprovementsEnabled}
                            onClick={() => handleMenuItemClick(tag)}
                            {...getMenuItemStyles(tag.key)}
                          >
                            {tag.value || tag.key}
                          </MenuItem>
                        ))}
                      </MenuGroup>
                    ))
                  ),
                )
                .otherwise(() => null)}
            </Box>
          )}

          {selectedTag && (
            <MenuGroup>
              <VariableFallbackForm selectedTag={selectedTag} onSubmit={handleInsertVariable} />
            </MenuGroup>
          )}
        </MenuList>
      );
    },
  ),
);

MergeTagsMenuWithTemplateRevisionIdContent.displayName = 'MergeTagsMenuWithTemplateRevisionIdContent';

const MergeTagsMenuWithTemplateRevisionId = React.forwardRef<HTMLDivElement, MergeTagsMenuWithTemplateRevisionIdProps>(
  (props, ref) => {
    const { templateRevisionId, mergeTagTarget, isDisabled = false, menuButton, disclosure, ...rest } = props;

    const queryClient = useQueryClient();
    const internalDisclosure = useDisclosure();
    const menuDisclosure = disclosure ?? internalDisclosure;
    const [query, setQuery] = React.useState('');
    const [selectedTag, setSelectedTag] = React.useState<VariableFallbackTagOption | null>(null);
    const isEditorV2 = useMatch('templateV2');

    MergeTagsByTemplateRevisionIdQuery.useWatchWidgetChanges({
      templateRevisionId,
      mergeTagTarget,
      includeLegacyTags: false,
    });

    const invalidateWidgetsOnMenuOpen = () => {
      if (isEditorV2) {
        void MergeTagsByTemplateRevisionIdQuery.invalidate(queryClient, templateRevisionId);
      }
    };

    return (
      <Menu
        // ref={ref}
        isLazy={true}
        placement="auto"
        {...menuDisclosure}
        {...rest}
        onClose={() => {
          setQuery('');
          setSelectedTag(null);
          menuDisclosure.onClose();
        }}
        onOpen={() => {
          invalidateWidgetsOnMenuOpen();
          menuDisclosure.onOpen();
        }}
      >
        <Tooltip
          label={
            <Text variant="-2" textAlign="center">
              Variables
            </Text>
          }
          hasArrow
          closeOnClick
          tabIndex={-1}
        >
          {/* manually wrap instead of shouldWrapChildren to avoid tab focus competition */}
          <span>{React.cloneElement(menuButton, { isDisabled })}</span>
        </Tooltip>
        <Portal>
          {menuDisclosure.isOpen && (
            <MergeTagsMenuWithTemplateRevisionIdContent
              {...props}
              setSelectedTag={setSelectedTag}
              selectedTag={selectedTag}
              setQuery={setQuery}
              query={query}
              ref={ref}
            />
          )}
        </Portal>
      </Menu>
    );
  },
);
