import {
  useTemplateMenuContext,
  useTemplateMenuDisclosureContext,
} from 'features/template/components/template-menu/template-menu';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Spacer,
  Text,
} from 'components/design/next';
import * as React from 'react';
import { useDiscardDraftMutation, useGetTemplateQuery } from 'features/template/query-builder';
import { TEMPLATE_REVISION_DISCARD_BY_ID_SUCCESS } from 'reducers/template-revision/template-revision.actions';
import { useInjector } from 'components/injection-provider';
import { useDispatch } from 'react-redux';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import { useRedirectToFolderView } from 'features/template/components/template-menu/delete-template-button/use-redirect-to-folder-view';
import { usePageDraftShouldAutoDelete } from 'pages/pages/_id/edit/page/use-page-draft-should-auto-delete';
import { TemplateType } from '@process-street/subgrade/process';

export const DiscardTemplateAlert = () => {
  const { templateId } = useTemplateMenuContext();
  const { discardTemplateAlertDisclosure: disclosure } = useTemplateMenuDisclosureContext();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const templateQuery = useGetTemplateQuery({ templateId });
  const template = templateQuery.data;

  const templateRevisionQuery = useNewestTemplateRevisionQuery(
    {
      templateId,
      editable: true,
    },
    { enabled: !!templateId && disclosure.isOpen },
  );

  const DiscardWrapper = template?.templateType === TemplateType.Page ? DiscardPage : DiscardWorkflow;

  const handleDiscard = (discardDraftMutation: ReturnType<typeof useDiscardDraftMutation>) => {
    if (templateRevisionQuery.data?.id) {
      discardDraftMutation.mutate(
        { tmplRevId: templateRevisionQuery.data?.id },
        {
          onSuccess: () => {
            if (disclosure.isOpen) {
              disclosure.onClose();
            }
          },
        },
      );
    }
  };

  return (
    <DiscardWrapper>
      {({ discardDraftMutation }) => (
        <AlertDialog
          leastDestructiveRef={cancelRef}
          isOpen={disclosure.isOpen}
          onClose={disclosure.onClose}
          onEsc={disclosure.onClose}
          onOverlayClick={disclosure.onClose}
          size="xl"
          scrollBehavior="inside"
        >
          <AlertDialogOverlay />
          <AlertDialogContent pt="0">
            <AlertDialogHeader py="4" px="8" borderBottom="1px" borderColor="gray.200" fontSize="lg">
              Discard draft?
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody pt="4" px="8" pb="8">
              <Text>
                All unpublished edits to this will be discarded and{' '}
                <Text as="span" fontWeight="700">
                  can’t be recovered
                </Text>
                .
              </Text>
            </AlertDialogBody>
            <AlertDialogFooter py="6" px="8" borderTop="1px" borderColor="gray.200">
              <Spacer />
              <Button ref={cancelRef} variant="ghost" mr="4" onClick={disclosure.onClose} colorScheme="gray">
                Cancel
              </Button>
              <Button
                isDisabled={discardDraftMutation.isLoading}
                variant="danger"
                onClick={() => handleDiscard(discardDraftMutation)}
              >
                Discard
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </DiscardWrapper>
  );
};

type DiscardProps = {
  children: ({
    discardDraftMutation,
  }: {
    discardDraftMutation: ReturnType<typeof useDiscardDraftMutation>;
  }) => React.ReactElement;
};

const DiscardWorkflow: React.FC<DiscardProps> = React.memo(({ children }) => {
  const { $state } = useInjector('$state');
  const { templateId } = useTemplateMenuContext();
  const dispatch = useDispatch();

  const discardDraftMutation = useDiscardDraftMutation({
    onSuccess: () => {
      dispatch({ type: TEMPLATE_REVISION_DISCARD_BY_ID_SUCCESS });
      if ($state.params.groupId) {
        $state.go('templateView.task', { id: templateId, groupId: $state.params.groupId });
      } else {
        $state.go('templateView', { id: templateId });
      }
    },
  });

  return children({ discardDraftMutation });
});

const DiscardPage: React.FC<DiscardProps> = React.memo(({ children }) => {
  const { $state } = useInjector('$state');
  const { templateId, view } = useTemplateMenuContext();

  const { redirectToFolderView } = useRedirectToFolderView({ templateId });
  const { shouldAutoDelete, deleteTemplate } = usePageDraftShouldAutoDelete(templateId);
  const discardDraftMutation = useDiscardDraftMutation({
    onSuccess: () => {
      if (shouldAutoDelete) {
        deleteTemplate.mutate(
          { templateId },
          {
            onSuccess: () => {
              if (view === 'show') {
                redirectToFolderView();
              }
            },
          },
        );
      } else {
        $state.go('pageView', { id: templateId });
      }
    },
  });

  return children({ discardDraftMutation });
});
