import { ScopeEventActions } from './scope-event.actions';
import { EventName } from 'services/event-name';
import { ChecklistEvent } from 'services/checklists/checklist-event';
import { ChecklistAssignmentEvent } from 'services/assignments/checklist-assignment-event';

export const ScopeEventAdapter = function ($ngRedux, $rootScope, InboxEvent) {
  'ngInject';

  const actions = {};

  $ngRedux.connect(null, ScopeEventActions)(actions);

  $rootScope.$on(EventName.TASK_ASSIGNED_UNASSIGNED, actions.taskAssignedUnassigned);
  $rootScope.$on(ChecklistAssignmentEvent.CREATE_OK, actions.checklistAssignmentCreateOk);
  $rootScope.$on(ChecklistAssignmentEvent.DELETE_OK, actions.checklistAssignmentDeleteOk);
  $rootScope.$on(InboxEvent.INBOX_UPDATED, actions.inboxUpdated);
  $rootScope.$on(ChecklistEvent.UPDATE_STARTED, actions.checklistUpdateStarted);
  $rootScope.$on(ChecklistEvent.DELETE_OK, actions.checklistDeleteOk);
  $rootScope.$on(EventName.TASK_STATUS_UPDATE_OK, actions.taskStatusUpdateOk);
  $rootScope.$on(EventName.TASK_DUE_DATE_UPDATE_OK, actions.taskDueDateUpdateOk);
  $rootScope.$on(EventName.TASK_DYNAMIC_DUE_DATE_UPDATED, actions.taskDueDateUpdateOk);
};
