import angular from 'angular';
import { dayjs as moment, HttpStatus } from '@process-street/subgrade/util';
import { ChecklistStatus, TaskStatus } from '@process-street/subgrade/process';
import { InboxItemType } from '@process-street/subgrade/inbox/inbox-model';
import { InboxConstants } from '@process-street/subgrade/inbox/inbox-constants';
import templateUrl from './list.component.html';
import balloonsIcon from './images/balloons.svg';
import boxIcon from './images/box.svg';
import gaugeIcon from './images/gauge.svg';
import jerseyIcon from './images/jersey.svg';
import robotIcon from './images/robot.svg';
import './list.scss';
import { ablyService } from 'app/pusher/ably.service';
import { AblyEvent } from 'app/pusher/ably-event';
import { inboxStatsSelector } from 'reducers/stats/stats.selectors';
import { createSelector } from 'reselect';
import { sortInboxItems } from './sort-inbox-items';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';
import { LocalStorageService } from 'features/storage/local-storage-service';
import { InboxListUtils } from 'directives/inbox/list/list-utils';
import { queryClient } from 'components/react-root';
import { GetConsolidatedTaskPermitsByTaskIdsQuery } from 'features/task/query-builder';
import { UpdateErrorToasts } from 'pages/tasks/helpers';
import { AttachmentEvent } from 'services/events/attachment-event';
import { CommentEvent } from 'services/events/comment-event';

angular.module('frontStreetApp.directives').component('psInboxList', {
  bindings: {
    user: '<',
    organization: '<',
    expandedItemType: '<',
    expandedItemId: '<',
  },
  require: {
    inboxCtrl: '^psInbox',
  },
  templateUrl,
  controller(
    $q,
    $anchorScroll,
    $rootScope,
    $scope,
    $timeout,
    $state,
    $uibModal,
    ChecklistService,
    InboxEvent,
    InboxListService,
    InboxService,
    SecurityService,
    SessionService,
    TaskService,
    TempDataService,
    TodoService,
    ToastService,
    $ngRedux,
  ) {
    const logger = trace({ name: 'InboxListCtrl' });

    const PAGE_SIZE = 25;

    const ctrl = this;

    ctrl.items = [];
    ctrl.groups = [];
    ctrl.permissionMapLookup = {};
    ctrl.taskPermissionMap = {};
    ctrl.selectedItems = [];

    // states
    ctrl.loading = false;
    ctrl.loadingMore = false;
    ctrl.moreItemsAvailable = true;
    ctrl.completedState = null;

    ctrl.completedStates = [
      {
        mainText: 'Hooray! Look at you go',
        subText: 'All done for today',
        icon: gaugeIcon,
      },
      {
        mainText: 'Kapow! Way to knock out those tasks',
        subText: 'All done for today',
        icon: robotIcon,
      },
      {
        mainText: 'Today is a good day',
        subText: '',
        icon: balloonsIcon,
      },
      {
        mainText: 'What was that sound?',
        subText: 'Oh it was just you crushing it today!',
        icon: jerseyIcon,
      },
      {
        mainText: 'In the midst of chaos',
        subText: 'There is Inbox',
        icon: boxIcon,
      },
    ];

    ctrl.$onInit = function () {
      ctrl.loading = true;

      // initial filters setup
      ctrl.filterMap = verifyFilters({
        ...ctrl.filterMap,
        itemsType: SessionService.getInboxProperty('itemTypeFilter'),
        filter: $state.params.tab ?? InboxConstants.Filter.INBOX,
      });

      ctrl.subscribeToInboxStats();

      // register this controller with the parent
      ctrl.inboxCtrl.registerInboxListCtrl(ctrl);

      ctrl._initListeners();
    };

    ctrl.$onDestroy = function () {
      if (ctrl.unsubscribeFromInboxStats) {
        ctrl.unsubscribeFromInboxStats();
      }
    };

    ctrl.initAbly = () => {
      const channelName = ablyService.getChannelNameForInbox(ctrl.organization.id, ctrl.user.id);
      const channel = ablyService.getChannel(channelName);

      channel.subscribe(AblyEvent.EventType.InboxTaskCompletable, message => {
        const item = JSON.parse(message.data);
        ctrl.addNewItem(item);
      });
    };

    ctrl.subscribeToInboxStats = () => {
      if (!ctrl.organization) {
        return;
      }
      if (ctrl.unsubscribeFromInboxStats) {
        ctrl.unsubscribeFromInboxStats();
      }
      const organizationId = ctrl.organization.id;
      const userId = ctrl.filterMap?.userId ?? ctrl.user.id;

      const mapStateToCtrl = createSelector([inboxStatsSelector], inbox => {
        const allUserStats = inbox[userId] || {};
        const orgUserStats = allUserStats[organizationId] || {};

        return {
          stats: orgUserStats,
          shouldShowComments: ctrl.filterMap.filter === InboxConstants.Filter.COMMENTS,
        };
      });

      ctrl.unsubscribeFromInboxStats = $ngRedux.connect(mapStateToCtrl)(ctrl);
    };

    ctrl.itemMatchesFilters = item => {
      const filterIsInbox = ctrl.filterMap.filter === InboxConstants.Filter.INBOX;
      const filterIsUpcoming = ctrl.filterMap.filter === InboxConstants.Filter.UPCOMING;
      const dueDateInInbox = InboxService.isDueDateInInbox(item.task.dueDate);

      const userMatches = ctrl.filterMap.userId === ctrl.user.id;
      const fitsInbox = filterIsInbox && dueDateInInbox;
      const fitsUpcoming = filterIsUpcoming && !dueDateInInbox;

      return userMatches && (fitsInbox || fitsUpcoming);
    };

    ctrl.addNewItem = item => {
      if (ctrl.itemMatchesFilters(item)) {
        ctrl.items.push(item);
        ctrl.groups = sortAndGroupItems(ctrl.items);
        $scope.$apply();
      }
    };

    ctrl.$onChanges = function (changes) {
      if (changes.expandedItemType) {
        ctrl.expandedItemType = changes.expandedItemType.currentValue;
      }
      if (changes.expandedItemId) {
        ctrl.expandedItemId = changes.expandedItemId.currentValue;
      }

      toggleExpandedItemModal();
    };

    ctrl.registerInboxItemDetailsController = function (instance) {
      ctrl.inboxItemDetailsCtrl = instance;
    };

    function toggleExpandedItemModal() {
      if (ctrl.expandedItemType && ctrl.expandedItemId) {
        // FIXME: This is temporary hack that goes back to the inbox if the modal cannot be always opened
        if (!isReferrerInbox()) {
          $state.go('inbox');
          return;
        }

        ctrl.expandedItem = findItemByTypeAndId(ctrl.expandedItemType, ctrl.expandedItemId);

        if (ctrl.expandedItem) {
          if (!itemModalInstance || !itemModalOpen) {
            itemModalInstance = undefined;
            openItemDetailsModal();
          } else {
            ctrl.inboxItemDetailsCtrl.setItem(ctrl.expandedItem);
          }
        }
      } else if (itemModalInstance) {
        closeItemDetailsModal();
      }
    }

    function isReferrerInbox() {
      const previousState = TempDataService.getPreviousState();
      return !!(previousState && previousState.state.name.startsWith('inbox'));
    }

    let itemModalInstance;
    let itemModalOpen = false;

    function openItemDetailsModal() {
      itemModalInstance = $uibModal.open({
        component: 'ps-inbox-item-details',
        animation: true,
        backdrop: 'static',
        size: 'inbox-medium',
        windowClass: 'inbox-item-modal',
        resolve: {
          item: ctrl.expandedItem,
          user: ctrl.user,
          organization: ctrl.organization,
          inboxListCtrl: ctrl,
        },
      });

      itemModalInstance.opened.then(() => {
        itemModalOpen = true;
      });
      itemModalInstance.closed.then(() => {
        itemModalOpen = false;
        if ($state.current.name.startsWith('inbox')) {
          $state.go('inbox');
        }
      });
    }

    function closeItemDetailsModal() {
      itemModalInstance.dismiss();
    }

    $scope.$on('$destroy', () => {
      if (itemModalInstance) {
        itemModalInstance.dismiss();
      }
      removeHiddenItems();

      ablyService.close();
    });

    ctrl.showPreviousItemDetails = function () {
      if (ctrl.expandedItemType && ctrl.expandedItemId) {
        const expandedItem = findItemByTypeAndId(ctrl.expandedItemType, ctrl.expandedItemId);

        const currentExpandedIsFirst = ctrl.isFirstItem(expandedItem);

        if (currentExpandedIsFirst) {
          $state.go('inbox');
        } else {
          const nextItem = ctrl.items[indexOfItem(ctrl.items, expandedItem) - 1];

          const { itemType } = nextItem;
          const itemId = InboxService.isTask(itemType) ? nextItem.task.id : nextItem.checklist.id;

          $state.go('inbox.item', { type: InboxService.convertItemTypeToPathStateType(nextItem.itemType), itemId });
        }
      }
    };

    ctrl.showNextItemDetails = function () {
      if (ctrl.expandedItemType && ctrl.expandedItemId) {
        const expandedItem = findItemByTypeAndId(ctrl.expandedItemType, ctrl.expandedItemId);
        const currentExpandedIsLast = ctrl.isLastItem(expandedItem);

        if (currentExpandedIsLast) {
          $state.go('inbox');
        } else {
          const nextItem = ctrl.items[indexOfItem(ctrl.items, expandedItem) + 1];

          if (ctrl.isLastItem(nextItem)) {
            $scope.$emit('inbox:loadMore');
          }

          const { itemType } = nextItem;
          const itemId = InboxService.isTask(itemType) ? nextItem.task.id : nextItem.checklist.id;

          $state.go('inbox.item', { type: InboxService.convertItemTypeToPathStateType(nextItem.itemType), itemId });
        }
      }
    };

    ctrl.isFirstItem = function (item) {
      const visibleAndGivenItems = ctrl.items.filter(itm => !itm.hidden || eqItem(itm, item));
      return indexOfItem(visibleAndGivenItems, item) === 0;
    };

    ctrl.isLastItem = function (item) {
      const visibleAndGivenItems = ctrl.items.filter(itm => !itm.hidden || eqItem(itm, item));
      return indexOfItem(visibleAndGivenItems, item) === visibleAndGivenItems.length - 1;
    };

    ctrl.toggleItemSelection = function (item) {
      if (item.selected) {
        ctrl.unselectItem(item);
      } else {
        ctrl.selectItem(item);
      }
    };

    ctrl.selectItem = item => {
      if (!item.masked) {
        item.selected = true;
      }
    };

    ctrl.setSearchBoxFocused = function (focused) {
      ctrl.searchBoxFocused = focused;
    };

    ctrl.isAnimationEnabled = function () {
      return !ctrl.searchBoxFocused && !ctrl.loadingMore;
    };

    ctrl.unselectItem = item => {
      if (!item.masked) {
        item.selected = false;
      }
    };

    ctrl.selectAll = function () {
      ctrl.items.forEach(itm => {
        ctrl.selectItem(itm);
      });
    };

    ctrl.unselectAll = function () {
      ctrl.items.forEach(itm => {
        ctrl.unselectItem(itm);
      });
    };

    ctrl.countSelected = function () {
      return ctrl.getSelectedItems().length;
    };

    ctrl.getSelectedItems = function () {
      return ctrl.items.filter(itm => itm.selected && !itm.hidden);
    };

    ctrl.areAllSelected = function () {
      const availableItems = ctrl.items.filter(itm => !itm.hidden && !itm.masked);
      return availableItems.length === ctrl.countSelected();
    };

    ctrl.getNonEmptyGroupKeys = function () {
      return ctrl.groups.filter(grp => !ctrl.isGroupEmpty(grp)).map(grp => grp.key);
    };

    ctrl.isGroupEmpty = function (group) {
      return !group.items || !group.items.filter(itm => !itm.hidden).length;
    };

    ctrl.getGroupItems = function (groupKey) {
      const group = ctrl.groups.find(grp => grp.key === groupKey);

      if (group) {
        return group.items;
      } else {
        return [];
      }
    };

    ctrl.shouldShowLoadingMoreIndicator = function (groupKey) {
      const lastGroupKey = ctrl.groups[ctrl.groups.length - 1].key;

      return ctrl.loadingMore && lastGroupKey === groupKey;
    };

    ctrl.updateDueDate = function (item, dueDate) {
      switch (item.itemType) {
        case InboxItemType.Checklist:
          updateChecklistDueDate(item, dueDate);
          break;
        case InboxItemType.StandardTask:
        case InboxItemType.ApprovalTask:
          ctrl._updateTaskDueDate(item, dueDate);
          break;
        default:
          logger.error('unsupported inbox item type %s', ctrl.item.itemType);
      }
    };

    ctrl.onTaskItemDueDateUpdate = function (item) {
      const taskItemIndex = indexOfItem(ctrl.items, item);
      const filterIsInbox = ctrl.filterMap.filter === InboxConstants.Filter.INBOX;
      const filterIsUpcoming = ctrl.filterMap.filter === InboxConstants.Filter.UPCOMING;

      if (
        (filterIsInbox && !InboxService.isDueDateInInbox(item.task.dueDate)) ||
        (filterIsUpcoming && InboxService.isDueDateInInbox(item.task.dueDate))
      ) {
        hideItem(item);
      } else {
        ctrl.items[taskItemIndex] = item;
        ctrl.groups = sortAndGroupItems(ctrl.items);
      }
    };

    ctrl.onChecklistItemDueDateUpdate = function (item) {
      const taskItemIndex = indexOfItem(ctrl.items, item);
      const filterIsInbox = ctrl.filterMap.filter === InboxConstants.Filter.INBOX;
      const filterIsUpcoming = ctrl.filterMap.filter === InboxConstants.Filter.UPCOMING;

      if (
        (filterIsInbox && !InboxService.isDueDateInInbox(item.checklist.dueDate)) ||
        (filterIsUpcoming && InboxService.isDueDateInInbox(item.checklist.dueDate))
      ) {
        hideItem(item);
      } else {
        ctrl.items[taskItemIndex] = item;
        ctrl.groups = sortAndGroupItems(ctrl.items);
      }
    };

    ctrl.onTaskItemAssigneesUpdate = function (item) {
      const taskItemExists = indexOfItem(ctrl.items, item) >= 0;
      const selectedUserId = verifyFilters(ctrl.filterMap).userId;

      const currentSelectedAssignee = item.assignees.find(assignee => assignee.id === selectedUserId);

      if (currentSelectedAssignee) {
        if (!taskItemExists) {
          ctrl.items.push(item);
          ctrl.groups = sortAndGroupItems(ctrl.items);
        }
      } else {
        hideItem(item);
      }
    };

    ctrl.onChecklistItemAssigneesUpdate = function (item) {
      const checklistItemExists = indexOfItem(ctrl.items, item) >= 0;
      const selectedUserId = verifyFilters(ctrl.filterMap).userId;

      const currentSelectedAssignee = item.assignees.find(assignee => assignee.id === selectedUserId);

      if (currentSelectedAssignee) {
        if (!checklistItemExists) {
          ctrl.items.push(item);
          ctrl.groups = sortAndGroupItems(ctrl.items);
        }
      } else {
        hideItem(item);
      }
    };

    ctrl.onChecklistAutocompleted = function (item) {
      hideItem(item, true /*hideChecklistTasks*/);
    };

    function updateChecklistDueDate(item, dueDate) {
      let hiddenItems = [];
      const originalDueDate = item.checklist.dueDate;

      const checklistItemIndex = indexOfItem(ctrl.items, item);

      const filterIsInbox = ctrl.filterMap.filter === InboxConstants.Filter.INBOX;
      const filterIsUpcoming = ctrl.filterMap.filter === InboxConstants.Filter.UPCOMING;

      if (
        (filterIsInbox && !InboxService.isDueDateInInbox(dueDate)) ||
        (filterIsUpcoming && InboxService.isDueDateInInbox(dueDate))
      ) {
        hiddenItems = hideItem(item);
      } else {
        item.checklist.dueDate = dueDate;
        ctrl.items[checklistItemIndex] = item;
        ctrl.groups = sortAndGroupItems(ctrl.items);
      }

      ChecklistService.updateDueDate(item.checklist, dueDate, originalDueDate).then(
        () => {
          ctrl.notifyIfInboxCompleted();
        },
        response => {
          item.checklist.dueDate = originalDueDate;
          if (hiddenItems.length > 0) {
            unhideItems(hiddenItems);
          } else {
            ctrl.groups = sortAndGroupItems(ctrl.items);
          }

          switch (response.status) {
            case HttpStatus.BAD_REQUEST:
              ToastService.openToast({
                status: 'warning',
                title: `We couldn't update the due date the`,
                description: 'The due date must be in the future.',
              });

              break;
            default:
              ToastService.openToast({
                status: 'error',
                title: `We're having problems updating the due date`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
          }
        },
      );
    }

    ctrl._updateTaskDueDate = (item, dueDate, performUpdate = true) => {
      let hiddenItems = [];
      const originalDueDate = item.task.dueDate;

      const taskItemIndex = indexOfItem(ctrl.items, item);

      const filterIsInbox = ctrl.filterMap.filter === InboxConstants.Filter.INBOX;
      const filterIsUpcoming = ctrl.filterMap.filter === InboxConstants.Filter.UPCOMING;

      const isDueDateInInbox = InboxService.isDueDateInInbox(dueDate);

      if ((filterIsInbox && !isDueDateInInbox) || (filterIsUpcoming && isDueDateInInbox)) {
        hiddenItems = hideItem(item);
      } else {
        item.task.dueDate = dueDate;
        unhideItems([item]);
        ctrl.items[taskItemIndex] = item;
        ctrl.groups = sortAndGroupItems(ctrl.items);
      }

      if (!performUpdate) {
        return;
      }

      TaskService.updateDueDate(item.task.id, dueDate).then(
        () => {
          ctrl.notifyIfInboxCompleted();
          const data = {
            originalTask: item.task,
            updatedDueDate: dueDate,
            originalDueDate,
          };

          item.task.dueDate = dueDate;
          $rootScope.$broadcast(EventName.TASK_DUE_DATE_UPDATE_OK, data);
        },
        response => {
          item.task.dueDate = originalDueDate;
          if (hiddenItems.length > 0) {
            unhideItems(hiddenItems);
          } else {
            ctrl.groups = sortAndGroupItems(ctrl.items);
          }

          switch (response.status) {
            case HttpStatus.BAD_REQUEST:
              ToastService.openToast({
                status: 'warning',
                title: `We couldn't update the due date the`,
                description: 'The due date must be in the future.',
              });

              break;
            default:
              ToastService.openToast({
                status: 'error',
                title: `We're having problems updating the due date`,
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
          }
        },
      );
    };

    ctrl.completeAll = function () {
      const selectedItems = TodoService.excludeApprovalTasks(ctrl.getSelectedItems());
      let failedItemsCount = 0;
      let invalidFormFieldsCount = 0;
      TodoService.completeAll(selectedItems).then(responses => {
        responses.forEach(res => {
          if (
            res.response === InboxConstants.TodoResponse.OK ||
            res.response === InboxConstants.TodoResponse.NOTHING_TO_UPDATE
          ) {
            const completedItem = InboxListService.findMatchingItemByTodo(selectedItems, res.todo);

            const { todoType } = res.todo;
            if (InboxService.isTask(todoType)) {
              hideItem(completedItem);
            } else {
              hideItem(completedItem, true /*removeChecklistTasks*/);
            }
          } else {
            failedItemsCount += 1;

            if (res.response === InboxConstants.TodoResponse.CONFLICT) {
              invalidFormFieldsCount += res.invalidFormFields ? res.invalidFormFields.length : 0;
            }
          }
          ctrl.notifyIfInboxCompleted();
        });

        ctrl.notifyInboxUpdated(selectedItems, InboxConstants.Actions.COMPLETE_ALL);

        if (failedItemsCount && invalidFormFieldsCount) {
          ToastService.openToast({
            status: 'warning',
            title: `We couldn't complete ${failedItemsCount} task(s)`,
            description: 'Some of the fields are invalid or required and missing.',
          });
        } else if (failedItemsCount) {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems completing the task(s)`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        }
      }, handleBulkActionFailure);
    };

    ctrl.updateAllDueDates = function (dueDate) {
      const selectedItems = ctrl.getSelectedItems();
      let failedItemsCount = 0;

      TodoService.updateAllDueDates(selectedItems, dueDate).then(responses => {
        const filterIsInbox = ctrl.filterMap.filter === InboxConstants.Filter.INBOX;
        const filterIsUpcoming = ctrl.filterMap.filter === InboxConstants.Filter.UPCOMING;

        responses.forEach(res => {
          if (
            res.response === InboxConstants.TodoResponse.OK ||
            res.response === InboxConstants.TodoResponse.NOTHING_TO_UPDATE
          ) {
            const item = findMatchingItemByTodo(selectedItems, res.todo);

            const itemIndex = indexOfItem(ctrl.items, item);

            if (
              (filterIsInbox && !InboxService.isDueDateInInbox(dueDate)) ||
              (filterIsUpcoming && InboxService.isDueDateInInbox(dueDate))
            ) {
              hideItem(item);
            } else {
              if (InboxService.isTask(res.todo.todoType)) {
                item.task.dueDate = dueDate;
              } else {
                item.checklist.dueDate = dueDate;
              }
              ctrl.items[itemIndex] = item;
            }
          } else {
            failedItemsCount += 1;
          }
          ctrl.notifyIfInboxCompleted();
        });

        ctrl.notifyInboxUpdated(selectedItems, InboxConstants.Actions.UPDATE_ALL_DUE_DATES, dueDate);

        ctrl.groups = sortAndGroupItems(ctrl.items);
        if (failedItemsCount) {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems completing the task(s)`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        }

        ctrl.unselectAll();
      }, handleBulkActionFailure);
    };

    function handleBulkActionFailure(response) {
      let message;
      if (response.data.message) {
        ({ message } = response.data);
      } else {
        message = "We couldn't update the items. Please try again later.";
      }

      ToastService.openToast(message);
    }

    function findItemByTypeAndId(itemType, itemId) {
      return ctrl.items.find(
        itm =>
          itm.itemType === itemType &&
          ((itm.itemType === InboxItemType.Checklist && itm.checklist.id === itemId) ||
            (InboxService.isTask(itm.itemType) && itm.task.id === itemId)),
      );
    }

    function findMatchingItemByTodo(items, todo) {
      const { todoType } = todo;
      const todoId = todo.id;
      return items.find(
        itm =>
          itm.itemType === todoType &&
          ((InboxService.isTask(todoType) && itm.task.id === todoId) ||
            (todoType === InboxItemType.Checklist && itm.checklist.id === todoId)),
      );
    }

    /**
     * Gets request for item completion.
     * @param item
     * @param autocompleted If item is autocompleted on BE and it needs to be marked as completed on FE.
     */
    function getCompleteItemRequest(item, autocompleted) {
      let completionRequest;

      if (autocompleted) {
        completionRequest = $q.resolve(item);
      } else {
        switch (item.itemType) {
          case InboxItemType.Checklist:
            completionRequest = ctrl.completeChecklist(item);
            break;
          case InboxItemType.StandardTask:
          case InboxItemType.OneOffTask:
            completionRequest = ctrl.completeTask(item);
            break;
          case InboxItemType.ApprovalTask:
            // Approval Task is autocompleted on BE
            completionRequest = $q.resolve();
            break;
          default:
            completionRequest = $q.reject();
            logger.error('unsupported inbox item type %s', ctrl.item.itemType);
        }
      }

      return completionRequest;
    }

    function hideItemAndGetHiddenItems(item) {
      let hiddenItems = [];
      switch (item.itemType) {
        case InboxItemType.Checklist:
          hiddenItems = hideItem(item, true /*hideChecklistTasks*/);
          break;
        case InboxItemType.StandardTask:
        case InboxItemType.ApprovalTask:
        case InboxItemType.OneOffTask:
          hiddenItems = hideItem(item, false /*hideChecklistTasks*/);
          break;
        default:
      }
      return hiddenItems;
    }

    function getNextItem(item, items) {
      const visibleAndGivenItems = items.filter(itm => !itm.hidden || eqItem(itm, item));
      return visibleAndGivenItems[indexOfItem(visibleAndGivenItems, item) + 1];
    }

    ctrl.completeItem = function (item, autocompleted) {
      let completedFromModal = false;
      if (
        ctrl.expandedItemType &&
        ctrl.expandedItemId &&
        eqItem(findItemByTypeAndId(ctrl.expandedItemType, ctrl.expandedItemId), item)
      ) {
        completedFromModal = true;
      }
      const completionRequest = getCompleteItemRequest(item, autocompleted);
      const hiddenItems = hideItemAndGetHiddenItems(item);

      return completionRequest.then(
        updatedItem => {
          // find next item before we remove completed one from the list
          const nextItem = getNextItem(item, ctrl.items);
          if (nextItem && ctrl.isLastItem(nextItem)) {
            $scope.$emit('inbox:loadMore');
          }

          if (completedFromModal) {
            if (ctrl.isLastItem(item)) {
              $state.go('inbox');
            } else {
              const { itemType } = nextItem;
              const itemId = InboxService.isTask(itemType) ? nextItem.task.id : nextItem.checklist.id;

              $state.go('inbox.item', {
                type: InboxService.convertItemTypeToPathStateType(nextItem.itemType),
                itemId,
              });
            }
          }

          ctrl.notifyIfInboxCompleted();

          ctrl.showCtaOnItemCompletion(updatedItem);

          return updatedItem;
        },
        () => {
          unhideItems(hiddenItems);
        },
      );
    };

    ctrl.completeChecklist = function (item) {
      return ChecklistService.updateStatus(item.checklist, ChecklistStatus.Completed).then(
        () => {
          return item;
        },
        response => {
          ToastService.openToast(UpdateErrorToasts.getWorkflowUpdateErrorToastOptions(response, ctrl.user?.timeZone));

          return $q.reject();
        },
      );
    };

    ctrl.completeTask = function (item) {
      const originalStatus = item.task.status;
      const newStatus = TaskStatus.Completed;
      item.task.status = newStatus;

      return TaskService.updateTaskStatus(item.task, newStatus, item.checklist).then(
        () => {
          return item;
        },
        response => {
          switch (response.status) {
            case HttpStatus.CONFLICT: {
              let message;
              if (response.data.invalidFormFields) {
                message = `${response.data.invalidFormFields.length} field(s) still need to be completed.`;
              } else {
                message = 'The workflow run has been updated. Please refresh and try again.';
              }
              ToastService.openToast({
                status: 'warning',
                title: "We couldn't update the task.",
                description: message,
              });
              break;
            }
            default:
              ToastService.openToast({
                status: 'error',
                title: "We're having problems updating the task.",
                description: DefaultErrorMessages.unexpectedErrorDescription,
              });
          }

          // Rollback

          item.task.status = originalStatus;

          return $q.reject();
        },
      );
    };

    function removeHiddenItems() {
      removeItems(ctrl.getHiddenItems());
    }

    ctrl.getHiddenItems = function () {
      return ctrl.items.filter(itm => !!itm.hidden);
    };

    function getVisibleItems() {
      return ctrl.items.filter(itm => !itm.hidden);
    }

    function removeItems(itemsToRemove) {
      itemsToRemove.forEach(itm => {
        ctrl.items.splice(indexOfItem(ctrl.items, itm), 1);
      });

      ctrl.groups = sortAndGroupItems(ctrl.items);
    }

    function hideItem(item, hideChecklistTasks) {
      const hiddenItems = [];

      item.hidden = true;
      hiddenItems.push(item);

      if (hideChecklistTasks && item.itemType === InboxItemType.Checklist) {
        const relatedTaskItems = ctrl.items.filter(
          itm => InboxService.isTask(itm.itemType) && itm.checklist.id === item.checklist.id,
        );

        relatedTaskItems.forEach(taskItem => {
          taskItem.hidden = true;
          hiddenItems.push(taskItem);
        });
      }

      return hiddenItems;
    }

    function unhideItems(hiddenItems) {
      hiddenItems.forEach(item => {
        delete item.hidden;
      });
    }

    ctrl.applyFilters = function (filterMap, isInitialLoad) {
      logger.info('Applying filters', filterMap);

      if (
        !ctrl.filterMap ||
        ctrl.filterMap.userId !== filterMap.userId ||
        ctrl.filterMap.group !== filterMap.group ||
        ctrl.filterMap.filter !== filterMap.filter ||
        ctrl.filterMap.pseudoGroup !== filterMap.pseudoGroup ||
        ctrl.filterMap.itemsType !== filterMap.itemsType
      ) {
        ctrl.loading = true;
      }

      ctrl.filterMap = angular.copy(filterMap);
      ctrl.subscribeToInboxStats();
      ctrl.loadItems().then(() => {
        ctrl.loading = false;
        if (isInitialLoad) {
          // If needed, scroll to "no due date" section after initial load
          $timeout(() => $anchorScroll(), 0);
          toggleExpandedItemModal();
          ctrl.initAbly();
        }
      });
    };

    const eqItem = (a, b) =>
      a?.itemType === b?.itemType && a?.checklist.id === b?.checklist.id && a?.task?.id === b?.task?.id;

    const indexOfItem = (items, item) => items.findIndex(i => eqItem(i, item));

    ctrl.loadItems = function () {
      if (!ctrl.organization) {
        return $q.resolve();
      }

      return InboxService.getAllItemsByOrganization(ctrl.organization, verifyFilters(ctrl.filterMap)).then(
        items => {
          ctrl.moreItemsAvailable = items.length >= PAGE_SIZE;

          ctrl.items = items;

          getPermissionMaps(items).then(permissionMaps => {
            ctrl.permissionMapLookup = {};
            permissionMaps.forEach(permissionMap => {
              ctrl.permissionMapLookup[permissionMap.checklistId] = permissionMap;
            });
          });

          ctrl.initTaskPermits(items);

          ctrl.groups = sortAndGroupItems(ctrl.items);
        },
        () => {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems loading the inbox items`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        },
      );
    };

    function getPermissionMaps(items) {
      const checklistIds = items.map(item => item.checklist.id);

      return SecurityService.getAllConsolidatedByChecklistIds(checklistIds);
    }

    ctrl.shouldShowAssignee = function () {
      return (
        ctrl.filterMap?.userId === ctrl.user.id &&
        !ctrl.items?.length &&
        !!ctrl.stats?.assignmentStats?.hasWorkflowTasks &&
        !ctrl.stats?.assignmentStats?.hasWorkflowRunTasks &&
        !ctrl.loading
      );
    };

    ctrl.shouldShowEmptyState = function () {
      const visibleItems = getVisibleItems();
      return (
        !ctrl.shouldShowComments &&
        !visibleItems.length &&
        !ctrl.loading &&
        !ctrl.loadingMore &&
        !ctrl.moreItemsAvailable
      );
    };

    ctrl.shouldShowList = function () {
      return !!ctrl.items.length && !ctrl.loading;
    };

    ctrl.shouldShowAllClear = function () {
      return !ctrl.completedState && !ctrl.getHiddenItems()?.length;
    };

    ctrl.infiniteScrollDisabled = function () {
      return ctrl.loading || ctrl.loadingMore || !ctrl.moreItemsAvailable;
    };

    ctrl.resolveEmptyStateMainMessage = function () {
      const filterIsInbox = ctrl.filterMap.filter === InboxConstants.Filter.INBOX;

      if (filterIsInbox) {
        return 'Check upcoming for more tasks.';
      } else {
        return 'Check inbox for more tasks.';
      }
    };

    ctrl.loadMoreItems = function () {
      if (ctrl.loading || ctrl.loadingMore) {
        return $q.resolve();
      }

      ctrl.loadingMore = true;

      const offset = ctrl.items.length || 0;

      return InboxService.getAllItemsByOrganization(ctrl.organization, verifyFilters(ctrl.filterMap), offset).then(
        items => {
          if (items.length < PAGE_SIZE) {
            ctrl.moreItemsAvailable = false;
          }

          ctrl.items = ctrl.items.concat(items);

          getPermissionMaps(items).then(permissionMaps => {
            permissionMaps.forEach(permissionMap => {
              ctrl.permissionMapLookup[permissionMap.checklistId] = permissionMap;
            });
          });

          ctrl.initTaskPermits(items);

          const additionalGroups = sortAndGroupItems(items);

          ctrl.groups = InboxService.mergeGroups(ctrl.groups, additionalGroups);

          ctrl.loadingMore = false;
        },
        () => {
          ToastService.openToast({
            status: 'error',
            title: `We're having problems loading the inbox items`,
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        },
      );
    };

    function verifyFilters(filterMap) {
      const map = filterMap || {};

      if (!map.userId && !map.pseudoGroup) {
        map.userId = ctrl.user.id;
      }

      if (!map.group) {
        map.group = InboxConstants.Group.DUE_DATE;
      }

      if (!map.filter) {
        map.filter = InboxConstants.Filter.INBOX;
      }

      return map;
    }

    function sortAndGroupItems(items) {
      const sortedItems = sortInboxItems(items);

      if (ctrl.filterMap && ctrl.filterMap.group === InboxConstants.Group.PROCESS) {
        return InboxService.groupItemsByProcess(sortedItems);
      } else {
        return InboxService.groupItemsByDueDate(sortedItems);
      }
    }

    const groupKeyTitleMap = {};
    groupKeyTitleMap[InboxConstants.StdGroupKey.OVERDUE] = 'Overdue';
    groupKeyTitleMap[InboxConstants.StdGroupKey.TODAY] = 'Today';
    groupKeyTitleMap[InboxConstants.StdGroupKey.THIS_WEEK] = 'This Week';
    groupKeyTitleMap[InboxConstants.StdGroupKey.NEXT_WEEK] = 'Next Week';
    groupKeyTitleMap[InboxConstants.StdGroupKey.THIS_MONTH] = 'This Month';
    groupKeyTitleMap[InboxConstants.StdGroupKey.NEXT_MONTH] = 'Next Month';
    groupKeyTitleMap[InboxConstants.StdGroupKey.NO_DUE_DATE] = 'No Due Date';

    ctrl.resolveGroupTitle = function (groupKey) {
      let title;

      if (ctrl.filterMap && ctrl.filterMap.group === InboxConstants.Group.PROCESS) {
        title = groupKey;
      } else {
        const parsedMoment = moment(groupKey, 'YYYY-MM-DD');

        if (parsedMoment.isValid()) {
          title = parsedMoment.format('MMMM gggg');
        } else if (groupKeyTitleMap[groupKey]) {
          title = groupKeyTitleMap[groupKey];
        }
      }

      // TODO should throw an exception in this case, but there is a small problem with it (talk to Cameron)
      return title;
    };

    ctrl.resolveGroupId = group => InboxListUtils.resolveGroupId(group);

    ctrl._initListeners = () => {
      $scope.$on(AttachmentEvent.ATTACHMENT_CREATE_OK, (__event, taskId, checklistId) => {
        updateDoodadsCountByTaskIdAndChecklistId(taskId, checklistId, 1);
      });

      $scope.$on(AttachmentEvent.ATTACHMENT_DELETE_OK, (__event, taskId, checklistId) => {
        updateDoodadsCountByTaskIdAndChecklistId(taskId, checklistId, -1);
      });

      $scope.$on(CommentEvent.COMMENT_CREATE_OK, (__event, taskId, checklistId) => {
        updateDoodadsCountByTaskIdAndChecklistId(taskId, checklistId, 1);
      });

      $scope.$on(CommentEvent.COMMENT_DELETE_OK, (__event, taskId, checklistId) => {
        updateDoodadsCountByTaskIdAndChecklistId(taskId, checklistId, -1);
      });

      $scope.$on(EventName.TASK_STATUS_UPDATE_OK, (__event, task) => {
        updateItemVisibilityByTask(task);
      });

      // This reacts to newly hidden or shown fields that happen via conditional logic
      $scope.$on(EventName.TASK_HIDDEN_UPDATED, (__event, data) => {
        updateItemVisibilityByTask(data.updatedTask);
      });

      $scope.$on(EventName.TASK_DYNAMIC_DUE_DATE_UPDATED, (__event, { updatedTask: { id, dueDate } }) => {
        const taskItems = ctrl.items.filter(item => InboxService.isTask(item.itemType));

        const item = taskItems.find(taskItem => taskItem.task.id === id);
        if (item) {
          ctrl._updateTaskDueDate(item, dueDate, false /* performUpdate */);
        }
      });
    };

    function updateItemVisibilityByTask(task) {
      const item = ctrl.items.find(itm => InboxService.isTask(itm.itemType) && itm.task && itm.task.id === task.id);

      if (item) {
        const itemShouldBeHidden = task.status === TaskStatus.Completed || task.hidden;
        if (itemShouldBeHidden) {
          hideItem(item, false /* hideChecklistTasks */);
        } else {
          unhideItems([item]);
        }
      }
    }

    function updateDoodadsCountByTaskIdAndChecklistId(taskId, checklistId, diff) {
      const matchingTaskItem = ctrl.items.find(
        itm => InboxService.isTask(itm.itemType) && itm.task && itm.task.id === taskId,
      );

      const matchingChecklistItem = ctrl.items.find(
        itm => itm.itemType === InboxItemType.Checklist && itm.checklist && itm.checklist.id === checklistId,
      );

      if (matchingTaskItem) {
        matchingTaskItem.totalDoodads += diff;
      }

      if (matchingChecklistItem) {
        matchingChecklistItem.totalDoodads += diff;
      }
    }

    ctrl.notifyIfInboxCompleted = function () {
      if (getVisibleItems().length === 0) {
        $rootScope.$broadcast(InboxEvent.INBOX_COMPLETED);

        ctrl.showInboxCompleteState();
      }
    };

    ctrl.notifyInboxUpdated = function (selectedItems, action, dueDate) {
      $rootScope.$broadcast(InboxEvent.INBOX_UPDATED, ctrl.organization.id, selectedItems, action, dueDate);
    };

    ctrl.showInboxCompleteState = function () {
      ctrl.completedState = ctrl.getInboxCompleteDataItem(ctrl.completedStates);
    };

    ctrl.getInboxCompleteDataItem = function (list) {
      if (list.length > 1) {
        return list[ctrl.getRandomInt(0, list.length - 1)];
      } else if (list.length === 1) {
        return list[0];
      }
      return null;
    };

    ctrl.getRandomInt = function (min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    };

    const CreatePlaybookCtaDateKey = 'CreatePlaybookCta_date';
    const CreatePlaybookCtaTaskCompletedKey = 'CreatePlaybookCta_task_count';

    ctrl.createPlaybookCtaVisible = false;
    ctrl.showCreatePlaybookCta = () => {
      ctrl.createPlaybookCtaVisible = true;
      LocalStorageService.setItem(CreatePlaybookCtaDateKey, Date.now().toString(10));
    };

    ctrl.showCtaOnItemCompletion = item => {
      const lastDate = LocalStorageService.getItem(CreatePlaybookCtaDateKey);

      if (lastDate) {
        return; // already shown
      }

      if (item.itemType === InboxItemType.Checklist) {
        ctrl.showCreatePlaybookCta();
      } else {
        const tasksCompleted = parseInt(LocalStorageService.getItem(CreatePlaybookCtaTaskCompletedKey) ?? 0, 10) + 1;
        LocalStorageService.setItem(CreatePlaybookCtaTaskCompletedKey, tasksCompleted.toString(10));

        if (tasksCompleted === 3) {
          ctrl.showCreatePlaybookCta();
        }
      }
    };

    ctrl.initTaskPermits = items => {
      const taskItems = items.filter(item => InboxService.isTask(item.itemType));
      if (taskItems.length === 0) return;

      const taskIds = taskItems.map(t => t.task.id);
      const params = { taskIds };

      $q((resolve, reject) =>
        queryClient
          .fetchQuery(GetConsolidatedTaskPermitsByTaskIdsQuery.getKey(params), () =>
            GetConsolidatedTaskPermitsByTaskIdsQuery.queryFn(params),
          )
          .then(tasksPermits => resolve(tasksPermits))
          .catch(e => reject(e)),
      ).then(tasksPermits => {
        const { taskPermissionMap } = ctrl;
        tasksPermits.forEach(t => (taskPermissionMap[t.taskId] = t.taskPermissionMap.canUpdate));
        ctrl.taskPermissionMap = Object.assign({}, taskPermissionMap); // just in case for change detection
      });
    };
  },
});
