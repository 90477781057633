import * as React from 'react';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { PagesEditorPage } from './editor.component';
import { IsEditableProvider } from 'features/rich-text';
import { UIActorProvider, useSharedContext } from 'app/pages/forms/_id/shared';
import {
  FormEditorPageActorRefProvider,
  makeFormEditorPageMachine,
} from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { useInterpret, useSelector } from '@xstate/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import _isEqual from 'lodash/isEqual';
import { TemplateSettingsModalProvider } from 'app/pages/templates/_id/components/template-settings-modal/template-settings-modal-context';
import { isTemplateRevisionEqual } from 'app/pages/workflows/_id/edit-v2/helpers/is-template-revision-equal';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';
import { useGetConsolidatedTemplatePermissionsQuery } from 'app/features/permissions/query-builder';
import { useNavigate } from '@process-street/adapters/navigation';
import { queryString } from '@process-street/subgrade/util';

export type PageEditorWrapperProps = {
  editable?: boolean;
};

export const PagesEditorWrapper: React.FC<React.PropsWithChildren<PageEditorWrapperProps>> = ({ editable = true }) => {
  const sharedContext = useSharedContext();
  const machine = React.useRef(makeFormEditorPageMachine({ sharedContext })).current;
  const actor = useInterpret(machine);
  const uiActorRef = useSelector(actor, FormEditorPageActorSelectors.getUIActorRef, _isEqual);
  const template = useSelector(actor, FormEditorPageActorSelectors.getTemplate, _isEqual);
  const draftTemplateRevision = useSelector(
    actor,
    FormEditorPageActorSelectors.getTemplateRevision,
    isTemplateRevisionEqual,
  );
  const navigate = useNavigate();

  const templatePermissions = useGetConsolidatedTemplatePermissionsQuery(template?.id, {
    enabled: Boolean(template?.id),
  });

  React.useEffect(
    function redirectToViewModeIfNoEditPermission() {
      if (templatePermissions.data && !templatePermissions.data?.permissionMap.templateUpdate) {
        navigate({
          pathname: 'pageViewV2',
          search: queryString.stringify({ id: template?.id }),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- template id
    [templatePermissions.data, navigate],
  );

  if (!template || !draftTemplateRevision) {
    return null;
  }
  return (
    <FormEditorPageActorRefProvider actor={actor}>
      <UIActorProvider uiActorRef={uiActorRef}>
        <ThemeProvider2024>
          <DndProvider backend={HTML5Backend} context={window}>
            <IsEditableProvider isEditable={editable}>
              <TemplateSettingsModalProvider templateId={template.id} templateRevisionId={draftTemplateRevision.id}>
                <PagesEditorPage template={template} />
              </TemplateSettingsModalProvider>
            </IsEditableProvider>
          </DndProvider>
        </ThemeProvider2024>
      </UIActorProvider>
    </FormEditorPageActorRefProvider>
  );
};
