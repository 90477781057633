import * as React from 'react';
import {
  Box,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { mailto } from '@process-street/subgrade/process';
import { useAdminEmails } from 'app/components/paywalls/use-admin-emails';
import { useCurrentUser } from 'app/hooks/use-current-user';
import { LocationService } from '@process-street/subgrade/util';
import { Muid } from '@process-street/subgrade/core';

export type PermissionPopoverProps = React.PropsWithChildren<PopoverProps> & {
  isDisabled?: boolean;
  templateId: Muid;
};

export const PermissionPopover = ({ isDisabled, children, templateId, ...props }: PermissionPopoverProps) => {
  const disclosure = useDisclosure();
  const [adminEmail, ...bcc] = useAdminEmails();
  const currentUser = useCurrentUser();

  const templateUrl = LocationService.templateHref(templateId, undefined);

  const href = mailto({
    to: adminEmail,
    subject: `Someone on your team wants to edit a Workflow`,
    body: `${
      currentUser?.username ? currentUser.username : ''
    } would like to edit a Workflow. Update permissions here: ${templateUrl}`,
    bcc,
  });

  return (
    <Popover
      {...props}
      {...disclosure}
      isOpen={isDisabled ? false : disclosure.isOpen}
      trigger="hover"
      colorScheme="blackAlpha"
    >
      <PopoverTrigger>
        <Box>{children}</Box>
      </PopoverTrigger>
      <PopoverContent maxW="228px" bgColor="black" color="white" fontSize="sm">
        <PopoverArrow bgColor="black" />

        <PopoverBody cursor="default" textAlign="center">
          You don&apos;t have permission to edit
          <br /> this Workflow.&nbsp;
          <Link href={href} textDecor="underline" _hover={{ color: 'gray.100', textDecor: 'underline' }}>
            Request access
          </Link>
          .
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
