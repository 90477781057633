import * as React from 'react';
import { Box } from 'components/design/next';
import { useFeatureFlag } from 'features/feature-flags';
import { Checklist } from '@process-street/subgrade/process';
import { GetOneOffTasksByChecklistQuery } from 'features/one-off-tasks/query-builder';
import { OneOffTaskItem } from './one-off-task-item';

export interface PrintOneOffTasksProps {
  checklist: Checklist;
}

export const PrintOneOffTasks: React.FC<React.PropsWithChildren<PrintOneOffTasksProps>> = ({ checklist }) => {
  const isOneOffTasksEnabled = useFeatureFlag('oneOffTasks');

  const oneOffTasksQuery = GetOneOffTasksByChecklistQuery.useQuery(
    {
      checklistId: checklist?.id,
    },
    { enabled: isOneOffTasksEnabled && !!checklist?.id },
  );

  if (!isOneOffTasksEnabled) {
    return null;
  }

  return (
    <Box pb="8">
      {oneOffTasksQuery?.data?.map(task => {
        return <OneOffTaskItem key={`task-${task.id}`} task={task} />;
      })}
    </Box>
  );
};
