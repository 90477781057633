import * as React from 'react';
import { ButtonProps, Link, Text, Tooltip } from 'components/design/next';
import { useTemplateMenuContext } from '../template-menu';
import { GetAllRulesByTemplateRevisionIdQuery } from 'features/conditional-logic/query-builder';
import { useGetNewestTemplateRevisionsByTemplateIdQuery } from 'features/template/query-builder';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { match } from 'ts-pattern';

export type ExportConditionalLogicButtonProps = { children: React.ReactElement<ButtonProps> };

export const ExportConditionalLogicButton: React.FC<ExportConditionalLogicButtonProps> = React.memo(({ children }) => {
  const { templateId } = useTemplateMenuContext();

  const latestRevisionQuery = useGetNewestTemplateRevisionsByTemplateIdQuery(
    { templateId },
    { enabled: Boolean(templateId) },
  );

  const { data: { permissionMap } = {} } = useGetConsolidatedTemplatePermissionsQuery(templateId);

  const canEditTemplate = permissionMap?.templateUpdate;

  const rulesQuery = GetAllRulesByTemplateRevisionIdQuery.useQuery({
    templateRevisionId: latestRevisionQuery.data?.[0]?.id,
  });

  const emptyRules = rulesQuery.data?.definitions.length === 0;
  const isDisabled = !canEditTemplate || emptyRules;
  const disabledReason = match({ canEditTemplate, emptyRules })
    .with({ canEditTemplate: false }, () => 'You do not have permissions to access this functionality.')
    .with({ emptyRules: true }, () => 'No rules to export.')
    .otherwise(() => undefined);

  const btn = React.cloneElement(children, {
    as: Link,
    // @ts-expect-error wrong typing
    href: isDisabled ? null : `/workflows/${templateId}/print-rules`, // disable link
    disabled: isDisabled,
    isExternal: true,
  });

  return isDisabled ? (
    <Tooltip label={<Text>{disabledReason}</Text>} aria-label="Export Conditional Logic button">
      {btn}
    </Tooltip>
  ) : (
    btn
  );
});
