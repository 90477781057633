import { Box } from 'components/design/next';
import { ChecklistRuleDefinition, ConditionalLogicCommonUtils } from '@process-street/subgrade/conditional-logic';
import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { Node } from 'directives/rules/template/task-templates-selector/selector-helper';
import { ConditionalLogicUtils } from 'features/conditional-logic/utils/conditional-logic-utils';
import * as React from 'react';
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

import { match, P } from 'ts-pattern';
import { SideMenuItem, SideMenuItemProps } from '../side-menu-item';
import { UseConditionalLogicAccordionReturn } from './use-accordion-handlers';
import { useVirtualizedRowsSizes } from './use-virtualized-rows-sizes';
import { useTemplateTypeContext } from 'utils/template/template-type-context';
import { useConditionalLogicModalStore } from '../modal/store';

type VirtualizedAccordionProps = {
  rules: ChecklistRuleDefinition[];
  taskNodes: Node[];
  widgetsMap: Record<string, Widget>;
  filteredTaskNodes: Node[];
  accordion: UseConditionalLogicAccordionReturn;
};

export const VirtualizedAccordion: React.FC<React.PropsWithChildren<VirtualizedAccordionProps>> = ({
  rules,
  taskNodes,
  widgetsMap,
  filteredTaskNodes,
  accordion,
}) => {
  const {
    selectedWidget,
    selectedTask,
    selectedRule,
    nodesStatus,
    normalizedData,
    dispatch: dispatchModalState,
  } = useConditionalLogicModalStore();

  // All the rules that show or hide the selected widget
  const rulesAffectingSelectedWidget = React.useMemo(
    () => ConditionalLogicUtils.getRulesAffectingWidget(selectedWidget, selectedRule, nodesStatus, rules),
    [selectedWidget, selectedRule, nodesStatus, rules],
  );

  const lastRuleAffectingSelectedWidget = rulesAffectingSelectedWidget[rulesAffectingSelectedWidget.length - 1];

  const nodeContainingAffectingWidget = lastRuleAffectingSelectedWidget
    ? taskNodes.find(node =>
        node.widgets?.some(widgetNode =>
          ConditionalLogicCommonUtils.getConditionFormFieldWidgetGroupIdsFromRule(lastRuleAffectingSelectedWidget).has(
            ConditionalLogicUtils.getNodeGroupId(widgetNode),
          ),
        ),
      )
    : null;

  const nodesAffectedByRule = selectedRule
    ? taskNodes.filter(taskNode => {
        return taskNode.widgets?.some(
          widgetNode =>
            ConditionalLogicUtils.isWidgetNodeAffectedByRule(widgetNode, selectedRule, nodesStatus) &&
            !ConditionalLogicUtils.isWidgetNodeTriggeringRule(widgetNode, selectedRule, nodesStatus),
        );
      })
    : [];

  const taskNodeTriggeringRule = match({ selectedRule, selectedWidget })
    .with({ selectedRule: P.not(P.nullish) }, ({ selectedRule }) =>
      taskNodes.find(taskNode =>
        taskNode.widgets?.some(widgetNode =>
          ConditionalLogicUtils.isWidgetNodeTriggeringRule(widgetNode, selectedRule, nodesStatus),
        ),
      ),
    )
    .with({ selectedWidget: P.not(P.nullish) }, ({ selectedWidget }) => {
      const widgetHeaderId = selectedWidget.header.id;
      const widgetNode = normalizedData.nodes.byId[widgetHeaderId];

      if (!widgetNode?.parentId) return null;

      const taskNode = normalizedData.nodes.byId[widgetNode.parentId];

      return taskNode;
    })
    .otherwise(() => null);

  const handleSelectTask = (taskTemplate: TaskTemplate) => {
    dispatchModalState({ type: 'SET_SELECTED_TASK', payload: taskTemplate });
    accordion.expand();
  };

  const rowsProps: SideMenuItemProps[] = match({ selectedWidget, selectedTask })
    .with({ selectedWidget: P.not(P.nullish) }, () => {
      const rowsProps: SideMenuItemProps[] = [];

      if (nodeContainingAffectingWidget) {
        rowsProps.push({
          isLast: false,
          index: 0,
          node: nodeContainingAffectingWidget,
          widgetsMap,
          widgetsNodes: nodeContainingAffectingWidget.widgets?.filter(widget => {
            const nodeStatus = nodesStatus[ConditionalLogicUtils.getNodeGroupId(widget)];

            return nodeStatus?.isTriggerAt.has(lastRuleAffectingSelectedWidget?.id ?? '');
          }),
          showArrow: true,
          ruleAffectingWidget: lastRuleAffectingSelectedWidget,
          color: 'gray',
        });
      }

      if (taskNodeTriggeringRule) {
        rowsProps.push({
          isLast: false,
          index: nodeContainingAffectingWidget ? 1 : 0,
          node: taskNodeTriggeringRule,
          widgetsMap,
          showOnlyTrigger: true,
          showArrow: true,
        });
      }

      nodesAffectedByRule.forEach((node, index, array) => {
        const isLast = index === array.length;

        if (node) {
          if (selectedWidget) index++;
          if (taskNodeTriggeringRule) index++;

          rowsProps.push({
            node,
            isLast,
            index,
            widgetsMap,
            onSelect: handleSelectTask,
          });
        }
      });

      return rowsProps;
    })
    .with({ selectedTask: P.not(P.nullish) }, ({ selectedTask }) => {
      const node = normalizedData.nodes.byId[selectedTask.id];

      if (!node) return [];

      return [
        {
          isLast: true,
          index: 0,
          node,
          widgetsMap,
          isSelected: true,
        },
      ];
    })
    .otherwise(() => {
      const rowsProps: SideMenuItemProps[] = [];

      filteredTaskNodes.forEach((node, index, array) => {
        const isLast = index === array.length - 1;

        if (!node) return null;
        if (selectedWidget) index++;
        if (taskNodeTriggeringRule) index++;

        rowsProps.push({
          node,
          isLast,
          index,
          widgetsMap,
          onSelect: handleSelectTask,
        });
      });

      return rowsProps;
    });

  const virtualizedRowsSizes = useVirtualizedRowsSizes({
    accordion,
    rowsProps,
  });

  return (
    <Box
      h={`calc(100vh - ${SIDEMENU_HEADING_HEIGHT}px)`}
      sx={{
        '.variable-size-list': {
          pl: 12,
          pr: 8,
        },
      }}
    >
      <ReactVirtualizedAutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <VariableSizeList
            className="variable-size-list"
            data-testid="variable-size-list"
            ref={virtualizedRowsSizes.listRef}
            height={height}
            width={width}
            itemCount={rowsProps.length}
            itemSize={virtualizedRowsSizes.getSize}
            itemData={{
              rowsProps,
              setSize: virtualizedRowsSizes.updateRowSize,
              expandedIndex: accordion.state.current,
              toggle: accordion.toggle,
            }}
          >
            {Row}
          </VariableSizeList>
        )}
      </ReactVirtualizedAutoSizer>
    </Box>
  );
};

type RowProps = ListChildComponentProps & {};

const Row: React.FC<React.PropsWithChildren<RowProps>> = ({ data, index, style }) => {
  const sideMenuItemProps = data.rowsProps[index];
  const { isForm } = useTemplateTypeContext();

  const handleToggle = () => {
    data.toggle(index);
  };

  if (!sideMenuItemProps) return null;

  return (
    <Box
      position={style.position}
      left={`${style.left}px`}
      height={`${style.height}px`}
      top={`${style.top}px`}
      width={style.width}
      pl={isForm ? '8' : '12'}
      pr="8"
    >
      <SideMenuItem {...sideMenuItemProps} isExpanded={data.expandedIndex.has(index)} onToggle={handleToggle} />
    </Box>
  );
};

const SIDEMENU_HEADING_HEIGHT = 260;
