import { Muid } from '@process-street/subgrade/core';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'features/checklist-revisions/query-builder';
import { GetChecklistQueryResponse, makeGetChecklistQueryObserver } from 'features/checklists/query-builder';
import {
  GetAllTasksByChecklistRevisionIdQueryResponse,
  makeGetAllOneOffTasksByChecklistIdQueryObserver,
  makeGetAllTasksByChecklistRevisionIdQueryObserver,
} from 'features/task/query-builder';
import {
  GetFormFieldValuesByChecklistRevisionIdQueryResponse,
  GetWidgetsByChecklistRevisionIdQueryResponse,
  makeGetFormFieldValuesByChecklistRevisionIdQueryObserver,
  makeGetWidgetsByChecklistRevisionIdQueryObserver,
} from 'features/widgets/query-builder';
import { ActorRefFrom, assign, createMachine, sendTo, spawn } from 'xstate';
import { FormResponseActor, makeFormResponseMachine } from './components/form-response-body/form-response-machine';
import {
  makeQueryMachine,
  QueryActor,
  QueryActorSelectors,
  SystemUpdateEvent,
} from 'utils/query-builder/query-machine/query-machine';
import { WithSharedContext } from './types';
import { RefetchOnMountOnly } from 'utils/query-builder';
import { GetCurrentUserResponse, makeGetCurrentUserQueryObserver } from 'features/user/query-builder';
import {
  GetTasksAssignmentsByChecklistRevisionQueryResponse,
  makeGetTasksAssignmentsByChecklistRevisionQuery,
} from 'app/features/checklist-revisions/query-builder/get-tasks-assignments-by-checklist-revision-query';
import { GetDueDateRulesByChecklistRevisionIdQuery } from 'app/features/dynamic-due-dates/query-builder';
import { GetAllTaskTemplatesByChecklistRevisionIdQuery } from 'app/features/task-templates/query-builder/task-templates-by-checklist-revision-id';
import { GetTemplateQueryResponse, makeGetTemplateQueryObserver } from 'app/features/template/query-builder';
import { FormResponsePageMachineUtils } from './utils/form-response-page-machine-utils';
import { GetOneOffTasksByChecklistQuery } from 'features/one-off-tasks/query-builder';

export type FormResponsePageMachineContext = {
  formResponseActor?: FormResponseActor;
  // Queries
  checklistQuery?: QueryActor<GetChecklistQueryResponse>;
  checklistRevisionQuery?: QueryActor<GetActiveChecklistRevisionByChecklistIdQuery.Response>;
  currentUserQuery?: QueryActor<GetCurrentUserResponse>;
  dueDateRulesQuery?: QueryActor<GetDueDateRulesByChecklistRevisionIdQuery.Response>;
  formFieldValuesQuery?: QueryActor<GetFormFieldValuesByChecklistRevisionIdQueryResponse>;
  taskAssignmentsQuery?: QueryActor<GetTasksAssignmentsByChecklistRevisionQueryResponse>;
  taskTemplatesQuery?: QueryActor<GetAllTaskTemplatesByChecklistRevisionIdQuery.Response>;
  tasksQuery?: QueryActor<GetAllTasksByChecklistRevisionIdQueryResponse>;
  oneOffTasksQuery?: QueryActor<GetOneOffTasksByChecklistQuery.Response>;
  templateQuery?: QueryActor<GetTemplateQueryResponse>;
  widgetsQuery?: QueryActor<GetWidgetsByChecklistRevisionIdQueryResponse>;
};

type Event = SystemUpdateEvent<
  | { id: 'checklist-query'; data: GetChecklistQueryResponse }
  | { id: 'checklist-revision-query'; data: GetActiveChecklistRevisionByChecklistIdQuery.Response }
  | { id: 'tasks-query'; data: GetAllTasksByChecklistRevisionIdQueryResponse }
  | { id: 'widgets-query'; data: GetWidgetsByChecklistRevisionIdQueryResponse }
  | { id: 'form-field-values-query'; data: GetFormFieldValuesByChecklistRevisionIdQueryResponse }
  | { id: 'current-user-query'; data: GetCurrentUserResponse }
  | { id: 'task-assignments-query'; data: GetTasksAssignmentsByChecklistRevisionQueryResponse }
  | { id: 'due-date-rules-query'; data: GetDueDateRulesByChecklistRevisionIdQuery.Response }
  | { id: 'task-templates-query'; data: GetAllTaskTemplatesByChecklistRevisionIdQuery.Response }
  | { id: 'template-query'; data: GetTemplateQueryResponse }
>;

export const makeFormResponsePageMachine = ({
  formResponseId,
  sharedContext,
}: WithSharedContext<{ formResponseId: Muid }>) => {
  const { queryClient } = sharedContext;
  return createMachine(
    {
      id: 'form-response-page',
      predictableActionArguments: true,
      schema: {
        events: {} as Event,
        context: {} as FormResponsePageMachineContext,
      },
      context: {},
      tsTypes: {} as import('./form-response-page-machine.typegen').Typegen0,
      initial: 'loading',
      states: {
        loading: {
          entry: ['spawnCurrentUserQuery', 'spawnChecklistQuery', 'spawnChecklistRevisionQuery'],
          on: {
            'xstate.update': [
              {
                cond: 'isChecklistRevisionIdReady',
                actions: [
                  'spawnTasksQuery',
                  'spawnWidgetsQuery',
                  'spawnFormFieldValuesQuery',
                  'spawnTaskAssignmentsQuery',
                  'spawnDueDateRulesQuery',
                  'spawnTaskTemplatesQuery',
                  'spawnOneOffTasksQuery',
                ],
              },
              {
                cond: 'isChecklistReady',
                actions: ['spawnTemplateQuery'],
              },
              {
                cond: 'isAllDataReady',
                actions: ['spawnFormResponseActor'],
                target: 'ready',
              },
            ],
          },
        },
        ready: {
          on: {
            'xstate.update': [
              {
                cond: 'isChecklistUpdate',
                actions: ['sendChecklistToFormResponseActor'],
              },
            ],
          },
        },
      },
    },
    {
      actions: {
        spawnCurrentUserQuery: assign({
          currentUserQuery: (_ctx, _evt) => {
            return (
              _ctx.currentUserQuery ??
              spawn(
                makeQueryMachine({
                  observer: makeGetCurrentUserQueryObserver({
                    queryClient,
                    options: { ...RefetchOnMountOnly },
                  }),
                }),
                { sync: true, name: 'current-user-query' },
              )
            );
          },
        }),
        spawnChecklistQuery: assign({
          checklistQuery: (_ctx, _evt) => {
            return (
              _ctx.checklistQuery ??
              spawn(
                makeQueryMachine({
                  observer: makeGetChecklistQueryObserver({
                    checklistId: formResponseId,
                    queryClient,
                    options: { ...RefetchOnMountOnly },
                  }),
                }),
                { sync: true, name: 'checklist-query' },
              )
            );
          },
        }),
        spawnChecklistRevisionQuery: assign({
          checklistRevisionQuery: (_ctx, _evt) => {
            return (
              _ctx.checklistRevisionQuery ??
              spawn(
                makeQueryMachine({
                  observer: GetActiveChecklistRevisionByChecklistIdQuery.makeObserver({
                    checklistId: formResponseId,
                    queryClient,
                    options: { ...RefetchOnMountOnly },
                  }),
                }),
                { sync: true, name: 'checklist-revision-query' },
              )
            );
          },
        }),
        spawnTasksQuery: assign({
          tasksQuery: (ctx, _evt) => {
            return (
              ctx.tasksQuery ??
              spawn(
                makeQueryMachine({
                  observer: makeGetAllTasksByChecklistRevisionIdQueryObserver({
                    checklistRevisionId: FormResponsePageMachineUtils.getChecklistRevisionId(ctx),
                    queryClient,
                    options: { ...RefetchOnMountOnly },
                  }),
                }),
                { sync: true, name: 'tasks-query' },
              )
            );
          },
        }),
        spawnOneOffTasksQuery: assign({
          oneOffTasksQuery: (ctx, _evt) => {
            return (
              ctx.oneOffTasksQuery ??
              spawn(
                makeQueryMachine({
                  observer: makeGetAllOneOffTasksByChecklistIdQueryObserver({
                    checklistId: FormResponsePageMachineUtils.getChecklistId(ctx),
                    queryClient,
                    options: { ...RefetchOnMountOnly },
                  }),
                }),
                { sync: true, name: 'one-off-tasks-query' },
              )
            );
          },
        }),
        spawnWidgetsQuery: assign({
          widgetsQuery: (ctx, _evt) => {
            return (
              ctx.widgetsQuery ??
              spawn(
                makeQueryMachine({
                  observer: makeGetWidgetsByChecklistRevisionIdQueryObserver({
                    checklistRevisionId: FormResponsePageMachineUtils.getChecklistRevisionId(ctx),
                    queryClient,
                    options: { ...RefetchOnMountOnly },
                  }),
                }),
                { sync: true, name: 'widgets-query' },
              )
            );
          },
        }),
        spawnFormFieldValuesQuery: assign({
          formFieldValuesQuery: (ctx, _evt) => {
            return (
              ctx.formFieldValuesQuery ??
              spawn(
                makeQueryMachine({
                  observer: makeGetFormFieldValuesByChecklistRevisionIdQueryObserver({
                    checklistRevisionId: FormResponsePageMachineUtils.getChecklistRevisionId(ctx),
                    queryClient,
                    options: { ...RefetchOnMountOnly },
                  }),
                }),
                { sync: true, name: 'form-field-values-query' },
              )
            );
          },
        }),
        spawnTemplateQuery: assign({
          templateQuery: (ctx, _evt) => {
            return (
              ctx.templateQuery ??
              spawn(
                makeQueryMachine({
                  observer: makeGetTemplateQueryObserver({
                    templateId: FormResponsePageMachineUtils.getTemplateId(ctx),
                    queryClient,
                    options: { ...RefetchOnMountOnly },
                  }),
                }),
                { sync: true, name: 'template-query' },
              )
            );
          },
        }),

        spawnTaskAssignmentsQuery: assign({
          taskAssignmentsQuery: ctx => {
            return (
              ctx.taskAssignmentsQuery ??
              spawn(
                makeQueryMachine({
                  observer: makeGetTasksAssignmentsByChecklistRevisionQuery({
                    queryClient,
                    options: { ...RefetchOnMountOnly },
                    checklistRevisionId: QueryActorSelectors.getQueryData(ctx.checklistRevisionQuery)?.id,
                  }),
                }),
                { sync: true, name: 'task-assignments-query' },
              )
            );
          },
        }),

        spawnDueDateRulesQuery: assign({
          dueDateRulesQuery: (ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: GetDueDateRulesByChecklistRevisionIdQuery.makeQueryObserver({
                  queryClient,
                  options: { ...RefetchOnMountOnly },
                  checklistRevisionId: QueryActorSelectors.getQueryData(ctx.checklistRevisionQuery)?.id,
                }),
              }),
              { sync: true, name: 'due-date-rules-query' },
            );
          },
        }),

        spawnTaskTemplatesQuery: assign({
          taskTemplatesQuery: (ctx, _evt) => {
            return spawn(
              makeQueryMachine({
                observer: GetAllTaskTemplatesByChecklistRevisionIdQuery.makeQueryObserver({
                  queryClient,
                  options: { ...RefetchOnMountOnly },
                  checklistRevisionId: QueryActorSelectors.getQueryData(ctx.checklistRevisionQuery)?.id,
                }),
              }),
              { sync: true, name: 'task-templates-query' },
            );
          },
        }),

        spawnFormResponseActor: assign({
          formResponseActor: (ctx, _evt) => {
            return spawn(
              makeFormResponseMachine({
                sharedContext,
                currentUser: QueryActorSelectors.getQueryData(ctx.currentUserQuery)!,
                checklist: QueryActorSelectors.getQueryData(ctx.checklistQuery)!,
                checklistRevision: QueryActorSelectors.getQueryData(ctx.checklistRevisionQuery)!,
                tasks: QueryActorSelectors.getQueryData(ctx.tasksQuery)!,
                widgets: QueryActorSelectors.getQueryData(ctx.widgetsQuery)!,
                formFieldValues: QueryActorSelectors.getQueryData(ctx.formFieldValuesQuery)!,
                template: QueryActorSelectors.getQueryData(ctx.templateQuery)!,
              }),
              `form-response-actor-${formResponseId}`,
            );
          },
        }),
        sendChecklistToFormResponseActor: sendTo(
          ctx => ctx.formResponseActor!,
          (ctx, __) => ({ type: 'UPDATE_CHECKLIST', checklist: QueryActorSelectors.getQueryData(ctx.checklistQuery) }),
        ),
      },
      guards: {
        isChecklistRevisionIdReady: (_ctx, evt) =>
          QueryActorSelectors.isUpdateEventSuccess(evt, 'checklist-revision-query'),
        isChecklistReady: (_ctx, evt) => QueryActorSelectors.isUpdateEventSuccess(evt, 'checklist-query'),
        isAllDataReady: ctx => {
          const queries = [
            ctx.currentUserQuery,
            ctx.checklistQuery,
            ctx.checklistRevisionQuery,
            ctx.tasksQuery,
            ctx.widgetsQuery,
            ctx.formFieldValuesQuery,
            ctx.templateQuery,
          ];

          return queries.every(query => QueryActorSelectors.getQueryData(query) !== undefined);
        },
        isChecklistUpdate: (_ctx, evt) => QueryActorSelectors.isUpdateEventSuccess(evt, 'checklist-query'),
      },
    },
  );
};

export type FormResponsePageMachine = ReturnType<typeof makeFormResponsePageMachine>;
export type FormResponsePageActor = ActorRefFrom<FormResponsePageMachine>;
