import { AiTaskTemplateId } from '@process-street/subgrade/process';
import { Circle, SquareProps, Icon, IconProps, Text, TextProps } from 'components/design/next';
import React from 'react';

export const ProcessPeteCircle: React.FC<React.PropsWithChildren<SquareProps>> = props => {
  return <Circle bg="gray.100" p="1.5" size="22px" {...props} />;
};

export const ProcessPeteIcon: React.FC<React.PropsWithChildren<Partial<IconProps>>> = props => {
  return <Icon icon="process-pete" color="gray.500" size="4" variant="far" {...props} />;
};

export const AiTaskAuditHelperText: React.FC<React.PropsWithChildren<TextProps>> = props => {
  return <Text as="span" fontWeight="bold" variant="-2" {...props} />;
};

export type CommonAuditProps = {
  aiTaskTemplate: AiTaskTemplateId;
};
