import { Box, Button, HStack, Icon, IconButton, Input, Tooltip, Text, VStack } from 'components/design/next';
import {
  conditionRequiresOperand,
  DataSetColumnDef,
  DataSetColumnFilterForm,
  DataSetFilterParams,
  DataSetTextFilterCondition,
  DataSetTextFilterConditionV1,
  SavedViewFilterOperator,
} from '@process-street/subgrade/process';
import { noop } from '@process-street/subgrade/util';
import { useMachine } from '@xstate/react';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import * as React from 'react';
import { SelectInstance, StylesConfig } from 'react-select';
import { match, P } from 'ts-pattern';
import { useDataSetFilters } from '../../store';
import { makeColumnsFilterItemStateMachine } from './columns-filter-item-machine';
import { Muid } from '@process-street/subgrade/core';
import { useFeatureFlag } from 'features/feature-flags';
import { useToken } from '@chakra-ui/react';

export type OperatorOptionType = {
  value: string;
  label: string;
};

export type ColumnOptionType = {
  value: string;
  label: string;
};

interface ColumnsFilterItemProps {
  index: number;
  filter: DataSetColumnFilterForm;
  columnOptions: ColumnOptionType[];
  columnsMap: Record<string, DataSetColumnDef>;
  operator?: SavedViewFilterOperator;
  onOperatorChange?: (operator: SavedViewFilterOperator) => void;
}

export const ColumnsFilterItem: React.FC<React.PropsWithChildren<ColumnsFilterItemProps>> = ({
  filter,
  columnOptions,
  columnsMap,
  index,
  operator,
  onOperatorChange,
}) => {
  const isDataSetsV2Enabled = useFeatureFlag('dataSetsV2');
  const columnRef = React.useRef<SelectInstance<ColumnOptionType>>(null);
  const conditionRef = React.useRef<SelectInstance<ColumnOptionType>>(null);
  const valueRef = React.useRef<HTMLInputElement>(null);

  const [state, send] = useMachine(
    () => makeColumnsFilterItemStateMachine<Muid, DataSetFilterParams['condition'], string>(),
    {
      devTools: true,
      context: {
        columnId: filter.columnId,
        condition: filter.filterParams?.condition,
        value: filter.filterParams?.value,
      },
      actions: {
        persist: (ctx, _e) => {
          match(ctx)
            .with({ columnId: P.string, condition: P.string, value: P.any }, ({ columnId, condition, value }) => {
              dataSetFilters.setFilter({ index, columnId, condition, value });
            })
            .otherwise(noop);
        },
        focusColumn: (_c, _e) => {
          columnRef.current?.focus?.();
        },
        focusCondition: (_c, _e) => {
          conditionRef.current?.focus?.();
        },
        focusValue: (_c, _e) => {
          valueRef.current?.focus();
        },
      },
    },
  );

  const dataSetFilters = useDataSetFilters();

  const handleColumnChange = (column: ColumnOptionType) => {
    send({ type: 'SELECT_COLUMN', id: column.value });
  };

  const handleConditionChange = (operator: OperatorOptionType) => {
    const value = operator.value as DataSetTextFilterCondition;
    send({ type: 'SELECT_CONDITION', condition: value });
  };

  const handleValueChange = (value: string) => {
    send({ type: 'UPDATE_VALUE', value });
  };

  const handleRemoveFilter = () => {
    dataSetFilters.removeFilter(index);
  };

  const needsOperand = conditionRequiresOperand(state.context.condition);

  const [fontMd] = useToken('fontSizes', ['md']);

  const dropdownStyles: StylesConfig<ColumnOptionType, false> = {
    option: styles => ({
      ...styles,
      fontSize: fontMd,
    }),
  };

  return (
    <HStack w="full" spacing={2}>
      <Tooltip label="Remove filter">
        <IconButton
          aria-label="Remove filter"
          data-testid="remove-filter"
          size="sm"
          variant="tertiary"
          mr="-1"
          icon={<Icon icon="times" size="3.5" color="gray.500" />}
          onClick={handleRemoveFilter}
        />
      </Tooltip>

      {isDataSetsV2Enabled && (
        <VStack minW={15}>
          {operator ? (
            <Button
              variant="tertiary"
              size="sm"
              color="gray.500"
              textTransform="uppercase"
              flexShrink="0"
              onClick={() =>
                onOperatorChange?.(
                  operator === SavedViewFilterOperator.And ? SavedViewFilterOperator.Or : SavedViewFilterOperator.And,
                )
              }
            >
              {operator}
            </Button>
          ) : (
            <Text variant="-1u" color="gray.500" fontWeight="normal">
              Show
            </Text>
          )}
        </VStack>
      )}

      <Box w="full">
        <BlvdSelect
          selectRef={columnRef}
          placeholder="Field"
          options={columnOptions}
          openMenuOnFocus={state.matches('column')}
          value={
            state.context.columnId
              ? {
                  label: columnsMap[state.context.columnId]?.name ?? '',
                  value: state.context.columnId,
                }
              : undefined
          }
          onChange={value => {
            if (value && BlvdSelectHelpers.isOptionType<ColumnOptionType>(value)) {
              handleColumnChange(value);
            }
          }}
          styles={dropdownStyles}
        />
      </Box>

      <Box w="full">
        <BlvdSelect
          selectRef={conditionRef}
          isDisabled={state.matches('column')}
          openMenuOnFocus={state.matches('condition')}
          value={state.context.condition ? conditionToOption(state.context.condition) : undefined}
          placeholder="Condition"
          options={isDataSetsV2Enabled ? conditionOptions : conditionOptionsV1}
          onChange={value => {
            if (value && BlvdSelectHelpers.isOptionType<OperatorOptionType>(value)) {
              handleConditionChange(value);
            }
          }}
          styles={dropdownStyles}
        />
      </Box>

      <Box w="full">
        <Input
          ref={valueRef}
          isDisabled={state.matches('condition') || state.matches('column') || !needsOperand}
          borderColor="gray.300"
          placeholder={needsOperand ? 'Value' : undefined}
          value={state.context.value ?? ''}
          onChange={e => handleValueChange(e.target.value)}
        />
      </Box>
    </HStack>
  );
};

const conditionLabelsMap: Record<DataSetTextFilterCondition | DataSetTextFilterConditionV1, string> = {
  Equals: 'Is equal to',
  NotEqual: 'Is not equal to',
  Contains: 'Contains',
  NotContains: 'Does not contain',
  IsEmpty: 'Is empty',
  IsNotEmpty: 'Is not empty',
};

const conditionToOption = (
  condition: DataSetTextFilterCondition | DataSetTextFilterConditionV1,
): OperatorOptionType => ({
  value: condition,
  label: conditionLabelsMap[condition],
});

const conditionOptions: OperatorOptionType[] = Object.values(DataSetTextFilterCondition).map(conditionToOption);
const conditionOptionsV1: OperatorOptionType[] = Object.values(DataSetTextFilterConditionV1).map(conditionToOption);
