import * as React from 'react';
import { Icon, MenuItem, MenuItemProps } from 'components/design/next';
import { useWidgetActorRef } from '../../../shared/widget-context';

export const MoveDownMenuItem: React.FC<React.PropsWithChildren<MenuItemProps>> = props => {
  const { widgetActorRef } = useWidgetActorRef();
  return (
    <MenuItem
      iconSpacing="2"
      aria-label="Move down"
      icon={<Icon icon="arrow-down" size="4" />}
      onClick={() => widgetActorRef.send({ type: 'MOVE_DOWN' })}
      {...props}
    >
      Move Down
    </MenuItem>
  );
};
