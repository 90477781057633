import angular from 'angular';
import templateUrl from './checklist-header.component.html';
import './checklist-header.scss';

angular.module('frontStreetApp.directives').component('psChecklistHeader', {
  bindings: {
    actionable: '<',
    title: '<',
    onUpdate: '&',
    toggleCompletedTasksVisibility: '<',
    hideCompletedTasks: '<',
  },
  templateUrl,
  controller(FeatureFlagService, ToastService) {
    const ctrl = this;

    ctrl.isMergeTagImprovementsEnabled = FeatureFlagService.getFeatureFlags().mergeTagImprovements;

    ctrl.handleTitleBlur = function (title) {
      if (!title || !title.length) {
        ToastService.openToast({
          status: 'warning',
          title: `Don't forget to name your workflow run!`,
        });
      }
    };

    ctrl.updateTitle = function (title) {
      if (title) {
        ctrl.onUpdate({ title });
      }
    };
  },
});
