import angular from 'angular';
import Encase from 'encase';
import './inbox.scss';
import { DefaultErrorMessages } from 'components/utils/error-messages';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'InboxCtrl',
    function (
      $q,
      $scope,
      $state,
      FeatureFlagService,
      InboxService,
      OrganizationService,
      SecurityService,
      SessionService,
      UserSettingsService,
      ToastService,
    ) {
      const ctrl = this;
      $scope.initialized = false;

      ctrl.$onInit = () => {
        if ($state.params.type && $state.params.itemId) {
          $scope.expandedItemType = Encase.toUpperCamel($state.params.type);
          $scope.expandedItemId = $state.params.itemId;
        } else {
          $scope.expandedItemType = undefined;
          $scope.expandedItemId = undefined;
        }

        SessionService.setDefaultHomepage('inbox');

        if (!initUser()) {
          return;
        }

        initOrganization($scope.user);

        redirectToNewInbox();
      };

      $scope.$on('$stateChangeSuccess', (__event, __toState, toParams) => {
        if (toParams.type && toParams.itemId) {
          $scope.expandedItemType = InboxService.convertPathStateTypeToItemType(toParams.type);
          $scope.expandedItemId = toParams.itemId;
        } else {
          $scope.expandedItemType = undefined;
          $scope.expandedItemId = undefined;
        }
      });

      /**
       * Initializes user and redirects to login if user is not fully authenticated
       */
      function initUser() {
        if (SecurityService.isAuthenticatedFully()) {
          $scope.user = SessionService.getUser();

          return true;
        } else {
          $state.go('login', { url: $state.href('inbox') });

          return false;
        }
      }

      async function redirectToNewInbox() {
        const featureFlags = await FeatureFlagService.getFeatureFlagsAsync();
        if (featureFlags.inboxRevamp) {
          $state.go('myWork');
        }
      }

      function initOrganization(user) {
        return OrganizationService.getById(SecurityService.getSelectedOrganizationIdByUser(user)).then(
          organization => {
            $scope.organization = organization;

            $scope.initialized = true;
          },
          () => {
            ToastService.openToast({
              status: 'error',
              title: `We're having problems loading the inbox`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
            $state.go('dashboard');

            return $q.reject();
          },
        );
      }

      $scope.$on('$destroy', () => {
        // Save the table view properties
        if (!$state.is('logout')) {
          UserSettingsService.updateDefaultHomepage(SessionService.getDefaultHomepage());
        }
      });
    },
  );
