import * as React from 'react';
import { GetOneOffTasksByChecklistQuery } from 'features/one-off-tasks/query-builder';
import { Checklist, ChecklistStatus } from '@process-street/subgrade/process';
import { Box, Divider, HStack, Icon, IconButton, List, Spacer, Text, Tooltip, VStack } from 'components/design/next';
import { useFeatureFlag } from 'features/feature-flags';
import { OneOffTaskListItem } from './one-off-task-list-item';
import { OneOffTask } from '@process-street/subgrade/one-off-task';
import { useOneOffTaskDrawerStore } from 'features/one-off-tasks/components/shared/one-off-task-drawer-store';

export type OneOffTaskListProps = {
  checklist: Checklist;
  requiredTaskIds: OneOffTask['id'][];
};

export const OneOffTaskList: React.FC<React.PropsWithChildren<OneOffTaskListProps>> = ({
  checklist,
  requiredTaskIds,
}) => {
  const oneOffTasksEnabled = useFeatureFlag('oneOffTasks');

  const oneOffTasksQuery = GetOneOffTasksByChecklistQuery.useQuery(
    {
      checklistId: checklist.id,
    },
    { enabled: oneOffTasksEnabled },
  );

  const { createAttachedTask } = useOneOffTaskDrawerStore();

  if (!oneOffTasksEnabled) {
    return null;
  }

  const attachNewTask = () =>
    createAttachedTask({
      preselectedTemplateId: checklist.template.id,
      preselectedChecklistId: checklist.id,
    });

  const tasks = oneOffTasksQuery.data ?? [];

  return (
    <VStack
      spacing={4}
      marginLeft={7}
      marginTop="20px"
      backgroundColor="white"
      borderColor="gray.200"
      borderWidth="1px"
      borderStyle="solid"
      borderRadius="10px"
      pl={4}
      pr={1}
      paddingY={4}
    >
      <HStack width="full">
        <Text variant="-1u" color="gray.400">
          Tasks attached ({tasks.length})
        </Text>
        <Tooltip
          label={<Text fontSize="xs">Create a task attached to this workflow run</Text>}
          hasArrow
          placement="top"
          shouldWrapChildren
        >
          <Icon size="4" icon="info-circle" variant="far" color="gray.500" />
        </Tooltip>
        <Spacer />
        <IconButton
          aria-label="create task"
          variant="tertiary"
          color="gray.600"
          size="sm"
          sx={{ mr: '8px !important' }} // override Chakra's injected styles
          icon={<Icon icon="plus" variant="far" size="4" />}
          onClick={attachNewTask}
          isDisabled={checklist.status !== ChecklistStatus.Active}
        />
      </HStack>

      {tasks.length > 0 && (
        <List spacing={2} width="full">
          {tasks.map((task, index) => (
            <React.Fragment key={task.id}>
              <OneOffTaskListItem key={task.id} checklist={checklist} task={task} requiredTaskIds={requiredTaskIds} />
              {index === tasks.length - 1 ? null : (
                <Box pr={3}>
                  <Divider />
                </Box>
              )}
            </React.Fragment>
          ))}
        </List>
      )}
    </VStack>
  );
};
