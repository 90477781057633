import create from 'zustand';
import { combine } from 'zustand/middleware';
import { Muid } from '@process-street/subgrade/core';

export enum AiGenerationType {
  WorkflowTasks = 'WorkflowTasks',
  WorkflowFormFields = 'WorkflowFormFields',
  ImportWorkflowFormFields = 'ImportWorkflowFormFields',
  SingleTask = 'SingleTask',
  ImportWorkflowTasks = 'ImportWorkflowTasks',
}

type AiGenerationState = {
  isGenerating: boolean;
  generationType: AiGenerationType;
  taskTemplateId?: Muid;
};

const INITIAL_STATE: AiGenerationState = {
  isGenerating: false,
  generationType: AiGenerationType.WorkflowTasks,
};

export const useAiGenerationSlice = create(
  combine(INITIAL_STATE, set => {
    const startWorkflowTasksGeneration = () =>
      set({
        isGenerating: true,
        generationType: AiGenerationType.WorkflowTasks,
      });
    const startWorkflowGeneration = () =>
      set({
        isGenerating: true,
        generationType: AiGenerationType.WorkflowFormFields,
      });

    const startWorkflowImport = () =>
      set({
        isGenerating: true,
        generationType: AiGenerationType.ImportWorkflowTasks,
      });

    const finishWorkflowImport = () =>
      set({
        isGenerating: true,
        generationType: AiGenerationType.ImportWorkflowFormFields,
      });

    const startTaskGeneration = (taskTemplateId: Muid) =>
      set({
        isGenerating: true,
        generationType: AiGenerationType.SingleTask,
        taskTemplateId,
      });

    const stopGeneration = () =>
      set({
        isGenerating: false,
        generationType: AiGenerationType.WorkflowFormFields,
      });

    return {
      startWorkflowTasksGeneration,
      startWorkflowGeneration,
      startWorkflowImport,
      startTaskGeneration,
      finishWorkflowImport,
      stopGeneration,
    };
  }),
);
