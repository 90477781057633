import angular from 'angular';
import { MergeTagsConstants } from '@process-street/subgrade/form/merge-tags-constants';
import { urlService } from 'services/url-service';
import templateUrl from './template-text-widget.component.html';
import './template-text-widget.scss';
import { Key } from 'services/key';
import { useTemplateWidgetsStore } from 'features/widgets/use-template-widgets-store';
import { FormatBlockType } from 'directives/text-widget-toolbar/toolbar-blocks';

// tinyMCE is lazy loaded via the app router, see app.states.js

angular.module('frontStreetApp.directives').component('psTemplateTextWidget', {
  bindings: {
    widget: '<',
    templateRevision: '<',
    mergeTagsEnabled: '<',
    disabled: '<',
    onUpdate: '&',
  },
  templateUrl,
  controller($scope, $sce, $ngRedux, $timeout, MergeTagsService) {
    const ctrl = this;
    ctrl.blurred = false;

    ctrl.$onInit = () => {
      ctrl.unsubscribeFromTemplateWidgetsStore = useTemplateWidgetsStore.subscribe(state => {
        const body = ctrl.editor.getBody();
        if (state.updatingWidgetGroupIds.has(ctrl.widget.header.group.id)) {
          ctrl.editor.getBody().setAttribute('contenteditable', false);
          body.style.backgroundColor = 'var(--ps-colors-gray-100)';
        } else {
          ctrl.editor.getBody().setAttribute('contenteditable', true);
          body.style.backgroundColor = '#fff';
        }
      });
    };

    ctrl.$onDestroy = () => {
      ctrl.unsubscribeFromTemplateWidgetsStore();
    };

    ctrl.$onChanges = function (changes) {
      if (changes.widget) {
        ctrl.widget = changes.widget.currentValue;
        ctrl.setContent(ctrl.widget.content);

        if (ctrl.disabled) {
          parseWidgetContent(ctrl.widget.content);
        }
      }
    };

    ctrl.tinymceOptions = {
      // Should be setup, but there is (was?) a bug in UI TinyMCE
      init_instance_callback(editor) {
        // This gets passed to the toolbar component
        ctrl.editor = editor;
        editor.on('keydown', event => {
          if (event.keyCode === Key.TAB && !ctrl.hasParentElement(editor, 'li')) {
            if (event.shiftKey) {
              editor.execCommand('Outdent');
            } else {
              editor.execCommand('Indent');
            }

            event.preventDefault();

            return false;
          } else {
            return undefined;
          }
        });

        editor.on('blur', () => {
          ctrl.blurred = true;
        });
      },
      browser_spellcheck: true,
      entity_encoding: 'raw',
      inline: true,
      plugins: 'autolink link lists paste',
      default_link_target: '_blank',
      menubar: false,
      toolbar: false,
      valid_elements:
        'a[href|rel|target|title],strong/b,em/i,u,p[class|style],ul,ol,li[style],' +
        'br,hr,h1[style],h2[style],h3[style],pre,code,span[style]',
      valid_classes: Object.values(FormatBlockType).join(' '),
      valid_styles: {
        li: 'text-align',
        p: 'padding-left,text-align',
        span: 'background-color,color,text-decoration',
        h1: 'text-align',
        h2: 'text-align',
        h3: 'text-align',
      },
      paste_auto_cleanup_on_paste: true,
      paste_preprocess(__plugin, args) {
        // TODO This should be part of HtmlServiceUtils
        if (args.content && args.content.includes('<table')) {
          const element = angular.element(args.content);

          let newContent = '';

          element.find('tr').each((__index, el) => {
            const tr = angular.element(el);

            let tdText = '';
            tr.find('td, th').each((__idx, e) => {
              const td = angular.element(e);
              tdText += `${td.text()}&emsp;`;
            });

            newContent += `${tdText}<br>`;
          });

          args.content = newContent;
        }
      },
      paste_postprocess(__plugin, args) {
        args.node.childNodes.forEach(node => {
          // anchor - set proper targets
          if (node.tagName === 'A') {
            urlService.setTargetForLink(node);
          }
        });
      },
      fix_list_elements: true,
      convert_urls: false,
      link_assume_external_targets: true,
    };

    ctrl.hasParentElement = function (editor, tagName) {
      // store bookmark to the current selection
      editor.selection.getBookmark();

      const [bookmark] = editor.dom.select('[data-mce-type=bookmark]');
      const parentElementExist = ctrl.searchParentElement(bookmark, tagName);
      bookmark.remove();
      return parentElementExist;
    };

    ctrl.searchParentElement = function (elem, tagName) {
      const parent = elem.parentElement;
      if (!parent) {
        return false;
      } else if (parent.nodeName.toLowerCase() === tagName) {
        return true;
      } else {
        return ctrl.searchParentElement(parent, tagName);
      }
    };

    /**
     * Parses widget's content within the context of template view
     *
     * @param content
     */
    function parseWidgetContent(content) {
      if (!content) {
        return;
      }

      MergeTagsService.parseTemplateContent(content, ctrl.templateRevision, MergeTagsConstants.Target.GENERAL).then(
        parsedContent => {
          ctrl.setContent(parsedContent);
        },
      );
    }

    // There's a bit of an issue where TinyMCE will modify the content after it's pasted.
    // So, what we do is wait until the user has focused the content before we start watching for changes.

    let timeout;

    ctrl.watchWidgetContent = () => {
      $scope.$watch(() => ctrl.widget && ctrl.widget.content, ctrl.contentUpdated);
    };

    ctrl.contentUpdated = (newValue, oldValue) => {
      if (newValue !== oldValue) {
        $timeout.cancel(timeout);

        if (ctrl.blurred) {
          ctrl.onUpdate({ widget: ctrl.widget });
        } else {
          timeout = $timeout(() => {
            ctrl.onUpdate({ widget: ctrl.widget });
          }, 500);
        }
      }
    };

    let watched = false;

    ctrl.focus = () => {
      ctrl.blurred = false;

      if (!watched) {
        watched = true;
        ctrl.watchWidgetContent();
      }
    };

    // This is necessary in order to keep html styles (e.g. Indent)
    // otherwise data-ng-bind-html will remove them.
    ctrl.setContent = function (content) {
      ctrl.trustedContent = $sce.trustAsHtml(content);
    };
  },
});
