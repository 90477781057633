import { Template, TemplateType } from '@process-street/subgrade/process';
import * as React from 'react';
import {
  forwardRef,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuItemProps,
  MenuList,
  MenuListProps,
  MenuProps,
  Portal,
  useDisclosure,
} from 'components/design/next';
import { useInjector } from 'components/injection-provider';
import { GetTemplateQuery, useGetTemplateQuery } from 'features/template/query-builder';
import { createUsableContext } from '@process-street/subgrade/util';
import { DiscardTemplateAlert, DiscardTemplateButton } from './discard-template-button';
import { match, P } from 'ts-pattern';
import { PageMenu } from 'features/template/components/template-menu/page';
import { WorkflowViewMenu } from 'features/template/components/template-menu/workflow-view-menu';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import { FormMenu } from './form';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { useMatch } from '@process-street/adapters/navigation';
import { WorkflowEditMenu } from './workflow-edit-menu';
import { CustomNotificationsModal } from 'app/pages/templates/_id/components/custom-notifications-modal';
import { TemplateRevisionWithTemplate } from '@process-street/subgrade/process';
import { useWorkflowState } from 'app/components/focus-bar/workflow/use-workflow-state';
import { TagsModal } from 'app/features/tags/components/tags-modal';
import { MoveToFolderModalWrapper } from 'app/components/move-to-folder';
import { DuplicateTemplateModal } from './duplicate-template-modal';
import { ArchiveTemplateAlert } from './archive-template-button';
import { DeleteTemplateAlert } from './delete-template-button';
import { AddToPublicLibraryModal } from './add-to-public-library-button';
import { useTemplateMenuDisclosureContextValue } from 'features/template/components/template-menu/hooks/use-template-menu-disclosure-context-value';

export type Context = {
  setCloseOnBlur: (t: boolean) => void;
  closeOnBlur: boolean;
  templateId: Template['id'];
  /** Some effects should depend on the context of being in a list or a page itself */
  view: 'index' | 'show';
};
export const [useTemplateMenuContext, TemplateMenuContext] = createUsableContext<Context>({
  hookName: 'useTemplateMenuContext',
  providerName: 'TemplateMenuContext.Provider',
});

export type DisclosureContext = {
  addToPublicModalDisclosure: ReturnType<typeof useDisclosure>;
  archiveTemplateAlertDisclosure: ReturnType<typeof useDisclosure>;
  deleteTemplateAlertDisclosure: ReturnType<typeof useDisclosure>;
  discardTemplateAlertDisclosure: ReturnType<typeof useDisclosure>;
  duplicateTemplateModalDisclosure: ReturnType<typeof useDisclosure>;
  moveTemplateModalDisclosure: ReturnType<typeof useDisclosure>;
  publicShareModalDisclosure: ReturnType<typeof useDisclosure>;
  tagsModalDisclosure: ReturnType<typeof useDisclosure>;
};

export const [useTemplateMenuDisclosureContext, TemplateMenuDisclosureContext] = createUsableContext<DisclosureContext>(
  {
    hookName: 'useTemplateMenuDisclosureContext',
    providerName: 'TemplateMenuDisclosureContext.Provider',
  },
);

const TemplateMenuList: React.FC<React.PropsWithChildren<MenuListProps>> = props => {
  return <MenuList maxH="70vh" overflowY="auto" {...props} />;
};

export const TemplateMenuItem = forwardRef<MenuItemProps, 'button'>((props, ref) => {
  return <MenuItem ref={ref} iconSpacing="2" color="gray.600" {...props} />;
});

export type MenuViewModes = 'view' | 'dashboard' | 'edit';

export type Props = Omit<MenuProps, 'children'> & {
  mode: MenuViewModes | 'edit';
  templateId?: Template['id'];
  menuButton?: React.ReactElement;
  // TODO a better pattern will be to deconstruct template menu to more composable parts so we don't have to do these kinds of namespaced props
  menuListProps?: Omit<MenuListProps, 'children'>;
  onClose?: () => void;
};

const Edit: React.FC<React.PropsWithChildren<Omit<Props, 'mode' | 'templateId'>>> = ({
  children,
  menuButton,
  menuListProps,
  ...props
}) => {
  const { templateId } = useTemplateMenuContext();
  const { tagsModalDisclosure } = useTemplateMenuDisclosureContext();
  const workflowState = useWorkflowState();
  const editable = workflowState === 'edit';
  const customNotificationsDisclosure = useDisclosure();

  const { data: templateRevision } = useNewestTemplateRevisionQuery(
    {
      templateId,
      editable: true,
    },
    { enabled: !!templateId },
  );

  const tmplRevId = templateRevision?.id;
  const isTemplateEditorV2 = Boolean(useMatch('templateV2'));
  const isPageEditorV2 = Boolean(useMatch('pageV2'));
  const isEditorV2 = isTemplateEditorV2 || isPageEditorV2;

  return (
    <>
      <Menu autoSelect={false} {...props}>
        {({ isOpen }) => (
          <>
            {menuButton ?? (
              <MenuButton
                as={IconButton}
                icon={<Icon size="4" variant="far" icon="ellipsis-h" color="white" />}
                _hover={{ bgColor: 'gray.500' }}
                _active={{ bgColor: 'gray.500' }}
                _focus={{ bgColor: 'gray.500' }}
                variant="outline"
                colorScheme="gray"
                isDisabled={!tmplRevId}
                aria-label="actions"
              />
            )}
            {isOpen && (
              <Portal>
                <ThemeProvider2024>
                  <TemplateMenuList {...menuListProps} zIndex="popover" fontSize={isEditorV2 ? 'md' : 'lg'}>
                    {isEditorV2 ? (
                      <>
                        {isTemplateEditorV2 && (
                          <WorkflowEditMenu onOpenCustomNotificationsDisclosure={customNotificationsDisclosure.onOpen}>
                            {children}
                          </WorkflowEditMenu>
                        )}
                        {isPageEditorV2 && <PageMenu>{children}</PageMenu>}
                      </>
                    ) : (
                      <>
                        {children}
                        <DiscardTemplateButton>
                          <MenuItem
                            aria-label="discard changes"
                            color="red.500"
                            fontSize="md"
                            icon={<Icon icon="trash-alt" color="red.500" size="4" />}
                          >
                            Discard Changes
                          </MenuItem>
                        </DiscardTemplateButton>
                      </>
                    )}
                  </TemplateMenuList>
                </ThemeProvider2024>
              </Portal>
            )}
          </>
        )}
      </Menu>
      <TagsModal onClose={tagsModalDisclosure.onClose} isOpen={tagsModalDisclosure.isOpen} templateId={templateId} />
      <MoveToFolderModalWrapper />
      <DuplicateTemplateModal />
      <ArchiveTemplateAlert />
      <DeleteTemplateAlert />
      <AddToPublicLibraryModal />
      <DiscardTemplateAlert />

      {templateRevision && (
        <CustomNotificationsModal
          {...customNotificationsDisclosure}
          templateRevision={templateRevision as TemplateRevisionWithTemplate}
          isReadOnly={!editable}
        />
      )}
    </>
  );
};

type ViewProps = Omit<Props, 'templateId' | 'mode'> & {
  menuButton?: React.ReactElement;
  children?: React.ReactNode;
  mode: MenuViewModes;
};

const View = ({ children, menuButton, menuListProps, ...props }: ViewProps) => {
  const { templateId, closeOnBlur } = useTemplateMenuContext();
  const { tagsModalDisclosure } = useTemplateMenuDisclosureContext();
  const customNotificationsDisclosure = useDisclosure();

  const { data: templateRevision } = useNewestTemplateRevisionQuery(
    {
      templateId,
      editable: false,
    },
    { enabled: !!templateId },
  );

  const templateQuery = useGetTemplateQuery(
    { templateId },
    {
      enabled: Boolean(templateId),
      staleTime: GetTemplateQuery.staleTime,
    },
  );

  return (
    <>
      <Menu closeOnBlur={closeOnBlur} autoSelect={false} {...props}>
        <Portal>
          <TemplateMenuList zIndex="popover" {...menuListProps}>
            <MenuGroup>
              {match(templateQuery)
                .with({ status: 'success', data: { templateType: TemplateType.Page } }, () => (
                  <PageMenu>{children}</PageMenu>
                ))
                .with({ status: 'success', data: { templateType: TemplateType.Form } }, () => (
                  <FormMenu>{children}</FormMenu>
                ))
                .with(
                  { status: 'success', data: { templateType: P.union(TemplateType.Playbook, TemplateType.Form) } },
                  () => (
                    <WorkflowViewMenu
                      mode={props.mode}
                      onOpenCustomNotificationsDisclosure={customNotificationsDisclosure.onOpen}
                    >
                      {children}
                    </WorkflowViewMenu>
                  ),
                )
                .otherwise(() => null)}
            </MenuGroup>
          </TemplateMenuList>
        </Portal>

        {menuButton ?? (
          <MenuButton
            as={IconButton}
            icon={<Icon aria-label="actions" icon="ellipsis-h" variant="far" size="4" />}
            variant="tertiary"
          />
        )}
      </Menu>

      <TagsModal onClose={tagsModalDisclosure.onClose} isOpen={tagsModalDisclosure.isOpen} templateId={templateId} />
      <MoveToFolderModalWrapper />
      <DuplicateTemplateModal />
      <ArchiveTemplateAlert />
      <DeleteTemplateAlert />
      <AddToPublicLibraryModal />

      {templateRevision && (
        <CustomNotificationsModal
          {...customNotificationsDisclosure}
          templateRevision={templateRevision as TemplateRevisionWithTemplate}
          isReadOnly={true}
        />
      )}
    </>
  );
};

export const TemplateMenu: React.FC<React.PropsWithChildren<Props>> = props => {
  const { $stateParams } = useInjector('$stateParams');
  const templateId = props.templateId ?? $stateParams.id;
  const [closeOnBlur, setCloseOnBlur] = React.useState(true);
  const view: Context['view'] = props.templateId ? 'index' : 'show';

  const templateMenuContext = React.useMemo<Context>(
    () => ({ setCloseOnBlur, closeOnBlur, templateId, view }),
    [closeOnBlur, view, templateId],
  );

  const templateMenuDisclosureContextValue = useTemplateMenuDisclosureContextValue({ setCloseOnBlur });

  React.useEffect(() => {
    setCloseOnBlur(true);
  }, [props.mode]);

  return (
    <TemplateMenuContext.Provider value={templateMenuContext}>
      <TemplateMenuDisclosureContext.Provider value={templateMenuDisclosureContextValue}>
        {match(props.mode)
          .with('edit', () => {
            const { mode: _, ...rest } = props;
            return <Edit {...rest} />;
          })
          .with(P.union('view', 'dashboard'), () => {
            const { mode, ...rest } = props;
            return <View mode={mode as MenuViewModes} {...rest} />;
          })
          .exhaustive()}
      </TemplateMenuDisclosureContext.Provider>
    </TemplateMenuContext.Provider>
  );
};
