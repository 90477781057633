import { FileFormFieldValue, FileFormFieldWidget } from '@process-street/subgrade/process';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { readFile } from 'features/comments/components/common/attachment/use-upload-attachment-comment';
import {
  CreateWidgetUploadUrlByChecklistRevisionIdMutation,
  CreateWidgetUploadUrlMutationResponse,
  DeleteFormFieldValueMutation,
  FinishWidgetUploadByChecklistRevisionIdMutation,
  FinishWidgetUploadByChecklistRevisionIdMutationResponse,
  UpdateFormFieldValueMutationResponse,
  UploadToS3Mutation,
  UploadToS3MutationResponse,
} from 'features/widgets/query-builder';
import { ToastServiceImpl } from 'services/toast-service.impl';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { ActionObject, ActorRefFrom, assign, createMachine, forwardTo, send, sendParent, spawn } from 'xstate';
import { FormFieldMachineBuilderProps, WithFormFieldMachineEvent } from '../../../types';
import { makeValidationMachine, ValidationActorRef, ValidationParentEvent } from '../validation-machine';
import { makeFileValidationSchema } from 'pages/forms/_id/edit/components/form-fields/file-form-field/file-form-field-settings-schema';
import { makeRulesEngineTargetMachine } from '../../form-response-body/rules-engine-machine';
import { trace } from 'components/trace';

export type Context = {
  widget: FileFormFieldWidget;
  formFieldValue?: FileFormFieldValue;
  file?: File;
  uploadUrl?: string;
  uploadProgress: number;
  key?: string;
  validationActor: ValidationActorRef<File | undefined>;
  rulesEngineTargetActor: ActorRefFrom<typeof makeRulesEngineTargetMachine>;
  inputNode: HTMLElement | null;
};

export type Event = WithFormFieldMachineEvent<
  | ValidationParentEvent
  | { type: 'UPLOAD_FORM_FIELD_FILE_MUTATION' }
  | { type: 'DELETE_FORM_FIELD_VALUE_MUTATION' }
  | { type: 'PROMPT_DELETE' }
  | { type: 'CONFIRM_DELETE' }
  | { type: 'CANCEL_DELETE' },
  File
>;

export const makeFileFormFieldMachine = ({
  formFieldWidget,
  formFieldValue,
  checklistRevisionId,
  sharedContext,
  isEditable,
  inputNode,
}: FormFieldMachineBuilderProps<FileFormFieldWidget>) => {
  const { queryClient } = sharedContext;

  const logger = trace({ name: 'FileFormFieldMachine' });
  const validationSchema = makeFileValidationSchema({ required: formFieldWidget.required });

  const initialState = isEditable ? 'enabled' : 'disabled';

  return createMachine(
    {
      context: () =>
        ({
          inputNode,
          isEditable: false,
          widget: formFieldWidget,
          formFieldValue,
          uploadProgress: 0,
          validationActor: spawn(
            makeValidationMachine({ validationSchema, initialValue: formFieldValue?.fieldValue }),
            {
              name: 'validation-actor',
            },
          ),
          rulesEngineTargetActor: spawn(
            makeRulesEngineTargetMachine({ type: 'widget', widgetHeaderGroupId: formFieldWidget.header.group.id }),
            { name: 'hidden-by-rule-actor', sync: true },
          ),
        } as Context),
      schema: {
        context: {} as Context,
        events: {} as Event,
        services: {} as {
          updateFormFieldValue: {
            data: UpdateFormFieldValueMutationResponse;
          };
          createWidgetUploadUrlMutation: {
            data: CreateWidgetUploadUrlMutationResponse;
          };
          uploadToS3Mutation: {
            data: UploadToS3MutationResponse;
          };
          finishUploadMutation: {
            data: FinishWidgetUploadByChecklistRevisionIdMutationResponse;
          };
        },
      },
      tsTypes: {} as import('./file-form-field-machine.typegen').Typegen0,
      id: `file-form-field-machine:${formFieldWidget.id}`,
      predictableActionArguments: true,
      preserveActionOrder: true,
      initial: 'input',
      type: 'parallel',
      on: {
        SET_NODE: { actions: ['assignNode'] },
        SCROLL_INTO_VIEW: { actions: ['scrollIntoView'] },
      },
      states: {
        input: {
          initial: initialState,
          states: {
            disabled: {},
            enabled: {
              initial: 'idle',
              on: { FORM_COMPLETE: 'disabled' },
              states: {
                idle: {
                  on: {
                    FOCUS: 'focused',
                    PROMPT_DELETE: { target: 'deleteConfirmation' },
                  },
                },
                focused: {
                  on: {
                    CHANGE: {
                      target: 'idle',
                      actions: ['assignFile', 'sendUploadFormFieldFileMutationEvent'],
                    },
                    BLUR: 'idle',
                  },
                },
                deleteConfirmation: {
                  on: {
                    CONFIRM_DELETE: {
                      target: 'idle',
                      actions: ['sendDeleteFormFieldValueMutationEvent'],
                    },
                    CANCEL_DELETE: 'idle',
                  },
                },
              },
            },
          },
        },

        mutation: {
          initial: 'idle',
          states: {
            idle: {
              id: 'idle',
              on: {
                UPLOAD_FORM_FIELD_FILE_MUTATION: {
                  target: 'uploading.creatingUploadUrl',
                  cond: 'isFileSelected',
                },
                DELETE_FORM_FIELD_VALUE_MUTATION: 'deleting',
                UPDATE_VALUE: { target: 'uploading', cond: 'isFileSelected' },
              },
            },
            uploading: {
              initial: 'creatingUploadUrl',
              states: {
                creatingUploadUrl: {
                  invoke: {
                    src: 'createWidgetUploadUrlMutation',
                    onDone: {
                      target: 'uploadingToS3',
                      actions: ['assignUploadUrl', 'assignKey'],
                      cond: 'hasUploadUrl',
                    },
                    onError: {
                      target: '#idle',
                      actions: ['showUploadErrorToast'],
                    },
                  },
                },
                uploadingToS3: {
                  invoke: {
                    src: 'uploadToS3Mutation',
                    onDone: { target: 'finishingUpload', cond: 'hasKey' },
                    onError: { target: '#idle', actions: ['showUploadErrorToast'] },
                  },
                },
                finishingUpload: {
                  invoke: {
                    src: 'finishUploadMutation',
                    onDone: {
                      target: 'complete',
                      actions: ['assignFormFieldValue'],
                    },
                    onError: {
                      target: '#idle',
                      actions: ['showUploadErrorToast'],
                    },
                  },
                },
                complete: {
                  after: {
                    UPLOAD_SUCCESS_DELAY: '#idle',
                  },
                },
              },
            },
            deleting: {
              invoke: {
                src: 'deleteFormFieldValueMutation',
                onDone: {
                  target: 'idle',
                  actions: ['assignFormFieldValueUndefined', 'assignFileUndefined'],
                },
                onError: {
                  target: 'idle',
                  actions: ['showDeleteErrorToast'],
                },
              },
            },
          },
        },

        // This state is a kind of controller to forward events up and down
        // Since it is a parallel state, in can listen for events without blocking or getting blocked by nested states
        validation: {
          initial: 'enabled',
          states: {
            enabled: {
              on: {
                CHANGE: { actions: 'forwardToValidation' },
                REVEAL_INVALID: { actions: 'forwardToValidation' },
                BLUR: { actions: 'forwardToValidation' },
                VALID: { actions: 'sendParentValid' },
                INVALID: { actions: 'sendParentInvalid' },
                DELETE_FORM_FIELD_VALUE_MUTATION: { actions: 'sendResetToValidation' },
                HIDE: { actions: 'sendParentValid', target: 'disabled' },
              },
            },
            disabled: {
              on: {
                REVEAL: { target: 'enabled', actions: 'restoreValidationWithParent' },
              },
            },
          },
        },
      },
    },
    {
      services: {
        createWidgetUploadUrlMutation: async ctx => {
          return makeMutation(queryClient, {
            mutationKey: CreateWidgetUploadUrlByChecklistRevisionIdMutation.key,
            mutationFn: () => {
              if (!ctx.file) throw new Error('file is missing');

              return CreateWidgetUploadUrlByChecklistRevisionIdMutation.mutationFn({
                checklistRevisionId,
                fileName: ctx.file.name,
                mimeType: ctx.file.type,
                widgetId: ctx.widget.id,
              });
            },
          }).execute();
        },
        uploadToS3Mutation: async (ctx, _) => {
          const fileBuffer = await readFile(ctx.file!);

          return makeMutation(queryClient, {
            mutationKey: UploadToS3Mutation.key,
            mutationFn: () => {
              if (!ctx.uploadUrl) throw new Error('uploadUrl is missing');
              if (!ctx.file) throw new Error('file is missing');

              return UploadToS3Mutation.mutationFn({
                url: ctx.uploadUrl,
                file: ctx.file,
                data: fileBuffer,
              });
            },
          }).execute();
        },
        finishUploadMutation: async (ctx, _) => {
          return makeMutation(queryClient, {
            mutationKey: FinishWidgetUploadByChecklistRevisionIdMutation.key,
            mutationFn: () => {
              if (!ctx.key) throw new Error('key is missing');
              if (!ctx.file) throw new Error('file is missing');

              return FinishWidgetUploadByChecklistRevisionIdMutation.mutationFn({
                key: ctx.key,
                contentType: ctx.file.type,
                widgetId: ctx.widget.id,
                originalFilename: ctx.file.name,
                checklistRevisionId,
              });
            },
          }).execute();
        },
        deleteFormFieldValueMutation: async (ctx, _) => {
          return makeMutation(queryClient, {
            mutationKey: DeleteFormFieldValueMutation.key,
            mutationFn: () =>
              DeleteFormFieldValueMutation.mutationFn({
                checklistRevisionId,
                widgetId: ctx.widget.id,
              }),
          }).execute();
        },
      },
      actions: {
        assignNode: assign({ inputNode: (_, event) => event.node }),
        assignFile: assign({
          file: (_, event) => event.value,
        }),
        assignUploadUrl: assign({
          uploadUrl: (_, event) => event.data.url,
        }),
        assignKey: assign({
          key: (_, event) => event.data.key,
        }),
        assignFormFieldValue: assign({
          formFieldValue: (_, event) => event.data.formFieldValue,
        }),
        assignFileUndefined: assign({
          file: (_, __) => undefined,
        }),
        assignFormFieldValueUndefined: assign({
          formFieldValue: (_, __) => undefined,
        }),
        showUploadErrorToast: (_, error) => {
          logger.error(error.data);
          ToastServiceImpl.openToast({
            status: 'error',
            title: "We're having problems uploading the file",
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        },
        showDeleteErrorToast: () => {
          ToastServiceImpl.openToast({
            status: 'error',
            title: "We're having problems deleting the file",
            description: DefaultErrorMessages.unexpectedErrorDescription,
          });
        },
        sendUploadFormFieldFileMutationEvent: send('UPLOAD_FORM_FIELD_FILE_MUTATION'),
        sendDeleteFormFieldValueMutationEvent: send('DELETE_FORM_FIELD_VALUE_MUTATION'),

        sendParentInvalid: sendParent({ type: 'INVALID_WIDGET', widgetId: formFieldWidget.id }),
        sendParentValid: sendParent({ type: 'VALID_WIDGET', widgetId: formFieldWidget.id }),
        forwardToValidation: forwardTo(ctx => ctx.validationActor) as ActionObject<Context, Event>,
        sendResetToValidation: send({ type: 'RESET' }, { to: ctx => ctx.validationActor }),
        restoreValidationWithParent: sendParent((ctx, _evt) => {
          if (ctx.validationActor.getSnapshot()?.matches('valid')) {
            return { type: 'VALID_WIDGET', widgetId: formFieldWidget.id };
          }
          return { type: 'INVALID_WIDGET', widgetId: formFieldWidget.id };
        }),
        scrollIntoView: ctx => {
          ctx.inputNode?.scrollIntoView();
        },
      },
      delays: {
        UPLOAD_SUCCESS_DELAY: 1000,
      },
      guards: {
        isFileSelected: ctx => Boolean(ctx.file),
        hasUploadUrl: (ctx, e) => Boolean(ctx.uploadUrl) || Boolean(e.data.url),
        hasKey: ctx => Boolean(ctx.key),
      },
    },
  );
};

export type FileFormFieldMachine = ReturnType<typeof makeFileFormFieldMachine>;
export type FileFormFieldActor = ActorRefFrom<FileFormFieldMachine>;
