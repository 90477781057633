import { ChakraTheme, extendTheme, StyleObjectOrFn, ThemeConfig, withDefaultColorScheme } from '@chakra-ui/react';
import * as ColorManipulator from 'utils/colorManipulator';
import * as EditorElements from '../../../pages/pages/_id/edit/page/components/elements';
import { Avatar } from './avatar';
import { Button } from './button';
import { Popover } from './popover';
import { Progress } from './progress';
import { customSpace } from './space';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
  cssVarPrefix: 'ps',
};

export const bluePalette = {
  50: '#F7FAFC',
  100: '#EBF4FB',
  200: '#D1EAFF',
  300: '#A0CAED',
  400: '#51A8E4',
  500: '#0079CA',
  600: '#085F98',
  // The following colors were copied and pasted from Chakra's default blue palette to fill the gap.
  700: '#2C5282',
  800: '#2A4365',
  900: '#1A365D',
};

const grayPalette = {
  50: '#F9FAFB',
  100: '#EEF0F2',
  200: '#DEE4E8',
  300: '#C2CDD6',
  400: '#8E9EAC',
  500: '#5F7482',
  600: '#434F5C',
  700: '#1E2B36',
  800: '#151D23',
};

const borderColor = grayPalette[200];

export const theme = extendTheme(
  {
    config,
    colors: {
      transparent: 'transparent',
      currentColor: 'currentColor',
      current: 'currentColor',
      brand: bluePalette,
      gray: {
        50: '#F9FAFB',
        100: '#EEF0F2',
        200: '#DEE4E8',
        300: '#C2CDD6',
        400: '#8E9EAC',
        500: '#5F7482',
        600: '#434F5C',
        700: '#1E2B36',
        800: '#151D23',
      },
      blue: bluePalette,
      green: {
        100: '#EEF9F5',
        200: '#D6F2E8',
        300: '#AEE4D2',
        400: '#7AC9A6',
        500: '#00A589',
        600: '#006453',
      },
      red: {
        100: '#FBEFF1',
        200: '#F8D8DD',
        300: '#F1B1BC',
        400: '#F8798D',
        500: '#E83857',
        600: '#A13242',
      },
      yellow: {
        100: '#FFF8E5',
        200: '#FFEFBF',
        300: '#FFDE7F',
        400: '#FFCB33',
        500: '#FFBE00',
        600: '#BF8F00',
      },
      purple: {
        100: '#F0ECFC',
        200: '#DAD1F9',
        300: '#B6A4F3',
        500: '#6A41D7',
        600: '#5238AF',
      },
      orange: {
        100: '#FFF4E8',
        200: '#FFE4C6',
        300: '#FFC98C',
        500: '#F58200',
        600: '#BF6E13',
      },
      indigo: {
        100: '#E7E9FF',
        200: '#C5CAFF',
        300: '#959FFD',
        500: '#4756E0',
        600: '#1B2583',
      },
      teal: {
        100: '#E4F7F8',
        200: '#B9EBEE',
        300: '#79D9DF',
        500: '#19A7B0',
        600: '#0A7077',
      },
      black: '#1E2B36',
      white: '#FFFFFF',
      trueBlack: '#000000',
    },
    radii: {
      'none': '0',
      'xs': customSpace['0.5'],
      'sm': '0.2rem',
      'base': '0.4rem',
      'md': '0.6rem',
      'lg': '0.8rem',
      'xl': '1.2rem',
      '2xl': '1.6rem',
      '3xl': '2.4rem',
      'full': '9999px',
    },
    fonts: {
      body: `Inter,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue", system-ui, sans-serif`,
      heading: `Inter,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue", system-ui, sans-serif`,
      mono: `Menlo, Monaco, Consolas, 'Courier New', monospace`,
    },
    fontSizes: {
      '4': customSpace['4'],
      '5': customSpace['5'],
      '6': customSpace['6'],
      '7': customSpace['7'],
      '8': customSpace['8'],
      '9': customSpace['9'],
      '10': customSpace['10'],
      'xs': '12px',
      'sm': '14px',
      'md': '16px',
      'lg': '20px',
      'xl': '28px',
      '2xl': '36px',
      '3xl': '48px',
    },
    fontWeights: {
      light: 300,
      normal: 400,
      medium: 500,
      bold: 700,
    },
    lineHeights: {
      normal: 'normal',
      none: '1',
      shorter: '1.2',
      short: '1.33333',
      base: '1.5',
      tall: '1.66666',
      taller: '2',
    },
    letterSpacings: {
      tighter: '-0.05em',
      tight: '-0.025em',
      normal: '0',
      wide: '0.025em',
      wider: '0.0625em',
      widest: '0.1em',
    },
    textStyles: {
      '-2': {
        fontSize: 'xs',
        lineHeight: 'short',
      },
      '-2u': {
        letterSpacing: 'wider',
        fontSize: 'xs',
        fontWeight: 'bold',
        lineHeight: 'short',
        textTransform: 'uppercase',
      },
      '-1': {
        fontSize: 'sm',
        lineHeight: 'short',
      },
      '-1u': {
        letterSpacing: 'wider',
        fontSize: 'sm',
        lineHeight: 'short',
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },
      '1': {
        fontSize: 'md',
        lineHeight: 'base',
      },
      '2': {
        fontSize: 'lg',
        lineHeight: 'shorter',
      },
      '3': {
        fontSize: 'xl',
        lineHeight: '1.142857143',
      },
      '4': {
        fontSize: '2xl',
        lineHeight: 'short',
      },
      '5': {
        fontSize: '3xl',
        lineHeight: 'tall',
      },
      'inherit': {
        fontSize: 'inherit',
        lineHeight: 'inherit',
        color: 'inherit',
        bgColor: 'inherit',
      },
    },
    space: customSpace,
    borderWidths: customSpace,
    sizes: customSpace,
    styles: {
      global: {
        ':root': {
          // app/styles/boulevard-theme/_boulevard-variables.scss:114
          '--ps-navbar-height': '62px',
          '--ps-navbar-offset': '64px', // 62px height + 2px border bottom
          '--ps-colors-chakra-border-color': borderColor,
        },
        '[data-ds-next]': {
          color: 'currentColor',
          display: 'block',
          fontSize: '16px',
          lineHeight: 'none',
          WebkitTextSizeAdjust: '100%',
          WebkitPrintColorAdjust: 'exact',
        },
        '[data-js-focus-visible] :focus:not([data-focus-visible-added], [role="dialog"])': {
          outline: 'none',
          boxShadow: 'none',
        },
        // This is a special case where we want focused popovers still to have box shadow
        // rather than the focus indicator.
        '.chakra-popover__popper > .chakra-popover__content:focus': {
          boxShadow: 'xl',
        },
        '.css-0': {
          marginTop: 'initial',
          marginBottom: 'initial',
          lineHeight: 'initial',
        },
        '.chakra-select__icon': {
          fontSize: 'inherit',
        },
        '.chakra-text': {
          margin: 'initial',
          letterSpacing: 'inherit',
          textTransform: 'inherit',
        },
        '.chakra-image': {
          borderStyle: 'none',
          display: 'block',
          verticalAlign: 'middle',
          maxWidth: '100%',
          height: 'auto',
        },
        '@media print': {
          '&[data-hide-on-print]': {
            visibility: 'hidden',
          },

          '& [data-ds-next]': {
            color: 'inherit !important',
            background: 'inherit !important',
            boxShadow: 'inherit !important',
          },
          '[data-ds-next] img': {
            maxWidth: '33% !important',
          },
        },
        '.chakra-stack': {
          borderStyle: 'none',
        },
        '.chakra-collapse': {
          overflow: 'visible !important',
        },
        '.chakra-divider': {
          margin: 0,
        },
        '.chakra-input': {
          border: 'none',
        },
        '.chakra-button, .chakra-menu__menuitem, .chakra-accordion__button, .chakra-modal__close-btn, .chakra-popover__close-btn, .chakra-tabs__tab':
          {
            background: 'transparent',
            padding: 0,
            textTransform: 'none',
            borderStyle: 'none',
            overflow: 'visible',
            fontFamily: 'inherit',
            outline: 'initial',
            color: 'currentColor',
            fontSize: '100%',
            lineHeight: 1.15,
            margin: 0,
            cursor: 'pointer',
          },
        // this also needs a reset like above, but fontSize shouldn't be 100% because the close button svg size is based on font size
        '.chakra-alert > button[aria-label="Close"], button[aria-label="close"]': {
          background: 'transparent',
          borderStyle: 'none',
          cursor: 'pointer',
          padding: 0,
        },
        '.chakra-breadcrumb__list': {
          ps: 0,
          mb: 0,
        },
        '.chakra-breadcrumb__link': {
          'color': 'inherit',
          'fontWeight': 'inherit',
          'textDecoration': 'inherit',
          '&:hover': {
            color: 'inherit',
            fontWeight: 'inherit',
            textDecoration: 'inherit',
          },
        },
        '[class*=chakra-editor]': {
          textTransform: 'inherit',
          marginTop: 0,
          marginBottom: 0,
        },
        '[list]::-webkit-calendar-picker-indicator, [list]::-webkit-list-button': {
          display: 'none !important',
        },
        '.chakra-toast .chakra-alert__desc': {
          lineHeight: 'normal',
        },
        '.chakra-stat dl': {
          marginBottom: 0,
        },
        // https://github.com/airbnb/visx/issues/1337
        '.visx-tooltip-glyph': {
          '& svg': {
            overflow: 'visible !important',
          },
        },
        // TinyMce Balloon Toolbar
        '.tox .tox-collection--list .tox-collection__item': {
          paddingTop: '12px !important',
          paddingBottom: '12px !important',
        },
        '.tox-tinymce-inline, .tox-tinymce-aux': {
          zIndex: 'var(--ps-zIndices-popover)',
        },

        'label.chakra-checkbox, label.chakra-radio, label.chakra-switch': {
          marginBottom: '0',
        },
        '[hidden], template': {
          // fix bug in old imported normalize.css version, reverting to real browser default
          display: 'none !important',
        },
      },
    },
    components: {
      Alert: {
        baseStyle: {
          container: {
            borderRadius: 'base',
            alignItems: 'center',
          },
          title: {
            fontSize: 'md',
            fontWeight: 'medium',
            lineHeight: '24px',
          },
          description: {
            fontSize: 'md',
            lineHeight: 'base',
          },
        },
        variants: {
          subtle: {
            title: {
              color: 'gray.700',
            },
            description: {
              color: 'gray.600',
            },
          },
        },
      },
      Input: {
        parts: ['field', 'addon'],
        sizes: {
          md: {
            field: {
              borderRadius: 'base',
            },
          },
        },
      },
      FormLabel: {
        baseStyle: {
          color: 'gray.600',
        },
      },
      Form: {
        parts: ['helperText'],
        baseStyle: {
          helperText: {
            color: 'gray.400',
          },
        },
      },
      Text: {
        defaultProps: {
          variant: '1',
          fontWeight: 'inherit',
        },
        variants: ['-2', '-2u', '-1', '-1u', '1', '2', '3', '4', '5'].reduce((acc, curr) => {
          acc[curr] = ({ theme: t }) => t.textStyles[curr];
          return acc;
        }, {} as Record<string, StyleObjectOrFn>),
      },
      Spinner: {
        baseStyle: {
          color: 'brand.500',
        },
      },
      Checkbox: {
        // https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/checkbox.ts#L71-L87
        sizes: {
          sm: {
            control: { h: 3, w: 3 },
            label: { fontSize: 'sm' },
            // converted from 0.42 rem, assuming 16px root
            icon: { fontSize: '0.72rem' },
          },
          md: {
            control: { w: 4, h: 4 },
            label: { fontSize: 'md' },
            // converted from 0.625 rem, assuming 16px root
            icon: { fontSize: '1rem' },
          },
          lg: {
            control: { w: 5, h: 5 },
            label: { fontSize: 'lg' },
            // converted from 0.625 rem, assuming 16px root
            icon: { fontSize: '1rem' },
          },
        },
      },
      Switch: {
        sizes: {
          sm: {
            container: {
              '--switch-track-width': '18px',
              '--switch-track-height': '8px',
            },
          },
          md: {
            container: {
              '--switch-track-width': '26px',
              '--switch-track-height': '12px',
            },
          },
          lg: {
            container: {
              '--switch-track-width': '42px',
              '--switch-track-height': '20px',
            },
          },
        },
      },
      Tooltip: {
        baseStyle: {
          borderRadius: 'base',
          fontSize: 'xs',
          py: '2',
          px: '3',
        },
        variants: {
          error: {
            bg: 'white',
            color: 'red.500',
            borderColor: 'gray.300',
            borderWidth: 'px',
            borderStyle: 'solid',
          },
        },
      },
      Button,
      Progress,
      Menu: {
        parts: ['item', 'list', 'groupTitle'],
        variants: {
          gray: {
            item: {
              '_hover': {
                'backgroundColor': 'gray.200',
                'color': 'black',
                '* [data-item-description]': { color: 'black' },
                '& svg': {
                  color: 'black',
                },
              },
              '&[data-item-selected="true"]': {
                'backgroundColor': 'gray.200',
                'color': 'black',
                '* [data-item-description]': { color: 'black' },
              },
              '_focus': {
                'backgroundColor': 'gray.200',
                'color': 'black',
                '* [data-item-description]': { color: 'black' },
              },
            },
          },
        },
        baseStyle: {
          item: {
            'fontWeight': '400',
            'px': 4,
            'py': 2,
            ...(() => {
              const hover = {
                backgroundColor: 'brand.100',
                _disabled: {
                  'backgroundColor': 'inherit',
                  'color': 'inherit',
                  '* [data-item-description], & a': { color: 'inherit' },
                  '& svg': {
                    color: 'inherit',
                  },
                },
              };
              return {
                _hover: hover,
                _focus: hover,
              };
            })(),
            '&[data-item-selected="true"]': {
              'backgroundColor': 'brand.100',
              '* [data-item-description]': {},
              '_disabled': {
                'cursor': 'not-allowed',
                'opacity': 0.8,
                'backgroundColor': 'brand.100',
                'color': 'brand.500',
                '* [data-item-description]': { color: 'brand.500' },
              },
            },
          },
          list: {
            borderColor: 'gray.300',
            borderWidth: 'px',
            borderStyle: 'solid',
          },
          groupTitle: {
            fontSize: 'xs',
            textTransform: 'uppercase',
            color: 'gray.400',
            letterSpacing: 'wider',
          },
        },
      },
      Link: {
        variants: {
          noline: {
            _hover: {
              textDecoration: 'none',
            },
            _focus: {
              boxShadow: 'none',
            },
          },
        },
      },
      Select: {
        baseStyle: {
          icon: {
            width: 10,
            fontSize: 'xl',
            right: 0,
          },
          field: {
            pe: 10,
            pr: 10,
          },
        },
      },
      Popover,
      Modal: {
        parts: ['dialog', 'header'],
        baseStyle: {
          dialog: {
            borderRadius: '2xl',
          },
          header: {
            borderTopRadius: '2xl',
          },
        },
      },
      List: {
        parts: ['container'],
        baseStyle: {
          container: {
            paddingLeft: 0,
          },
        },
      },
      ...EditorElements,
      Tag: {
        parts: ['container', 'label', 'closeButton', 'leftIcon', 'rightIcon'],
        baseStyle: {
          container: {
            fontWeight: 700,
          },
        },
        sizes: {
          sm: {
            container: {
              borderRadius: 'full',
              py: 1,
              px: 4,
            },
          },
          md: {
            container: {
              borderRadius: 'full',
              py: 1,
              px: 4,
            },
          },
          lg: {
            container: {
              borderRadius: 'full',
              py: 1,
              px: 4,
            },
          },
        },
      },
      Badge: {
        baseStyle: {
          textTransform: 'none',
          borderRadius: 'full',
        },
        variants: {
          primary: {
            bg: 'brand.100',
            color: 'brand.500',
          },
          danger: {
            bg: 'red.100',
            color: 'red.500',
          },
          warning: {
            bg: 'yellow.100',
            color: 'yellow.600',
          },
        },
      },
      Avatar,
      Drawer: {
        variants: {
          /** This variant is used for drawers that should not block the page, like the insert widget menu */
          aside: {
            dialog: {
              pointerEvents: 'auto',
            },
            dialogContainer: {
              pointerEvents: 'none',
              zIndex: 2,
            },
          },
        },
      },
      Tabs: {
        parts: ['tablist', 'tabpanel', 'tab'],
        variants: {
          default: {
            tablist: {
              color: 'gray.500',
              borderBottom: '1px solid',
              borderBottomColor: 'gray.300',
            },
            tab: {
              fontSize: 'md',
              fontWeight: 'medium',
              px: 4,
              py: '2.5',
              marginBottom: '-1.5px',
              _selected: {
                color: 'brand.500',
                borderBottom: '2px solid currentColor',
              },
            },
          },
        },
      },
    },
    breakpoints: {
      '3xl': '120em',
    },
  },
  withDefaultColorScheme({ colorScheme: 'brand' }),
) as ChakraTheme;

export const theme2024 = extendTheme(
  {
    fontSizes: {
      '4': customSpace['3'],
      '5': customSpace['4'],
      '6': customSpace['5'],
      '7': customSpace['6'],
      '8': customSpace['7'],
      '9': customSpace['8'],
      '10': customSpace['9'],
      'xs': '10px',
      'sm': '12px',
      'md': '14px',
      'lg': '16px',
      'xl': '20px',
      '2xl': '28px',
      '3xl': '36px',
    },
  } as Partial<ChakraTheme>,
  theme,
) as ChakraTheme;

export const getBrandTheme = (baseTheme: ChakraTheme, color: string) => {
  return extendTheme({
    ...baseTheme,
    colors: {
      ...baseTheme.colors,
      brand: {
        50: ColorManipulator.lighten(color, 0.95),
        100: ColorManipulator.lighten(color, 0.9),
        200: ColorManipulator.lighten(color, 0.5),
        300: ColorManipulator.lighten(color, 0.3),
        400: ColorManipulator.lighten(color, 0.1),
        500: color,
        600: ColorManipulator.darken(color, 0.1),
        700: ColorManipulator.darken(color, 0.2),
        800: ColorManipulator.darken(color, 0.3),
        900: ColorManipulator.darken(color, 0.4),
      },
    },
  });
};

export type Theme = typeof theme;
