import {
  HStack,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { TaskAssignment } from '@process-street/subgrade/role-assignment';
import * as React from 'react';
import { TaskListItemAssigneeIndicatorAvatar } from './task-list-item-assignee-indicator-avatar';
import { Option } from 'space-monad';
import { toModelRef } from '@process-street/subgrade/core';
import { isGroupUser } from '@process-street/subgrade/util/user-type-utils';

export type TaskListItemAssigneeIndicatorPopoverProps = React.PropsWithChildren<{
  taskAssignments: TaskAssignment[];
}>;

export const TaskListItemAssigneeIndicatorPopover = ({
  children,
  taskAssignments,
}: TaskListItemAssigneeIndicatorPopoverProps) => {
  return (
    <Popover isLazy>
      <PopoverTrigger>{children}</PopoverTrigger>

      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader fontSize="sm" fontWeight="medium" color="gray.700">
            Assignments
          </PopoverHeader>
          <PopoverBody>
            <UnorderedList as={VStack} spacing={4} w="full" mb={0} ml={0}>
              {taskAssignments.map(assignment => {
                const user = Option(assignment)
                  .map(assignment => assignment.organizationMembership)
                  .map(toModelRef)
                  .map(om => om.user)
                  .map(toModelRef)
                  .get();

                if (!user) return null;

                return (
                  <ListItem key={assignment.id} as={HStack} alignItems="flex-start" spacing={2}>
                    <TaskListItemAssigneeIndicatorAvatar user={user} />

                    <VStack alignItems="flex-start" spacing="px">
                      <Text w="full" variant="-1" fontWeight="medium">
                        {user.username}
                      </Text>
                      <Text w="full" variant="-2" color="gray.400">
                        {isGroupUser(user) ? 'Group' : user.email}
                      </Text>
                    </VStack>
                  </ListItem>
                );
              })}
            </UnorderedList>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
