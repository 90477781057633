import * as React from 'react';
import {
  Button,
  Divider,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Template } from '@process-street/subgrade/process';
import { Icon } from 'components/design/next';
import { useCopyToClipboard } from 'react-use';
import { useInjector } from 'components/injection-provider';
import { UpgradePlanAlert, useUiDisclosure } from 'pages/forms/_id/shared';
import { AutomationsDiscoverability } from './automations-discoverability';
import { SendLinkButton } from 'pages/forms/_id/edit/components/send-link-via-email-modal/send-link-button';

export type CopyFormShareLinkProps = {
  template: Template;
  isViewOnlyForm: boolean;
};

export const CopyFormShareLink: React.FC<React.PropsWithChildren<CopyFormShareLinkProps>> = ({
  template,
  isViewOnlyForm,
}) => {
  const { $state } = useInjector('$state');
  const path = $state.href('addFormResponse', {
    id: template.id,
  });
  const shareLink = `${window.location.origin}${path}`;

  const shareMenuDisclosure = useUiDisclosure('shareMenu', {
    onOpen: () => {
      setTimeout(() => copyLinkRef.current?.select(), 100);
    },
  });

  const [isLinkCopied, setLinkCopied] = React.useState(false);
  const [isEmbedCodeCopied, setEmbedCodeCopied] = React.useState(false);
  const [, copyToClipboard] = useCopyToClipboard();

  const handleCopyLinkToClipboard = () => {
    setLinkCopied(true);
    copyToClipboard(shareLink);
  };

  const embedCode = `<iframe src="${shareLink}?embedded=true" width="100%" height="500" style="border: none;">Loading…</iframe>`;
  const handleCopyEmbedCodeToClipboard = () => {
    setEmbedCodeCopied(true);
    copyToClipboard(embedCode);
  };

  React.useEffect(() => {
    if (isLinkCopied) {
      const timeoutId = setTimeout(() => {
        setLinkCopied(false);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [isLinkCopied]);

  React.useEffect(() => {
    if (isEmbedCodeCopied) {
      const timeoutId = setTimeout(() => {
        setEmbedCodeCopied(false);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [isEmbedCodeCopied]);

  const copyLinkRef = React.useRef<HTMLInputElement>(null);

  return (
    <Popover isLazy {...shareMenuDisclosure}>
      <PopoverTrigger>
        <IconButton
          aria-label="Share form"
          variant="outlined"
          colorScheme="gray"
          w="10"
          h="10"
          borderStyle="solid"
          borderWidth="thin"
          borderColor="gray.300"
        >
          <Icon icon="share-nodes" size="4" color="gray.600" />
        </IconButton>
      </PopoverTrigger>

      <PopoverContent as={VStack} pt="4" pb="6" px="4" alignItems="flex-start" minW="300px" spacing="2">
        <HStack spacing="2" pb="2">
          <Icon icon="link" size="4" color="gray.400" />
          <Text variant="-2u" color="gray.400">
            Get the link
          </Text>
          <PopoverCloseButton top="2" />
        </HStack>
        <InputGroup size="md">
          <Input
            ref={copyLinkRef}
            value={shareLink}
            pr="2"
            cursor="default"
            backgroundColor="gray.50"
            onClick={e => e.currentTarget.select()}
            isReadOnly
          />
          <InputRightElement w="auto">
            <Button
              variant="solid"
              colorScheme="gray"
              size="sm"
              fontSize="xs"
              fontWeight="semibold"
              onClick={handleCopyLinkToClipboard}
              h="7"
              my="1"
              mx="2"
              px="3"
              py="1"
              _before={{
                content: "''",
                width: '2',
                background: 'gray.50',
                display: 'block',
                position: 'absolute',
                left: '-2',
                height: 'full',
              }}
            >
              {isLinkCopied ? 'Copied' : 'Copy'}
            </Button>
          </InputRightElement>
        </InputGroup>

        <>
          <Divider mt="16px !important" mb="16px !important" />

          <HStack spacing="2" pb="2">
            <Icon icon="code" size="4" color="gray.400" />
            <Text variant="-2u" color="gray.400">
              Embed
            </Text>
          </HStack>

          <InputGroup size="md">
            <Input
              value={embedCode}
              pr="2"
              cursor="default"
              backgroundColor="gray.50"
              onClick={e => e.currentTarget.select()}
              isReadOnly
            />
            <InputRightElement w="auto">
              <Button
                variant="solid"
                colorScheme="gray"
                size="sm"
                fontSize="xs"
                fontWeight="semibold"
                onClick={handleCopyEmbedCodeToClipboard}
                h="7"
                my="1"
                mx="2"
                px="3"
                py="1"
                _before={{
                  content: "''",
                  width: '2',
                  background: 'gray.50',
                  display: 'block',
                  position: 'absolute',
                  left: '-2',
                  height: 'full',
                }}
              >
                {isEmbedCodeCopied ? 'Copied' : 'Copy'}
              </Button>
            </InputRightElement>
          </InputGroup>

          <Divider mt="16px !important" mb="16px !important" />
          <HStack spacing="2" pb="2">
            <Icon icon="envelope" size="4" color="gray.400" />
            <Text variant="-2u" color="gray.400">
              Email
            </Text>
          </HStack>

          <SendLinkButton />
        </>

        {!isViewOnlyForm && (
          <>
            <Divider mt="16px !important" mb="16px !important" />
            <AutomationsDiscoverability />
          </>
        )}

        <UpgradePlanAlert />
      </PopoverContent>
    </Popover>
  );
};
