import { UserOrigin } from '@process-street/subgrade/core';
import { dayjs as moment } from '@process-street/subgrade/util';
import angular from 'angular';
import { HttpStatus } from '@process-street/subgrade/util';
import { GoNativeService } from 'features/go-native/go-native-service';
import { GtmService } from 'features/google/gtm-service';
import { IntercomService } from 'services/interop/intercom-service';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { EventName } from 'services/event-name';
import { trace } from 'components/trace';
import { LocalStorageService } from 'features/storage/local-storage-service';
import { SignUpStateService } from 'services/sign-up-state-service';
import { SignUpErrorCode } from 'services/sign-up-error-code';
import { ToastServiceImpl } from 'services/toast-service.impl';
import { AnalyticsService } from 'components/analytics/analytics.service';

const NEW_ORG_ID_KEY = 'newOrganizationId';

angular
  .module('frontStreetApp.services')
  .service(
    'SignUpService',
    function (
      $q,
      $rootScope,
      $state,
      $timeout,
      $window,
      auth,
      Authentication,
      Auth0Service,
      ElevioService,
      FacebookService,
      FeatureFlagService,
      SessionService,
    ) {
      const logger = trace({ name: 'SignUpService' });

      const self = this;

      self.completeAndLogin = function ({ email, username, password, subscribe }) {
        return self
          .complete({
            email,
            username,
            password,
            subscribe,
          })
          .then(() => {
            return self._login(email, password);
          });
      };

      self.signUp = function (email, source, origin) {
        return Authentication.signUp({
          email,
          source,
          origin: origin || UserOrigin.FrontStreet,
        }).$promise.then(
          result => {
            logger.info('succeeded to sign-up user');

            const { user } = result;

            GoNativeService.setup(user.id);
            ElevioService.changeUser(user, result.elevioUserHash);
            IntercomService.boot(user, result.intercomUserHash);

            AnalyticsService.trackEvent('user signed up - step 1 completed', {
              'user id': user.id,
              'user email': user.email,
              'source': user.source,
              'origin': user.origin,
            });

            return user;
          },
          response => {
            switch (response.status) {
              case HttpStatus.CONFLICT: {
                let message;
                switch (response.data.code) {
                  case SignUpErrorCode.DUPLICATE:
                    logger.warn('user is already signed-up');
                    message = 'You already have an account. Please login instead.';
                    break;
                  case SignUpErrorCode.LOCKED:
                    logger.warn('user needs to sign-up with SSO');
                    message = 'Please sign-up using SSO.';
                    break;
                  case SignUpErrorCode.BLOCKED:
                    logger.warn('user email is blocked');
                    message = 'You cannot sign-up with that email address, please use a different one.';
                    break;
                  default:
                    message = 'Please try again later.';
                    break;
                }

                ToastServiceImpl.openToast({
                  status: 'warning',
                  title: message,
                });

                break;
              }
              default:
                logger.error(
                  `failed to sign-up user. Response status: ${response.status}. Status text: ${response.statusText}.
                Response: %s`,
                  JSON.stringify(response),
                );

                ToastServiceImpl.openToast({
                  status: 'error',
                  title: `We're having problems completing your sign up`,
                  description: DefaultErrorMessages.unexpectedErrorDescription,
                });

                break;
            }

            return $q.reject(response);
          },
        );
      };

      self.complete = info => {
        // FIXME These should be passed in explicitly, not implicitly via the $state params
        const { planId, coupon, codeId } = $state.params;

        return Authentication.complete({
          email: info.email,
          emailVerificationCodeId: codeId,
          username: info.username || null,
          password: info.password || null,
          telephoneNumber: info.telephoneNumber || null,
          subscribe: info.subscribe || false,
          timeZone: moment.tz.guess(),
          planId,
          coupon,
          organizationName: info.organizationName || null,
          organizationIndustry: info.organizationIndustry || null,
        }).$promise.then(
          completeResult => {
            // These have been used by now, so let's make sure to clear them out
            SignUpStateService.removeCoupon();
            SignUpStateService.removePlanId();
            SignUpStateService.removeSource();

            logger.info('succeeded to complete user sign-up');

            const { user } = completeResult;

            AnalyticsService.trackEvent('user signed up - step 2 completed', {
              'user id': user.id,
              'user email': user.email,
              'source': user.source,
              'origin': user.origin,
              'subscribed to blog': info.subscribe,
            });

            if (info.subscribe) {
              AnalyticsService.trackEvent('subscribed to blog', {
                'user id': user.id,
                'user email': user.email,
                'source': user.source,
                'origin': user.origin,
                'blog subscription date': new Date().toISOString(),
              });
            }

            // Set template and checklists menus to expanded
            SessionService.setTemplateEditorProperty('menuExpanded', true);
            SessionService.setChecklistEditorProperty('menuExpanded', true);

            const organization = completeResult.newOrganization;
            if (organization) {
              LocalStorageService.setItem(NEW_ORG_ID_KEY, organization.id);
              self._trackOrganizationCreated(organization);
            }
            FacebookService.trackCompleteRegistration();

            GtmService.pushEvent({
              event: 'user.completed',
              fields: {
                userId: user.id,
                userUsername: user.username,
                userEmail: user.email,
                organizationId: organization?.id ?? undefined,
              },
            });

            if (user.telephoneNumber) {
              AnalyticsService.trackEvent('phone number added', {
                'phone number': user.telephoneNumber,
              });
            }

            return completeResult;
          },
          response => {
            switch (response.status) {
              case HttpStatus.NOT_FOUND:
                logger.warn('failed to complete sign-up because it has already been completed');
                ToastServiceImpl.openToast({
                  status: 'warning',
                  title: 'This sign-up has already been completed.',
                });

                break;
              case HttpStatus.CONFLICT:
                logger.warn('failed to complete sign-up because password is invalid');
                ToastServiceImpl.openToast({
                  status: 'error',
                  title: auth.convertPasswordValidationErrorCodesToMessage(response.data.errorCodes),
                });

                break;
              default:
                logger.error('failed to complete user sign-up, response: %s', JSON.stringify(response));
                ToastServiceImpl.openToast({
                  status: 'error',
                  title: 'We were not able to complete the sign-up',
                  description: DefaultErrorMessages.unexpectedErrorDescription,
                });

                break;
            }

            return $q.reject(response);
          },
        );
      };

      self._login = (email, password) => {
        const url = LocalStorageService.getItem('url');
        const { templateId, pageId } = SignUpStateService.getTemplateIds();
        SignUpStateService.removeTemplateIds();

        // The user is redirect to the "signUpRedirectUrl" after they complete their organization details
        if (url) {
          LocalStorageService.setItem('signUpRedirectUrl', url);
        } else if (templateId) {
          LocalStorageService.setItem('signUpRedirectUrl', $state.href('templateAdd', { templateId }));
        } else if (pageId) {
          LocalStorageService.setItem('signUpRedirectUrl', $state.href('pageAdd', { pageId }));
        }

        // Convert
        const redirectFromConvert = window.redirectFromConvert;
        LocalStorageService.setItem('redirectFromConvert', redirectFromConvert);

        // organization ID
        const organizationId = LocalStorageService.getItem(NEW_ORG_ID_KEY);
        LocalStorageService.removeItem(NEW_ORG_ID_KEY);

        if (organizationId) {
          LocalStorageService.setItem('url', $state.href('newCompleteSignUp.organization', { email, organizationId }));
        } else {
          LocalStorageService.setItem('url', $state.href('dashboard'));
        }

        Auth0Service.login(email, password, errorMessage => {
          $timeout(() => {
            ToastServiceImpl.openToast({
              status: 'error',
              title: errorMessage,
            });
          });
        });
      };

      self._trackOrganizationCreated = function (organization) {
        $rootScope.$broadcast(
          EventName.ORGANIZATION_CREATED,
          {
            organization,
            plan: organization.subscription.plan,
          },
          true /* authFlow */,
        );
      };
    },
  );
