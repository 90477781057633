import * as React from 'react';
import { StackProps, VStack } from 'components/design/next';
import { useSelector } from '@xstate/react';
import { Widget } from '@process-street/subgrade/process';
import { MotionConfig, Reorder } from 'framer-motion';
import { useEvent } from 'react-use';
import _isEqual from 'lodash/isEqual';
import _keyBy from 'lodash/keyBy';
import _pick from 'lodash/pick';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { makeGetActor } from 'app/pages/forms/_id/edit/components/widgets-list/make-get-actor';
import { WidgetListItem } from 'app/pages/forms/_id/edit/components/widgets-list/widget-list-item';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';
import { useUIActorRef } from 'app/pages/forms/_id/shared';

type WidgetsListProps = StackProps;

export const WidgetsList = React.memo((props: WidgetsListProps) => {
  const actor = useFormEditorPageActorRef();
  const { uiActorRef } = useUIActorRef();
  const { send } = actor;
  const isMobile = uiActorRef.getSnapshot()?.context.isMobile;
  const isReadOnly = useSelector(actor, FormEditorPageActorSelectors.isReadOnly);

  const widgetsByTemplateRevisionId = useSelector(
    actor,
    FormEditorPageActorSelectors.getWidgetsByTemplateRevisionId,
    _isEqual,
  );

  const template = useSelector(actor, FormEditorPageActorSelectors.getTemplate);

  const getActor = React.useCallback(
    <W extends Widget>(widget: W) => {
      const snapshot = actor.getSnapshot();

      if (!snapshot) {
        throw new Error('`editor` snapshot is not available');
      }

      const { widgetActorMap } = snapshot.context;

      return makeGetActor(widgetActorMap)(widget);
    },
    [actor],
  );

  const visibleWidgets = React.useMemo(() => {
    const ws = widgetsByTemplateRevisionId?.all ?? [];
    // extra step here to make sure the actor exists,
    // we look it up again within the `match` branches for better type checking
    return ws.filter(w => Boolean(getActor(w)));
  }, [getActor, widgetsByTemplateRevisionId]);

  const lastWidgetIndex = visibleWidgets.length - 1;

  const shouldShowEmptyState = visibleWidgets.length === 0;

  const isPublishing = useSelector(actor, FormEditorPageActorSelectors.isPublishing);
  const isReordering = useSelector(actor, FormEditorPageActorSelectors.isReordering);

  const handleReorder = React.useCallback(
    (widgets: Widget[]) => {
      send({ type: 'REORDER_WIDGETS', widgets });
    },
    [send],
  );

  // we send MOUSE_UP on these two window events because you can move the mouse off the drag icon while the mouse is clicked
  // and still be dragging. The machine will only respond to this event while in the dragging state.
  useEvent('mouseup', () => {
    if (isReordering) {
      send('MOUSE_UP');
    }
  });

  useEvent('touchend', () => {
    if (isReordering) {
      send('MOUSE_UP');
    }
  });

  if (!template) return null;

  const canReorder = !isMobile && !isReadOnly;

  return (
    <MotionConfig reducedMotion={'always'}>
      <VStack
        as={Reorder.Group}
        axis="y"
        values={visibleWidgets}
        // we are modifying the stack spacing to use padding instead of margin.
        // this allows a better hover experience when for drag'n'drop to insert a widget
        // because margin is not included in element.getBoundingClientRect()
        spacing="0"
        sx={{
          'listStyle': 'none',
          '[data-component="FormFieldLabel"]': { width: 'auto' },
          '& > *:first-of-type': {
            pb: 4,
          },
          '& > *:last-of-type': {
            pb: 0,
          },
          '& > *:not(style)~*:not(style)': {
            py: '4',
          },
        }}
        onReorder={handleReorder}
        alignItems="stretch"
        w="full"
        py="8"
        px="0"
        {...(isPublishing ? { pointerEvents: 'none' } : {})}
        {...props}
      >
        {shouldShowEmptyState
          ? null
          : visibleWidgets.map((widget, index) => (
              <WidgetListItem
                key={widget.header.id}
                widget={widget}
                isFirst={index === 0}
                isLast={index === lastWidgetIndex}
                canReorder={canReorder}
                getActor={getActor}
              />
            ))}
      </VStack>
    </MotionConfig>
  );
});

WidgetsList.displayName = 'WidgetsList';
