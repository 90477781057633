import angular from 'angular';
import { InboxItemType } from '@process-street/subgrade/inbox';

angular.module('frontStreetApp.services').service('InboxListService', function (InboxService) {
  const self = this;

  self.findMatchingItemByTodo = function (items, todo) {
    const { todoType } = todo;
    const todoId = todo.id;
    return items.find(
      itm =>
        itm.itemType === todoType &&
        ((InboxService.isTask(todoType) && itm.task.id === todoId) ||
          (todoType === InboxItemType.Checklist && itm.checklist.id === todoId)),
    );
  };
});
