import * as React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerContentProps,
  DrawerProps,
  Icon,
  ModalBodyProps,
} from 'components/design/next';
import { useUIActorRef, useUiDisclosure } from 'app/pages/forms/_id/shared';

export type TaskListDrawerProps = Omit<DrawerProps, 'isOpen' | 'onClose' | 'children'> & {
  contentProps: DrawerContentProps;
  bodyProps?: ModalBodyProps;
  body: React.ReactElement;
};

export const TaskListDrawer = ({ contentProps, body, bodyProps, ...rest }: TaskListDrawerProps) => {
  const { uiActorRef } = useUIActorRef();
  const disclosure = useUiDisclosure('taskList');
  const isMobile = uiActorRef.getSnapshot()?.context.isMobile;

  const onClose = () => {
    if (isMobile) {
      uiActorRef.send({ type: 'CLOSE_DISCLOSURE', name: 'taskList' });
    }
    disclosure.onClose();
  };

  return (
    <Drawer
      variant="aside"
      trapFocus={false}
      blockScrollOnMount={false}
      placement="left"
      {...disclosure}
      {...rest}
      closeOnEsc={false}
      onClose={onClose}
    >
      <DrawerContent maxW={{ base: 'full', md: '480px' }} {...contentProps}>
        {isMobile && (
          <DrawerCloseButton zIndex={1}>
            <Icon icon="times" size="4" color="gray.400" />
          </DrawerCloseButton>
        )}

        <DrawerBody px={0} pt={8} pb={4} {...bodyProps}>
          {body}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
