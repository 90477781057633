import { Muid } from '@process-street/subgrade/core';
import { axiosService } from 'services/axios-service';
import axios, { AxiosResponse } from 'axios';
import { FileWidget } from '@process-street/subgrade/process';
import { env } from 'components/common/env';

export type GetUploadUrlRequest = {
  headerId: Muid;
  fileName: string;
  mimeType: string;
};

export type GetUploadUrlResponse = {
  url: string;
  key: string;
};

export const getUploadUrl = async (req: GetUploadUrlRequest): Promise<GetUploadUrlResponse> => {
  return axiosService
    .getAxios()
    .post<GetUploadUrlResponse>(`/1/widgets/${req.headerId}/upload-url`, req)
    .then(res => res.data);
};

export type FinishUploadRequest = {
  headerId: Muid;
  key: string;
  originalFilename: string;
  contentType: string;
};

export const finishUpload = async (req: FinishUploadRequest): Promise<FileWidget> => {
  return axiosService
    .getAxios()
    .post<FileWidget>(`/1/widgets/${req.headerId}/finish-upload`, req)
    .then(res => res.data);
};

export type UploadRequest = {
  file: File;
  url: string;
  data: string | ArrayBuffer;
};

export type UploadResponse = {
  url: string;
  key: string;
};

export type ProgressEvent = {
  lengthComputable: boolean;
  loaded: number;
  total: number;
};

export const uploadToS3 = async (req: UploadRequest, onProgress: (value: number) => void): Promise<UploadResponse> => {
  // A separate instance of axios as S3 complains about our auth token.
  return axios.put(req.url, req.data, {
    headers: { 'Content-Type': req.file.type },
    onUploadProgress: (event: ProgressEvent) => {
      onProgress((100 * event.loaded) / event.total);
    },
  });
};

type WistiaUploadResponse = {
  hashed_id: string;
};

export const uploadToWistia = async (
  formData: FormData,
  onProgress: (value: number) => void,
): Promise<AxiosResponse<WistiaUploadResponse>> => {
  formData.append('api_password', env?.WISTIA_API_PASSWORD);
  formData.append('project_id', env?.WISTIA_PROJECT_ID);
  // A separate instance of axios as Wistia complains about our auth token.
  return axios.post(env?.WISTIA_UPLOAD_URL, formData, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
    onUploadProgress: (event: ProgressEvent) => {
      onProgress((100 * event.loaded) / event.total);
    },
  });
};
