import React from 'react';
import { HStack, VStack, useBoolean, useDisclosure } from 'components/design/next';
import { ContentText } from './content-text';
import { Checklist, Comment, Task, Template } from '@process-street/subgrade/process';
import { isAdmin, isNotIdRef, User } from '@process-street/subgrade/core';
import { HoverableListItem } from './hoverable-list-item';
import { AuthorActionsMenu } from './author-actions-menu';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { DeleteCommentDialog } from './delete-comment-dialog';
import { EditComment } from './edit-comment';
import { AuditInfoStack } from './audit-info';
import { DeleteButton } from './delete-button';
import { match, P } from 'ts-pattern';
import { useFocusComment } from './use-focus-comment';
import { useFeatureFlag } from 'app/features/feature-flags';

type CommentItemProps = {
  comment: Comment;
  checklistId: Checklist['id'];
  templateId: Template['id'];
};

export const CommentItem: React.FC<React.PropsWithChildren<CommentItemProps>> = ({
  comment,
  checklistId,
  templateId,
}) => {
  const {
    audit: { createdDate, ...audit },
    content,
    id,
  } = comment;
  const isMyWorkGAEnabled = useFeatureFlag('myWorkGA');

  const createdBy = isNotIdRef(audit.createdBy) ? audit.createdBy : ({} as User);
  const task = isNotIdRef(comment.task) ? comment.task : ({} as Task);

  const { data } = useGetCurrentUserInfoQuery({
    select: data => ({ currentUser: data.user, userIsAdmin: isAdmin(data?.organizationMembership.role) }),
  });
  const [isEditing, setIsEditing] = useBoolean();
  const currentUser = data?.currentUser;
  const isCommentAuthor = Boolean(currentUser?.id === createdBy.id);
  const userIsAdmin = Boolean(data?.userIsAdmin);

  const deleteDisclosure = useDisclosure();

  const { isCommentFocused } = useFocusComment({ id });
  return isEditing ? (
    <EditComment {...{ comment, templateId, checklistId, setIsEditing }} />
  ) : (
    <>
      <HoverableListItem
        id={id}
        key={id}
        border="none"
        w="full"
        px="1"
        pl={isMyWorkGAEnabled ? '0' : 'inherit'}
        bgColor="inherit"
        sx={{
          background: isCommentFocused ? 'brand.100' : 'white',
          transition: 'background 1s',
          transitionDelay: '0.2s',
        }}
      >
        {({ hovered }) => (
          <HStack alignItems="flex-start" spacing="3" width="full" justifyContent="space-between">
            <VStack alignItems="flex-start" width="full">
              <HStack width="full" h="8">
                <AuditInfoStack {...{ createdBy, createdDate }} flex="1" />
                {match([hovered, isCommentAuthor, userIsAdmin])
                  .with([true, true, P.any], () => (
                    <AuthorActionsMenu
                      {...{
                        onEdit: setIsEditing.on,
                        onDelete: deleteDisclosure.onOpen,
                      }}
                    />
                  ))
                  .with([true, false, true], () => <DeleteButton onClick={deleteDisclosure.onOpen} />)
                  .otherwise(() => null)}
              </HStack>
              <ContentText
                {...{
                  content,
                  checklistId,
                  minW: '0',
                  flex: '1',
                  pl: '9',
                }}
              />
            </VStack>
          </HStack>
        )}
      </HoverableListItem>

      <DeleteCommentDialog {...{ ...deleteDisclosure, commentId: id, task, checklistId, templateId }} />
    </>
  );
};
