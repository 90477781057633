import angular from 'angular';
import { InboxItemType } from '@process-street/subgrade/inbox';
import templateUrl from './task.component.html';
import './task.scss';

angular.module('frontStreetApp.directives').component('psTaskInboxItem', {
  bindings: {
    user: '<',
    item: '<',
    permissionMap: '<',
    taskPermissionMap: '<',
  },
  require: {
    inboxListCtrl: '^psInboxList',
  },
  templateUrl,
  controller(InboxService) {
    const ctrl = this;

    ctrl.resolveTaskName = () => InboxService.resolveTaskName(ctrl.item);

    ctrl.resolveDueDate = () => InboxService.resolveDueDate(ctrl.item.task.dueDate);

    ctrl.isOverdue = () => InboxService.isOverdue(ctrl.item.task.dueDate);

    ctrl.getOverdueClassName = () => InboxService.getOverdueClassName(ctrl.item.task.dueDate);

    ctrl.shouldShowDoodads = function () {
      return !!ctrl.item.totalDoodads;
    };

    ctrl.isSelected = function () {
      return !!ctrl.item.selected;
    };

    ctrl.toggleSelect = function () {
      ctrl.inboxListCtrl.toggleItemSelection(ctrl.item);
    };

    ctrl.shouldOpenSelection = function () {
      return ctrl.inboxListCtrl.countSelected() > 0;
    };

    ctrl.isApprovalTask = function (item) {
      return item.itemType === InboxItemType.ApprovalTask;
    };

    ctrl.getStateType = InboxService.convertItemTypeToPathStateType;
  },
});
