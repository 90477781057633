import { MenuItem } from '@chakra-ui/react';
import { TaskTemplate, TemplateRevision } from '@process-street/subgrade/process';
import { Icon } from 'app/components/design/next';
import { ConditionalLogicButtonWrapper } from 'app/pages/templates/_id/components/conditional-logic-button-wrapper';
import * as React from 'react';

export type ConditionalLogicMenuItemProps = {
  taskTemplate: TaskTemplate;
  templateRevisionId: TemplateRevision['id'];
  hasConditionalLogic: boolean;
};

export const ConditionalLogicMenuItem = ({
  taskTemplate,
  templateRevisionId,
  hasConditionalLogic,
}: ConditionalLogicMenuItemProps) => {
  return (
    <ConditionalLogicButtonWrapper taskTemplate={taskTemplate} templateRevisionId={templateRevisionId}>
      <MenuItem
        fontSize="md"
        icon={<Icon icon="shuffle" size="3.5" color={hasConditionalLogic ? 'purple.500' : 'gray.500'} />}
        color={hasConditionalLogic ? 'purple.500' : 'gray.600'}
      >
        Conditional Logic
      </MenuItem>
    </ConditionalLogicButtonWrapper>
  );
};
