import React from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  StackProps,
  Text,
} from 'components/design/next';
import { match, P } from 'ts-pattern';
import { useOS } from 'services/os-util';

import { AccountMenu } from '../account-menu';
import { UniversalNewMenu } from 'features/new-menu/component';
import { OrganizationLogo } from 'components/organization-logo/component';
import { MenuItemLink } from '../common';
import { useGlobalSearchIsEnabled } from 'features/global-search/use-global-search-is-enabled';
import { BootstrapRunButton } from 'features/new-buttons/run-button/run-button-bootstrap';
import { isGuest, UserType } from '@process-street/subgrade/core';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { canAccess, Feature } from 'services/features/features';

import { UpgradeButton } from './upgrade-button';
import { InboxItem, UnreadCommentsBadge } from './inbox-item';
import { useGetUnreadCommentsCountQuery } from 'features/comments/query-builder/get-unread-comments-count-query';

import { OnboardingSlideshow } from 'features/onboarding-slideshow/components/onboarding-slideshow';
import { AppModalName, AppModalQueryParam } from 'app/app.constants';
import { useGetOrganizationTheme } from 'features/brand-customization/query-builder/get-organization-theme';
import { CreateChecklistSource as Source } from 'services/checklist-service.interface';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';
import { useCurrentStateData } from './use-current-state-data';
import { useBackButtonContext } from 'components/back-button-provider';
import { useFeatureFlag } from 'features/feature-flags';
import { CommentsButton } from 'directives/ui/topbar/components/topbar/comments-button';
import { useHref, useLeakyLocation, useMatch, useNavigate } from '@process-street/adapters/navigation';
import { queryString } from '@process-street/subgrade/util';
import { useSelectedOrganization } from 'hooks/use-selected-organization';
import { usePlan } from 'services/use-plan-track';
import { LinkProps } from '@chakra-ui/react';
import { LayoutProps } from '@chakra-ui/system';
import { useCanViewDataSets } from 'pages/reports/data-sets/hooks/use-can-view-data-sets';
import { GoNativeService } from 'features/go-native/go-native-service';

const TopbarContainer: React.FC<React.PropsWithChildren<StackProps>> = props => {
  const selectedOrganizationId = useSelectedOrganization()?.id;
  const { data: hasBrandColor = false } = useGetOrganizationTheme<boolean>(
    { organizationId: selectedOrganizationId },
    {
      select: theme => Boolean(theme?.brandColor),
      enabled: Boolean(selectedOrganizationId),
    },
  );

  return (
    <HStack
      role="navigation"
      as="nav"
      position="fixed"
      bg="white"
      borderBottomWidth={0.5}
      borderBottomColor="gray.200"
      borderBottomStyle="solid"
      width="100%"
      height="var(--ps-navbar-height)"
      fontWeight="medium"
      boxSizing="content-box"
      bgColor={hasBrandColor ? 'brand.100' : undefined}
      {...props}
    ></HStack>
  );
};

export const Topbar = () => {
  const libraryHref = useHref('dashboard');
  const reportsHref = useHref('reports');
  const dataSetsHref = useHref('dataSets');
  const myWorkHref = useHref('myWork');
  const inboxHref = useHref('inbox');

  const location = useLeakyLocation();
  const matchReports = Boolean(useMatch('reports'));
  const matchDashboard = Boolean(useMatch('dashboard'));
  const matchInbox = Boolean(useMatch('inbox'));
  const matchDataSets = Boolean(useMatch('dataSets'));
  const navigate = useNavigate();

  // This is necessary to support multiple organizations in different tabs.
  // https://processstreet.slack.com/archives/C01AQAUEQ87/p1645628426879859
  const selectedOrganization = useSelectedOrganization();
  const selectedPlan = usePlan();

  const { metaSymbol } = useOS();
  const isInboxRevampEnabled = useFeatureFlag('inboxRevamp');
  const isDataSetsV2Enabled = useFeatureFlag('dataSetsV2');

  const activeTab = (() => {
    if (isDataSetsV2Enabled && matchDataSets) {
      return 'dataSets';
    }

    if (matchReports || matchDataSets) {
      return 'reports';
    }

    if (matchDashboard) {
      return 'library';
    }

    if (matchInbox) {
      return 'inbox';
    }

    if (location.pathname.includes('myWork')) {
      return 'myWork';
    }

    return undefined;
  })();
  const globalSearchEnabled = useGlobalSearchIsEnabled();

  const handleGlobalSearchClick = () => {
    navigate({
      pathname: location.pathname,
      search: queryString.stringify({
        ...queryString.parse(location.search),
        [AppModalQueryParam.Modal]: AppModalName.GlobalSearch,
      }),
      hash: location.hash,
    });
  };

  const userQuery = useGetCurrentUserInfoQuery({
    select: data => {
      return {
        currentUser: data?.user,
        userIsGuest: isGuest(data?.organizationMembership?.role),
        userIsAnonymous: Boolean(isAnonymousUser(data?.user)),
        userIsDeveloper: data?.user.developer,
      };
    },
  });

  const userIsAnonymous = userQuery.data?.userIsAnonymous;
  const userIsGuest = userQuery.data?.userIsGuest;
  const userCanViewDataSets = useCanViewDataSets();
  const guestCanRunChecklist = canAccess(Feature.GUESTS_CAN_CREATE_CHECKLISTS, selectedPlan?.id);

  const unreadCommentsQuery = useGetUnreadCommentsCountQuery({
    enabled: userQuery.data?.currentUser.userType === UserType.Standard,
  });
  const shouldShowUnreadCommentsBadge = isInboxRevampEnabled && !!unreadCommentsQuery.data?.count;

  const currentStateData = useCurrentStateData();
  const backButtonProvider = useBackButtonContext();
  if (currentStateData?.hideTopBar) return null;

  const desktopFlex: LayoutProps['display'] = {
    base: 'none',
    md: 'flex',
  };

  const goNativeApp = GoNativeService.isGoNativeApp();

  return match(userQuery)
    .with({ status: 'success', data: { userIsAnonymous: false } }, () => (
      <TopbarContainer justifyContent="space-between">
        {/* Navbar Left */}
        <List
          display="flex"
          mb={0}
          pl="3"
          alignItems="center"
          width={['100%', '100%', 'min-content']}
          gridGap={['3', '3', '6']}
        >
          {selectedOrganization && (
            <ListItem pl={backButtonProvider.shouldShowBackButton ? undefined : 1}>
              <Link background={'red.300'} href={reportsHref} aria-label="reports">
                <Box width="8" height="8" borderRadius="full" pos="relative">
                  <OrganizationLogo organization={selectedOrganization}>
                    <Image w="full" h="full" alt="Process Street" src="/images/ps-icon-blue.svg" />
                  </OrganizationLogo>
                </Box>
              </Link>
            </ListItem>
          )}

          {/* Mobile menu */}
          {!goNativeApp && (
            <ListItem hideFrom="md">
              <Menu>
                <Box position="relative">
                  <MenuButton
                    lineHeight="1"
                    as={IconButton}
                    icon={<Icon variant="far" icon="bars" size="6" />}
                    alignSelf="center"
                    variant="ghost"
                    color="gray.500"
                    _active={{ color: 'brand.500' }}
                    aria-label="main navigation"
                  />
                  {shouldShowUnreadCommentsBadge && <UnreadCommentsBadge top={0} right={0} />}
                </Box>
                <MenuList w="100vw" borderRadius={0} display={[null, null, 'none']}>
                  {userIsGuest && !guestCanRunChecklist ? null : (
                    <MenuItemLink href={libraryHref} color={activeTab === 'library' ? 'brand.500' : undefined}>
                      Library
                    </MenuItemLink>
                  )}
                  <MenuItemLink href={reportsHref} color={activeTab === 'reports' ? 'brand.500' : undefined}>
                    Reports
                  </MenuItemLink>
                  {isDataSetsV2Enabled && userCanViewDataSets && (
                    <MenuItemLink href={dataSetsHref} color={activeTab === 'dataSets' ? 'brand.500' : undefined}>
                      Data Sets
                    </MenuItemLink>
                  )}
                  {isInboxRevampEnabled ? (
                    <MenuItemLink
                      href={myWorkHref}
                      color={activeTab === 'myWork' ? 'brand.500' : undefined}
                      minW="max-content"
                    >
                      My Work
                    </MenuItemLink>
                  ) : (
                    <MenuItemLink href={inboxHref} color={activeTab === 'inbox' ? 'brand.500' : undefined}>
                      <InboxItem />
                    </MenuItemLink>
                  )}
                </MenuList>
              </Menu>
            </ListItem>
          )}

          {/* Desktop menu */}
          {isDataSetsV2Enabled ? (
            <>
              {isInboxRevampEnabled ? (
                <ListItem minW="max-content" display={desktopFlex}>
                  <TopbarItemLink href={myWorkHref} isActive={activeTab === 'myWork'}>
                    <HStack>
                      <Icon icon="square-check" size="4" variant="far" />
                      <Text>My Work</Text>
                    </HStack>
                  </TopbarItemLink>
                </ListItem>
              ) : (
                <ListItem display={desktopFlex}>
                  <TopbarItemLink href={inboxHref} isActive={activeTab === 'inbox'}>
                    <InboxItem />
                  </TopbarItemLink>
                </ListItem>
              )}

              {userIsGuest && !guestCanRunChecklist ? null : (
                <ListItem display={desktopFlex}>
                  <TopbarItemLink href={libraryHref} isActive={activeTab === 'library'}>
                    <HStack>
                      <Icon icon="book-open" size="4" variant="far" />
                      <Text>Library</Text>
                    </HStack>
                  </TopbarItemLink>
                </ListItem>
              )}

              <ListItem display={desktopFlex}>
                <TopbarItemLink href={reportsHref} isActive={activeTab === 'reports'}>
                  <HStack>
                    <Icon icon="chart-line" size="4" variant="far" />
                    <Text>Reports</Text>
                  </HStack>
                </TopbarItemLink>
              </ListItem>

              {isDataSetsV2Enabled && userCanViewDataSets && (
                <ListItem display={desktopFlex}>
                  <TopbarItemLink href={dataSetsHref} isActive={activeTab === 'dataSets'}>
                    <HStack>
                      <Icon icon="database" size="4" variant="far" />
                      <Text>Data&nbsp;Sets</Text>
                    </HStack>
                  </TopbarItemLink>
                </ListItem>
              )}
            </>
          ) : (
            <>
              {userIsGuest && !guestCanRunChecklist ? null : (
                <ListItem display={desktopFlex}>
                  <TopbarItemLink href={libraryHref} isActive={activeTab === 'library'}>
                    <HStack>
                      <Text>Library</Text>
                    </HStack>
                  </TopbarItemLink>
                </ListItem>
              )}

              <ListItem display={desktopFlex}>
                <TopbarItemLink href={reportsHref} isActive={activeTab === 'reports'}>
                  <HStack>
                    <Text>Reports</Text>
                  </HStack>
                </TopbarItemLink>
              </ListItem>

              {isInboxRevampEnabled ? (
                <ListItem display={desktopFlex}>
                  <TopbarItemLink href={myWorkHref} isActive={activeTab === 'myWork'}>
                    <HStack>
                      <Text>My Work</Text>
                    </HStack>
                  </TopbarItemLink>
                </ListItem>
              ) : (
                <ListItem display={desktopFlex}>
                  <TopbarItemLink href={inboxHref} isActive={activeTab === 'inbox'}>
                    <InboxItem />
                  </TopbarItemLink>
                </ListItem>
              )}
            </>
          )}
        </List>

        <List mb={0}>
          {!userIsGuest && (
            <ListItem display={desktopFlex} alignItems="center" gridGap={5}>
              <UpgradeButton />
            </ListItem>
          )}
        </List>
        <HStack as={List} pr="3" spacing={{ base: 2, md: 4 }} mb={0}>
          {globalSearchEnabled && (!userIsGuest || guestCanRunChecklist) && (
            <ListItem>
              <Box display={['inline-flex', null, 'none']}>
                <IconButton
                  aria-label="global search"
                  variant="ghost"
                  colorScheme="brand"
                  icon={<Icon icon="search" variant="far" size="4" />}
                  onClick={e => {
                    e.preventDefault();
                    handleGlobalSearchClick();
                  }}
                />
              </Box>

              <Button
                aria-label="global search"
                w="45"
                display={['none', null, 'inline-flex']}
                justifyContent="flex-start"
                fontSize="sm"
                fontWeight="normal"
                _hover={{ cursor: 'pointer' }}
                leftIcon={<Icon size="3.5" icon="search" variant="far" color="gray.500" mr="2" />}
                color="gray.400"
                colorScheme="gray"
                variant="outline"
                bgColor="white"
                onClick={e => {
                  e.preventDefault();
                  handleGlobalSearchClick();
                }}
              >
                {['Search', metaSymbol.length > 0 ? ` or ${metaSymbol}+K` : ''].join('')}
              </Button>
            </ListItem>
          )}
          <ListItem>
            {match([userIsAnonymous, userIsGuest, guestCanRunChecklist])
              .with([false, false, P.any], () => <UniversalNewMenu shouldCollapseOnMobile />)
              .with([P.any, true, true], () => <BootstrapRunButton source={Source.MAIN_NAV_BAR} />)
              .otherwise(() => null)}
          </ListItem>

          {isInboxRevampEnabled && !goNativeApp && (
            <ListItem>
              <CommentsButton shouldShowUnreadCommentsBadge={shouldShowUnreadCommentsBadge} />
            </ListItem>
          )}

          <ListItem>
            <AccountMenu />
          </ListItem>
        </HStack>
        <OnboardingSlideshow />
      </TopbarContainer>
    ))
    .otherwise(() => (
      <TopbarContainer>
        {selectedOrganization && (
          <List display="flex" mb={0} px="3" justifyContent="space-between" alignItems="center" width="100%">
            <ListItem>
              <OrganizationLogo organization={selectedOrganization}>
                <Image src={require('app/images/ps-icon-blue.svg')} height="8" alt="Process Street icon" />
              </OrganizationLogo>
            </ListItem>
            <ListItem>
              <Flex alignItems="center" maxW="100" py="0" px="3" color="gray.500">
                <Icon size="4" icon="users" variant="far" mr="2" />
                <Text noOfLines={1}>{selectedOrganization?.name}</Text>
              </Flex>
            </ListItem>
          </List>
        )}
      </TopbarContainer>
    ));
};

function TopbarItemLink({
  children,
  isActive,
  ...rest
}: {
  children: React.ReactNode;
  isActive?: boolean;
} & LinkProps) {
  return (
    <Link
      variant="noline"
      minWidth="max-content"
      _hover={{
        color: 'brand.500',
      }}
      _focus={{
        color: 'brand.500',
      }}
      color={isActive ? 'brand.500' : 'gray.500'}
      {...rest}
    >
      {children}
    </Link>
  );
}
