import * as React from 'react';
import { ButtonProps, Skeleton } from 'components/design/next';
import { TemplateType } from '@process-street/subgrade/process';
import { useGetConsolidatedTemplatePermissionsQuery } from 'features/permissions/query-builder';
import { useTemplateMenuContext, useTemplateMenuDisclosureContext } from '../template-menu';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import { match } from 'ts-pattern';
import { useGetTemplateQuery } from 'features/template/query-builder';

export interface DiscardTemplateButtonProps {
  children: React.ReactElement<ButtonProps>;
}

export const DiscardTemplateButton: React.FC<React.PropsWithChildren<DiscardTemplateButtonProps>> = React.memo(
  ({ children }) => {
    const { templateId } = useTemplateMenuContext();
    const { discardTemplateAlertDisclosure: disclosure } = useTemplateMenuDisclosureContext();

    const templateQuery = useGetTemplateQuery({ templateId });
    const template = templateQuery.data;

    const templateRevisionQuery = useNewestTemplateRevisionQuery(
      {
        templateId,
        editable: true,
      },
      { enabled: !!templateId },
    );

    if (!React.isValidElement(children) || Array.isArray(children)) {
      throw new Error('DiscardTemplateButton child must be a single clickable element');
    }

    // UI
    const btn = React.cloneElement(children, {
      onClick: () => {
        disclosure.onToggle();
      },
    });

    const TemplateDiscard = template?.templateType === TemplateType.Page ? Page : Workflow;

    return match(templateRevisionQuery)
      .with({ status: 'loading' }, () => <Skeleton display="inline-block">{btn}</Skeleton>)
      .otherwise(() => (
        <TemplateDiscard>
          <>{btn}</>
        </TemplateDiscard>
      ));
  },
);

const Workflow: React.FC<React.PropsWithChildren> = React.memo(({ children }) => {
  const { templateId } = useTemplateMenuContext();
  const permissionQuery = useGetConsolidatedTemplatePermissionsQuery(templateId, { enabled: !!templateId });
  const canDiscardTemplate = permissionQuery.data?.permissionMap?.templateUpdate;
  return canDiscardTemplate ? children : null;
});

const Page: React.FC<React.PropsWithChildren> = React.memo(({ children }) => {
  const { templateId } = useTemplateMenuContext();

  const permissionQuery = useGetConsolidatedTemplatePermissionsQuery(templateId, { enabled: !!templateId });
  const canDiscardTemplate = permissionQuery.data?.permissionMap?.pageUpdate;
  return canDiscardTemplate ? children : null;
});
