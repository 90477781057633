import { User } from '@process-street/subgrade/core';
import { useInjector } from 'app/components/injection-provider';
import { useCurrentUser } from 'app/hooks/use-current-user';
import { useFeatureFlag } from 'features/feature-flags';
import { useCallback, useEffect } from 'react';
import { TasksTableUtils } from '../components/tasks-table/tasks-table-utils';

const VERSION = 3;
export const MY_WORK_SETTINGS_KEY = `myWork.filters.${VERSION}`;

export type MyWorkPersistedSettings = Record<User['id'], string>;

export type UseGridSettingsPersistenceParams = {
  isLoaded: boolean;
  filters: TasksTableUtils.TasksTableFilters;
};

export const myWorkParamsToPersist = new Set([
  'userIds',
  'itemsType',
  'templateIds',
  'sortBy',
  'sortAsc',
  'snoozeStatus',
  'groupBy',
  'includeCompleted',
]);

export const buildURL = (filters: TasksTableUtils.TasksTableFilters): URL => {
  const url = new URL(window.location.href);

  Object.entries(filters).forEach(([key, value]) => {
    const valueString = value?.toString();

    if (!valueString) {
      url.searchParams.delete(key);
    }
    // Avoiding saving empty values and keys that should not be persisted.
    if (!valueString || !myWorkParamsToPersist.has(key)) return;

    const valueInt = parseInt(valueString, 10);
    if (key === 'groupBy' && !isNaN(valueInt)) {
      url.searchParams.set(key, Object.values(TasksTableUtils.TasksTableGroupBy)[valueInt]);
    } else {
      url.searchParams.set(key, valueString);
    }
  });

  return url;
};

export const useGridSettingsPersistence = ({ isLoaded, filters }: UseGridSettingsPersistenceParams) => {
  const { $location, $rootScope } = useInjector('$location', '$rootScope');
  const isMyWorkGAEnabled = useFeatureFlag('myWorkGA');
  const currentUser = useCurrentUser();

  const updateFiltersSearchParams = useCallback(() => {
    if (!currentUser) return;
    const url = buildURL(filters);

    $rootScope.$applyAsync(() => {
      $location.search(url.searchParams.toString());
    });

    const updated: MyWorkPersistedSettings = {
      ...JSON.parse(localStorage.getItem(MY_WORK_SETTINGS_KEY) ?? '{}'),
      [currentUser.id]: url.searchParams.toString(),
    };
    localStorage.setItem(MY_WORK_SETTINGS_KEY, JSON.stringify(updated));
  }, [$location, $rootScope, currentUser, filters]);

  useEffect(() => {
    if (isLoaded && isMyWorkGAEnabled) {
      updateFiltersSearchParams();
    }
  }, [isLoaded, filters, updateFiltersSearchParams, isMyWorkGAEnabled]);
};
