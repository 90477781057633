import * as React from 'react';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT } from 'app/components/focus-bar/workflow/focus-bar-hstack';
import { TOP_BAR_HEIGHT_CSS_VAR } from 'app/pages/forms/_id/shared';
import { TaskListDrawer } from '../task-list-drawer';
import { TaskListDrawerButton } from '../task-list-drawer-button';
import { TaskList } from '../task-list/task-list';
import { useSelector } from '@xstate/react';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { DeleteTaskTemplateAlertDialog } from 'app/pages/forms/_id/edit/components/form-editor-bottom-bar/delete-task-template-alert-dialog';
import { Template } from '@process-street/subgrade/process';
import { useIsAnonymousUser } from 'app/hooks/use-is-anonymous-user';
import _isEqual from 'lodash/isEqual';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';

export type TaskListSectionProps = {
  template: Template;
};

export const TaskListSection = React.memo(({ template }: TaskListSectionProps) => {
  const editorActor = useFormEditorPageActorRef();
  const isLoading = useSelector(editorActor, FormEditorPageActorSelectors.isLoading);
  const isReadOnly = useSelector(editorActor, FormEditorPageActorSelectors.isReadOnly);
  const isAnonymousUser = useIsAnonymousUser();

  const promptingDeleteTaskTemplateActor = useSelector(
    editorActor,
    FormEditorPageActorSelectors.getPromptingDeleteTaskTemplateActor,
    _isEqual,
  );

  const drawerContentProps = isAnonymousUser
    ? {
        h: `calc(100vh - var(--ps-sizes-${WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT}))`,
        top: `var(--ps-sizes-${WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT}) !important`,
      }
    : {
        h: `calc(100vh - ${TOP_BAR_HEIGHT_CSS_VAR} + var(--ps-sizes-${WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT}))`,
        top: `calc(${TOP_BAR_HEIGHT_CSS_VAR} + var(--ps-sizes-${WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT})) !important`,
      };

  return (
    <>
      <TaskListDrawerButton />

      {!isLoading && (
        <TaskListDrawer
          key={template.id}
          contentProps={{
            bottom: 0,
            bgColor: 'rgba(255, 255, 255, 0.84)',
            borderRightWidth: 'thin',
            borderRightColor: 'gray.100',
            borderRightStyle: 'solid',
            boxShadow: '0px 1px 2px 0px #0000000D',
            ...drawerContentProps,
          }}
          bodyProps={{ pt: 0 }}
          body={
            <ThemeProvider2024>
              <TaskList />
            </ThemeProvider2024>
          }
        />
      )}

      {!isReadOnly && promptingDeleteTaskTemplateActor && template ? (
        <DeleteTaskTemplateAlertDialog
          templateType={template.templateType}
          onCancel={() => promptingDeleteTaskTemplateActor?.send('CANCEL_DELETE_TASK_TEMPLATE')}
          onConfirm={() => promptingDeleteTaskTemplateActor?.send('CONFIRM_DELETE_TASK_TEMPLATE')}
        />
      ) : null}
    </>
  );
});

TaskListSection.displayName = 'TaskListSection';
