import * as React from 'react';
import { Icon, Text, VStack, Box } from 'components/design/next';

export const AssigneeEmptyState: React.FC<React.PropsWithChildren<unknown>> = () => (
  <VStack maxW="lg" mx="auto" spacing={6} alignItems="center" textAlign="center" pt="4" pb="10" px="7">
    <Icon
      variant="fad"
      icon="workflow"
      primaryLayer={{ color: 'indigo.500' }}
      secondaryLayer={{ color: 'indigo.500', opacity: 0.4 }}
      size="16"
      mb="4"
    />
    <Text variant="3" fontWeight="bold">
      You’ve been listed as an assignee on a workflow.
    </Text>
    <Text as="span" color="gray.600" variant="-2">
      No action is needed at this time, but we’ll let you know when you have an assignment to complete.
    </Text>

    <VStack mt={6} spacing={3}>
      <Text as="span" color="gray.600" variant="-2">
        Install our Slack app to get instant notifications!
      </Text>

      <Box>
        <a href="https://www.process.st/slack/authorize">
          <img
            alt="Add to Slack"
            height="40"
            width="139"
            src="https://platform.slack-edge.com/img/add_to_slack.png"
            srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
          />
        </a>
      </Box>
    </VStack>

    <VStack spacing={4}>
      <Text fontSize="md" fontWeight="500">
        Learn how to complete your assigned tasks using Inbox
      </Text>
      <Box>
        <iframe
          src="https://www.youtube.com/embed/ztILRJeWhbY"
          title="Learn the Process Street Inbox"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Box>
    </VStack>
  </VStack>
);
