import React, { useEffect, useState } from 'react';

import { Avatar as AvatarModel, User } from '@process-street/subgrade/core';
import { AvatarSize } from './AvatarSize';
import { getAvatarAttributes, getBadgeValue } from './avatar-helper';
import { useUserDeactivationHelpers } from 'features/user/use-user-deactivation-helpers';
import { DeactivatedAvatar } from 'components/common/Avatar/DeactivatedAvatar';
import { useAvatarClass } from 'components/common/Avatar/use-avatar-class';
import { AvatarBadge, Box, BoxProps, Image, Avatar as ChakraAvatar, Icon } from 'components/design/next';
import { useLeakyLocation } from '@process-street/adapters/navigation';
import { useFeatureFlag } from 'app/features/feature-flags';
import { match } from 'ts-pattern';

/** @deprecated */
export interface AvatarProps extends BoxProps {
  initials?: string;
  initialsProps?: BoxProps;
  size?: AvatarSize;
  url?: string;
  user?: User;
  userId?: User['id'];
  badge?: number;
  showUnconfirmedBadge?: boolean;
}

/**
 * @deprecated use (and extend) ChakraAvatar instead
 */
export const Avatar: React.FunctionComponent<React.PropsWithChildren<AvatarProps>> = ({
  url,
  initials,
  initialsProps,
  size = AvatarSize.Normal,
  user,
  userId: optionalUserId,
  badge,
  showUnconfirmedBadge = false,
  ...rest
}) => {
  const [avatar, setAvatar] = useState<AvatarModel>(getAvatarAttributes(url, initials, user));
  const deactivationHelpers = useUserDeactivationHelpers();
  const userId = user?.id ?? optionalUserId;
  const isMyWorkGAEnabled = useFeatureFlag('myWorkGA');

  const location = useLeakyLocation();
  const isFromMyWork = location.pathname === 'myWork' && isMyWorkGAEnabled;

  const { unconfirmedClass, initialsClass, imgClass, badgeClass, avatarClass } = useAvatarClass(size);

  const withMembershipStatus = (node: React.ReactElement) => {
    const isAvatarInactive = deactivationHelpers.isFeatureEnabled && userId && !deactivationHelpers.isActive(userId);

    if (isAvatarInactive) {
      return (
        <DeactivatedAvatar
          size={isFromMyWork ? AvatarSize.Small : size}
          title={avatar.title}
          key={`d-${user?.id}`}
          {...rest}
        />
      );
    }

    return node;
  };

  useEffect(() => {
    setAvatar(getAvatarAttributes(url, initials, user));
  }, [url, initials, user]);

  // In MyWork we want to use Chakra Avatars.
  // Changing this method for the whole app is a big change, so limiting to My Work under feature flag.
  if (isFromMyWork) {
    const isAvatarInactive = deactivationHelpers.isFeatureEnabled && userId && !deactivationHelpers.isActive(userId);
    if (isAvatarInactive) {
      <Box
        role="figure"
        borderStyle="dashed"
        borderColor="gray.300"
        borderWidth="thin"
        borderRadius="full"
        padding={match(size)
          .with(AvatarSize.ExtraSmall, () => 0.5)
          .with(AvatarSize.Small, () => 1)
          .with(AvatarSize.Normal, () => 2)
          .with(AvatarSize.Medium, () => 2.5)
          .with(AvatarSize.Large, () => 3)
          .otherwise(() => 1)}
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor="white"
        aria-label="Deactivated user avatar"
        flexShrink={0}
      >
        <Icon icon="user" color="gray.300" size="3" />
      </Box>;
    } else {
      return (
        <ChakraAvatar size="xs" bg="gray.300" src={avatar.url} name={user?.username}>
          {!!badge && (
            <AvatarBadge fontSize="8px" h="1em" minW="1em" px="0.5" bg="gray.400" border="none">
              {getBadgeValue(badge)}
            </AvatarBadge>
          )}
        </ChakraAvatar>
      );
    }
  }

  return withMembershipStatus(
    // flex display seems to fix occasional vertical misalignment of profile pictures
    <Box className={`avatar ${avatarClass}`} role="figure" key={user?.id} display="flex" {...rest}>
      {avatar && avatar.url && <Image title={avatar.title} src={avatar.url} className={imgClass} alt={avatar.title} />}
      {avatar && !avatar.url && avatar.initials && (
        <Box as="span" title={avatar.title} className={`${initialsClass}`} {...initialsProps}>
          {avatar.initials}
        </Box>
      )}
      {showUnconfirmedBadge && avatar.unconfirmed && !badge && (
        <span
          title="This user may not receive some emails because their email address is unconfirmed."
          className={unconfirmedClass}
        >
          <i className="fas fa-exclamation-circle" />
        </span>
      )}

      {!!badge && <span className={`badge ${badgeClass}`}>{getBadgeValue(badge)}</span>}
    </Box>,
  );
};
