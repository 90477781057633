import * as React from 'react';
import { BoxProps, VStack } from '@chakra-ui/react';

export type TaskListContainerProps = React.PropsWithChildren<BoxProps>;

export const TaskListContainer = (props: TaskListContainerProps) => {
  return (
    <VStack w="full" alignItems="flex-start" px={6} position="relative" spacing={3} overflow="auto" pt={3} {...props} />
  );
};
