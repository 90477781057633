import * as React from 'react';
import { useInjector } from 'components/injection-provider';
import { useCurrentOrPrivateRootFolderQuery } from 'features/new-menu/query';
import { useGetConsolidatedFolderPermissionsQuery } from 'features/permissions/query-builder';
import { ButtonProps, useDisclosure } from 'components/design/next';
import { CreateTemplateSource } from 'services/template-service.interface';
import { Folder } from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';
import { noop } from '@process-street/subgrade/util/noop';
import { useFeatureFlag } from '../feature-flags';

export type Props = {
  source: CreateTemplateSource;
  allowSelectFolder: boolean;
  isAiGenerated?: boolean;
};

export const useNewBlankWorkflowButton = ({
  source,
  allowSelectFolder = false,
  isAiGenerated = false,
}: Props): {
  buttonProps: Partial<ButtonProps>;
  folderPickerProps: {
    isOpen: boolean;
    onClose: () => void;
    onPick?: (folder: Folder) => void;
    initialFolderId: string;
    title: string;
  };
  buttonAction: () => void;
} => {
  const isReactWorkflowEditorEnabled = useFeatureFlag('reactWorkflowEditor');
  const { PremadeTemplateService } = useInjector('$state', 'PremadeTemplateService');
  const disclosure = useDisclosure();

  const { data: currentFolder, ...foldersQuery } = useCurrentOrPrivateRootFolderQuery();

  const { data: { permissionMap } = {}, ...permissionsQuery } = useGetConsolidatedFolderPermissionsQuery(
    currentFolder?.id ?? '',
    {
      enabled: !!currentFolder,
    },
  );

  const isDisabled = !permissionMap?.templateCreate;

  const onClick = React.useCallback(() => {
    match({ allowSelectFolder, currentFolder, isDisabled })
      .with({ allowSelectFolder: true, currentFolder: P.not(undefined), isDisabled: true }, () => {
        disclosure.onToggle();
      })
      .with({ currentFolder: P.not(undefined), isDisabled: false }, ({ currentFolder }) => {
        PremadeTemplateService.createBlankWorkflowInFolderAndRedirect({
          folderId: currentFolder.id,
          source,
          isAiGenerated,
          isReactWorkflowEditorEnabled,
        });
      })
      .otherwise(noop);
  }, [
    allowSelectFolder,
    currentFolder,
    isDisabled,
    disclosure,
    PremadeTemplateService,
    source,
    isAiGenerated,
    isReactWorkflowEditorEnabled,
  ]);

  const onPick = React.useCallback(
    (folder: Folder) => {
      PremadeTemplateService.createBlankWorkflowInFolderAndRedirect({ folderId: folder.id, source, isAiGenerated });
    },
    [PremadeTemplateService, isAiGenerated, source],
  );

  return {
    buttonProps: {
      isLoading: foldersQuery.isLoading || permissionsQuery.isLoading,
      isDisabled,
      onClick,
    },
    folderPickerProps: {
      isOpen: disclosure.isOpen,
      onClose: disclosure.onClose,
      onPick,
      initialFolderId: currentFolder?.id ?? '',
      title: 'Create Workflow in...',
    },
    buttonAction: onClick,
  };
};
