import { Template } from '@process-street/subgrade/process';
import * as React from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import { CoverImageByTemplateIdQuery } from 'app/features/cover-image/query-builder';
import { ChecklistCoverImage } from 'app/features/cover-image/components/checklist';

export const CoverImage = ({ templateId }: { templateId: Template['id'] }) => {
  const { data: coverImage } = CoverImageByTemplateIdQuery.useQuery({ templateId });
  const isDesktop = useBreakpointValue({ base: false, md: true }, { ssr: false });

  return coverImage ? (
    <ChecklistCoverImage
      coverImage={coverImage}
      mx={-6}
      mt={-3}
      mb="0"
      // disable LQIP on mobile, it looks bad with drawer open/close
      cdnImageProps={{ withLqip: isDesktop }}
    />
  ) : null;
};
