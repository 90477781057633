import * as React from 'react';
import {
  Text,
  Modal,
  ModalHeader,
  ModalFooter,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalProps,
  VStack,
  Stack,
  Spacer,
  IconButton,
  Icon,
  Textarea,
  Center,
  Box,
  useBreakpointValue,
  Tabs,
  TabPanel,
  Tab,
  TabPanels,
  TabList,
  ModalCloseButton,
} from 'components/design/next';
import { AiTaskTemplate, NativeAutomation } from '@process-street/subgrade/process';
import {
  ProcessPeteTextarea,
  ProcessPeteTextareaHeader,
  ProcessPeteTextareaHeaderIcon,
  ProcessPeteTextareaHeaderTitle,
} from '../common';
import { useMachine } from '@xstate/react';
import { makeTestAiTaskModalMachine } from './test-ai-task-modal-machine';
import { MergeTagsMenu } from 'features/merge-tags/components/merge-tags-menu';
import { MergeTagsConstants } from '@process-street/subgrade/form';
import { Muid } from '@process-street/subgrade/core';
import { useMergeTaggableInput } from 'hooks/use-merge-taggable-input';
import { PeteAtDesk } from './pete-at-desk';
import { useQueryClient } from 'react-query';
import { MaskedInput, MaskedInputParsers } from 'features/widgets/components/masked-input';

export interface TestAiTaskModalProps extends Omit<ModalProps, 'children'> {
  aiTaskTemplate: AiTaskTemplate;
  templateRevisionId?: Muid;
}

const maskedInputParser = MaskedInputParsers.makeParser('urls', 'merge-tags');

export const TestAiTaskModal: React.FC<React.PropsWithChildren<TestAiTaskModalProps>> = ({
  aiTaskTemplate,
  templateRevisionId,
  ...props
}) => {
  const { taskTemplate, nativeAutomation } = aiTaskTemplate;
  const queryClient = useQueryClient();
  const [state, send] = useMachine(() => makeTestAiTaskModalMachine({ aiTaskTemplate, queryClient }), {
    actions: {
      closeModal: () => props.onClose?.(),
    },
  });
  const promptConfig = nativeAutomation.actions.find(NativeAutomation.isExecuteAiPromptAction)?.config;
  if (!promptConfig) {
    throw new Error('ExecuteAiPromptAction not found');
  }
  const formatPrompt = 'formatPrompt' in promptConfig ? promptConfig.formatPrompt : undefined;

  // Listen for updates from the page form
  React.useEffect(() => {
    send({ type: 'NATIVE_AUTOMATION_ACTIONS_UPDATE', actions: nativeAutomation.actions });
  }, [nativeAutomation.actions, send]);

  const { inputs, systemPromptInputs, formatPromptInputs } = state.context;

  const { ref: promptRef, insertMergeTag: insertVariable } = useMergeTaggableInput<HTMLTextAreaElement>({
    get: () => state.context.prompt,
    set: value => send({ type: 'PROMPT_CHANGE', value }),
  });

  const { ref: systemPromptRef, insertMergeTag: insertVariableInSystemPrompt } =
    useMergeTaggableInput<HTMLTextAreaElement>({
      get: () => state.context.systemPrompt,
      set: value => send({ type: 'SYSTEM_PROMPT_CHANGE', value }),
    });

  const isDraftCreationDisabled =
    state.matches('gatheringInputs') || !(state.context.prompt || state.context.systemPrompt);

  const modalSize = useBreakpointValue({ base: 'xl', lg: '6xl' }) ?? 'xl';

  const promptHtml = React.useMemo(() => maskedInputParser(state.context.prompt), [state.context.prompt]);
  const systemPromptHtml = React.useMemo(
    () => maskedInputParser(state.context.systemPrompt),
    [state.context.systemPrompt],
  );

  const isSaveDisabled =
    state.context.initialPrompt === state.context.prompt &&
    state.context.systemPrompt === state.context.initialSystemPrompt;

  return (
    <Modal
      {...props}
      size={modalSize}
      onClose={(...args) => {
        props.onClose?.(...args);
        send({ type: 'MODAL_CLOSED' });
      }}
    >
      <ModalOverlay />
      {/* 3.75rem is the chakra modal margin */}
      <ModalContent maxH="calc(100vh - (2 * 3.75rem))">
        <ModalHeader>
          Test{' '}
          <Text as="span" variant="1" fontSize="18px" color="gray.500" fontWeight="normal">
            {taskTemplate?.name ?? 'AI Task'}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          as={Stack}
          direction={{ base: 'column', lg: 'row' }}
          justifyContent={{ base: 'flex-start', lg: 'space-between' }}
          alignItems="stretch"
          maxH={{ base: 'none', lg: '4xl' }}
          pt="4"
          px={{ base: '4', lg: '8' }}
          overflowY={{ base: 'auto', lg: 'hidden' }}
        >
          <Tabs w={{ base: 'full', lg: 'lg' }} display="flex" flexDirection="column">
            <TabList mb="6">
              <Tab>Instructions</Tab>
              <Tab>System</Tab>
            </TabList>

            <TabPanels flex="1" overflowY={{ base: 'visible', lg: 'auto' }} pr="4">
              <TabPanel px={0}>
                <VStack w="full" spacing="5">
                  <FormControl>
                    <ProcessPeteTextareaHeader>
                      <ProcessPeteTextareaHeaderIcon />
                      <ProcessPeteTextareaHeaderTitle>Instructions</ProcessPeteTextareaHeaderTitle>
                      <Spacer />
                      <MergeTagsMenu
                        {...{
                          templateRevisionId,
                          onSelect: (key, _fieldId, fallback) => insertVariable(key, fallback),
                          mergeTagTarget: MergeTagsConstants.Target.AI_TASK_PROMPT,
                        }}
                      />
                    </ProcessPeteTextareaHeader>

                    <MaskedInput html={promptHtml}>
                      <ProcessPeteTextarea
                        ref={promptRef}
                        value={state.context.prompt}
                        onChange={e => send({ type: 'PROMPT_CHANGE', value: e.target.value })}
                      />
                    </MaskedInput>
                  </FormControl>

                  {Object.entries(inputs).map(([input, value]) => (
                    <FormControl key={input}>
                      <FormLabel>{input.replace(/^form\./, '')}</FormLabel>
                      <Textarea
                        minH="10"
                        value={value}
                        onChange={e => send({ type: 'INPUT_CHANGE', key: input, value: e.target.value })}
                        placeholder="Type placeholder for your variable"
                      />
                    </FormControl>
                  ))}
                </VStack>
              </TabPanel>

              <TabPanel px={0}>
                <VStack w="full" spacing="5">
                  <FormControl>
                    <ProcessPeteTextareaHeader>
                      <ProcessPeteTextareaHeaderIcon />
                      <ProcessPeteTextareaHeaderTitle>System</ProcessPeteTextareaHeaderTitle>
                      <Spacer />
                      <MergeTagsMenu
                        {...{
                          templateRevisionId,
                          onSelect: (key, _fieldId, fallback) => insertVariableInSystemPrompt(key, fallback),
                          mergeTagTarget: MergeTagsConstants.Target.AI_TASK_PROMPT,
                        }}
                      />
                    </ProcessPeteTextareaHeader>

                    <MaskedInput html={systemPromptHtml}>
                      <ProcessPeteTextarea
                        ref={systemPromptRef}
                        placeholder="System prompt"
                        value={state.context.systemPrompt}
                        onChange={e => send({ type: 'SYSTEM_PROMPT_CHANGE', value: e.target.value })}
                      />
                    </MaskedInput>
                  </FormControl>

                  {Object.entries(systemPromptInputs).map(([input, value]) => (
                    <FormControl key={input}>
                      <FormLabel>{input.replace(/^form\./, '')}</FormLabel>
                      <Textarea
                        rows={1}
                        minH="10"
                        value={value}
                        onChange={e => send({ type: 'SYSTEM_PROMPT_INPUT_CHANGE', key: input, value: e.target.value })}
                        placeholder="Type placeholder for your variable"
                      />
                    </FormControl>
                  ))}

                  {formatPrompt ? (
                    <>
                      <FormControl>
                        <FormLabel>Format response</FormLabel>
                        <Textarea isReadOnly value={formatPrompt} bg="gray.50" />
                      </FormControl>

                      {Object.entries(formatPromptInputs).map(([input, value]) => (
                        <FormControl key={input}>
                          <FormLabel>{input.replace(/^form\./, '')}</FormLabel>
                          <Textarea
                            rows={1}
                            minH="10"
                            value={value}
                            onChange={e =>
                              send({ type: 'FORMAT_PROMPT_INPUT_CHANGE', key: input, value: e.target.value })
                            }
                            placeholder="Type placeholder for your variable"
                          />
                        </FormControl>
                      ))}
                    </>
                  ) : null}
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>

          <VStack
            alignItems="flex-start"
            spacing="6"
            overflowY={{ base: 'visible', lg: 'auto' }}
            w={{ base: 'full', lg: 'lg' }}
          >
            <FormControl display="flex" flexDirection="column" h="full">
              <ProcessPeteTextareaHeader status={state.matches('draftError') ? 'error' : 'info'}>
                <ProcessPeteTextareaHeaderTitle>
                  {state.matches('draftError') ? 'An error occurred generating the AI response.' : 'AI Sandbox'}
                </ProcessPeteTextareaHeaderTitle>

                <Spacer />

                <IconButton
                  variant="ghost"
                  aria-label="Refresh AI Draft"
                  isDisabled={isDraftCreationDisabled}
                  isLoading={state.matches('creatingDraft')}
                  colorScheme="gray"
                  onClick={() => send({ type: 'CREATE_DRAFT' })}
                  icon={<Icon icon="arrows-rotate" variant="far" size="4" />}
                />
              </ProcessPeteTextareaHeader>

              {state.context.response ? (
                <ProcessPeteTextarea
                  flex="1"
                  isReadOnly
                  aria-label="ai draft"
                  bg={state.matches('creatingDraft') ? 'gray.200' : 'gray.50'}
                  value={state.context.response}
                  minH="342px"
                />
              ) : (
                <Center
                  w="full"
                  minH="342px"
                  flex="1"
                  bg="white"
                  border="1px solid"
                  borderColor="gray.300"
                  borderTop="0"
                  borderRadius="base"
                  borderTopRadius="0"
                  p="2"
                >
                  <VStack spacing="5">
                    <Box opacity={isDraftCreationDisabled ? '0.4' : '1'}>{PeteAtDesk}</Box>
                    {!state.matches('draftError') && (
                      <Text variant="-1" color="gray.400" textAlign="center">
                        Fill in the prompt and variable fields to generate a test answer. You can do this multiple times
                        to tune your instructions.
                      </Text>
                    )}
                    <Button
                      size="lg"
                      isDisabled={isDraftCreationDisabled}
                      isLoading={state.matches('creatingDraft')}
                      variant="secondary"
                      onClick={() => send({ type: 'CREATE_DRAFT' })}
                    >
                      {state.matches('draftError') ? 'Retry' : 'Generate'}
                    </Button>
                  </VStack>
                </Center>
              )}
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button variant="ghost" onClick={props.onClose} colorScheme="gray">
              Cancel
            </Button>
            <Button
              onClick={() => send({ type: 'SAVE_PROMPT' })}
              isDisabled={isSaveDisabled}
              isLoading={state.matches('savingPrompt')}
            >
              Save
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
