import React from 'react';

export const PeteAtDesk = (
  <svg width="182" height="122" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity=".5"
      d="M0 119.486c0-.65 40.7424-1.178 91-1.178 50.258 0 91 .526 91 1.178 0 .651-20.895 2.066-71.153 2.066C60.5898 121.552 0 120.135 0 119.486Z"
      fill="#C2CDD6"
    />
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M171.981 74.0447H45.633v-2.4305h126.348c.422 0 .764.3419.764.764v.9025c0 .4222-.342.764-.764.764Z"
        fill="#CFE4F6"
      />
      <path
        d="M25.8574 72.3782v.9024c0 .4222.3419.764.764.764h19.0099v-2.4305H26.6214c-.4221 0-.764.3419-.764.7641Zm18.7686 1.6665h-1.793V118.84h1.793V74.0447Zm-14.7764 0h-1.793V118.84h1.793V74.0447Zm138.5624 0h-1.793V118.84h1.793V74.0447Zm-14.777 0h-1.793V118.84h1.793V74.0447Z"
        fill="#A0CAED"
      />
      <path
        d="M45.5234 74.0447h-1.7929V118.84h1.7929V74.0447Zm-14.7783 0h-1.793V118.84h1.793V74.0447Zm138.5649 0h-1.793V118.84h1.793V74.0447Zm-14.779 0h-1.793V118.84h1.793V74.0447Z"
        fill="#CFE4F6"
      />
    </g>
    <path
      d="M61.4785 71.5252v.0872h13.4361s-.2119-1.07-.7777-2.1417c-.5657-1.0699-1.4853-2.1382-2.8988-2.1382h-5.4558c-.082 0-.1641.0034-.2427.0086-2.2237.1213-4.0252 1.9314-4.0594 4.1841h-.0017Z"
      fill="#5F7482"
    />
    <path
      d="M65.9669 67.3325h-.1863c-.082 0-.1641.0034-.2427.0086-2.2237.1213-4.0252 1.9314-4.0594 4.1841v.0872h8.1666s-.2119-1.07-.7777-2.1417c-.5657-1.0699-1.4853-2.1382-2.8988-2.1382h-.0017Zm3.3923-.0052-.6512.0051c1.1161 0 2.0511.7572 2.7792 2.0938l.1948.0051c-.6136-1.1605-1.4084-1.8767-2.3228-2.104Z"
      fill="#434F5C"
    />
    <path
      d="M67.293 67.3206v.012h1.8049s-.0291-.1436-.1043-.2872c-.0752-.1435-.1999-.2871-.3897-.2871h-.7657c-.2991.0171-.5401.2598-.5452.5623Z"
      fill="#5F7482"
    />
    <path
      d="M67.8963 66.7583h-.0581c-.2991.0171-.5401.2598-.5452.5623v.012h1.0973s-.0291-.1436-.1043-.2872c-.0752-.1435-.2-.2871-.3897-.2871Z"
      fill="#434F5C"
    />
    <path
      d="M67.9959 66.8899h.8904c-.0324-.0341-.0683-.0632-.1093-.0854h-.7829v.0854h.0018Zm.133.1726h.8751s-.0051-.012-.0085-.0188c-.012-.0223-.0257-.0462-.041-.0684h-.8256v.0872Zm.1318.0873v.0854h.8119c-.0085-.0256-.0188-.0547-.0307-.0854h-.7812ZM143.5 113.404l2.61-.025.014 1.458c.128.376.203.803.209 1.259.005.457-.064.886-.185 1.263l.014 1.458-2.61.026c-.947.008-1.727-1.202-1.74-2.702-.016-1.503.74-2.728 1.687-2.737h.001Z"
      fill="#434F5C"
    />
    <path
      d="M146.138 118.839c.947 0 1.715-1.217 1.715-2.719 0-1.502-.768-2.719-1.715-2.719-.947 0-1.714 1.217-1.714 2.719 0 1.502.767 2.719 1.714 2.719Z"
      fill="#5F7482"
    />
    <path
      d="M145.16 116.12c0 .857.438 1.552.978 1.552s.977-.694.977-1.552c0-.858-.437-1.552-.977-1.552s-.978.694-.978 1.552Zm-8.505-2.716-2.61-.025-.014 1.458c-.128.376-.203.803-.208 1.259-.006.457.063.886.184 1.263l-.014 1.458 2.61.026c.947.008 1.727-1.202 1.74-2.702.016-1.503-.74-2.728-1.687-2.737h-.001Z"
      fill="#434F5C"
    />
    <path
      d="M135.731 116.12c0 1.503-.767 2.719-1.714 2.719s-1.714-1.216-1.714-2.719c0-1.502.767-2.719 1.714-2.719s1.714 1.217 1.714 2.719Z"
      fill="#5F7482"
    />
    <path
      d="M134.994 116.12c0 .857-.437 1.552-.977 1.552s-.978-.694-.978-1.552c0-.856.438-1.552.978-1.552s.977.694.977 1.552Zm-11.526-2.716 2.61-.025.014 1.458c.128.376.203.803.208 1.259.006.457-.063.886-.184 1.263l.013 1.458-2.609.026c-.947.008-1.727-1.202-1.74-2.702-.016-1.503.74-2.728 1.687-2.737h.001Z"
      fill="#434F5C"
    />
    <path
      d="M126.104 118.839c.947 0 1.714-1.217 1.714-2.719 0-1.502-.767-2.719-1.714-2.719s-1.714 1.217-1.714 2.719c0 1.502.767 2.719 1.714 2.719Z"
      fill="#5F7482"
    />
    <path
      d="M125.128 116.12c0 .857.437 1.552.978 1.552.54 0 .977-.694.977-1.552 0-.856-.437-1.552-.977-1.552-.541 0-.978.694-.978 1.552Zm-8.506-2.716-2.609-.025-.014 1.458c-.128.376-.204.803-.209 1.259-.005.457.064.886.185 1.263l-.014 1.458 2.61.026c.947.008 1.726-1.202 1.74-2.702.016-1.503-.74-2.728-1.687-2.737h-.002Z"
      fill="#434F5C"
    />
    <path
      d="M115.698 116.12c0 1.503-.767 2.719-1.714 2.719s-1.714-1.216-1.714-2.719c0-1.502.767-2.719 1.714-2.719s1.714 1.217 1.714 2.719Z"
      fill="#5F7482"
    />
    <path
      d="M114.963 116.12c0 .857-.437 1.552-.978 1.552-.54 0-.977-.694-.977-1.552 0-.858.437-1.552.977-1.552.541 0 .978.694.978 1.552Zm13.308-3.861h4.215v-5.416h-4.215v5.416Z"
      fill="#434F5C"
    />
    <path
      d="M133.209 112.259h-5.662v-2.068c0-.757.615-1.373 1.372-1.373h2.919c.758 0 1.373.616 1.373 1.373v2.068h-.002Z"
      fill="#5F7482"
    />
    <path
      d="M112.037 114.541c12.228-1.549 24.454-1.549 36.68 0 0-1.262-1.023-2.284-2.284-2.284-10.705-1.608-21.41-1.608-32.114 0-1.262 0-2.284 1.022-2.284 2.284h.002Z"
      fill="#434F5C"
    />
    <path
      d="M137.178 107.302h-12.476c-1.769 0-3.204-1.435-3.204-3.204v-.074h18.883v.074c0 1.769-1.436 3.204-3.205 3.204h.002Z"
      fill="#5F7482"
    />
    <path
      d="M121.315 104.026h19.253c2.367 0 4.286-1.919 4.286-4.2867h-27.826c0 2.3677 1.92 4.2867 4.287 4.2867Z"
      fill="#434F5C"
    />
    <path
      d="M116.991 99.7339h21.753c6.254 0 11.729-4.6131 13.356-11.2551l5.413-22.7735c.513-2.0887-1.307-4.0594-3.752-4.0594h-26.127c-1.22 0-2.269.7384-2.534 1.7776-.004.0119-.007.029-.011.041l-8.096 36.2694h-.002Z"
      fill="#5F7482"
    />
    <path
      d="M128.594 92.8441h15.269c.784-1.3178 1.393-2.7826 1.781-4.3654l5.78-25.1031c.245-.9931 1.457-1.7297 2.617-1.7297 2.531 0 4.376 2.6441 3.716 5.3361l-5.097 21.4967c-.388 1.5828-1.827 5.8267-2.617 7.1428-1.425 2.3998-3.17 3.3979-5.288 3.8133-.562.188-1.222.2957-1.996.2957h-12.018c-2.767 0-5.01-1.9622-5.01-4.3824 0-1.3828 1.282-2.504 2.863-2.504Z"
      fill="#434F5C"
    />
    <path
      d="M100.767 92.8441h15.269c.784-1.3178 1.393-2.7826 1.781-4.3654l5.78-25.1031c.245-.9931 1.456-1.7297 2.617-1.7297 2.531 0 4.376 2.6441 3.716 5.3361l-5.097 21.4967c-.388 1.5828-1.827 5.8267-2.617 7.1428-1.425 2.3998-3.17 3.3979-5.288 3.8133-.563.188-1.222.2957-1.997.2957h-12.017c-2.767 0-5.0097-1.9622-5.0097-4.3824 0-1.3828 1.2819-2.504 2.8627-2.504Z"
      fill="#5F7482"
    />
    <path
      d="m78.6459 71.9936-14.2001-1.6374c-1.446-.1675-2.5108-1.4323-2.4254-2.8852.0838-1.4528 1.2888-2.5877 2.7433-2.586l14.2941.0188c-.7144.9862-1.1691 2.1793-1.2443 3.4851-.0752 1.3058.2376 2.5433.8324 3.6064v-.0017Zm7.2467-9.1203 8.1529-5.0131 9.0165 5.1567-14.4501 6.2095c.0205-.1179.0445-.2358.0581-.3555.2684-2.4407-.8751-4.7106-2.7774-5.9976Z"
      fill="#434F5C"
    />
    <path
      d="M88.3781 64.1346c1.193 1.9622 1.2887 4.5021.0256 6.6095-.0632.1043-.1333.2-.2.2991-1.8886 2.7877-5.6438 3.6526-8.5682 1.9007-1.9434-1.164-3.0407-3.198-3.1005-5.3071-.0342-1.1777.2512-2.3775.9007-3.4629.6495-1.0853 1.5742-1.9023 2.6271-2.4288 1.8869-.9417 4.1978-.9349 6.1429.2291.9212.552 1.6511 1.3024 2.1741 2.1604h-.0017Zm-35.057 9.2487c.0906-1.6272 1.4118-2.8083 3.039-2.7177 2.0032.1111 3.5944-1.4323 3.7004-3.309.1111-2.0032-1.4323-3.5945-3.309-3.7005-1.6272-.0905-2.8082-1.4118-2.7177-3.0389.0906-1.6272 1.4118-2.8083 3.039-2.7177 5.1311.2855 9.1494 4.779 8.864 9.91-.2855 5.1311-4.779 9.1494-9.91 8.864-1.4887-.3333-2.7946-1.6631-2.704-3.2885l-.0017-.0017Z"
      fill="#EDEFF2"
    />
    <path
      d="M101.715 70.6895c4.954 0 8.97-4.016 8.97-8.9699 0-4.954-4.016-8.97-8.97-8.97-4.9539 0-8.9699 4.016-8.9699 8.97 0 4.9539 4.016 8.9699 8.9699 8.9699Z"
      fill="#C2CDD6"
    />
    <path d="M135.821 65.7054h-27.012v16.5007h27.012V65.7054Z" fill="#1E2B36" />
    <path
      d="M109.695 81.3122h22.579c1.374 0 2.5-.4376 2.5.9367v8.0948c0 1.3742-1.125 2.5005-2.5 2.5005h-22.579c-1.374 0-2.501-1.1246-2.501-2.5005v-8.0948c0-1.3743 1.125-.9367 2.501-.9367Z"
      fill="#EDEFF2"
    />
    <path
      d="M136.98 77.0613h-31.991c-1.381 0-2.501 1.1191-2.501 2.4997 0 1.3806 1.12 2.4997 2.501 2.4997h31.991c1.381 0 2.501-1.1191 2.501-2.4997 0-1.3806-1.12-2.4997-2.501-2.4997Zm-31.918 14.5113-4.332-.041-1.4439 16.8594 7.7209-4.469-1.945-12.3494Z"
      fill="#434F5C"
    />
    <path
      d="m104.811 86.7577 2.027-.2051c2.635-.2667 4.926 1.8032 4.926 4.4525v1.8579h-7.116c-1.323-.2837-2.263-1.9742-2.247-3.3261.015-1.3913 1.035-2.5656 2.41-2.7792Z"
      fill="#434F5C"
    />
    <path
      d="M91.4662 118.776c-2.5485-5.594-.2257-12.388 5.3669-15.217 5.5929-2.828 12.3849-.786 15.4379 4.694l-20.8048 10.523Z"
      fill="#EDEFF2"
    />
    <path
      d="M107.284 103.496c-.045.022-.087.041-.132.062-4.08 2.064-6.416 6.238-6.394 10.516l11.513-5.825c-1.191-2.138-2.952-3.75-4.987-4.755v.002Z"
      fill="#C2CDD6"
    />
    <path d="m117.447 91.5726-4.331-.041-1.444 16.8594 7.72-4.469-1.945-12.3494Z" fill="#434F5C" />
    <path
      d="M103.852 118.776c-2.549-5.594-.226-12.388 5.367-15.217 5.592-2.828 12.385-.786 15.437 4.694l-20.804 10.523Z"
      fill="#EDEFF2"
    />
    <path
      d="m117.197 86.7577 2.027-.2051c2.636-.2667 4.926 1.8032 4.926 4.4525v1.8579h-7.115c-1.323-.2837-2.263-1.9742-2.248-3.3261.016-1.3913 1.036-2.5656 2.41-2.7792Z"
      fill="#434F5C"
    />
    <path
      d="M115.862 93.5946c2.147 0 3.887-1.7402 3.887-3.8868 0-2.1465-1.74-3.8867-3.887-3.8867-2.146 0-3.886 1.7402-3.886 3.8867 0 2.1466 1.74 3.8868 3.886 3.8868Zm-12.386 0c2.146 0 3.886-1.7402 3.886-3.8868 0-2.1465-1.74-3.8867-3.886-3.8867-2.147 0-3.8871 1.7402-3.8871 3.8867 0 2.1466 1.7401 3.8868 3.8871 3.8868Z"
      fill="#EDEFF2"
    />
    <path d="m128.542 8.91857-2.3-.20608-1.201 13.40461 2.3.2061 1.201-13.40463Z" fill="#434F5C" />
    <path
      d="M129.66 23.8811c.178-1.9819-1.285-3.7326-3.267-3.9101-1.982-.1776-3.732 1.2851-3.91 3.267l-.063.7065c-.178 1.982 1.285 3.7326 3.267 3.9102 1.982.1776 3.733-1.2851 3.91-3.2671l.063-.7065Z"
      fill="#EDEFF2"
    />
    <path
      d="M127.746 9.78c2.7 0 4.89-2.18935 4.89-4.89004 0-2.7007-2.19-4.89005155-4.89-4.89005155-2.701 0-4.891 2.18935155-4.891 4.89005155 0 2.70069 2.19 4.89004 4.891 4.89004Z"
      fill="#fff"
    />
    <path
      d="M126.194 22.2128c-15.232-1.3639-28.6991 9.8895-30.063 25.122l-1.6785 18.7397c-.3025 3.3706 2.1998 6.3634 5.5705 6.666l42.949 3.8457c3.37.3025 6.363-2.1998 6.666-5.5703l1.678-18.7398c1.364-15.2325-9.889-28.6993-25.122-30.0633Z"
      fill="#EDEFF2"
    />
    <path
      d="m125.166 62.7808-21.722-1.9463c-.651-.0583-1.226.4218-1.284 1.0725-.058.6506.422 1.2253 1.073 1.2836l21.722 1.9463c.651.0583 1.225-.4219 1.284-1.0725.058-.6506-.422-1.2253-1.073-1.2836Z"
      fill="#B8B8B8"
    />
    <path
      d="m127.425 56.7902-25.264-2.2613c-4.2202-.3777-7.2114-4.1021-6.8456-8.1905.3777-4.22 4.1021-7.2112 8.1906-6.8454l25.397 2.2733c4.22.3777 7.211 4.1021 6.845 8.1905-.365 4.0884-4.102 7.2111-8.322 6.8334h-.001Z"
      fill="#434F5C"
    />
    <path
      d="m128.647 45.4744-25.553-2.2896c-2.113-.1892-3.9789 1.3699-4.1682 3.4826-.1893 2.1126 1.3702 3.9787 3.4822 4.168l25.553 2.2895c2.113.1893 3.979-1.3699 4.168-3.4825.19-2.1127-1.37-3.9787-3.482-4.168Z"
      fill="#fff"
    />
    <path
      d="m139.081 77.3076-1.447-9.4605 8.255-6.3018.103 15.7264c-.101-.065-.2-.1316-.306-.1914-2.145-1.1982-4.68-1.0256-6.605.2273Z"
      fill="#434F5C"
    />
    <path
      d="m140.239 83.7803-13.744 1.4323c-1.399.1453-2.669-.8255-2.895-2.2151-.226-1.3896.673-2.7125 2.048-3.0151l13.492-2.9808c-.466 1.0802-.646 2.3006-.444 3.55.201 1.2477.757 2.3502 1.541 3.2287h.002Z"
      fill="#434F5C"
    />
    <path
      d="M147.82 77.0118c1.367 1.8459 1.694 4.3653.629 6.5787-.053.1094-.115.212-.173.3163-1.627 2.9483-5.288 4.1516-8.359 2.6732-2.043-.9828-3.32-2.9074-3.573-5.0029-.14-1.1691.033-2.3895.581-3.5295.549-1.1401 1.393-2.0374 2.395-2.6578 1.793-1.1093 4.095-1.3144 6.138-.3316.969.4666 1.764 1.1451 2.362 1.9536Zm-31.577 14.2924c-.425-1.5725.459-3.1108 2.031-3.5364 1.937-.523 2.962-2.4886 2.472-4.3038-.523-1.9365-2.489-2.962-4.304-2.4715-1.573.4256-3.111-.458-3.537-2.0305-.425-1.5725.458-3.1108 2.031-3.5364 4.962-1.3417 10.189 1.6614 11.529 6.6215 1.341 4.9618-1.662 10.1886-6.622 11.5286-1.518.1504-3.176-.699-3.601-2.2732l.001.0017Z"
      fill="#EDEFF2"
    />
    <path
      d="M142.821 70.6895c4.954 0 8.97-4.016 8.97-8.9699 0-4.954-4.016-8.97-8.97-8.97-4.953 0-8.969 4.016-8.969 8.97 0 4.9539 4.016 8.9699 8.969 8.9699Z"
      fill="#C2CDD6"
    />
  </svg>
);
