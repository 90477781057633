import angular from 'angular';
import { CommentsModule } from 'components/comments/comments.module';
import { CsvExportModalModule } from 'components/csv-export-modal/csv-export-modal.module';
import { DesignModule } from 'components/design/design.module';
import { TrialExpiredPaywallModule } from 'components/paywalls/trial-expired/trial-expired-paywall.module';
import { RunChecklistModule } from 'components/run-checklist/run-checklist.module';
import { RunFromCsvModule } from 'components/run-from-csv/run-from-csv.module';
import { MembersOveragesAlertModule } from 'features/organization/manage/users/overages-alert/index.module';
import { ApprovalRulesModule } from './approval-rules/approval-rules.module';
import { ApprovalsModule } from './approvals/approvals.module';
import { AvatarModule } from './avatars/avatar.module';
import { BillingModule } from './billing/billing.module';
import { ChecklistTaskAssignmentModule } from './checklist-task-assignment/checklist-task-assignment.module';
import { CheckoutBusinessPlanModule } from './checkout-business-plan/checkout-business-plan.module';
import { CommonModule } from './common/common.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DynamicDueDatesModule } from './dynamic-due-dates/dynamic-due-dates.module';
import { FileUploadModule } from './file-upload/file-upload.module';
import { FilterTasksForAssigneeModule } from './filter-tasks-for-assignee/filter-tasks-for-assignee.module';
import { FormFieldValueModule } from './form-field-value/form-field-value.module';
import { InboxFilterBarModule } from './inbox/bar/filter/filter.module';
import { LoadAndGoModule } from './load-and-go/load-and-go.module';
import { MigrationModule } from './migration/migration.module';
import { MoveToFolderModule } from './move-to-folder/move-template.module';
import { NewOrganizationButtonModule } from 'features/create-organization/components/new-organization-button/index.module';
import { NotificationsModule } from './notifications/notifications.module';
import { OrderTreeModule } from './order-tree/order-tree.module';
import { OrganizationLogoModule } from './organization-logo/index.module';
import { OrganizationManageModule } from './organization-manage/organization-manage.module';
import { PersistorModule } from './persistor/persistor.module';
import { PopBoxModule } from './pop-box/pop-box.module';
import { RoleAssignmentsModule } from './role-assignments/role-assignments.module';
import { RoutingModule } from './routing/routing.module';
import { SlackModule } from './slack/slack.module';
import { SsoModule } from './sso/sso.module';
import { SwitchOrganizationModule } from './switch-organization/switch-organization.module';
import { TaskPermissionModule } from './task-permission/task-permission.module';
import { TaskStatsModule } from './task-stats/task-stats.module';
import { TemplateGalleryModule } from './template-gallery/template-gallery.module';
import { GlobalSearchModule } from 'features/global-search/global-search.module';
import { TemplateTaskAssignmentModule } from './template-task-assignment/template-task-assignment.module';
import { TemplateMembershipsModule } from './template/membership/memberships.module';
import { TopbarContentModule } from 'directives/ui/topbar/components/topbar.module';
import { InboxPaywallModule } from 'components/paywalls/inbox/index.module';
import { InboxAssigneeEmptyStateModule } from 'features/inbox/components/assignee-empty-state/index.module';
import { CopyRunLinkModule } from 'features/checklist/components/copy-run-link/index.module';
import { FocusBarModule } from 'components/focus-bar/focus-bar.module';

// this needs to happen _after_ the main `TemplateModule` import
import { ProfileModule } from './user/profile/profile.module';
import { UserModule } from './user/user.module';
import { UtilsModule } from './utils/utils.module';
import { WidgetsModule } from './widgets/widgets.module';
import { PermissionsModule } from 'components/permissions/index.module';
import { ShareModalModule } from 'components/share-modal/index.module';
import { InvitationModule } from 'components/invitation/index.module';
import { CreatePlaybookCtaModule } from 'features/create-organization/components/create-workflow-cta/index.module';
import { GuestSignupModule } from 'pages/guest-signup/index.module';
import { ChecklistPaywallModule } from './paywalls/use-checklist/checklist-paywall.module';
import { InviteFullMemberPaywallModule } from './paywalls/invite-full-member/invite-full-member-paywall.module';
import { EditTemplateButtonModule } from 'components/edit-template-button/edit-template-button.module';
import { TemplateShareButtonModule } from 'app/directives/template-share/template-share-button/template-share-button.module';
import { PreassigningFreeMembersModule } from 'components/paywalls/preassigning-free-members/index.module';
import { RunWorkflowButtonModule } from './run-workflow-button/run-workflow-button.module';
import { PendingUserModule } from 'app/components/pending-user/pending-user.module';
import { PaywallsModule } from 'components/paywalls/paywalls.module';
import { ButtonWithUpgradeTooltipModule } from 'components/button-with-upgrade-tooltip/index.module';
import { EmptyFolderModule } from 'pages/library/components/template-library/empty-folder/empty-folder.module';
import { TemplateTitleModule } from './template-title/template-title.module';
import { TemplateHeaderTitleModule } from 'app/directives/template-header/title/tempate-header-title.module';
import { ChecklistHeaderActionsModule } from 'app/directives/checklist-left-pane/header/actions/checklist-header-actions.module';
import { InboxCoverImageModule } from 'features/cover-image/components/inbox/index.module';
import { MergeTagsMenuModule } from 'features/merge-tags/components/merge-tags-menu/merge-tags-menu.module';
import { ChecklistMediaModule, TemplateMediaModule } from 'features/template-media/index.module';
import { InboxItemHeaderModule } from 'pages/inbox/components/item-header/item.module';
import { TemplateWorkflowAutomationsModule } from 'features/automations/components/workflow-automations/components/template/template-workflow-automations.module';
import { ChecklistWorkflowAutomationsModule } from 'features/automations/components/workflow-automations/components/checklist/checklist-workflow-automations.module';
import { TaskAutomationIndicatorModule } from 'features/automations/components/task-automation-indicator/task-automation-indicator.module';
import { AutomationsModalWrapperModule } from 'pages/templates/_id/automation/automation-modal-wrapper.module';
import { InboxCommentsListModule } from 'features/comments/components/inbox/comments-list.module';
import { MicrosoftTeamsModule } from 'features/microsoft-teams/index.module';
import { LoginModule } from 'features/login/index.module';
import { TaskCommentsModule } from 'features/comments/components/task/task-comments.module';
import { ConditionalLogicV2Module } from 'features/conditional-logic/index.module';
import { ChecklistCommentsOverviewPopoverModule } from 'features/comments/components/checklist/comments-overview-popover/checklist-comments-overview-popover.module';
import { FutureChecklistNameInputModule } from 'features/future-checklist/components/future-checklist-name-input/future-checklist-name-input.module';
import { TemplateShareLink } from 'features/template-share-link-tab/index.module';
import { RunViaLinkModule } from 'features/run-via-link/index.module';
import { ColorInputModule } from 'features/brand-customization/components/color-input/color-input.module';
import { OrganizationSettingsModule } from 'features/organization/manage/settings/settings.module';
import { MinbarModule } from 'features/app/components/minbar/minbar.module';
import { TaskTemplateLoadingSpinnerModule } from 'directives/task-template-list/item/task-template-loading-spinner.module';
import { DueDatePickerModule } from 'features/dynamic-due-dates/components/due-date-picker/due-date-picker.module';
import { DueDateTaskIndicatorModule } from 'features/dynamic-due-dates/components/due-date-task-indicator/due-date-task-indicator.module';
import { AiTaskErrorIndicatorModule } from 'features/task/components/ai-task-error-indicator/ai-task-error-indicator.module';
import { PromptOverrideModule } from 'features/developer/components/prompt-override/prompt-override.module';
import { TaskTemplateContextMenuModule } from 'directives/task-template-list/item/task-template-context-menu.module';
import { TaskPermissionsPickerModule } from 'components/task-permission/components/task-permissions-picker/task-permissions-picker.module';
import { DeleteSubtaskButtonModule } from 'features/template/components/delete-subtask-button/delete-subtask-button.module';
import { OrganizationNotificationsModule } from 'features/organization/manage/notifications/notifications.module';
import { ChecklistNameInputModule } from 'directives/checklist-left-pane/header/components/checklist-name-input/index.module';
import { IntegrationsModule } from 'pages/organizations/manage/integrations/components/integrations/integrations.module';
import { OneOffTaskListModule } from 'features/one-off-tasks/components/shared/one-off-task-list/index.module';
import { PrintOneOffTasksModule } from 'features/one-off-tasks/components/print-one-off-tasks/print-one-off-tasks.module';
import { InboxItemDueDatePickerModule } from 'features/inbox/components/inbox-item-due-date-picker/inbox-item-due-date-picker.module';
import { ViewOneOffTaskModule } from 'features/one-off-tasks/components/shared/view-one-off-task/view-one-off-task.module';
import { PrintTemplateRulesModule } from 'pages/templates/_id/print-rules/components/print-template-rules/print-template-rules.module';
import { ExportConditionalLogicWrapperModule } from 'features/template/components/template-menu/export-conditional-logic-button/index.module';
import { SandboxFocusBarModule } from 'features/template/components/sandbox-focus-bar/sandbox-focus-bar.module';
import { WorkflowsEditorV2PageModule } from 'app/pages/workflows/_id/edit-v2/editor.module';
import { FirstWorkflowModalModule } from 'pages/sign-up/first-workflow-modal/first-workflow-modal.module';

export const ComponentsModule = angular.module('app.components', [
  InboxItemHeaderModule,
  AiTaskErrorIndicatorModule,
  ApprovalRulesModule,
  ApprovalsModule,
  AutomationsModalWrapperModule,
  AvatarModule,
  BillingModule,
  ChecklistHeaderActionsModule,
  ChecklistMediaModule,
  ChecklistTaskAssignmentModule,
  ChecklistPaywallModule,
  CheckoutBusinessPlanModule,
  CommentsModule,
  ChecklistCommentsOverviewPopoverModule,
  CommonModule,
  CreatePlaybookCtaModule,
  DesignModule,
  DashboardModule,
  DueDatePickerModule,
  DueDateTaskIndicatorModule,
  DynamicDueDatesModule,
  EditTemplateButtonModule,
  EmptyFolderModule,
  ExportConditionalLogicWrapperModule,
  FileUploadModule,
  FilterTasksForAssigneeModule,
  FirstWorkflowModalModule,
  FormFieldValueModule,
  FutureChecklistNameInputModule,
  GlobalSearchModule,
  GuestSignupModule,
  InboxFilterBarModule,
  InboxCommentsListModule,
  InboxCoverImageModule,
  IntegrationsModule,
  InvitationModule,
  InviteFullMemberPaywallModule,
  LoadAndGoModule,
  MergeTagsMenuModule,
  MigrationModule,
  MoveToFolderModule,
  MembersOveragesAlertModule,
  NewOrganizationButtonModule,
  NotificationsModule,
  OneOffTaskListModule,
  OrderTreeModule,
  OrganizationLogoModule,
  OrganizationManageModule,
  PaywallsModule,
  PendingUserModule,
  PermissionsModule,
  PersistorModule,
  PopBoxModule,
  PreassigningFreeMembersModule,
  PrintOneOffTasksModule,
  ProfileModule,
  PromptOverrideModule,
  RoleAssignmentsModule,
  RoutingModule,
  RunChecklistModule,
  RunWorkflowButtonModule,
  RunFromCsvModule,
  SandboxFocusBarModule,
  ShareModalModule,
  SlackModule,
  SsoModule,
  SwitchOrganizationModule,
  CsvExportModalModule,
  TaskAutomationIndicatorModule,
  TaskCommentsModule,
  TaskPermissionModule,
  TaskPermissionsPickerModule,
  TaskStatsModule,
  TemplateHeaderTitleModule,
  TemplateMediaModule,
  TemplateGalleryModule,
  TemplateMembershipsModule,
  TemplateShareButtonModule,
  TemplateTaskAssignmentModule,
  TemplateTitleModule,
  TopbarContentModule,
  TrialExpiredPaywallModule,
  UserModule,
  UtilsModule,
  WidgetsModule,
  InboxPaywallModule,
  ButtonWithUpgradeTooltipModule,
  InboxAssigneeEmptyStateModule,
  CopyRunLinkModule,
  FocusBarModule,
  TemplateWorkflowAutomationsModule,
  ChecklistWorkflowAutomationsModule,
  MicrosoftTeamsModule,
  LoginModule,
  ConditionalLogicV2Module,
  TemplateShareLink,
  RunViaLinkModule,
  ColorInputModule,
  OrganizationSettingsModule,
  MinbarModule,
  TaskTemplateLoadingSpinnerModule,
  TaskTemplateContextMenuModule,
  DeleteSubtaskButtonModule,
  OrganizationNotificationsModule,
  ChecklistNameInputModule,
  InboxItemDueDatePickerModule,
  ViewOneOffTaskModule,
  PrintTemplateRulesModule,
  WorkflowsEditorV2PageModule,
]).name;
