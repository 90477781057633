import { Ref, User } from '@process-street/subgrade/core';
import { FormFieldValue } from '@process-street/subgrade/process';
import { TaskAssignment } from '@process-street/subgrade/role-assignment';

export namespace AblyEvent {
  export enum EventType {
    FormFieldValueUpdated = 'form-field-value-updated',
    FormFieldValueDeleted = 'form-field-value-deleted',
    InboxTaskCompletable = 'inbox-task-completable',
    TaskTemplateUpdated = 'task-template-updated',
    AiGenerationError = 'ai-generation-error',
    ChecklistRevisionNativeAutomationEvent = 'checklist-revision-native-automation-event',
    TaskTemplatesUpdated = 'task-templates-updated',
    AiWorkflowImportSuccess = 'ai-workflow-import-success',
    WorkflowRunUpdated = 'workflow-run-updated',
  }

  export enum FormFieldValueUpdateSource {
    ChecklistCreation = 'ChecklistCreation',
    UserUpdate = 'UserUpdate',
    ChecklistMigration = 'ChecklistMigration',
    NativeAutomation = 'NativeAutomation',
  }

  interface AffectedChecklistTaskAssignments {
    created: TaskAssignment[];
    deleted: TaskAssignment[];
  }

  export interface FormFieldValueUpdatedAblyEvent {
    formFieldWidgetGroupId: string;
    updatedBy: Ref<User>;
    updatedFormFieldValue: FormFieldValue;
    checklistTaskAssignments: AffectedChecklistTaskAssignments;
    source?: FormFieldValueUpdateSource;
  }
}
