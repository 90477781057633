import angular from 'angular';
import auth0 from 'auth0-js';
import { Auth0Constants } from '@process-street/subgrade/auth/auth0-constants';
import { MSTeamsUtils } from 'features/microsoft-teams/utils';
import { getEnv } from 'components/common/env';
import { AuthEvent } from 'services/auth-event/AuthEvent';
import { trace } from 'components/trace';
import { LocalStorageService } from 'features/storage/local-storage-service';
import { SignUpStateService } from 'services/sign-up-state-service';
import { WatchdogService } from 'services/boot/watchdog-service';
import jwtDecode from 'jwt-decode';

angular
  .module('frontStreetApp.services')
  .service(
    'Auth0Service',
    function (
      $location,
      $q,
      $rootScope,
      $state,
      $timeout,
      $window,
      Authentication,
      auth,
      SessionService,
      ToastService,
    ) {
      const logger = trace({ name: 'Auth0Service' });

      const self = this;

      const webAuth = new auth0.WebAuth({
        clientID: getEnv().AUTH0_APP_ID,
        domain: getEnv().AUTH0_DOMAIN,
        responseType: 'token',
        audience: getEnv().AUTH0_AUDIENCE,
        redirectUri: getEnv().AUTH0_REDIRECT_URI,
        scope: 'openid profile email',
      });

      // Login redirect to Auth0
      self.login = function (email, password, onFailure) {
        webAuth.login(
          {
            realm: getEnv().AUTH0_DATABASE_CONNECTION_NAME,
            username: email,
            password,
          },
          (err, data) => {
            if (err) {
              const ssoCodes = [
                Auth0Constants.CustomErrorCode.LOCKED,
                Auth0Constants.CustomErrorCode.UNCONSTRAINED_LOCKED,
              ];
              if (ssoCodes.includes(err.error_description)) {
                self._redirectToLoginSso(email);
              } else {
                onFailure(self._errorToMessage(err));
              }
            } else if (data && data.error) {
              onFailure(data.error_description);
            }
          },
        );
      };

      self._errorToMessage = function (err) {
        let message;

        switch (err.code) {
          case Auth0Constants.StandardErrorCode.REQUEST_ERROR:
            message = "Oops! We couldn't contact the server. If the problem persists, please contact support.";
            break;
          default:
            message = err.error_description;
        }

        return message;
      };

      self.getIDPLoginUrl = params => {
        return webAuth.client.buildAuthorizeUrl({
          prompt: 'select_account',
          ...params,
        });
      };

      self.loginUsingGoogle = params => {
        webAuth.authorize({
          connection: 'google-oauth2',
          prompt: 'select_account',
          ...params,
        });
      };

      self.loginUsingIDP = connection => {
        webAuth.authorize({
          connection,
          prompt: 'select_account',
        });
      };

      self.loginUsingGooglePopup = () => self._loginUsingTeamsPopup('google-oauth2');

      self.loginUsingMicrosoftPopup = () => self._loginUsingTeamsPopup('microsoft-login');

      self.loginUsingIDPPopup = providerName => self._loginUsingTeamsPopup(providerName);

      self._loginUsingTeamsPopup = async provider => {
        const teamsSdk = await import('@microsoft/teams-js');

        return new Promise((resolve, reject) => {
          teamsSdk.initialize(() => {
            teamsSdk.authentication.authenticate({
              url: window.location.origin + `/microsoft-teams/redirect?provider=${provider}`,
              failureCallback: reject,
              successCallback: resolve,
              width: 1200,
              height: 900,
            });
          });
        });
      };

      self.loginUsingSSO = function (email) {
        return Authentication.getConnectionName({ email }).$promise.then(result =>
          webAuth.authorize({ connection: result.name }),
        );
      };

      self.loginUsingSSOPopup = function (email) {
        return Authentication.getConnectionName({ email }).$promise.then(result =>
          self._loginUsingTeamsPopup(result.name),
        );
      };

      self.loginUsingConnection = function (connectionName) {
        webAuth.authorize({ connection: connectionName });
      };

      // Parses Auth0 Login Response
      self.parseAuth0Hash = function () {
        return new Promise(resolve => {
          webAuth.parseHash((parseErr, authResult) => {
            self.handleAuth0Response(parseErr, authResult).then(result => resolve(result));
          });
        });
      };

      self.handleAuth0Response = function (parseErr, authResult) {
        return new Promise(resolve => {
          if (authResult && authResult.accessToken) {
            resolve(authResult.accessToken);
          } else {
            // eslint-disable-next-line no-lonely-if
            if (parseErr && parseErr.errorDescription === '`state` does not match.') {
              // In some cases, like redirecting from PUB or the 'First attempt to login with IE11' (PS-3867)
              // Auth0 might return 'state mismatch' error. In this case we should try to renew token.
              self._renewToken().then(authResult1 => {
                resolve(authResult1.accessToken);
              });
            } else {
              if (parseErr) {
                logger.error('Parse error', parseErr);
              }
              $timeout(() => {
                self._redirectByError(parseErr);
              });
              resolve();
            }
          }
        });
      };

      self._redirectByError = function (errorMap) {
        if (errorMap) {
          const { error, errorDescription } = errorMap;

          const token = self._extractAccessTokenFromUrlHash();
          self._broadcastError(error, errorDescription, token);

          logger.warn('auth0 _redirectByError error', errorMap);

          if (errorDescription === Auth0Constants.CustomErrorCode.LOCKED) {
            self._redirectToLoginSso();
          } else {
            self._redirectToLogin();
          }
        } else {
          self._redirectToLogin();
        }
      };

      /** get full reload to trigger feature flag check in load-route.ts */
      self._redirectToLogin = function () {
        $state.go('login');
      };

      self._redirectToLoginSso = function (email) {
        $state.go('loginSSO', { email });

        ToastService.openToast({
          status: 'error',
          title: `Your organization must sign-in with SSO`,
          description: 'Please, try again using the SSO sign-in page.',
        });
      };

      self.redirectToStoredUrl = function () {
        const url = LocalStorageService.getItem('url');
        const { templateId, pageId } = SignUpStateService.getTemplateIds();
        SignUpStateService.removeTemplateIds();
        const isEmbeddedInMsTeams = MSTeamsUtils.isEmbeddedInMsTeams();
        if (url) {
          LocalStorageService.removeItem('url');
          logger.info('redirecting to url "%s"', url);
          // https://stackoverflow.com/a/36130560
          $timeout(() => $location.url(url));
        } else if (templateId) {
          logger.info('redirecting to template import [%s]', templateId);
          $state.go('templateAdd', { templateId });
        } else if (pageId) {
          logger.info('redirecting to page import [%s]', pageId);
          $state.go('pageAdd', { pageId });
        } else {
          logger.info('redirecting to home');
          if (SessionService.getDefaultHomepage() === 'inbox') {
            isEmbeddedInMsTeams ? $state.go('microsoftTeamsInbox') : $state.go('inbox');
          } else if (isEmbeddedInMsTeams) {
            $state.go('microsoftTeamsInbox');
          } else {
            $state.go('reports');
          }
        }
      };

      // Token Renewal

      self.renewTokenIfNeeded = function () {
        logger.info('Checking token to see if token needs refreshing.');
        const token = SessionService.getToken();

        const immediateRenewal = !token || self._getTokenRenewalDelay(token) === 0;
        if (immediateRenewal) {
          logger.info('Token is stale, initiating token renewal.');
          return self._renewToken().then(renewedToken => {
            return renewedToken.accessToken;
          });
        } else {
          logger.info('Token is fresh.');
          self.scheduleTokenRenewal();
          return Promise.resolve(token);
        }
      };

      let tokenRenewalTimeout = null;

      self.scheduleTokenRenewal = () => {
        const accessToken = SessionService.getToken();
        if (accessToken) {
          const delay = self._getTokenRenewalDelay(accessToken);

          logger.info('scheduling token renewal after %s ms', delay);
          $timeout.cancel(tokenRenewalTimeout);
          tokenRenewalTimeout = $timeout(self.renewTokenLogoutOnFailure, delay);
        }
      };

      self.unscheduleTokenRenewal = () => {
        logger.info('unscheduling token renewal');
        $timeout.cancel(tokenRenewalTimeout);
        tokenRenewalTimeout = null;
      };

      const RENEWAL_BUFFER_IN_MS = 600000;
      const SHORT_RENEWAL_BUFFER_IN_MS = 10000;
      const MAX_RENEWAL_DELAY_IN_MS = 604800000;

      self._getTokenRenewalDelay = function (accessToken) {
        // Minimum 0 and maximum 7 days
        const issuedAt = auth.getIssuedAt(accessToken);
        const expirationTime = auth.getExpirationTime(accessToken);
        // If the total duration of the token is less than the buffer, then use the short buffer
        // This is useful for testing token expiration
        const buffer =
          expirationTime - issuedAt > RENEWAL_BUFFER_IN_MS ? RENEWAL_BUFFER_IN_MS : SHORT_RENEWAL_BUFFER_IN_MS;
        return Math.min(Math.max(expirationTime - auth.getOffsetDateNow() - buffer, 0), MAX_RENEWAL_DELAY_IN_MS);
      };

      /**
       * Attempts to the renew the token and logs out on failure.
       *
       * @returns {Promise}
       */
      self.renewTokenLogoutOnFailure = function () {
        return self._renewToken().catch(error => {
          logger.warn('token renewal error: ', error);

          LocalStorageService.setItem('url', $location.url());
          WatchdogService.clear();

          if (error === Auth0Constants.CustomErrorCode.FORCED_SSO_RELOGIN && SessionService.getUser()) {
            const userEmail = SessionService.getUser().email;
            self.loginUsingSSO(userEmail).catch(() => {
              logger.info('redirecting to logout');
              $state.go('logout');
            });
          } else {
            logger.info('redirecting to logout');
            $state.go('logout');
          }

          return $q.reject(error);
        });
      };

      let pendingRenewTokenRequest = null;

      /**
       * This is an internal method! Do not use outside of this service.
       *
       * @returns {Promise}
       */
      self._renewToken = function () {
        // Do not do any navigation logic here
        // Reason: the renewal at refresh should allow anonymous user through
        // with expired token even if renewal has failed
        // Routing validation will log out the user in case of a restricted route access.
        if (!pendingRenewTokenRequest) {
          pendingRenewTokenRequest = self
            ._getTokensByCheckingAuth0Session()
            .then(result => {
              logger.info('token renewal successful');

              auth.storeToken(result.accessToken);

              self.scheduleTokenRenewal();

              return result;
            })
            .finally(() => {
              pendingRenewTokenRequest = null;
            });
        }

        return pendingRenewTokenRequest;
      };

      self._getTokensByCheckingAuth0Session = function () {
        const deferred = $q.defer();

        webAuth.checkSession({}, (authErr, authResult) => {
          if (authErr) {
            const errorDescription = authErr.error_description;
            self._broadcastError(authErr.error, errorDescription, SessionService.getToken());

            deferred.reject(errorDescription);
          } else {
            deferred.resolve({
              accessToken: authResult.accessToken,
              expiresIn: authResult.expiresIn,
            });
          }
        });

        return deferred.promise;
      };

      /**
       * Extracts access token from url hash
       *
       * @returns {string|undefined}
       * @private
       */
      self._extractAccessTokenFromUrlHash = function () {
        const accessTokenParameter = $location
          .hash()
          .split('&')
          .find(parameter => parameter.indexOf('access_token') > -1);

        return accessTokenParameter ? accessTokenParameter.split('=')[1] : undefined;
      };

      self._broadcastError = function (errorCode, description, token) {
        const errorNeedsLogging =
          Auth0Constants.NoLoggingRequiredStandardErrorCodes.indexOf(errorCode) === -1 &&
          Auth0Constants.NoLoggingRequiredCustomErrorCodes.indexOf(description) === -1;

        if (errorNeedsLogging) {
          const decodedToken = token ? jwtDecode(token) : 'token was not provided';
          $rootScope.$broadcast(AuthEvent.AUTH0_RETURNED_ERROR, description, JSON.stringify(decodedToken));
        }
      };

      self.logout = function () {
        webAuth.logout({
          returnTo: getEnv().AUTH0_REDIRECT_URI,
        });
      };
    },
  );
