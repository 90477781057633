import * as React from 'react';
import { Button, Grid, GridItem, HStack, Icon, IconButton, MotionWrapper, Text } from 'components/design/next';
import {
  DueDateRuleDefinition,
  DueDateRuleSourceType,
  FormFieldWidget,
  TaskTemplate,
  TemplateRevision,
  TemplateTaskAssignment,
} from '@process-street/subgrade/process';
import { getNameForTask } from './utils';
import { TaskListItemDynamicDueDateIndicatorHelpers } from '../../task-list-item-dynamic-due-date-indicator/task-list-item-dynamic-due-date-indicator.helpers';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { useSelector } from '@xstate/react';
import { ApprovalTaskListAssignees } from './approval-task-list-assignees';
import { useQueryClient } from 'react-query';
import {
  DeleteApprovalRuleMutation,
  GetApprovalRulesByTaskTemplateIdQuery,
} from 'app/features/approval-rules/query-builder';
import { ApprovalRuleSubject } from '@process-street/subgrade/approval-rule';
import { AnimatePresence } from 'framer-motion';
import _keyBy from 'lodash/keyBy';
import { useTaskTemplateListActorRef } from '../../../hooks/use-task-templates-list-actor';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';

export type ApprovalTaskListItemProps = {
  approvalRuleId: ApprovalRuleSubject['id'];
  dueDateRuleDefinition: DueDateRuleDefinition;
  taskTemplate: TaskTemplate;
  taskTemplates: Array<TaskTemplate>;
  templateRevisionId: TemplateRevision['id'];
  taskAssignments: TemplateTaskAssignment[];
  isReadOnly?: boolean;
};

export const ApprovalTaskListItem: React.FC<ApprovalTaskListItemProps> = ({
  approvalRuleId,
  dueDateRuleDefinition,
  taskTemplate,
  taskTemplates,
  templateRevisionId,
  taskAssignments,
  isReadOnly,
}) => {
  const actor = useFormEditorPageActorRef();
  const taskTemplateListActor = useTaskTemplateListActorRef();
  const queryClient = useQueryClient();

  const deleteApprovalRuleMutation = DeleteApprovalRuleMutation.useMutation({
    onSuccess: () =>
      queryClient.invalidateQueries(GetApprovalRulesByTaskTemplateIdQuery.getKey({ templateRevisionId })),
  });

  const widgetList = useSelector(actor, FormEditorPageActorSelectors.getWidgets);
  const widgetsMap = React.useMemo(() => _keyBy(widgetList, w => w.header.group.id), [widgetList]);

  const dueDateRuleLabel = React.useMemo(() => {
    if (!dueDateRuleDefinition) return null;
    const widget =
      dueDateRuleDefinition.sourceType === DueDateRuleSourceType.FormFieldValue
        ? widgetsMap[dueDateRuleDefinition.formFieldWidgetGroup!.id]
        : undefined;
    const task = taskTemplates.find(t => t.group.id === dueDateRuleDefinition.taskTemplateGroup?.id);
    return TaskListItemDynamicDueDateIndicatorHelpers.getFullLabelByRule(
      dueDateRuleDefinition,
      task,
      widget as unknown as FormFieldWidget,
    );
  }, [dueDateRuleDefinition, taskTemplates, widgetsMap]);

  const taskName = getNameForTask(taskTemplate);
  const onDelete = () =>
    deleteApprovalRuleMutation.mutate({
      templateRevisionId,
      ids: [approvalRuleId],
    });

  const onNavigateToTaskTemplate = () => {
    taskTemplateListActor.send({
      type: 'SELECT_TASK_TEMPLATE',
      taskTemplate,
      metaKey: false,
      ctrlKey: false,
      shiftKey: false,
    });
  };

  return (
    <AnimatePresence>
      <MotionWrapper
        key={approvalRuleId}
        initial={{ y: -10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 10, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <HStack
          as="li"
          _hover={{
            '.chakra-button': {
              opacity: 1,
            },
          }}
          _focusWithin={{
            '.chakra-button': {
              opacity: 1,
            },
          }}
        >
          <Grid
            borderRadius="md"
            backgroundColor="brand.50"
            gridTemplateColumns="1fr auto"
            px={{ base: 4, md: 8 }}
            py={8}
            w="full"
            _hover={{ bgColor: 'brand.100' }}
          >
            <GridItem gridColumn={1}>
              <Button
                variant="unstyled"
                color="brand.500"
                fontWeight="bold"
                mb="4"
                fontSize="md"
                onClick={onNavigateToTaskTemplate}
              >
                <Text maxW={{ base: '171px', md: '490px' }} isTruncated>
                  {taskName}
                </Text>
              </Button>

              {dueDateRuleDefinition && (
                <HStack color="gray.500" gap={2} mt={2}>
                  <Icon icon="clock" size="3" />
                  <Text maxW={{ base: '148px', md: '490px' }} isTruncated>
                    {dueDateRuleLabel}
                  </Text>
                </HStack>
              )}
            </GridItem>
            {taskAssignments && taskAssignments.length > 0 && (
              <GridItem gridColumn={2} display="flex" justifyContent="flex-end" alignItems="center">
                <ApprovalTaskListAssignees taskAssignments={taskAssignments} />
              </GridItem>
            )}
          </Grid>
          {!isReadOnly && (
            <IconButton
              onClick={onDelete}
              aria-label={`Delete approval rule ${taskName}`}
              icon={<Icon icon="xmark" size="4" color="gray.500" />}
              size="sm"
              variant="ghost"
              colorScheme="gray"
              opacity={{ base: 1, lg: 0 }}
            />
          )}
        </HStack>
      </MotionWrapper>
    </AnimatePresence>
  );
};
