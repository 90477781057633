import * as React from 'react';
import { Task } from 'pages/responses/_id/components/task';
import { Box, Center, Divider, Text, VStack } from '@chakra-ui/react';
import { TaskFooter } from '../task-footer/task-footer';
import { TaskActor, TaskMachineSelectors } from 'app/pages/responses/_id/components/task/task-machine';
import { useSelector } from '@xstate/react';

type TaskSectionProps = {
  currentTaskActor: TaskActor;
};

export const TaskSection = ({ currentTaskActor }: TaskSectionProps) => {
  const task = useSelector(currentTaskActor, TaskMachineSelectors.getTask);

  return (
    <Center as="main" w="full" pb="8">
      <Box
        bgColor="white"
        borderColor="gray.200"
        borderStyle="solid"
        borderWidth="1px"
        borderRadius={{ base: '0', lg: 'lg' }}
        py={9}
        px={{ base: '8', lg: '9' }}
        w="full"
        maxW={{ base: 'full', lg: '788px' }}
      >
        <VStack spacing="6" w="full" h="auto" mt={6}>
          <VStack w="full" alignItems="flex-start" spacing={2}>
            <Text
              as="h2"
              fontWeight="medium"
              fontSize="xl"
              color="gray.700"
              minH="6"
              noOfLines={1}
              _hover={{
                cursor: 'text',
              }}
            >
              {task.taskTemplate.name}
            </Text>

            <Divider />
          </VStack>
        </VStack>

        <VStack spacing={5} pt={6}>
          <Task taskMachine={currentTaskActor} />
        </VStack>

        <TaskFooter />
      </Box>
    </Center>
  );
};
