import { Camelize } from '@process-street/subgrade/core';
import { ActorRefFrom, assign, createMachine, spawn, StateFrom, sendTo } from 'xstate';
import { makeDisclosureMachine, DisclosureActor, DisclosureEvent } from 'utils/machines';
import { SharedContext } from '../use-shared-context';
import { ReactElement } from 'react';
import { ModalProps } from 'components/design/next';
import { ModalOverlayProps } from '@chakra-ui/modal';

type DisclosureName =
  | 'INSERT_WIDGET'
  | 'TASK_LIST'
  | 'DESIGN'
  | 'CONDITIONAL_LOGIC'
  | 'SHARE_MENU'
  | 'NEW_FORM_NAME'
  | 'MOVE_TO_STEP'
  | 'SHARE_VIA_EMAIL';

type Context = Record<`${Camelize<DisclosureName>}Disclosure`, DisclosureActor> & {
  modal?: ReactElement;
  modalProps?: Partial<ModalProps>;
  overlayProps?: Partial<ModalOverlayProps>;
  isMobile: boolean;
};

export type ContextDisclosureName = Extract<keyof Context, `${string}${'Disclosure'}`> extends `${infer T}Disclosure`
  ? T
  : never;

export type UIEvent =
  | { type: `${DisclosureEvent['type']}_DISCLOSURE`; name: ContextDisclosureName }
  | { type: 'WIDGET_ADDED' }
  | { type: 'SET_IS_MOBILE'; isMobile: boolean }
  | ({ type: 'OPEN_MODAL'; modal: ReactElement } & Pick<Context, 'modalProps' | 'overlayProps'>)
  | { type: 'CLOSE_MODAL' };

export type UIMachineArgs = {
  sharedContext: SharedContext;
};
export const makeUIMachine = (_args: UIMachineArgs) => {
  return createMachine(
    {
      id: 'ui-machine',
      predictableActionArguments: true,
      schema: {
        context: {} as Context,
        events: {} as UIEvent,
      },
      tsTypes: {} as import('./ui-machine.typegen').Typegen0,
      context: () => ({
        insertWidgetDisclosure: spawn(makeDisclosureMachine(), { name: 'insert-widget-disclosure' }),
        taskListDisclosure: spawn(makeDisclosureMachine(), { name: 'task-list-disclosure' }),
        designDisclosure: spawn(makeDisclosureMachine(), { name: 'design-disclosure' }),
        conditionalLogicDisclosure: spawn(makeDisclosureMachine(), { name: 'conditional-logic-disclosure' }),
        shareMenuDisclosure: spawn(makeDisclosureMachine(), { name: 'share-menu-disclosure' }),
        newFormNameDisclosure: spawn(makeDisclosureMachine(), { name: 'new-form-name-disclosure' }),
        moveToStepDisclosure: spawn(makeDisclosureMachine(), { name: 'move-to-step-disclosure' }),
        shareViaEmailDisclosure: spawn(makeDisclosureMachine(), { name: 'share-via-email-disclosure' }),
        modal: undefined,
        modalProps: undefined,
        overlayProps: undefined,
        isMobile: false,
      }),
      on: {
        OPEN_MODAL: { actions: 'assignModal' },
        CLOSE_MODAL: { actions: 'closeModal' },
        WIDGET_ADDED: [{ cond: 'isMobile', actions: 'sendCloseToInsertWidget' }],
        SET_IS_MOBILE: {
          actions: ['setIsMobile', 'sendToInsertWidget', 'sendToTaskList'],
        },
        OPEN_DISCLOSURE: { actions: 'sendToDisclosure' },
        CLOSE_DISCLOSURE: { actions: 'sendToDisclosure' },
      },
    },
    {
      actions: {
        assignModal: assign({
          modal: (_, event) => event.modal,
          modalProps: (_, event) => event.modalProps,
          overlayProps: (_, event) => event.overlayProps,
        }),

        closeModal: assign({
          modal: (_, __) => undefined,
          modalProps: (_, __) => undefined,
          overlayProps: (_, __) => undefined,
        }),
        setIsMobile: assign({
          isMobile: (_, event) => event.isMobile,
        }),
        sendToInsertWidget: sendTo('insert-widget-disclosure', (_ctx, evt) => {
          return { type: evt.isMobile ? 'CLOSE' : 'OPEN' };
        }),
        sendToTaskList: sendTo('task-list-disclosure', (_ctx, evt) => {
          return { type: evt.isMobile ? 'CLOSE' : 'OPEN' };
        }),
        sendCloseToInsertWidget: sendTo('insert-widget-disclosure', { type: 'CLOSE' }),
        sendToDisclosure: sendTo(
          (ctx, evt) => ctx[`${evt.name}Disclosure`],
          (_ctx, evt) => ({
            type: evt.type === 'OPEN_DISCLOSURE' ? 'OPEN' : 'CLOSE',
          }),
        ),
      },
      guards: {
        isMobile: ctx => ctx.isMobile,
      },
    },
  );
};

export type UIMachine = ReturnType<typeof makeUIMachine>;
export type UIActorRef = ActorRefFrom<UIMachine>;

export type State = StateFrom<UIMachine>;

export const UIMachineSelectors = {
  getIsDisclosureOpen: (disclosureName: ContextDisclosureName) => (state: State) => {
    const actor = state.context[`${disclosureName}Disclosure`];
    const isOpen = actor.getSnapshot()?.matches('open') ?? false;
    return isOpen;
  },
  getModal: (state: State) => state.context.modal,
  getModalProps: (state: State) => state.context.modalProps,
  getOverlayProps: (state: State) => state.context.overlayProps,
};
