import { Muid, Option } from '@process-street/subgrade/core';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { env } from 'components/common/env';

type InterceptorOptions = { organizationId: Muid | null; token: string | null };

interface AxiosService {
  initializeAxios(getOptions: () => InterceptorOptions): void;
  getAxios(): AxiosInstance;
}

export enum AllowedCustomHeaders {
  SandboxUser = 'X-Process-Street-Sandbox-User-Id',
}
export class AxiosServiceImpl implements AxiosService {
  public static getInstance() {
    return AxiosServiceImpl.instance;
  }

  private static instance = new AxiosServiceImpl();

  private initialized = false;

  private axiosInstance: Option<AxiosInstance>;

  public initializeAxios(getOptions: () => InterceptorOptions) {
    const config: AxiosRequestConfig = {
      // This global.env started complaining, but can't figure out why and doing `declare global` result in a duplicate declaration error
      // @ts-expect-error -- TODO
      baseURL: env?.APP_API_URL || global.env.APP_API_URL,
      params: {
        // @ts-expect-error -- TODO
        v: env?.APP_API_VERSION || global.env.APP_API_VERSION,
      },
    };

    this.axiosInstance = axios.create(config);

    this.axiosInstance.interceptors.request.use(async requestConfig => {
      const { token, organizationId } = getOptions();

      if (token !== null) {
        requestConfig.headers.Authorization = `Bearer ${token}`;
      }

      if (organizationId !== null) {
        requestConfig.headers['X-Process-Street-Organization-Id'] = organizationId;
      }
      return requestConfig;
    }, Promise.reject);

    this.initialized = true;
  }

  public getAxios(): AxiosInstance {
    if (this.initialized && this.axiosInstance) {
      return this.axiosInstance;
    }
    throw new Error('Axios instance is not yet initialized');
  }

  public setCustomHeader(key: AllowedCustomHeaders, value: string): void {
    if (this.initialized && this.axiosInstance) {
      if (Object.values(AllowedCustomHeaders).includes(key)) {
        this.axiosInstance.defaults.headers.common[key] = value;
      } else {
        throw new Error('Invalid custom header key');
      }
    } else {
      throw new Error('Axios instance is not yet initialized');
    }
  }
}

export const axiosService = AxiosServiceImpl.getInstance();
