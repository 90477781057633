import * as React from 'react';
import { ChecklistTaskListComponents as Components } from './components';
import { FormResponsePageMachineHooks } from 'app/pages/responses/_id/form-response-page-hooks';
import { FormResponseMachineHooks } from 'app/pages/responses/_id/components/form-response-body/form-response-machine-hooks';
import { FormResponsePageProviders } from '../../providers';
import { Task, TaskUtils } from '@process-street/subgrade/process';
import { OneOffTaskList } from 'pages/checklists/_id/components/task-list/components/one-off-task-list';

export const TaskList = () => {
  const formResponseActor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
  const tasks = FormResponsePageMachineHooks.useTasks();
  const oneOffTasks = FormResponsePageMachineHooks.useOneOffTasks();
  const checklist = FormResponsePageMachineHooks.useChecklist();
  const currentTaskIndex = FormResponseMachineHooks.useCurrentTaskIndex();
  const shouldHideCompletedTasks = FormResponseMachineHooks.useShouldHideCompletedTasks();

  const taskNumberById = React.useMemo(() => {
    return Object.fromEntries(tasks?.map((task, index) => [task.id, index]) ?? []);
  }, [tasks]);

  const displayedTasks = React.useMemo(
    () => tasks?.filter(task => !(shouldHideCompletedTasks && TaskUtils.isCompleted(task))),
    [tasks, shouldHideCompletedTasks],
  );

  const handleSelectTask = React.useCallback(
    (task: Task) => {
      formResponseActor.send({ type: 'SELECT_TASK', taskId: task.id });
    },
    [formResponseActor],
  );

  return (
    <Components.Container>
      {checklist && <Components.TopControls checklist={checklist} />}

      <Components.TasksWrapper>
        {displayedTasks?.map((task, index) => {
          // grab original task number, before hiding completed tasks
          const taskNumber = taskNumberById[task.id];
          return (
            <Components.Item
              key={task.id}
              task={task}
              stepNumber={taskNumber + 1}
              isFirst={index === 0}
              isLast={displayedTasks.length - 1 === index}
              isSelected={taskNumber === currentTaskIndex}
              onSelect={handleSelectTask}
            />
          );
        })}
      </Components.TasksWrapper>

      {/* requiredTaskIds were passed on failed WFR completion to indicate what completion was blocked by these tasks */}
      {checklist && oneOffTasks && <OneOffTaskList tasks={oneOffTasks} checklist={checklist} requiredTaskIds={[]} />}
    </Components.Container>
  );
};
