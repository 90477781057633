import * as React from 'react';
import { createUsableContext } from '@process-street/subgrade/util';
import * as AutomationSelectorMachine from './state.machine';
import { useMachine } from '@xstate/react';
import { InterpreterFrom, StateFrom } from 'xstate';
import { useDisclosure } from 'components/design/next';
import { ConfigWizardDisclosureContext } from '../../_id/config-wizard/context';
import { UnsavedChangesAlertDialog } from './unsaved-changes-alert-dialog';
import { useMatch } from '@process-street/adapters/navigation';
import { AppModalName, AppModalQueryParam } from 'app/app.constants';
import { useNgStateModalControls } from 'app/components/ng-state-modal-controls';
import { useStateParam } from 'app/hooks/use-state-param';

type Machine = typeof AutomationSelectorMachine.automationSelectorMachine;
type AutomationSelectorInterpreter = InterpreterFrom<Machine>;

type Context = [StateFrom<Machine>, AutomationSelectorInterpreter['send'], AutomationSelectorInterpreter];

export * from './state.machine';
export const [useAutomationSelector, AutomationsSelectorContext] = createUsableContext<Context>({
  hookName: 'useAutomationSelector',
  providerName: 'AutomationSelectorProvider',
});

export type AutomationSelectorProps = Partial<AutomationSelectorMachine.Context>;

export const AutomationSelectorProvider: React.FC<React.PropsWithChildren<AutomationSelectorProps>> = ({
  automationInstanceId,
  automationAppName,
  automationType,
  modalView,
  solutionTypeTag,
  ...props
}) => {
  const configWizardDisclosure = useDisclosure();

  const isEditorV2 = useMatch('templateV2');
  const modal = useStateParam({ key: AppModalQueryParam.Modal });

  const { onClose: onCloseNgStateModal } = useNgStateModalControls(AppModalName.Automations);

  const [state, send, service] = useMachine(AutomationSelectorMachine.automationSelectorMachine, {
    devTools: process.env.NODE_ENV === 'development',
    context: {
      automationInstanceId,
      automationAppName,
      automationType,
      modalView,
      solutionTypeTag,
    },
  });

  React.useEffect(
    function clearModalFromState() {
      if (isEditorV2 && modal === AppModalName.Automations && state.matches('hidden')) {
        onCloseNgStateModal();
      }
    },
    [state, isEditorV2, modal, onCloseNgStateModal],
  );

  const value = React.useMemo<Context>(() => [state, send, service], [state, send, service]);

  return (
    <AutomationsSelectorContext.Provider value={value}>
      <ConfigWizardDisclosureContext.Provider value={configWizardDisclosure}>
        {props.children}

        <UnsavedChangesAlertDialog />
      </ConfigWizardDisclosureContext.Provider>
    </AutomationsSelectorContext.Provider>
  );
};
