import { ApprovalRuleSubject } from '@process-street/subgrade/approval-rule';
import { TemplateRevision } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import {
  useQuery as useRQ,
  UseQueryOptions,
  QueryKey,
  QueryClient,
  QueryObserverOptions,
  QueryObserver,
} from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace GetApprovalRulesByTaskTemplateIdQuery {
  export type Params = { templateRevisionId?: TemplateRevision['id'] };

  export type Response = ApprovalRuleSubject[];

  export const key = ['template-revisions', 'approval-rules'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = ({ templateRevisionId }: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/template-revisions/${templateRevisionId}/approval-rules`)
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: Boolean(params.templateRevisionId) && options.enabled !== false,
    });
  };
  export const makeQueryObserver = ({
    queryClient,
    options,
    ...params
  }: Params & {
    queryClient: QueryClient;
    options?: QueryObserverOptions<Response, AxiosError>;
  }) => {
    return new QueryObserver<Response, AxiosError>(queryClient, {
      ...options,
      queryKey: getKey(params),
      queryFn: () => queryFn(params),
      enabled: Boolean(params.templateRevisionId) && options?.enabled !== false,
    });
  };
}
