import angular from 'angular';
import templateUrl from './assignments.component.html';
import './assignments.scss';

angular.module('frontStreetApp.directives').component('psInboxItemAssignments', {
  bindings: {
    users: '<',
  },
  templateUrl,
  controller(UserService) {
    const ctrl = this;

    ctrl.$onChanges = function (changes) {
      if (changes.users) {
        ctrl.users = changes.users.currentValue;
      }
    };

    ctrl.getCoverUser = () => UserService.getCoverUser(ctrl.users);

    ctrl.getBadge = function () {
      return ctrl.users.length > 1 && ctrl.users.length;
    };
  },
});
