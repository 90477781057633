import { useSelector } from '@xstate/react';
import { FormResponsePageProviders } from 'app/pages/checklists/_id/providers';
import { FormResponseMachineChecklistSelectors, FormResponseMachineSelectors } from './form-response-machine-selectors';

export namespace FormResponseMachineHooks {
  export const useCurrentTaskIndex = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineSelectors.getCurrentTaskIndex);
  };

  export const useCurrentTaskActorRef = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineSelectors.getCurrentTaskActor);
  };

  export const useShouldHideCompletedTasks = () => {
    const actor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
    return useSelector(actor, FormResponseMachineChecklistSelectors.getShouldHideCompletedTasks);
  };
}
