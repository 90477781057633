import * as React from 'react';

import { MotionConfig, Reorder } from 'framer-motion';
import { Box, VStack, useBreakpointValue } from 'components/design/next';
import { useSelector } from '@xstate/react';
import { useStateParam } from 'app/hooks/use-state-param';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { TaskTemplate } from '@process-street/subgrade/process';
import { useDebouncedCallback } from 'use-debounce';
import { TaskListNewButton } from '../task-list-new-button';
import { useEvent, useMount } from 'react-use';
import { MotionWrapper } from 'app/components/design/next';
import { SelectedTemplateTriggersEdit } from 'app/pages/templates/_id/components/selected-template-triggers/selected-template-triggers-edit';
import _isEqual from 'lodash/isEqual';
import { TaskListCoverImage } from '../task-list-cover-image';
import { useAiGenerationSlice } from 'app/pages/templates/_id/components/ai-generated-workflow-settings-modal/store';
import { AiWorkflowGenerationActorSelector } from 'app/pages/forms/_id/edit/ai-workflow-generation-machine';
import { MuidUtils } from '@process-street/subgrade/core';
import { BulkActionsDrawer } from './components/bulk-actions-drawer';
import { useTaskTemplateListActorRef } from '../../hooks/use-task-templates-list-actor';
import { isTemplateRevisionEqual } from '../../helpers/is-template-revision-equal';
import { WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT } from 'app/components/focus-bar/workflow/focus-bar-hstack';
import { TOP_BAR_HEIGHT_CSS_VAR, useUiDisclosure } from 'app/pages/forms/_id/shared';
import { useAddTaskVisibility } from '../../hooks';
import { SelectedTemplateTriggersView } from 'app/pages/templates/_id/components/selected-template-triggers/selected-template-triggers-view';
import { TaskListItemWrapper } from './task-list-item-wrapper';
import { TaskListHooks } from './task-list-hooks';
import { isPickedPropertiesEqual } from '../../helpers/is-picked-props-equal';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';
import { ReorderAutoScrollContext } from '../../providers/reorder-scroll-context';

const TASK_LIST_ITEM_HEIGHT_IN_PX = 40;

export const TaskList = () => {
  const templateId = useStateParam({ key: 'id' });

  const editorActor = useFormEditorPageActorRef();
  const taskTemplateListActor = useTaskTemplateListActorRef();
  const { onClose } = useUiDisclosure('taskList');

  const isMobile = useBreakpointValue({ base: true, md: false });

  const aiWorkflowGenerationActor = useSelector(
    editorActor,
    FormEditorPageActorSelectors.getAiWorkflowGeneratorActorRef,
  );
  const templateRevision = useSelector(
    editorActor,
    FormEditorPageActorSelectors.getTemplateRevision,
    isTemplateRevisionEqual,
  );
  const template = useSelector(
    editorActor,
    FormEditorPageActorSelectors.getTemplate,
    isPickedPropertiesEqual(['id', 'status']),
  );
  const isReordering = useSelector(editorActor, FormEditorPageActorSelectors.isReordering);
  const {
    handleOnScroll: handleAddTaskVisibilityScroll,
    addTaskButtonStyles,
    menuButtonRef,
    scrollContainerRef,
  } = useAddTaskVisibility();
  const isTaskAnimatingMap = useSelector(
    aiWorkflowGenerationActor,
    AiWorkflowGenerationActorSelector.getIsTaskAnimatingWidgetsMap,
    _isEqual,
  );

  const isReadOnly = useSelector(editorActor, FormEditorPageActorSelectors.isReadOnly, _isEqual);
  const taskTemplates = useSelector(editorActor, FormEditorPageActorSelectors.getTaskTemplates, _isEqual);
  const isTaskTemplateAssociatedWithRules = TaskListHooks.useIsTaskTemplateAssociatedWithRules();

  const lastTaskIndex = taskTemplates.length - 1;
  const { isGenerating } = useAiGenerationSlice();

  const taskAssignmentRulesMap = TaskListHooks.useTaskAssignmentRulesMap();
  const taskAssignmentsMap = TaskListHooks.useTaskAssignmentsMap();
  const organizationMembershipsMap = TaskListHooks.useOrganizationMembershipsMap();
  const dueDateRulesMap = TaskListHooks.useDueDateRulesMap();
  const taskTemplatesSelectionMap = TaskListHooks.useTaskTemplatesSelectionMap();

  const handleTaskTemplateChange = useDebouncedCallback(
    (taskTemplateId: TaskTemplate['id'], taskTemplate: Partial<TaskTemplate>) => {
      editorActor.send({ type: 'UPDATE_TASK_TEMPLATE', taskTemplate, taskTemplateId });
    },
    500,
  );

  const handleTaskDelete = React.useCallback(
    (taskTemplateId: TaskTemplate['id']) => {
      editorActor.send({ type: 'DELETE_TASK_TEMPLATE', taskTemplateId });
    },
    [editorActor],
  );

  const handleDuplicateTask = React.useCallback(
    (taskTemplateId: TaskTemplate['id']) => {
      editorActor.send({ type: 'DUPLICATE_TASK_TEMPLATE', taskTemplateId });
    },
    [editorActor],
  );

  const handleAddTask = React.useCallback(
    (at?: number, taskTemplate?: Partial<TaskTemplate>) => {
      const newTaskTemplate = { ...taskTemplate, id: MuidUtils.randomMuid(), group: { id: MuidUtils.randomMuid() } };

      editorActor.send({ type: 'CREATE_TASK_TEMPLATE', at, taskTemplate: newTaskTemplate });

      taskTemplateListActor.send({
        type: 'SELECT_TASK_TEMPLATE',
        taskTemplate: newTaskTemplate,
        metaKey: false,
        ctrlKey: false,
        shiftKey: false,
      });
    },
    [editorActor, taskTemplateListActor],
  );

  const handleBulkAdd = React.useCallback(
    (templateNameList: string[], currentTaskTemplate: TaskTemplate, currentStep: number) =>
      editorActor.send({ type: 'BULK_CREATE_TASK_FROM_PASTE', at: currentStep, currentTaskTemplate, templateNameList }),
    [editorActor],
  );

  const handleTaskTemplateSelect = React.useCallback(
    (taskTemplate: TaskTemplate, event?: React.MouseEvent) => {
      taskTemplateListActor.send({
        type: 'SELECT_TASK_TEMPLATE',
        taskTemplate,
        metaKey: Boolean(event?.metaKey),
        shiftKey: Boolean(event?.shiftKey),
        ctrlKey: Boolean(event?.ctrlKey),
      });
      if (isMobile) {
        onClose();
      }
    },
    [taskTemplateListActor, isMobile, onClose],
  );

  const handleNavigateBetweenTasks = React.useCallback(
    (toStepNumber: number, event: React.KeyboardEvent<HTMLInputElement>) => {
      const taskTemplates = taskTemplateListActor.getSnapshot()?.context?.taskTemplates ?? [];

      if (toStepNumber > taskTemplates.length || toStepNumber < 0) {
        return;
      }
      // This will ensure that the debounced updates are performed
      handleTaskTemplateChange.flush();

      taskTemplateListActor.send({
        type: 'SELECT_TASK_TEMPLATE',
        taskTemplate: taskTemplates[toStepNumber - 1],
        metaKey: event.metaKey,
        shiftKey: event.shiftKey,
        ctrlKey: event.ctrlKey,
      });
    },
    [taskTemplateListActor, handleTaskTemplateChange],
  );
  // we send MOUSE_UP on these two window events because you can move the mouse off the drag icon while the mouse is clicked
  // and still be dragging. The machine will only respond to this event while in the dragging state.
  useEvent('mouseup', () => {
    if (isReordering) {
      editorActor.send('MOUSE_UP');
    }
  });

  useEvent('touchend', () => {
    if (isReordering) {
      editorActor.send('MOUSE_UP');
    }
  });

  const scrollContainerProps = React.useContext(ReorderAutoScrollContext)?.taskAutoScroll?.scrollContainerProps;

  useMount(function setScrollerRef() {
    if (!scrollContainerProps) return;
    scrollContainerProps.scrollContainerRef.current = scrollContainerRef.current;
  });

  useMount(function calculateInitialScrollPosition() {
    const foundSelectedTask = Object.entries(taskTemplatesSelectionMap).find(([_, isSelected]) => isSelected);
    const foundIndex = taskTemplates.findIndex(taskTemplate => taskTemplate.id === foundSelectedTask?.[0]);
    const pixelsToScroll = foundIndex * TASK_LIST_ITEM_HEIGHT_IN_PX;
    scrollContainerRef.current?.scrollTo({ top: pixelsToScroll });
  });

  if (!templateId || !templateRevision || !template) return null;

  return (
    <MotionConfig reducedMotion={isReadOnly || isGenerating ? 'always' : undefined}>
      <VStack
        w="full"
        position="relative"
        overflow="auto"
        maxH={`calc(100vh - (${TOP_BAR_HEIGHT_CSS_VAR} + var(--ps-sizes-${WORKFLOW_EDITOR_FOCUS_BAR_HEIGHT})))`}
        onScroll={event => {
          scrollContainerProps?.onScroll(event);
          handleAddTaskVisibilityScroll();
        }}
        ref={scrollContainerRef}
      >
        <TaskListCoverImage isReadOnly={isReadOnly} templateId={templateId} />
        <VStack
          w="full"
          spacing={0}
          px={6}
          py={4}
          axis="y"
          as={isGenerating ? undefined : Reorder.Group}
          values={taskTemplates}
          onReorder={(reorderedTaskTemplates: TaskTemplate[]) => {
            editorActor.send({ type: 'REORDER_TASK_TEMPLATES', taskTemplates: reorderedTaskTemplates });
          }}
        >
          {isReadOnly ? (
            <SelectedTemplateTriggersView
              stackProps={{
                bg: 'white',
                borderColor: 'gray.200',
                borderStyle: 'solid',
                borderWidth: 'thin',
                borderRadius: 'full',
                variant: 'ghost',
                py: 0.5,
                px: 0.5,
                h: 'auto',
                spacing: 1,
                overflow: 'hidden',
              }}
              automationItemProps={{
                border: 'none',
                borderRadius: 'full',
              }}
              menuButtonProps={{
                border: 'none',
                fontSize: 'md',
                borderRadius: 'full',
                _before: {
                  content: '"("',
                  mt: '-1px',
                },
                _after: {
                  content: '")"',
                  mt: '-1px',
                },
                color: 'gray.600',
                fontWeight: 'semibold',
                minW: '11',
                letterSpacing: '0.1px',
              }}
            />
          ) : (
            <SelectedTemplateTriggersEdit {...selectedTemplateTriggersEditProps} />
          )}

          {taskTemplates.map((taskTemplate, index) => {
            if (!taskTemplate.name && isGenerating) return null;
            return (
              <TaskListItemWrapper
                isSelected={taskTemplatesSelectionMap[taskTemplate.id] ?? false}
                key={taskTemplate.id}
                dueDateRulesMap={dueDateRulesMap}
                taskAssignmentsMap={taskAssignmentsMap}
                organizationMembershipsMap={organizationMembershipsMap}
                taskAssignmentRulesMap={taskAssignmentRulesMap}
                stepNumber={index + 1}
                taskTemplate={taskTemplate}
                templateId={templateId}
                templateRevision={templateRevision}
                isGenerating={isGenerating}
                isAnimating={isTaskAnimatingMap[taskTemplate.group.id]?.isAnimating}
                isLast={index === lastTaskIndex}
                isTaskTemplateAssociatedWithRules={isTaskTemplateAssociatedWithRules}
                onChange={handleTaskTemplateChange}
                onDelete={handleTaskDelete}
                onDuplicate={handleDuplicateTask}
                onAddNewTask={handleAddTask}
                onSelect={handleTaskTemplateSelect}
                onNavigateBetweenTasks={handleNavigateBetweenTasks}
                onBulkAdd={handleBulkAdd}
                isReadOnly={isReadOnly}
              />
            );
          })}

          {!isReadOnly && (
            <MotionWrapper
              initial={{ opacity: 0, y: 10 }}
              animate={{
                opacity: 1,
                y: 0,
                transition: {
                  // Animate once all the task list items have completed their animation
                  delay: 0.08 * (Math.min(taskTemplates.length, 10) + 1),
                },
              }}
            >
              <Box w="full">
                <TaskListNewButton
                  taskTemplates={taskTemplates}
                  onAddTask={handleAddTask}
                  ref={menuButtonRef}
                  sx={addTaskButtonStyles}
                />
              </Box>
            </MotionWrapper>
          )}
        </VStack>

        {!isReadOnly && <BulkActionsDrawer templateRevision={templateRevision} template={template} />}
      </VStack>
    </MotionConfig>
  );
};

const selectedTemplateTriggersEditProps = {
  stackProps: {
    bg: 'white',
    borderColor: 'gray.200',
    borderStyle: 'solid',
    borderWidth: 'thin',
    borderRadius: 'full',
    variant: 'ghost',
    py: 0.5,
    px: 0.5,
    h: 'auto',
    spacing: 1,
  },
  automationItemProps: {
    border: 'none',
    borderRadius: 'full',
    variant: 'ghost',
  },
  plusButtonProps: {
    variant: 'ghost',
    borderRadius: 'full',
    bgColor: 'white',
    colorScheme: 'gray',
  },
  menuButtonProps: {
    border: 'none',
    fontSize: 'md',
    borderRadius: 'full',
    _before: {
      content: '"("',
      mt: '-1px',
    },
    _after: {
      content: '")"',
      mt: '-1px',
    },
    color: 'gray.600',
    fontWeight: 'semibold',
    minW: '11',
    letterSpacing: '0.1px',
  },
  emptyButtonProps: {
    variant: 'ghost',
    borderRadius: 'full',
  },
};
