import { OrganizationMembershipRole } from '@process-street/subgrade/core';
import { useSelector } from '@xstate/react';
import { useGetCurrentUserInfoQuery } from 'app/features/user/query-builder';
import { useSelectedOrganization } from 'app/hooks/use-selected-organization';
import { useSharedContext } from 'app/pages/forms/_id/shared';
import { useTaskTemplateListActorRef } from 'app/pages/workflows/_id/edit-v2/hooks/use-task-templates-list-actor';
import { canAccess, Feature } from 'app/services/features/features';
import { Box, Button, HStack, Icon, useDisclosure } from 'components/design/next';
import * as React from 'react';
import { TaskTemplateListActorSelectors } from '../../../task-template-list-machine';
import { StopTaskModal } from './stop-task-modal';

export const BulkStopTaskButton = () => {
  const { sessionService, userSettingsService } = useSharedContext();
  const actor = useTaskTemplateListActorRef();
  const isSomeSelectedTaskStopped = useSelector(actor, TaskTemplateListActorSelectors.isSomeSelectedTaskStopped);
  const organization = useSelectedOrganization();
  const currentUserInfoQuery = useGetCurrentUserInfoQuery();
  const disclosure = useDisclosure();

  const isFeatureAvailable = React.useMemo(() => {
    if (organization) {
      const planId = organization.subscription.plan.id;
      return canAccess(Feature.STOP_TASK, planId);
    }
  }, [organization]);

  const isAdmin = React.useMemo(() => {
    if (currentUserInfoQuery.data) {
      return currentUserInfoQuery.data?.organizationMembership.role === OrganizationMembershipRole.Admin;
    }
    return false;
  }, [currentUserInfoQuery.data]);

  const addStopTaskModalSeen = sessionService.getTemplateEditorProperty('addStopTaskModalSeen');

  const shouldShowUpgradeModal = React.useMemo(() => {
    return !isFeatureAvailable || !addStopTaskModalSeen;
  }, [addStopTaskModalSeen, isFeatureAvailable]);

  const handleOnStop = () => {
    if (shouldShowUpgradeModal) {
      sessionService.setTemplateEditorProperty('addStopTaskModalSeen', true /* value */);
      disclosure.onOpen();
    } else {
      actor.send({ type: 'BULK_UPDATE_STOP', stop: true });
    }
  };

  return (
    <>
      <HStack spacing="2">
        <Button
          w={isSomeSelectedTaskStopped ? '115px' : '163px'}
          display="flex"
          color="gray.500"
          justifyContent="flex-start"
          variant="tertiary"
          iconSpacing="3"
          onClick={handleOnStop}
          leftIcon={<Icon icon="hand" variant="far" size="4" color="gray.500" />}
        >
          Add stop
        </Button>
        {isSomeSelectedTaskStopped && (
          <Box
            as={Button}
            position="relative"
            bgColor="white"
            w="10"
            h="10"
            variant="outline"
            borderColor="gray.300"
            borderWidth="px"
            onClick={() => actor.send({ type: 'BULK_UPDATE_STOP', stop: false })}
            _hover={{ bgColor: 'gray.100' }}
          >
            <Icon icon="hand" size="4" color="gray.500" />
            <Box position="absolute" top="9px" left="11px">
              <Icon icon="slash" size="5" color="gray.500" />
            </Box>
          </Box>
        )}
      </HStack>
      {disclosure.isOpen && (
        <StopTaskModal
          disclosure={disclosure}
          isAdmin={isAdmin}
          isFeatureAvailable={isFeatureAvailable}
          onAddStop={() => {
            actor.send({ type: 'BULK_UPDATE_STOP', stop: true });
            userSettingsService.updateTemplateEditor(sessionService.getTemplateEditorProperties());
            disclosure.onClose();
          }}
        />
      )}
    </>
  );
};
