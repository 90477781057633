import angular from 'angular';
import { ChecklistStatus } from '@process-street/subgrade/process';
import { SatisMeterEvent } from 'services/interop/satis-meter-event';
import { ChecklistEvent } from 'services/checklists/checklist-event';

angular
  .module('frontStreetApp.services')
  .service('SatisMeterListener', function ($rootScope, InboxEvent, SatisMeterService, SessionService, TempDataService) {
    const self = this;

    self.listen = () => {
      $rootScope.$on(ChecklistEvent.UPDATE_OK, (__event, data) => {
        if (data?.updatedChecklist?.status === ChecklistStatus.Completed) {
          self.bootSatisMeter();
        }
      });

      $rootScope.$on(InboxEvent.INBOX_COMPLETED, () => {
        self.bootSatisMeter();
      });

      $rootScope.$on(SatisMeterEvent.AiGeneratedTemplateFirstEdit, () => {
        self.bootSatisMeter(SatisMeterEvent.AiGeneratedTemplateFirstEdit);
      });
    };

    self.bootSatisMeter = event => {
      const user = SessionService.getUser();
      const forceSurvey = TempDataService.isSatisMeterSurveyForced();
      SatisMeterService.boot(user, forceSurvey, event);
    };
  });
