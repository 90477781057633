import * as React from 'react';
import { isNotIdRef } from '@process-street/subgrade/core';
import { ChecklistInboxItem } from '@process-street/subgrade/inbox';

import { HStack, Icon, VStack, Text } from 'components/design/next';
import { useInjector } from 'components/injection-provider';

import { DueDateBadge } from './due-date-badge';
import { Assignees } from './assignees';
import { useInboxItemDetailsModal } from 'app/pages/tasks/hooks/use-inbox-item-details-modal';
import { useFeatureFlag } from 'app/features/feature-flags';

export const ChecklistItem = ({ item }: { item: ChecklistInboxItem }) => {
  const isMyWorkGAEnabled = useFeatureFlag('myWorkGA');
  const inboxItemDetailsModal = useInboxItemDetailsModal();

  const checklist = isNotIdRef(item.checklist) ? item.checklist : undefined;
  const templateName = isNotIdRef(item.template) ? item.template.name : '';

  const { $state } = useInjector('$state');
  const checklistHref = $state.href('msTeamsChecklist', { id: item.checklist.id });

  const handleClick = async () => {
    await inboxItemDetailsModal.onOpen(item);
  };

  const vStackProps = isMyWorkGAEnabled
    ? {
        onClick: handleClick,
        cursor: 'pointer',
      }
    : {
        as: 'a',
        href: checklistHref,
      };

  return (
    <>
      {isMyWorkGAEnabled ? (
        <Icon
          icon="circle-play"
          variant="fad"
          primaryLayer={{ color: 'purple.500' }}
          secondaryLayer={{ color: 'purple.300' }}
          size="5"
          aria-label="workflow"
        />
      ) : (
        <Icon icon="list" variant="far" color="gray.500" size="5" aria-label="workflow run icon" />
      )}

      <VStack {...vStackProps} align="left" minW="0" width="full" flex="1" spacing="0">
        <Text
          aria-label="workflow run title"
          color="gray.700"
          fontWeight="medium"
          fontSize={{ base: 'sm', lg: 'md' }}
          noOfLines={1}
        >
          {checklist?.name}
        </Text>
        <Text aria-label="workflow title" color="gray.600" fontWeight="regular" fontSize="xs" noOfLines={1}>
          {templateName}
        </Text>
      </VStack>
      <HStack>
        {checklist?.dueDate && <DueDateBadge date={checklist.dueDate} />}
        {item.assignees && <Assignees assignees={item.assignees} />}
      </HStack>
    </>
  );
};
