import { GridApi } from '@ag-grid-community/core';
import { Button, HStack, Text } from '@chakra-ui/react';
import { InboxItemType } from '@process-street/subgrade/inbox';
import { Icon } from 'components/design/next';
import * as React from 'react';
import { TasksTableUtils } from '../tasks-table/tasks-table-utils';
import { MyWorkGridContext } from './use-grid-context';

export type CommentsRendererProps = {
  value: number;
  data: TasksTableUtils.TasksTableItem;
  api?: GridApi;
  context: MyWorkGridContext;
};

export const CommentsRenderer = ({ value, data, context }: CommentsRendererProps) => {
  if (!TasksTableUtils.isInboxItemRow(data)) return null;
  if (!value) return null;

  const handleClick = async () => {
    if (data.itemType === InboxItemType.OneOffTask) {
      context.viewTaskById({ taskId: data.task.id, options: { shouldScrollToComments: true } });
      context.focusManagerActions.setItem({ item: data });
    } else {
      await context.openDrawer(data, { shouldScrollToComments: true });
    }
  };

  const ffProps = { as: Button, variant: 'unstyled', onClick: handleClick };

  return (
    <HStack h="full" alignItems="center" justifyContent="center" {...(context.isMyWorkGAEnabled ? ffProps : {})}>
      <Icon icon="comment" size="4" color="gray.500" />

      <Text fontSize="sm" color="gray.700" aria-label={`${value} comment(s)`}>
        {value}
      </Text>
    </HStack>
  );
};
