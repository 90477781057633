import { TemplateType } from '@process-street/subgrade/process';
import { Flex, Icon, Text, HStack, FlexProps } from 'components/design/next';
import * as React from 'react';
import { Hit as RIHit } from 'react-instantsearch-core';
import { useListboxContext } from 'components/listbox';
import { Highlight } from 'features/global-search/components/highlight';
import { useCrossLinkSearchContext } from '..';
import { useDoClickIfSelected } from 'features/global-search/components/use-do-click-if-selected';

type Doc = {
  name: string;
  description?: string;
  tasks?: string;
  templateType: string;
  content?: string;
};
export type IHit = RIHit<Doc> & {
  _snippetResult?: RIHit['_highlightResult'];
};

export type UIProps = { hit: IHit } & FlexProps;

export const Hit: React.FC<React.PropsWithChildren<UIProps>> = ({ hit, ...props }) => {
  const { selectedId } = useListboxContext();
  const { onSelect } = useCrossLinkSearchContext();
  const selected = selectedId === hit.objectID;

  const ref = React.useRef<HTMLDivElement>(null);
  useDoClickIfSelected({ id: hit.objectID, node: ref.current });

  const matchingLevels: (string | undefined)[] = ['full', 'partial'];
  const showTasksSnippet = matchingLevels.includes(hit?._snippetResult?.tasks?.matchLevel);
  const showContentSnippet = matchingLevels.includes(hit?._snippetResult?.content?.matchLevel);

  const icon = hit.templateType === TemplateType.Playbook ? 'workflow' : 'file-alt';
  const iconColor = hit.templateType === TemplateType.Playbook ? 'indigo.500' : 'teal.500';
  const iconLabel = hit.templateType === TemplateType.Playbook ? 'workflow' : 'workflow';

  return (
    <Flex
      ref={ref}
      role="button"
      onClick={() => {
        onSelect(hit.objectID);
      }}
      minHeight="14"
      maxHeight="23"
      flexDir="column"
      justifyContent="center"
      py="2"
      px="4"
      backgroundColor={selected ? 'gray.100' : 'inherit'}
      // this is a somewhat redundant style because if rendered in a listboxoption, hover will result in `selected` any way
      // this is mostly to allow for an isolated story that doesn't depend on listbox option
      _hover={{ textDecoration: 'none', color: 'inherit', backgroundColor: 'gray.100' }}
      {...props}
    >
      <Flex alignItems="center">
        <Icon size="4" variant="fas" icon={icon} color={iconColor} mr="2" aria-label={iconLabel} />
        <Flex direction="column" overflowX="hidden">
          <Text noOfLines={1} fontSize="sm" color="gray.600" title="title">
            <Highlight hit={hit} attribute="name" />
          </Text>

          {hit.description ? (
            <Text noOfLines={1} fontSize="xs" color="gray.500" title="description">
              <Highlight hit={hit} attribute="description" />
            </Text>
          ) : null}

          {showTasksSnippet ? (
            <Text noOfLines={1} fontSize="xs" color="gray.500" fontStyle="italic">
              <>
                <Text as="span" variant="inherit" color="gray.600">
                  Tasks:
                </Text>{' '}
                <Highlight hit={hit} attribute="tasks" highlightProperty="_snippetResult" />
              </>
            </Text>
          ) : null}

          {showContentSnippet && (
            <Text noOfLines={1} fontSize="xs" color="gray.500" fontStyle="italic">
              <Highlight hit={hit} attribute="content" highlightProperty="_snippetResult" />
            </Text>
          )}
        </Flex>
        {selected ? (
          <HStack ml="auto" spacing="1">
            <Icon
              icon="level-down"
              variant="far"
              transform="rotate(90deg)"
              color="gray.400"
              aria-label="select by pressing the enter key"
              title="Press the enter key to select."
              size="4"
            />
          </HStack>
        ) : null}
      </Flex>
    </Flex>
  );
};
