import * as React from 'react';
import { Icon, Tooltip, Flex } from 'components/design/next';
import { useSelector } from 'react-redux';
import { ReduxAppState } from 'reducers/types';
import { DueDateRuleDefinition, FormFieldWidget, Task, TaskTemplate, Widget } from '@process-street/subgrade/process';
import { Muid, Option } from '@process-street/subgrade/core';
import { DynamicDueDatesSelector } from 'components/dynamic-due-dates/store/dynamic-due-dates.selectors';
import { DueDateHelper } from 'features/dynamic-due-dates/services/due-date-helper';
import { DynamicDueDateLabelService } from 'components/dynamic-due-dates/services/dynamic-due-date-label.service';
import { TaskTemplateSelector } from 'reducers/task-template/task-template.selectors';
import { match, P } from 'ts-pattern';

export type DueDateTaskIndicatorProps = {
  templateRevisionId?: Muid;
  checklistRevisionId?: Muid;
  taskTemplate: TaskTemplate;
  isTaskSelected?: boolean;
  task?: Task;
};

export const DueDateTaskIndicator: React.FC<React.PropsWithChildren<DueDateTaskIndicatorProps>> = ({
  templateRevisionId,
  checklistRevisionId,
  taskTemplate,
  isTaskSelected,
  task,
}) => {
  const rules = useSelector<ReduxAppState, DueDateRuleDefinition[]>(
    match({ templateRevisionId, checklistRevisionId })
      .with({ templateRevisionId: P.string }, ({ templateRevisionId }) =>
        DynamicDueDatesSelector.getAllByTemplateRevisionId(templateRevisionId),
      )
      .with({ checklistRevisionId: P.string }, ({ checklistRevisionId }) =>
        DynamicDueDatesSelector.getAllByChecklistRevisionId(checklistRevisionId),
      )
      .otherwise(() => () => []),
  );

  const dateWidgets: FormFieldWidget[] = useSelector<ReduxAppState, Widget[]>(
    match({ templateRevisionId, checklistRevisionId })
      .with({ templateRevisionId: P.string }, ({ templateRevisionId }) =>
        DynamicDueDatesSelector.getDateWidgetsByTemplateRevisionId(templateRevisionId),
      )
      .with({ checklistRevisionId: P.string }, ({ checklistRevisionId }) =>
        DynamicDueDatesSelector.getDateWidgetsByChecklistRevisionId(checklistRevisionId),
      )
      .otherwise(() => () => []),
  ) as FormFieldWidget[];

  const taskTemplates = useSelector<ReduxAppState, TaskTemplate[]>(
    match({ templateRevisionId, checklistRevisionId })
      .with({ templateRevisionId: P.string }, ({ templateRevisionId }) =>
        TaskTemplateSelector.getAllByTemplateRevisionId(templateRevisionId),
      )
      .with({ checklistRevisionId: P.string }, ({ checklistRevisionId }) =>
        TaskTemplateSelector.getAllByChecklistRevisionId(checklistRevisionId),
      )
      .otherwise(() => () => []),
  );

  const rule: Option<DueDateRuleDefinition> = DueDateHelper.findRuleForTask(rules, taskTemplate);

  if (!rule || task?.dueDate) return null;

  const label = DynamicDueDateLabelService.getFullLabelByRule(rule, taskTemplates, dateWidgets);

  return (
    <Tooltip label={label}>
      <Flex w="8" h="8" alignItems="center" justifyContent="center">
        <Icon aria-label={label} icon="clock" color={isTaskSelected ? 'white' : 'purple.500'} size="4" />
      </Flex>
    </Tooltip>
  );
};
