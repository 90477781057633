import { StateFrom } from 'xstate/lib';
import { QueryActorSelectors } from 'app/utils/query-builder';
import { FormEditorPageMachineHelpers } from './form-editor-page-machine-helpers';
import {
  DueDateRuleDefinition,
  FieldType,
  FormFieldWidget,
  TaskTemplate,
  Widget,
  WidgetHeader,
  WidgetType,
} from '@process-street/subgrade/process';
import { FormEditorPageMachine, TaskTemplatesLookupMap, WidgetsLookupMap } from './form-editor-page-machine-types';
import { ApprovalRuleSubject } from '@process-street/subgrade/approval-rule';
import { ChecklistRuleDefinition } from '@process-street/subgrade/conditional-logic';
import { Muid } from '@process-street/subgrade/core';

export const FormEditorPageActorSelectors = {
  getTemplate(state: StateFrom<FormEditorPageMachine>) {
    return QueryActorSelectors.getQueryData(state.context.templateQuery);
  },
  getTemplateRevision(state: StateFrom<FormEditorPageMachine>) {
    return (
      FormEditorPageMachineHelpers.getDraftTemplateRevision(state.context) ??
      FormEditorPageMachineHelpers.getPublishedTemplateRevision(state.context)
    );
  },
  getPublishedTemplateRevision(state: StateFrom<FormEditorPageMachine>) {
    return FormEditorPageMachineHelpers.getPublishedTemplateRevision(state.context);
  },
  getWidgetsForTaskTemplateGroupId(taskTemplateGroupId?: Muid) {
    return (state: StateFrom<FormEditorPageMachine>) => {
      if (!taskTemplateGroupId) return [];

      const taskTemplatesLookup: TaskTemplatesLookupMap =
        QueryActorSelectors.getQueryData(state.context.taskTemplatesByTemplateRevisionIdQuery) ??
        FormEditorPageMachineHelpers.emptyTaskTemplatesLookupMap;

      const taskTemplateIdFromLookup = taskTemplatesLookup.byGroupId[taskTemplateGroupId];
      return FormEditorPageActorSelectors.getWidgetsForTaskTemplateId(taskTemplateIdFromLookup)(state);
    };
  },
  getWidgetsForTaskTemplateId(taskTemplateId: TaskTemplate['id']) {
    return (state: StateFrom<FormEditorPageMachine>) => {
      const widgetsLookup =
        QueryActorSelectors.getQueryData(state.context.widgetsByTemplateRevisionIdQuery) ??
        FormEditorPageMachineHelpers.emptyWidgetsLookup;
      const widgetsIds = widgetsLookup.byTaskTemplateId[taskTemplateId] ?? [];

      return widgetsIds.map(widgetId => widgetsLookup.byId[widgetId]);
    };
  },
  getWidgetById(widgetId: Widget['id']) {
    return (state: StateFrom<FormEditorPageMachine>) => {
      const widgetsLookup =
        QueryActorSelectors.getQueryData(state.context.widgetsByTemplateRevisionIdQuery) ??
        FormEditorPageMachineHelpers.emptyWidgetsLookup;

      return widgetsLookup.byId[widgetId];
    };
  },
  getWidgetByHeaderId(widgetHeaderId: Muid) {
    return (state: StateFrom<FormEditorPageMachine>) => {
      const widgetsLookup =
        QueryActorSelectors.getQueryData(state.context.widgetsByTemplateRevisionIdQuery) ??
        FormEditorPageMachineHelpers.emptyWidgetsLookup;
      return widgetsLookup.byHeaderId[widgetHeaderId];
    };
  },
  getWidgetByGroupId(widgetId: WidgetHeader['group']['id']) {
    return (state: StateFrom<FormEditorPageMachine>) => {
      const widgetsLookup =
        QueryActorSelectors.getQueryData(state.context.widgetsByTemplateRevisionIdQuery) ??
        FormEditorPageMachineHelpers.emptyWidgetsLookup;
      const widgetIdFromLookup = widgetsLookup.byGroupId[widgetId];

      return widgetsLookup.byId[widgetIdFromLookup];
    };
  },
  getTaskTemplateById(taskTemplateId: TaskTemplate['id']) {
    return (state: StateFrom<FormEditorPageMachine>) => {
      const taskTemplatesLookup: TaskTemplatesLookupMap =
        QueryActorSelectors.getQueryData(state.context.taskTemplatesByTemplateRevisionIdQuery) ??
        FormEditorPageMachineHelpers.emptyTaskTemplatesLookupMap;

      return taskTemplatesLookup.byId[taskTemplateId];
    };
  },
  getTaskTemplateByGroupId(taskTemplateGroupId: TaskTemplate['group']['id']) {
    return (state: StateFrom<FormEditorPageMachine>): TaskTemplate | undefined => {
      const taskTemplatesLookup: TaskTemplatesLookupMap =
        QueryActorSelectors.getQueryData(state.context.taskTemplatesByTemplateRevisionIdQuery) ??
        FormEditorPageMachineHelpers.emptyTaskTemplatesLookupMap;
      const taskTemplateId = taskTemplatesLookup.byGroupId[taskTemplateGroupId];

      return taskTemplatesLookup.byId[taskTemplateId];
    };
  },
  getPublishedWidgetsForTaskTemplateGroupId(taskTemplateGroupId?: Muid) {
    return (state: StateFrom<FormEditorPageMachine>) => {
      const taskTemplates = state.context.publishedTaskTemplates ?? [];
      const taskTemplate = taskTemplates.find(tt => tt.group.id === taskTemplateGroupId);
      return state.context.publishedWidgets?.filter(w => w.header.taskTemplate.id === taskTemplate?.id) ?? [];
    };
  },
  getTaskTemplateWithPosition(taskTemplateGroupId?: Muid) {
    return (state: StateFrom<FormEditorPageMachine>) => {
      const draftTaskTemplatesLookup =
        QueryActorSelectors.getQueryData(state.context.taskTemplatesByTemplateRevisionIdQuery) ??
        FormEditorPageMachineHelpers.emptyTaskTemplatesLookupMap;

      const taskTemplates =
        draftTaskTemplatesLookup.all.length > 0
          ? draftTaskTemplatesLookup.all
          : state.context.publishedTaskTemplates ?? [];
      const index = taskTemplates.findIndex(tt => tt.group.id === taskTemplateGroupId);
      const taskTemplate = taskTemplates[index];

      return {
        taskTemplate,
        index,
        isFirst: index === 0,
        isLast: index === taskTemplates.length - 1,
      };
    };
  },
  getTaskTemplates(state: StateFrom<FormEditorPageMachine>) {
    const taskTemplatesLookup =
      QueryActorSelectors.getQueryData(state.context.taskTemplatesByTemplateRevisionIdQuery) ??
      FormEditorPageMachineHelpers.emptyTaskTemplatesLookupMap;

    if (!taskTemplatesLookup.all.length) return state.context.publishedTaskTemplates ?? [];
    return taskTemplatesLookup.all ?? [];
  },
  getGeneratedTaskTemplates(state: StateFrom<FormEditorPageMachine>) {
    return QueryActorSelectors.getQueryData(state.context.generatedTaskTemplatesByTemplateRevisionIdQuery) ?? [];
  },
  getWidgets(state: StateFrom<FormEditorPageMachine>) {
    const widgetsLookup: WidgetsLookupMap =
      QueryActorSelectors.getQueryData(state.context.widgetsByTemplateRevisionIdQuery) ??
      FormEditorPageMachineHelpers.emptyWidgetsLookup;

    if (!widgetsLookup.all.length) return state.context.publishedWidgets ?? [];

    return widgetsLookup.all;
  },
  getWidgetsByTaskTemplateId(state: StateFrom<FormEditorPageMachine>) {
    const widgetsLookup: WidgetsLookupMap =
      QueryActorSelectors.getQueryData(state.context.widgetsByTemplateRevisionIdQuery) ??
      FormEditorPageMachineHelpers.emptyWidgetsLookup;
    return widgetsLookup.byTaskTemplateId;
  },
  getGroups(state: StateFrom<FormEditorPageMachine>) {
    const groups = QueryActorSelectors.getQueryData(state.context.groupsQuery) ?? [];
    return groups;
  },
  getPublishedTaskTemplates(state: StateFrom<FormEditorPageMachine>) {
    return state.context.publishedTaskTemplates ?? [];
  },
  getFirstTaskTemplate(state: StateFrom<FormEditorPageMachine>): TaskTemplate | undefined {
    return (
      QueryActorSelectors.getQueryData(state.context.taskTemplatesByTemplateRevisionIdQuery) ??
      FormEditorPageMachineHelpers.emptyTaskTemplatesLookupMap
    ).all[0];
  },
  getFirstTaskTemplateGroupId(state: StateFrom<FormEditorPageMachine>) {
    return (
      QueryActorSelectors.getQueryData(state.context.taskTemplatesByTemplateRevisionIdQuery) ??
      FormEditorPageMachineHelpers.emptyTaskTemplatesLookupMap
    ).all[0]?.group?.id;
  },
  getWidgetActorMap(state: StateFrom<FormEditorPageMachine>) {
    return state.context.widgetActorMap;
  },
  isPublishing(state: StateFrom<FormEditorPageMachine>) {
    return state.matches('publishing');
  },
  isLoading(state: StateFrom<FormEditorPageMachine>) {
    return state.matches('loading');
  },
  isDeleting(state: StateFrom<FormEditorPageMachine>) {
    return Object.keys(state.context.deleteTaskTemplateActorRefMap).length > 0;
  },
  isPersistingReordering(state: StateFrom<FormEditorPageMachine>) {
    return state.matches('editing.reorderingTaskTemplates.persisting');
  },
  isReordering(state: StateFrom<FormEditorPageMachine>) {
    return (
      state.matches('editing.reorderingTaskTemplates.reordered') || state.matches('editing.reorderingWidgets.reordered')
    );
  },
  getOrganizationMemberships(state: StateFrom<FormEditorPageMachine>) {
    return QueryActorSelectors.getQueryData(state.context.organizationMembershipsQuery) ?? [];
  },
  getTaskAssignments(state: StateFrom<FormEditorPageMachine>) {
    return QueryActorSelectors.getQueryData(state.context.templateTaskAssignmentsQuery) ?? [];
  },
  getTaskAssignmentRules(state: StateFrom<FormEditorPageMachine>) {
    return QueryActorSelectors.getQueryData(state.context.taskAssignmentRulesQuery) ?? [];
  },
  getRules(state: StateFrom<FormEditorPageMachine>): Array<ChecklistRuleDefinition> {
    return QueryActorSelectors.getQueryData(state.context.rulesQuery)?.definitions ?? [];
  },
  getApprovalRules(state: StateFrom<FormEditorPageMachine>): Array<ApprovalRuleSubject> {
    return QueryActorSelectors.getQueryData(state.context.approvalRulesQuery) ?? [];
  },
  getDueDateRules(state: StateFrom<FormEditorPageMachine>): Array<DueDateRuleDefinition> {
    return QueryActorSelectors.getQueryData(state.context.dueDateRulesQuery) ?? [];
  },
  getAiWorkflowGeneratorActorRef(state: StateFrom<FormEditorPageMachine>) {
    return state.context.aiWorkflowGenerationActorRef;
  },
  getUIActorRef(state: StateFrom<FormEditorPageMachine>) {
    return state.context.uiActorRef;
  },
  getTaskTemplateListActorRef(state: StateFrom<FormEditorPageMachine>) {
    return state.context.taskTemplateListActorRef;
  },
  deleteTasksNeedsConfirmation(state: StateFrom<FormEditorPageMachine>) {
    return state.context.taskTemplateListActorRef;
  },
  isReadOnly(state: StateFrom<FormEditorPageMachine>) {
    return state.context.isReadOnly;
  },
  getDateWidgetsByTemplateRevisionId(state: StateFrom<FormEditorPageMachine>): Array<FormFieldWidget> {
    const widgetsLookup: WidgetsLookupMap =
      QueryActorSelectors.getQueryData(state.context.widgetsByTemplateRevisionIdQuery) ??
      FormEditorPageMachineHelpers.emptyWidgetsLookup;

    return widgetsLookup.all.filter(
      (w: Widget): w is FormFieldWidget =>
        w.header.type === WidgetType.FormField && (w as FormFieldWidget).fieldType === FieldType.Date,
    );
  },
  getWidgetsByTemplateRevisionId(state: StateFrom<FormEditorPageMachine>) {
    return QueryActorSelectors.getQueryData(state.context.widgetsByTemplateRevisionIdQuery);
  },
  getPromptingDeleteTaskTemplateActor(state: StateFrom<FormEditorPageMachine>) {
    const actorMap = state.context.deleteTaskTemplateActorRefMap;
    const promptingActor = Object.values(actorMap).find(actor => {
      return actor.getSnapshot()?.matches('prompt');
    });

    return promptingActor;
  },
};
