import { UseToastOptions } from 'components/design/next';
import {
  ActivityObjectType,
  ActivityVerb,
  ChecklistActivity,
  TemplateActivity,
  TemplateRevisionActivity,
} from '@process-street/subgrade/activity';
import { Plan } from '@process-street/subgrade/billing';
import {
  ChecklistStatus,
  TemplateRevision,
  TemplateRevisionStatus,
  TemplateStatus,
  TemplateType,
} from '@process-street/subgrade/process';
import { HttpStatus } from '@process-street/subgrade/util';
import angular from 'angular';
import { AxiosError } from 'axios';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import {
  UndeleteChecklistMutation,
  UndeleteChecklistMutationResponse,
} from 'features/checklists/query-builder/undelete-checklist';
import {
  UndeleteTemplateMutation,
  UndeleteTemplateMutationResponse,
} from 'features/template/query-builder/undelete-template-mutation';
import { QueryClient } from 'react-query';
import { InterpretedActivity } from 'services/activities/interpreters/InterpreterContext';
import { EventName } from 'services/event-name';
import { features } from 'services/features/features';
import { ToastServiceImpl } from 'services/toast-service.impl';
import { match, P } from 'ts-pattern';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { createMachine } from 'xstate';
import {
  CreateTemplateRevisionMutation,
  CreateTemplateRevisionResult,
} from 'features/template-revisions/query-builder';
import { StateService } from '@uirouter/core';

type Context = {
  queryClient: QueryClient;
  sentence: InterpretedActivity;
  $rootScope: angular.IRootScopeService;
  $state: StateService;
  plan?: Plan;
};

type Event = {
  type: 'UNDELETE';
};

export const createSentenceMachine = ({
  queryClient,
  sentence,
  $rootScope,
  $state,
  plan,
}: {
  queryClient: QueryClient;
  sentence: InterpretedActivity;
  $rootScope: angular.IRootScopeService;
  $state: StateService;
  plan?: Plan;
}) => {
  return createMachine(
    {
      context: {
        queryClient,
        sentence,
        $rootScope,
        $state,
        plan,
      },
      schema: {
        context: {} as Context,
        events: {} as Event,
        services: {} as {
          undeleteChecklistMutation: {
            data: UndeleteChecklistMutationResponse;
          };
          undeleteTemplateMutation: {
            data: UndeleteTemplateMutationResponse;
          };
          restoreTemplateRevisionMutation: {
            data: CreateTemplateRevisionResult;
          };
        },
      },
      predictableActionArguments: true,
      preserveActionOrder: true,
      id: `sentence-machine:${sentence.activity.id}`,
      tsTypes: {} as import('./sentence-machine.typegen').Typegen0,
      initial: isUndeletable(sentence) ? 'undeletable' : 'notUndeletable',
      states: {
        notUndeletable: {
          id: 'notUndeletable',
        },
        undeletable: {
          id: 'undeletable',
          on: {
            UNDELETE: [
              {
                target: 'undeleting.checklist',
                cond: 'isChecklist',
              },
              {
                target: 'undeleting.template',
                cond: 'isTemplate',
              },
              {
                target: 'undeleting.templateRevision',
                cond: 'isTemplateRevision',
              },
            ],
          },
        },
        undeleting: {
          states: {
            checklist: {
              invoke: {
                src: 'undeleteChecklistMutation',
                onDone: {
                  target: '#notUndeletable',
                  actions: ['showUndeletedSuccessToast'],
                },
                onError: [
                  {
                    target: '#undeletable',
                    actions: ['showUndeletedErrorToast', 'showPaywall'],
                    cond: 'isPaymentRequired',
                  },
                  {
                    target: '#undeletable',
                    actions: ['showUndeletedErrorToast'],
                  },
                ],
              },
            },
            template: {
              invoke: {
                src: 'undeleteTemplateMutation',
                onDone: {
                  target: '#notUndeletable',
                  actions: ['showUndeletedSuccessToast'],
                },
                onError: [
                  {
                    target: '#undeletable',
                    actions: ['showUndeletedErrorToast', 'showPaywall'],
                    cond: 'isPaymentRequired',
                  },
                  {
                    target: '#undeletable',
                    actions: ['showUndeletedErrorToast'],
                  },
                ],
              },
            },
            templateRevision: {
              invoke: {
                src: 'restoreTemplateRevisionMutation',
                onDone: {
                  target: '#notUndeletable',
                  actions: ['showUndeletedSuccessToast', 'goToTemplate'],
                },
                onError: [
                  {
                    target: '#undeletable',
                    actions: ['showUndeletedErrorToast', 'showPaywall'],
                    cond: 'isPaymentRequired',
                  },
                  {
                    target: '#undeletable',
                    actions: ['showUndeletedErrorToast'],
                  },
                ],
              },
            },
          },
        },
      },
    },
    {
      services: {
        undeleteChecklistMutation: async ctx => {
          return makeMutation(ctx.queryClient, {
            mutationKey: UndeleteChecklistMutation.key,
            mutationFn: () =>
              UndeleteChecklistMutation.mutationFn({
                checklistId: (ctx.sentence.activity as ChecklistActivity).object.id,
              }),
          }).execute();
        },
        undeleteTemplateMutation: async ctx => {
          return makeMutation(ctx.queryClient, {
            mutationKey: UndeleteTemplateMutation.key,
            mutationFn: () =>
              UndeleteTemplateMutation.mutationFn({
                templateId: (ctx.sentence.activity as TemplateActivity).object.id,
              }),
          }).execute();
        },
        restoreTemplateRevisionMutation: async ctx => {
          return makeMutation(ctx.queryClient, {
            mutationKey: CreateTemplateRevisionMutation.key,
            mutationFn: () =>
              CreateTemplateRevisionMutation.mutationFn({
                templateId: ((ctx.sentence.activity as TemplateRevisionActivity).object as TemplateRevision).template
                  .id,
                baseTemplateRevisionId: (ctx.sentence.activity as TemplateRevisionActivity).object.id,
              }),
          }).execute();
        },
      },
      actions: {
        showPaywall: ctx => {
          if (!plan || !features.isFreemiumTrack(plan.id)) return;

          ctx.$rootScope.$broadcast(EventName.TEMPLATE_SHOW_PAYWALL);
        },
        showUndeletedErrorToast: (ctx, e) => {
          const error = e.data as AxiosError;

          const resourceType = getResultTypeLabel(ctx.sentence);

          if (!resourceType) return;

          const toastOptions = match<AxiosError, UseToastOptions | null>(error)
            .with({ response: { status: HttpStatus.PAYMENT_REQUIRED } }, () => null)
            .with({ response: { status: HttpStatus.NOT_FOUND } }, () => ({
              status: 'warning',
              title: `You cannot restore the ${resourceType} it was already restored.`,
            }))
            .otherwise(() => ({
              status: 'error',
              title: `We're having problems restoring the ${resourceType}`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            }));

          if (!toastOptions) return;

          ToastServiceImpl.openToast(toastOptions);
        },
        goToTemplate: ctx => {
          ctx.$state.go('template', { id: (ctx.sentence.activity.object as TemplateRevision).template.id });
        },
        showUndeletedSuccessToast: ctx => {
          const resourceType = getResultTypeLabel(ctx.sentence);

          ToastServiceImpl.openToast({
            status: 'success',
            title: `${resourceType} restored`,
          });
        },
      },
      guards: {
        isChecklist: ctx => ctx.sentence.activity.objectType === ActivityObjectType.Checklist,
        isTemplate: ctx => ctx.sentence.activity.objectType === ActivityObjectType.Template,
        isTemplateRevision: ctx => ctx.sentence.activity.objectType === ActivityObjectType.TemplateRevision,
        isPaymentRequired: (_, e) => (e.data as AxiosError).response?.status === HttpStatus.PAYMENT_REQUIRED,
      },
    },
  );
};

const isUndeletable = (sentence: InterpretedActivity) => {
  return match(sentence.activity)
    .with(
      {
        objectType: ActivityObjectType.Template,
        verb: P.union(ActivityVerb.Deleted, ActivityVerb.Updated),
        data: { status: TemplateStatus.Deleted },
        object: { status: TemplateStatus.Deleted },
      },
      () => true,
    )
    .with(
      {
        objectType: ActivityObjectType.Checklist,
        verb: P.union(ActivityVerb.Deleted, ActivityVerb.Updated),
        data: { status: ChecklistStatus.Deleted },
        object: { status: ChecklistStatus.Deleted },
      },
      () => true,
    )
    .with(
      {
        objectType: ActivityObjectType.TemplateRevision,
        verb: P.union(ActivityVerb.Updated),
        data: { status: TemplateRevisionStatus.Finished },
      },
      () => true,
    )
    .otherwise(() => false);
};

const getResultTypeLabel = (sentence: InterpretedActivity) => {
  return match(sentence.activity)
    .with(
      {
        objectType: ActivityObjectType.Template,
        object: {
          templateType: TemplateType.Page,
        },
      },
      () => 'Page',
    )
    .with({ objectType: ActivityObjectType.Template, object: { templateType: TemplateType.Form } }, () => 'Form')
    .with(
      { objectType: ActivityObjectType.Template, object: { templateType: TemplateType.Playbook } },
      () => 'Workflow',
    )
    .with({ objectType: ActivityObjectType.TemplateRevision }, () => 'Workflow version')
    .otherwise(() => null);
};
