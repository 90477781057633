import * as React from 'react';
import { useActor } from '@xstate/react';
import { SnippetFormFieldActor } from './snippet-form-field-machine';
import { InputGroup, Text, VStack } from 'components/design/next';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { FormFieldLabel } from '../common/form-field-label';
import { WidgetActorProvider } from '../../../../shared/widget-context';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { FormFieldRecentlyMovedIndicator } from '../common/form-field-recently-moved-indicator';
import { snippetSettingsSchema } from './snippet-form-field-schema';
import { SettingsModalFields } from '../common/settings/fields';
import { InlineSettings } from '../common/settings/inline-settings';
import { ViewModeInteractionWrapper } from '../../view-mode-interaction-wrapper/view-mode-interaction-wrapper';

export type SnippetFormFieldWidgetProps = {
  isFirst: boolean;
  isLast: boolean;
  actor: SnippetFormFieldActor;
};

export const SnippetFormFieldWidget: React.FC<React.PropsWithChildren<SnippetFormFieldWidgetProps>> = ({
  actor,
  isFirst,
  isLast,
}) => {
  const [state, send] = useActor(actor);
  const { widget, template, labelActor, recentlyMovedFrom, isReadOnly } = state.context;
  const ref = React.useRef<HTMLDivElement | null>(null);

  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer>
        <VStack alignItems="flex-start" flex="1">
          {recentlyMovedFrom && <FormFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
          {labelActor && <FormFieldLabel isReadOnly={isReadOnly} actor={labelActor} />}
          <ViewModeInteractionWrapper templateId={template.id}>
            <InputGroup
              w="full"
              ref={node => {
                ref.current = node;
                if (node && !state.context.inputNode) {
                  send({ type: 'SET_NODE', node });
                }
              }}
              scrollMarginBottom={17}
            >
              <InlineSettings widget={widget} schema={snippetSettingsSchema}>
                <SettingsModalFields.SnippetValue
                  isDisabled={isReadOnly}
                  id={`form-field-widget-${widget.id}`}
                  templateRevisionId={widget.templateRevision.id}
                />
              </InlineSettings>
              {!isReadOnly && (
                <>
                  <WidgetListItemDragIcon />
                  <FormsWidgetMenu>
                    <FormsWidgetMenuItems.Required widget={widget} />
                    <FormsWidgetMenuItems.ConditionalLogic
                      widget={widget}
                      templateRevisionId={widget.templateRevision.id}
                      templateType={template.templateType}
                    />
                    <FormsWidgetMenuItems.Divider />
                    <FormsWidgetMenuItems.Duplicate />
                    <FormsWidgetMenuItems.MoveToStep widget={widget} />
                    <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
                    <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
                    <FormsWidgetMenuItems.Delete />
                  </FormsWidgetMenu>
                </>
              )}
            </InputGroup>
          </ViewModeInteractionWrapper>
          <Text fontSize="sm" color="gray.500" mt="1" alignSelf="flex-end" fontStyle="italic">
            This field will not be visible in workflow runs.
          </Text>
        </VStack>
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};
