import * as React from 'react';
import { ExecuteAiPrompt } from '@process-street/subgrade/process';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { FieldTypePreservingOmit } from '@process-street/subgrade/core';
import { BlvdSelectProps, BlvdSelect } from 'components/design/BlvdSelect';
import { useAiTaskWidgetSelect, type AiTaskWidgetOption, UseAiTaskWidgetSelectArgs } from './use-ai-task-widget-select';
import { AiTaskWidgetSelectOption } from './ai-task-widget-select-option';
import { AiTaskWidgetSelectValue } from './ai-task-widget-select-value';

export type { AiTaskWidgetOption };

export interface AiTaskWidgetSelectProps
  extends FieldTypePreservingOmit<BlvdSelectProps<AiTaskWidgetOption>, 'value' | 'onChange'>,
    UseAiTaskWidgetSelectArgs {
  onChange: (value: AiTaskWidgetOption) => void;
}

export const AiTaskWidgetSelect: React.FC<React.PropsWithChildren<AiTaskWidgetSelectProps>> = ({
  value: valueProp,
  onChange,
  filter = ExecuteAiPrompt.isOutputWidget,
  ...props
}) => {
  const { value, taskWidgetOptions } = useAiTaskWidgetSelect({ filter, value: valueProp });

  return (
    <BlvdSelect<AiTaskWidgetOption>
      isSearchable
      options={taskWidgetOptions ?? []}
      value={value}
      onChange={option => {
        if (BlvdSelectHelpers.isOptionType<AiTaskWidgetOption>(option)) {
          onChange(option);
        }
      }}
      components={components}
      {...props}
    />
  );
};

const components = {
  Option: AiTaskWidgetSelectOption,
  SingleValue: AiTaskWidgetSelectValue,
};
