import * as React from 'react';
import { Button, Text, VStack, Icon } from 'components/design/next';
import { KeyStrings } from 'services/key';
import { MultiSelectFieldValue, MultiSelectItemValueStatus, TemplateRevision } from '@process-street/subgrade/process';
import { Muid, MuidUtils } from '@process-street/subgrade/core';
import { Subtask } from './subtask';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { UseDropOnSubtaskListItemValue } from './use-drop-on-subtask-list-item';
import { SubtasksFormFieldProvider, useSubtasksFormFieldContext } from './subtasks-form-field-context';
import { SubtasksFormFieldMachine } from './subtasks-form-field-machine';

type SubtasksFormFieldImplProps = {
  editorType?: SubtaskEditor;
  templateRevisionId?: TemplateRevision['id'];
  groupId?: Muid;
  isReadOnly?: boolean;
};
export enum SubtaskEditor {
  OneOffTask = 'OneOffTask',
  WFEditor = 'WFEditor',
}

export const SubtasksFormFieldImpl = React.memo(
  ({
    editorType = SubtaskEditor.OneOffTask,
    templateRevisionId,
    groupId,
    isReadOnly = false,
  }: SubtasksFormFieldImplProps) => {
    const actorRef = useSubtasksFormFieldContext();
    const api = SubtasksFormFieldMachine.Hooks.useApi(actorRef);
    const items = SubtasksFormFieldMachine.Hooks.useItems(actorRef);
    const errors = SubtasksFormFieldMachine.Hooks.useErrors(actorRef);
    const touched = SubtasksFormFieldMachine.Hooks.useTouched(actorRef);

    const handleAddSubtask = React.useCallback(() => {
      api.appendItem({ editorType });

      if (items.length === 0) {
        api.setFocus(0);
      } else {
        requestAnimationFrame(() => {
          api.setFocus(items.length);
        });
      }
    }, [items, api, editorType]);

    const createEmptyItem = React.useCallback(
      (name = ''): MultiSelectFieldValue => {
        const itemType = editorType === SubtaskEditor.OneOffTask ? 'Dynamic' : 'Static';
        return {
          status: MultiSelectItemValueStatus.NotCompleted,
          itemType,
          id: MuidUtils.randomMuid(),
          name,
        };
      },
      [editorType],
    );

    const handleSubtaskChange = React.useCallback(
      (index: number, updatedItem: MultiSelectFieldValue) => {
        const name = updatedItem.name ?? '';
        const [newName, ...newNames] = name.split('\n');

        api.onUpdate(index, {
          ...updatedItem,
          name: newName,
        });

        newNames.forEach((name, i) => {
          const newItem = createEmptyItem(name);
          // Insert after the original item and previous item
          api.onInsert(index + 1 + i, editorType, newItem);
        });

        if (newNames.length) {
          api.setFocus(index + newNames.length);
        }
      },
      [api, createEmptyItem, editorType],
    );

    const handleKeyDown = React.useCallback(
      (index: number, e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const subtasks = api.getItems();
        const subtask = subtasks[index];
        // Out of bounds
        if (!subtask) return;

        if (e.key === KeyStrings.BACKSPACE && (subtask.name ?? '').length === 0) {
          e.preventDefault();
          e.stopPropagation();
          api.onDelete(index);
          api.setFocus(index - 1);

          return;
        }

        if (e.key === KeyStrings.ENTER) {
          e.preventDefault();
          e.stopPropagation();
          api.onInsert(index, editorType);
          api.setFocus(index + 1);

          return;
        }

        if (e.key === KeyStrings.ARROW_UP && index > 0) {
          e.preventDefault();
          e.stopPropagation();
          api.setFocus(index - 1);
          return;
        }

        if (e.key === KeyStrings.ARROW_DOWN && index < subtasks.length - 1) {
          e.preventDefault();
          e.stopPropagation();
          api.setFocus(index + 1);
          return;
        }
      },
      [api, editorType],
    );

    const handleDeleteSubtask = React.useCallback(
      (index: number) => {
        api.onDelete(index);
      },
      [api],
    );

    const handleDrop = React.useCallback(
      ({ before, after, item }: UseDropOnSubtaskListItemValue) => {
        api.dropItem({ before, after, item });
      },
      [api],
    );

    const handleBlur = React.useCallback(
      (index: number) => {
        api.onBlur(index);
      },
      [api],
    );

    const subtasksContent = React.useMemo(
      () => (
        <VStack w="full" alignItems="flex-start" justifyContent="flex-start" spacing={3}>
          {items.length > 0 && (
            <>
              {editorType === SubtaskEditor.OneOffTask && (
                <Text variant="-2u" color="gray.600">
                  Subtasks
                </Text>
              )}

              <VStack w="full" justifyContent="flex-start" alignItems="flex-start" spacing={1} listStyleType="none">
                {items.map((subtask, index) => {
                  return (
                    <Subtask
                      key={`subtask-${subtask.id}`}
                      index={index}
                      subtask={subtask}
                      hasError={Boolean(errors[index])}
                      editorType={editorType}
                      isTouched={Boolean(touched[index])}
                      onChange={handleSubtaskChange}
                      onKeyDown={handleKeyDown}
                      onBlur={handleBlur}
                      onDelete={handleDeleteSubtask}
                      onDrop={handleDrop}
                      templateRevisionId={templateRevisionId}
                      groupId={groupId}
                      isReadOnly={isReadOnly}
                    />
                  );
                })}
              </VStack>
            </>
          )}
          {!isReadOnly && (
            <Button
              variant="outline"
              leftIcon={<Icon icon="plus" size="3" />}
              fontSize="sm"
              colorScheme="gray"
              color="gray.600"
              fontWeight="normal"
              borderColor="gray.300"
              borderWidth="thin"
              type="button"
              onClick={handleAddSubtask}
            >
              Add subtask
            </Button>
          )}
        </VStack>
      ),
      [
        handleDeleteSubtask,
        handleKeyDown,
        handleSubtaskChange,
        editorType,
        errors,
        groupId,
        handleAddSubtask,
        handleBlur,
        handleDrop,
        isReadOnly,
        templateRevisionId,
        touched,
        items,
      ],
    );

    return editorType === SubtaskEditor.OneOffTask ? (
      <DndProvider backend={HTML5Backend} context={window}>
        {subtasksContent}
      </DndProvider>
    ) : (
      <>{subtasksContent}</>
    );
  },
);

SubtasksFormFieldImpl.displayName = 'SubtasksFormFieldImpl';

export type SubtasksFormFieldProps = SubtasksFormFieldImplProps & {
  items: MultiSelectFieldValue[];
  onSave: (items: MultiSelectFieldValue[]) => void;
};

export const SubtasksFormField = ({ items, onSave, ...props }: SubtasksFormFieldProps) => {
  return (
    <SubtasksFormFieldProvider items={items} groupId={props.groupId} onSave={onSave}>
      <SubtasksFormFieldImpl {...props} />
    </SubtasksFormFieldProvider>
  );
};
