import {
  Button,
  ButtonProps,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemProps,
  MenuList,
  Portal,
  Text,
  useDisclosure,
  VStack,
} from 'components/design/next';
import { useCreateTemplatePaywall, useTemplateGalleryPaywallCheck } from 'components/paywalls/create-template';
import { NewFolderModal } from 'features/new-menu/new-folder-modal';
import * as React from 'react';
import { RunButton } from 'features/new-buttons/run-button/run-button';
import { useCurrentOrPrivateRootFolderQuery } from './query';
import { useFeatureFlag } from 'features/feature-flags';
import { CreateTemplateSource } from 'services/template-service.interface';
import { CreateChecklistSource } from 'services/checklist-service.interface';
import { useNewBlankWorkflowButton } from 'features/new-buttons/use-new-blank-workflow-button';
import { PaywallButton } from 'components/paywalls';
import { useNewWorkflowFromTemplateButton } from 'features/new-buttons/use-new-workflow-from-template-button';
import { useNewBlankFormButton } from 'features/new-buttons/use-new-blank-form-button';
import { useNewBlankPageButton } from 'features/new-buttons/use-new-blank-page-button';
import { FolderPickerModal, PickButtonProps } from 'components/folder-picker/modal';
import { useSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { NewWorkflowModal } from './new-workflow-modal';
import { useCreateProjectTask } from 'features/one-off-tasks/components/shared/one-off-task-drawer-store';
import { useBreakpointValue } from '@chakra-ui/react';
import { CreateDataSetModal } from 'pages/data-sets/components/create-data-set-modal';
import { useCanViewDataSets } from 'app/pages/reports/data-sets/hooks/use-can-view-data-sets';
import { AiGeneratedDataSetSettingsModal } from 'app/pages/data-sets/components/ai-generated-dataset-settings-modal';

export type UniversalNewMenuProps = React.PropsWithChildren<
  ButtonProps & {
    shouldHideOneOffTask?: boolean;
    shouldHideFolder?: boolean;
    shouldHideRunWorkflow?: boolean;
    shouldCollapseOnMobile?: boolean;
    source?: CreateTemplateSource;
  }
>;

const PickButton: React.FC<React.PropsWithChildren<PickButtonProps>> = ({ pickType, ...props }) => (
  <Button {...props}>
    Create
    {pickType === 'to_private' ? ' to Private' : null}
    {pickType === 'to_organization' ? ' to Organization' : null}
  </Button>
);

export const NewMenuMenuItem: React.FC<React.PropsWithChildren<MenuItemProps & ButtonProps>> = props => {
  return (
    <MenuItem
      justifyContent="flex-start"
      as={Button}
      variant="unstyled"
      borderRadius="0"
      whiteSpace="normal"
      {...props}
    />
  );
};

export const UniversalNewMenu = ({
  shouldHideRunWorkflow,
  shouldHideFolder,
  shouldHideOneOffTask,
  shouldCollapseOnMobile,
  source = CreateTemplateSource.MAIN_NAV_BAR,
  children,
  ...buttonProps
}: UniversalNewMenuProps) => {
  useTemplateGalleryPaywallCheck();
  const organizationId = useSelector(SessionSelector.getSelectedOrganizationId);

  const { folderPickerProps: newBlankWorkflowFolderPickerProps } = useNewBlankWorkflowButton({
    source,
    allowSelectFolder: true,
  });
  const { folderPickerProps: newWorkflowFromTemplateFolderPickerProps } = useNewWorkflowFromTemplateButton({
    source,
    allowSelectFolder: true,
  });
  const { buttonProps: newBlankFormButtonProps, folderPickerProps: newBlankFormFolderPickerProps } =
    useNewBlankFormButton({
      source,
      allowSelectFolder: true,
    });
  const { buttonProps: newBlankPageButtonProps, folderPickerProps: newBlankPageFolderPickerProps } =
    useNewBlankPageButton({
      source,
      allowSelectFolder: true,
    });

  const { data: currentFolder, isLoading: currentFolderLoading } = useCurrentOrPrivateRootFolderQuery();
  const createProjectTask = useCreateProjectTask();
  const isOneOffTasksEnabled = useFeatureFlag('oneOffTasks');
  const isDataSetsV2Enabled = useFeatureFlag('dataSetsV2');
  const userCanViewDataSets = useCanViewDataSets();

  const title = buttonProps.isDisabled
    ? "You don't have permission to create workflows, pages, or folders in this folder"
    : 'Click to create a new workflow, form, task, page, or folder';

  const newFolderDisclosure = useDisclosure();
  const addFolderIsLoading = currentFolderLoading;

  const folderPickerProps = [
    newBlankWorkflowFolderPickerProps,
    newWorkflowFromTemplateFolderPickerProps,
    newBlankFormFolderPickerProps,
    newBlankPageFolderPickerProps,
  ].find(props => props.isOpen);

  const newWorkflowDisclosure = useDisclosure();
  const createDataSetDisclosure = useDisclosure();

  const responsiveButtonType = useBreakpointValue({ base: IconButton, md: Button }) ?? Button;
  const buttonIcon = <Icon icon="plus" size="4" />;
  const responsiveLeftIcon = useBreakpointValue({ base: undefined, md: buttonIcon });
  const responsiveIcon = useBreakpointValue({ base: buttonIcon, md: undefined });
  const generateDataSetWithAiDisclosure = useDisclosure();

  return (
    <>
      <Menu autoSelect={false} isLazy={true}>
        <MenuButton
          as={shouldCollapseOnMobile ? responsiveButtonType : Button}
          leftIcon={shouldCollapseOnMobile ? responsiveLeftIcon : buttonIcon}
          icon={shouldCollapseOnMobile ? responsiveIcon : undefined}
          iconSpacing={2}
          width="full"
          title={title}
          textAlign="left"
          colorScheme="brand"
          variant="solid"
          {...buttonProps}
        >
          {children ?? 'New'}
        </MenuButton>

        <Portal>
          <MenuList color="gray.600" right={0} width="268px" zIndex="popover">
            {!shouldHideRunWorkflow && (
              <>
                <RunButton source={CreateChecklistSource.MAIN_NAV_BAR}>
                  <NewMenuMenuItem>
                    <HStack spacing="2" py={2}>
                      <Icon variant="fas" icon="play" color="brand.500" size="4" />
                      <Text fontWeight="medium">Run a Workflow</Text>
                    </HStack>
                  </NewMenuMenuItem>
                </RunButton>
                <MenuDivider />
              </>
            )}
            <MenuGroup title="create">
              <PaywallButton useHook={useCreateTemplatePaywall}>
                <NewMenuMenuItem onClick={newWorkflowDisclosure.onOpen} isDisabled={false}>
                  <HStack spacing="2">
                    <Icon variant="fas" icon="workflow" color="indigo.500" size="4" />
                    <VStack w="full" align="left" spacing="0">
                      <Text fontWeight="medium">Workflow</Text>
                      <Text fontSize="xs" color="gray.500" data-item-description={true}>
                        Streamline processes
                      </Text>
                    </VStack>
                  </HStack>
                </NewMenuMenuItem>
              </PaywallButton>

              <PaywallButton useHook={useCreateTemplatePaywall}>
                <NewMenuMenuItem {...newBlankFormButtonProps} isDisabled={false}>
                  <HStack spacing="2">
                    <Icon variant="fas" icon="ballot" color="orange.500" size="4" />
                    <VStack w="full" align="left" spacing="0">
                      <HStack>
                        <Text fontWeight="medium">Form</Text>
                      </HStack>
                      <Text fontSize="xs" color="gray.500" data-item-description={true}>
                        Create a form to collect data
                      </Text>
                    </VStack>
                  </HStack>
                </NewMenuMenuItem>
              </PaywallButton>

              {isDataSetsV2Enabled && userCanViewDataSets && (
                <NewMenuMenuItem onClick={createDataSetDisclosure.onOpen} isDisabled={false}>
                  <HStack spacing="2">
                    <Icon variant="fas" icon="database" color="yellow.500" size="4" />
                    <VStack w="full" align="left" spacing="0">
                      <HStack>
                        <Text fontWeight="medium">Data Set</Text>
                      </HStack>
                      <Text fontSize="xs" color="gray.500" data-item-description={true}>
                        Create and store tables of data
                      </Text>
                    </VStack>
                  </HStack>
                </NewMenuMenuItem>
              )}

              <NewMenuMenuItem {...newBlankPageButtonProps} isDisabled={false}>
                <HStack spacing="2">
                  <Icon variant="fas" icon="file-alt" color="teal.500" size="4" />
                  <VStack w="full" align="left" spacing="0">
                    <HStack>
                      <Text fontWeight="medium">Page</Text>
                    </HStack>
                    <Text fontSize="xs" color="gray.500" data-item-description={true}>
                      Document team knowledge
                    </Text>
                  </VStack>
                </HStack>
              </NewMenuMenuItem>

              {isOneOffTasksEnabled && !shouldHideOneOffTask && (
                <NewMenuMenuItem onClick={createProjectTask}>
                  <HStack spacing="2">
                    <Icon
                      variant="fad"
                      icon="circle-check"
                      primaryLayer={{
                        opacity: 1,
                        color: 'teal.500',
                      }}
                      secondaryLayer={{
                        opacity: 0.4,
                        color: 'teal.500',
                      }}
                      size="4"
                    />
                    <VStack w="full" alignItems="start" spacing="0">
                      <HStack>
                        <Text fontWeight="medium">Task</Text>
                      </HStack>
                      <Text fontSize="xs" color="gray.500" data-item-description={true}>
                        Create a task
                      </Text>
                    </VStack>
                  </HStack>
                </NewMenuMenuItem>
              )}

              {!shouldHideFolder && (
                <NewMenuMenuItem onClick={newFolderDisclosure.onOpen} as={Button} isLoading={addFolderIsLoading}>
                  <HStack spacing="2">
                    <Icon variant="fas" icon="folder" color="gray.500" size="4" />
                    <VStack w="full" align="left" spacing="0">
                      <HStack>
                        <Text fontWeight="medium">Folder</Text>
                      </HStack>
                      <Text fontSize="xs" color="gray.500" data-item-description={true}>
                        Organize workflows and pages
                      </Text>
                    </VStack>
                  </HStack>
                </NewMenuMenuItem>
              )}
            </MenuGroup>
          </MenuList>
        </Portal>
      </Menu>

      {currentFolder ? (
        <NewFolderModal
          selectedOrganizationId={currentFolder.organization.id}
          currentFolderId={currentFolder.id}
          {...newFolderDisclosure}
        />
      ) : null}

      {folderPickerProps && (
        <FolderPickerModal
          {...folderPickerProps}
          verb="add"
          organizationId={organizationId}
          pickButton={PickButton}
        ></FolderPickerModal>
      )}

      {newWorkflowDisclosure.isOpen && <NewWorkflowModal {...newWorkflowDisclosure} />}
      {createDataSetDisclosure.isOpen && (
        <CreateDataSetModal
          {...createDataSetDisclosure}
          onGenerateWithAi={() => {
            createDataSetDisclosure.onClose();
            generateDataSetWithAiDisclosure.onOpen();
          }}
        />
      )}
      {generateDataSetWithAiDisclosure.isOpen && (
        <AiGeneratedDataSetSettingsModal {...generateDataSetWithAiDisclosure} />
      )}
    </>
  );
};
