import angular from 'angular';
import { htmlEscaped, HttpStatus } from '@process-street/subgrade/util';
import { ArrayService } from 'services/array-service';
import { EmailService } from 'services/email-service';
import templateUrl from './checklist-assigner.component.html';
import { DefaultErrorMessages } from 'components/utils/error-messages';

angular.module('frontStreetApp.directives').component('psChecklistAssigner', {
  bindings: {
    checklist: '<',
    users: '<',
    disabled: '<',
    onAssign: '&',
    onUnassign: '&',
  },
  templateUrl,
  controller(ChecklistAssignmentService, FeatureFlagService, SessionService, UserService, ToastService) {
    const ctrl = this;
    ctrl.isMyWorkGAEnabled = FeatureFlagService.getFeatureFlags().myWorkGA;

    ctrl.helpText =
      'Search for a person in your organization by name or email address, ' +
      'or enter an email address to invite a guest.';

    ctrl.assignUser = function (user) {
      if (!EmailService.isValidEmailAddress(user.email)) {
        ToastService.openToast({
          status: 'warning',
          title: `The email address appears to be invalid`,
        });
        return;
      }

      ctrl.users.push(user);

      const name = UserService.getLabel(user);

      ChecklistAssignmentService.assignOrInvite(ctrl.checklist, user, false /* autoAssign */).then(
        assignment => {
          // Remove the original user in case they were just a shell for invitation
          ArrayService.desplice(ctrl.users, user);
          ctrl.users.push(assignment.organizationMembership.user);

          ToastService.openToast({
            status: 'success',
            title: htmlEscaped`Added ${name} to this workflow run`,
          });

          if (ctrl.onAssign) {
            ctrl.onAssign({ user });
          }
        },
        response => {
          switch (response.status) {
            case HttpStatus.CONFLICT:
              ToastService.openToast({
                status: 'warning',
                title: htmlEscaped`${name} is already a member of this workflow run.`,
              });
              break;
            case HttpStatus.FORBIDDEN:
              ToastService.openToast({
                status: 'warning',
                title: htmlEscaped`${name} can not be assigned to this workflow run.`,
              });
              break;
            case HttpStatus.BAD_REQUEST:
              ToastService.openToast({
                status: 'warning',
                title: htmlEscaped`Failed to add ${name} to this workflow run.`,
                description: DefaultErrorMessages.getUserInactiveDescription(name, response),
              });
              break;
            default:
              ToastService.openToast({
                status: 'warning',
                title: htmlEscaped`Failed to add ${name} to this workflow run.`,
              });
              break;
          }

          ArrayService.desplice(ctrl.users, user);
        },
      );
    };
  },
});
