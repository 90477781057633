import { NumberFormFieldWidget } from '@process-street/subgrade/process';
import { UpdateFormFieldValueMutationResponse } from 'features/widgets/query-builder';
import { FormFieldMachineBuilderProps, WithFormFieldMachineEvent } from 'pages/responses/_id/types';
import { Option } from 'space-monad';
import { ActionObject, actions, ActorRefFrom, assign, createMachine, spawn } from 'xstate';
import { makeValidationMachine, ValidationActorRef, ValidationParentEvent } from '../validation-machine';
import { makeNumberValidationSchema } from 'pages/forms/_id/edit/components/form-fields/number-form-field/number-form-field-schema';
import {
  makeRulesEngineTargetMachine,
  sendRulesActorFormFieldValueUpdate,
} from '../../form-response-body/rules-engine-machine';
import { makeUpdateFormFieldValueMutation } from '../make-update-form-field-value-mutation';
import { FormResponseErrorToasts } from 'pages/responses/_id/utils/form-response-error-toasts';
import { AxiosError } from 'axios';

const { send, sendParent, cancel, forwardTo } = actions;

export type Context = {
  widget: NumberFormFieldWidget;
  value: string | undefined;
  validationActor: ValidationActorRef<string | undefined>;
  rulesEngineTargetActor: ActorRefFrom<typeof makeRulesEngineTargetMachine>;
  inputNode: HTMLElement | null;
};

export type Event = WithFormFieldMachineEvent<ValidationParentEvent, string | undefined>;

export const makeNumberFormFieldMachine = ({
  formFieldWidget,
  formFieldValue,
  autoFocus,
  checklistRevisionId,
  sharedContext,
  isEditable,
  inputNode,
}: FormFieldMachineBuilderProps<NumberFormFieldWidget>) => {
  const stringValue = Option(formFieldValue)
    .map(ffv => ffv.fieldValue.value)
    .map(String)
    .get();

  const validationSchema = makeNumberValidationSchema({
    required: formFieldWidget.required,
    constraints: formFieldWidget.constraints,
  });

  const initialState = isEditable ? 'enabled' : 'disabled';

  return createMachine(
    {
      context: () => ({
        inputNode,
        widget: formFieldWidget,
        value: stringValue,
        validationActor: spawn(makeValidationMachine({ validationSchema, initialValue: stringValue }), {
          name: 'validation-actor',
        }),
        rulesEngineTargetActor: spawn(
          makeRulesEngineTargetMachine({ type: 'widget', widgetHeaderGroupId: formFieldWidget.header.group.id }),
          { name: 'hidden-by-rule-actor', sync: true },
        ),
      }),
      tsTypes: {} as import('./number-form-field-machine.typegen').Typegen0,
      schema: {
        context: {} as Context,
        events: {} as Event,
        services: {} as {
          updateFormFieldValueMutation: {
            data: UpdateFormFieldValueMutationResponse;
          };
        },
      },
      predictableActionArguments: true,
      preserveActionOrder: true,
      id: `number-form-field-machine:${formFieldWidget.id}`,
      type: 'parallel',
      on: {
        SET_NODE: { actions: ['assignNode'] },
        SCROLL_INTO_VIEW: { actions: ['scrollIntoView'] },
      },
      states: {
        input: {
          initial: initialState,
          states: {
            disabled: {},
            enabled: {
              initial: 'idle',
              on: { FORM_COMPLETE: 'disabled' },
              states: {
                idle: { on: { FOCUS: 'focused' } },
                focused: {
                  on: {
                    BLUR: 'idle',
                    CHANGE: { actions: ['assignValue', 'sendRulesActorFormFieldValueUpdate'] },
                  },
                },
              },
            },
          },
        },

        autoFocus: {
          initial: autoFocus ? 'enabled' : 'disabled',
          states: { disabled: {}, enabled: {} },
        },

        mutation: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                UPDATE_VALUE: { target: 'debouncing', actions: ['sendDebouncedUpdateValue'] },
              },
            },
            debouncing: {
              on: {
                UPDATE_VALUE: {
                  actions: ['cancelDebouncedUpdateValue', 'sendDebouncedUpdateValue'],
                },
                DEBOUNCED_UPDATE_VALUE: 'updating',
              },
            },
            updating: {
              invoke: {
                src: 'updateFormFieldValueMutation',
                onDone: 'idle',
                onError: {
                  target: 'idle',
                  actions: ['showErrorToast'],
                },
              },
            },
          },
        },

        // This state is a kind of controller to forward events up and down
        // Since it is a parallel state, in can listen for events without blocking or getting blocked by nested states
        validation: {
          initial: 'enabled',
          states: {
            enabled: {
              on: {
                CHANGE: { actions: 'forwardToValidation' },
                REVEAL_INVALID: { actions: 'forwardToValidation' },
                BLUR: { actions: 'forwardToValidation' },
                VALID: { actions: 'sendParentValid' },
                INVALID: { actions: 'sendParentInvalid' },
                HIDE: { actions: 'sendParentValid', target: 'disabled' },
              },
            },
            disabled: {
              on: {
                REVEAL: { target: 'enabled', actions: 'restoreValidationWithParent' },
              },
            },
          },
        },
      },
    },
    {
      services: {
        updateFormFieldValueMutation: async (_, event) =>
          makeUpdateFormFieldValueMutation({
            queryClient: sharedContext.queryClient,
            body: { checklistRevisionId, widgetId: formFieldWidget.id, value: event.value ? String(event.value) : '' },
          }).execute(),
      },
      actions: {
        assignNode: assign({ inputNode: (_, event) => event.node }),
        assignValue: assign({
          value: (_, event) => event.value,
        }),
        showErrorToast: (_, event) => FormResponseErrorToasts.showFormFieldUpdateErrorToast(event.data as AxiosError),
        sendDebouncedUpdateValue: send(
          (_, event) => ({
            type: 'DEBOUNCED_UPDATE_VALUE',
            value: event.value,
          }),
          {
            delay: 500,
            id: 'debounced-update-value',
          },
        ),
        cancelDebouncedUpdateValue: cancel('debounced-update-value'),
        sendParentInvalid: sendParent({ type: 'INVALID_WIDGET', widgetId: formFieldWidget.id }),
        sendParentValid: sendParent({ type: 'VALID_WIDGET', widgetId: formFieldWidget.id }),
        forwardToValidation: forwardTo(ctx => ctx.validationActor) as ActionObject<Context, Event>,
        restoreValidationWithParent: sendParent((ctx, _evt) => {
          if (ctx.validationActor.getSnapshot()?.matches('valid')) {
            return { type: 'VALID_WIDGET', widgetId: formFieldWidget.id };
          }
          return { type: 'INVALID_WIDGET', widgetId: formFieldWidget.id };
        }),

        sendRulesActorFormFieldValueUpdate: (_ctx, event) =>
          sendRulesActorFormFieldValueUpdate({
            formFieldValue: { ...formFieldValue!, fieldValue: { value: event.value } },
            formFieldWidget,
          }),
        scrollIntoView: ctx => {
          ctx.inputNode?.scrollIntoView();
        },
      },
    },
  );
};

export type NumberFormFieldMachine = ReturnType<typeof makeNumberFormFieldMachine>;
export type NumberFormFieldActor = ActorRefFrom<NumberFormFieldMachine>;
