import * as React from 'react';
import { TemplateRevision } from '@process-street/subgrade/process';
import { Box, ButtonGroup, ButtonGroupProps, Icon, Tooltip } from 'components/design/next';
import { MiddleGroupButton } from './view-middle-button';
import { useNavigate } from '@process-street/adapters/navigation';
import { queryString } from '@process-street/subgrade/util';
import { useGetConsolidatedTemplatePermissionsQuery } from 'app/features/permissions/query-builder';
import { PermissionPopover } from './permission-popover';
import { useGetDraftTmplRevisionByTmplIdQuery } from 'app/features/template-revisions/query-builder';
import { AppModalName, AppModalQueryParam } from 'app/app.constants';

export type ViewMiddleButtonGroupProps = ButtonGroupProps & {
  templateRevision: TemplateRevision;
};

export enum MiddleGroupItems {
  Edit = 'Edit',
  ConditionalLogic = 'Logic',
  Automations = 'Automations',
  View = 'View',
}

export const ViewMiddleButtonGroupV2: React.FC<ViewMiddleButtonGroupProps> = ({ templateRevision, ...props }) => {
  const navigate = useNavigate();

  const draftTmplRevisionQuery = useGetDraftTmplRevisionByTmplIdQuery({ templateId: templateRevision.template.id });
  const draftExists = Boolean(draftTmplRevisionQuery.data);

  const templatePermissionsQuery = useGetConsolidatedTemplatePermissionsQuery(templateRevision.template.id);
  const hasPermission = Boolean(templatePermissionsQuery.data?.permissionMap.templateUpdate);

  const goToTemplateView = (modal?: AppModalName.ConditionalLogic | AppModalName.Automations) => {
    if (!hasPermission) return;

    navigate({
      pathname: 'templateV2',
      search: queryString.stringify({
        id: templateRevision.template.id,
        [AppModalQueryParam.Modal]: modal,
      }),
    });
  };

  return (
    <ButtonGroup spacing="2" {...props} alignItems="center">
      <PermissionPopover templateId={templateRevision.template.id} isDisabled={hasPermission}>
        <MiddleGroupButton isDisabled={!hasPermission} onClick={() => goToTemplateView(undefined)}>
          {MiddleGroupItems.Edit}
        </MiddleGroupButton>
      </PermissionPopover>
      <Icon icon="chevron-right" size="4" color="gray.500" />
      <PermissionPopover templateId={templateRevision.template.id} isDisabled={hasPermission}>
        <MiddleGroupButton isDisabled={!hasPermission} onClick={() => goToTemplateView(AppModalName.ConditionalLogic)}>
          {MiddleGroupItems.ConditionalLogic}
        </MiddleGroupButton>
      </PermissionPopover>
      <Icon icon="chevron-right" size="4" color="gray.500" />
      <PermissionPopover templateId={templateRevision.template.id} isDisabled={hasPermission}>
        <MiddleGroupButton isDisabled={!hasPermission} onClick={() => goToTemplateView(AppModalName.Automations)}>
          {MiddleGroupItems.Automations}
        </MiddleGroupButton>
      </PermissionPopover>
      <Icon icon="chevron-right" size="4" color="gray.500" />
      <MiddleGroupButton
        rightIcon={
          draftExists ? (
            <Tooltip label="Latest published version">
              <Box mb="-1px">
                <Icon icon="info-circle" size="3.5" />
              </Box>
            </Tooltip>
          ) : undefined
        }
        isActive
      >
        {MiddleGroupItems.View}
      </MiddleGroupButton>
    </ButtonGroup>
  );
};
