import { ChecklistTaskListComponents as Components } from '..';
import { Checklist } from '@process-street/subgrade/process';
import * as React from 'react';
import { HStack } from '@chakra-ui/react';
import { GetCoverIconByTemplateId } from 'app/features/cover-icon/query-builder';
import { ChecklistCoverIcon } from 'app/features/cover-icon/components/checklist';
import { Breadcrumbs } from 'app/components/breadcrumbs';
import { useCurrentUser } from 'app/hooks/use-current-user';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';
import { CoverImage } from './cover-image';
import { AutomationsIndicator } from './automations-indicator';
import { HeaderActionsMenu } from './header-actions-menu';

type TaskListTopControlsProps = {
  checklist: Checklist;
};

export function TaskListTopControls({ checklist }: TaskListTopControlsProps) {
  const templateId = checklist.template.id;
  const { data: coverIcon } = GetCoverIconByTemplateId.useQuery({ templateId });

  const user = useCurrentUser();
  const isAnonymous = user ? isAnonymousUser(user) : true;

  return (
    <>
      <CoverImage templateId={templateId} />
      <HStack justifyContent="space-between" w="full">
        {!isAnonymous && <Breadcrumbs templateId={templateId} shouldShowTemplateBreadcrumb />}
        <AutomationsIndicator checklist={checklist} />
      </HStack>
      <HStack justifyContent="flex-start" w="full">
        {coverIcon && <ChecklistCoverIcon flexShrink="0" w={10} h={10} mr={3} icon={coverIcon} />}
        <Components.ChecklistName checklist={checklist} />
        <HeaderActionsMenu />
      </HStack>
    </>
  );
}
