import { Muid, OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import {
  DueDateRuleDefinition,
  TaskTemplate,
  TemplateRevision,
  TaskTemplateTaskType,
  Widget,
  isFormFieldWidget,
} from '@process-street/subgrade/process';
import { TaskAssignmentRule } from '@process-street/subgrade/role-assignment';
import {
  Box,
  ButtonProps,
  Center,
  HStack,
  Icon,
  Input,
  Spinner,
  Text,
  useBreakpointValue,
} from 'components/design/next';
import { ReorderUtils } from 'app/pages/forms/_id/edit/components/widgets-list/reorder-utils';
import { useFormEditorPageActorRef } from 'app/pages/forms/_id/edit/form-editor-page-machine';
import { KeyStrings } from 'app/services/key';
import * as React from 'react';
import { TaskListItemAutomationIndicator } from '../task-list-item-automation-indicator';
import { TaskListItemConditionalLogicIndicator } from '../task-list-item-conditional-logic-indicator';
import { TaskListItemDynamicDueDateIndicator } from '../task-list-item-dynamic-due-date-indicator';
import { TaskListItemMenu } from '../task-list-item-menu';
import { TaskListItemAiTaskIndicator } from './task-list-item-ai-task-indicator';
import { TaskListItemAvatar } from './task-list-item-avatar';
import { useHighlightedTasksContext } from '../../providers/highlighted-tasks-context';
import { useSelector } from '@xstate/react';
import { StateFrom } from 'xstate';
import _isEqual from 'lodash/isEqual';
import { Option } from 'space-monad';
import { FormEditorPageMachine } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-types';
import { FormEditorPageActorSelectors } from 'app/pages/forms/_id/edit/form-editor-page-machine/form-editor-page-machine-selectors';
import { TaskConditionalLogicContextProvider } from './task-conditional-logic-context';

export type TaskListItemProps = {
  templateRevision: TemplateRevision;
  taskTemplate: TaskTemplate;
  isSelected: boolean;
  isLast: boolean;
  stepNumber: number;
  templateId: Muid;
  organizationMemberships: OrganizationMembershipWithUser[];
  assignmentRules: TaskAssignmentRule[];
  dueDateRuleDefinition?: DueDateRuleDefinition;
  isMultiSelecting?: boolean;
  isGenerating?: boolean; // Indicates whether or not the workflow is being generated
  isAnimating?: boolean; // Indicates whether or not the current task list item is being animated
  isReadOnly?: boolean;
  isTaskTemplateAssociatedWithRules: (args: { widgets: Array<Widget>; taskTemplate: TaskTemplate }) => boolean;
  onChange: (taskTemplateId: TaskTemplate['id'], taskTemplate: Partial<TaskTemplate>) => void;
  onDelete: (taskTemplateId: TaskTemplate['id']) => void;
  onDuplicate: (taskTemplateId: TaskTemplate['id']) => void;
  onAddNewTask: (at: number) => void;
  onSelect: (taskTemplate: TaskTemplate, e?: React.MouseEvent) => void;
  onNavigateBetweenTasks: (toStepNumber: number, event: React.KeyboardEvent<HTMLInputElement>) => void;
  onBulkAdd: (taskTemplateList: string[], currentTaskTemplate: TaskTemplate, currentStep: number) => void;
};

export const TaskListItem = React.memo((props: TaskListItemProps) => {
  const {
    templateRevision,
    taskTemplate,
    isSelected,
    templateId,
    stepNumber,
    organizationMemberships,
    assignmentRules,
    dueDateRuleDefinition,
    isGenerating,
    isAnimating,
    isMultiSelecting,
    isTaskTemplateAssociatedWithRules,
    isReadOnly = false,
    onChange,
    onAddNewTask,
    onDelete,
    onDuplicate,
    onSelect,
    onNavigateBetweenTasks,
    onBulkAdd,
  } = props;
  const [name, setName] = React.useState(taskTemplate.name);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const { highLightedTasksMap } = useHighlightedTasksContext();
  const shouldHighlight = Boolean(highLightedTasksMap && highLightedTasksMap[taskTemplate.group.id]);

  const actor = useFormEditorPageActorRef();

  const dueDateFormFieldSelector = React.useCallback(
    (state: StateFrom<FormEditorPageMachine>) => {
      return Option(dueDateRuleDefinition?.formFieldWidgetGroup?.id)
        .map(widgetGroupId => FormEditorPageActorSelectors.getWidgetByGroupId(widgetGroupId)(state))
        .filter(isFormFieldWidget)
        .get();
    },
    [dueDateRuleDefinition],
  );

  const dueDateTaskTemplateSelector = React.useCallback(
    (state: StateFrom<FormEditorPageMachine>) => {
      return Option(dueDateRuleDefinition?.taskTemplateGroup?.id)
        .map(groupId => FormEditorPageActorSelectors.getTaskTemplateByGroupId(groupId)(state))
        .get();
    },
    [dueDateRuleDefinition],
  );

  const dueDateFormField = useSelector(actor, dueDateFormFieldSelector, _isEqual);
  const dueDateTaskTemplate = useSelector(actor, dueDateTaskTemplateSelector, _isEqual);

  const buttonStyles = React.useMemo(() => createWrapperStyles(props, shouldHighlight), [props, shouldHighlight]);

  React.useEffect(() => {
    if (isSelected && !isMultiSelecting && !isGenerating && inputRef?.current) {
      setTimeout(() => {
        if (inputRef?.current) {
          inputRef.current.focus();
        }
      }, 0);
    }
  }, [isSelected, isMultiSelecting, isGenerating]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
    onChange(taskTemplate.id, {
      name: e.currentTarget.value,
    });
  };

  const handleNameBlur = (_: React.FocusEvent<HTMLInputElement>) => {
    if (taskTemplate.name !== name) {
      onChange(taskTemplate.id, { name });
    }
  };

  const handleSelect = (e: React.MouseEvent<HTMLParagraphElement>) => {
    onSelect(taskTemplate, e);
  };

  React.useEffect(
    function syncInternalName() {
      const isFocused = inputRef.current === document.activeElement;
      if (props.taskTemplate.name !== name && !isFocused) {
        setName(props.taskTemplate.name ?? '');
      }
    },
    // Removed `name` from the array, so we don't trigger this effect when internal state changes
    // eslint-disable-next-line
    [props.taskTemplate.name],
  );

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case KeyStrings.ARROW_UP:
        onNavigateBetweenTasks(stepNumber - 1, e);
        break;
      case KeyStrings.ARROW_DOWN:
        onNavigateBetweenTasks(stepNumber + 1, e);
        break;
      case KeyStrings.ENTER:
        onAddNewTask(stepNumber - 1);
        break;
      default:
        return;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case KeyStrings.BACKSPACE:
        if (!name) {
          e.preventDefault();
          onDelete(taskTemplate.id);
        }
        break;
      default:
        return;
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedText = event.clipboardData.getData('text');
    const [firstLine, ...linesArray] = pastedText.split(/\r?\n/);

    if (linesArray.length > 0) {
      event.preventDefault();
      const newName = (name ?? '').concat(firstLine);
      setName(newName); // Optimistic update
      onChange(taskTemplate.id, {
        name: newName,
      });
      onBulkAdd(linesArray, taskTemplate, stepNumber - 1);
    }
    // for single line pastes, default edit event will happen
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  const onSwitchSelectedTask = (taskTemplate: TaskTemplate) => {
    onSelect(taskTemplate, undefined);
  };

  const handlePointerDownCapture: React.MouseEventHandler = React.useCallback(
    e => {
      if (isReadOnly) {
        e.stopPropagation();
        return;
      }

      const target = e.target as HTMLElement;
      const isDragHandle = ReorderUtils.isTargetDragHandle(target);

      // allow reordering from verified handles (e.g., select dropdown choices)
      if (isDragHandle) return;
      e.stopPropagation();
    },
    [isReadOnly],
  );

  return (
    <HStack w="full" onPointerDownCapture={handlePointerDownCapture} spacing="0">
      <Box
        p={2}
        pl={0}
        pr={3}
        cursor={isReadOnly ? 'default' : 'grab'}
        onMouseDown={() => {
          if (isReadOnly) return;

          actor.send('REORDER_TASK_TEMPLATES_DRAG_START');
        }}
        onTouchStart={() => {
          if (isReadOnly) return;

          actor.send('REORDER_TASK_TEMPLATES_DRAG_START');
        }}
        {...ReorderUtils.HANDLE_PROPS}
      >
        <Center
          minW={5}
          w={5}
          h={5}
          bgColor={isSelected ? 'brand.500' : 'transparent'}
          borderRadius="full"
          role="group"
          _hover={isReadOnly ? {} : { bgColor: 'transparent' }}
          position="relative"
        >
          <Text
            fontSize="sm"
            fontWeight={isSelected ? '700' : '400'}
            color={isSelected ? 'white' : 'gray.400'}
            _groupHover={isReadOnly ? {} : { visibility: 'hidden' }}
            visibility="visible"
            position="absolute"
          >
            {taskTemplate.taskType === TaskTemplateTaskType.AI ? 'AI' : stepNumber}
          </Text>

          {!isReadOnly && (
            <Icon
              visibility="hidden"
              _groupHover={{ visibility: 'visible' }}
              color="gray.400"
              icon="grip-vertical"
              size="3.5"
              variant="fas"
              position="absolute"
            />
          )}

          {taskTemplate.stop && (
            <Box mt={8}>
              <Icon aria-label="Stop task" icon="hand-paper" size="3" color={isSelected ? 'brand.500' : 'gray.400'} />
            </Box>
          )}
        </Center>
      </Box>

      <Box {...buttonStyles} role="group">
        <HStack>
          {isSelected && !isReadOnly && !isMultiSelecting && !isGenerating && !isMobile ? (
            <Input
              name={taskTemplate.id}
              ref={inputRef}
              id={`task-template-${taskTemplate.id}`}
              w="full"
              flex="1"
              value={name}
              _focusVisible={{ borderColor: 'transparent', boxShadow: 'unset' }}
              border={0}
              px={3}
              py={2.5}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
              onChange={handleNameChange}
              onBlur={handleNameBlur}
              mt="-1px"
              placeholder="Type task name here"
              _placeholder={{ color: 'gray.400', fontWeight: 'normal' }}
              onPaste={handlePaste}
              autoFocus
            />
          ) : (
            <Text
              role="button"
              aria-label={taskTemplate.name}
              onClick={handleSelect}
              flex="1"
              w="full"
              h={10}
              pl={3}
              lineHeight="40px"
              mt="-1px"
              textAlign="left"
              fontSize="md"
              noOfLines={1}
            >
              {isGenerating ? taskTemplate.name : name}
            </Text>
          )}

          {isAnimating && <Spinner />}

          <HStack h={10} mt="-1px" spacing={1}>
            <TaskConditionalLogicContextProvider
              isTaskTemplateAssociatedWithRules={isTaskTemplateAssociatedWithRules}
              taskTemplate={taskTemplate}
            >
              <>
                {!isReadOnly && (
                  <TaskListItemMenu
                    taskTemplate={taskTemplate}
                    templateRevision={templateRevision}
                    isSelected={isSelected}
                    onDelete={() => onDelete(taskTemplate.id)}
                    onDuplicate={() => onDuplicate(taskTemplate.id)}
                    onAddStopTask={onChange}
                    switchSelectedTask={onSwitchSelectedTask}
                  />
                )}

                <TaskListItemConditionalLogicIndicator />
              </>
            </TaskConditionalLogicContextProvider>

            {dueDateRuleDefinition && (
              <TaskListItemDynamicDueDateIndicator
                ruleDefinition={dueDateRuleDefinition}
                formFieldWidget={dueDateFormField}
                taskTemplate={dueDateTaskTemplate}
              />
            )}

            {taskTemplate.taskType === TaskTemplateTaskType.AI && <TaskListItemAiTaskIndicator />}

            <TaskListItemAutomationIndicator
              templateRevision={templateRevision}
              taskTemplate={taskTemplate}
              editable={!isReadOnly}
              templateId={templateId}
            />

            <TaskListItemAvatar
              taskTemplate={taskTemplate}
              templateRevision={templateRevision}
              taskAssignmentRules={assignmentRules}
              organizationMemberships={organizationMemberships}
              isEditable={!isReadOnly}
            />
          </HStack>
        </HStack>
      </Box>
    </HStack>
  );
});

TaskListItem.displayName = 'TaskListItem';

const createWrapperStyles = (
  { isSelected, taskTemplate, stepNumber, isLast }: TaskListItemProps,
  shouldHighlight: boolean,
): Partial<ButtonProps> => {
  const isFirst = stepNumber === 1;
  return {
    'w': 'full',
    'justifyContent': 'flex-start',
    'fontWeight': isSelected ? '600' : taskTemplate.name?.endsWith(':') ? '700' : '400',
    'noOfLines': 1,
    'size': 'sm',
    'borderStyle': 'solid',
    'borderWidth': 'thin',
    'borderColor': isSelected ? 'brand.500' : 'gray.200',
    'borderRadius': 0,
    'h': 10,
    'pr': 1,
    'color': isSelected ? 'brand.600' : 'gray.600',
    'borderTopLeftRadius': isFirst ? '8px' : undefined,
    'borderTopRightRadius': isFirst ? '8px' : undefined,
    'borderBottomLeftRadius': isLast ? '8px' : undefined,
    'borderBottomRightRadius': isLast ? '8px' : undefined,
    'borderTopColor': isSelected || isFirst ? undefined : 'transparent',
    'aria-selected': isSelected,
    'bgColor': shouldHighlight ? 'brand.200' : 'white',
  };
};
