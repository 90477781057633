import * as React from 'react';
import { Form, Formik } from 'formik';
import { VStack } from 'components/design/next';
import { FieldType, FormFieldWidgetOfType } from '@process-street/subgrade/process';
import { ObjectSchema } from 'yup';
import { WidgetSettings } from 'pages/forms/_id/edit/components/form-fields/common/settings/widget-settings';
import { match } from 'ts-pattern';

export type SettingsInlineContentProps<Type extends FieldType> = React.PropsWithChildren<{
  widget: FormFieldWidgetOfType<Type>;
  schema: ObjectSchema<WidgetSettings<Type>>;
  onUpdate: (widget: FormFieldWidgetOfType<Type>) => void;
  onClose: () => void;
}>;

const CONSTRAINTS_TO_CONVERT_TO_UNDEFINED = ['restriction', 'decimalPlaces'] as const;
const CONFIGS_TO_CONVERT_TO_UNDEFINED = ['unit'] as const;

export const SettingsInlineContent = React.memo(function SettingsInlineContent<Type extends FieldType>({
  widget,
  schema,
  onUpdate,
  onClose,
  children,
}: SettingsInlineContentProps<Type>) {
  const initialValues = {
    config: widget.config,
    constraints: widget.constraints,
  } as WidgetSettings<Type>;

  const handleSubmit = (values: WidgetSettings<Type>) => {
    const { config, constraints } = values;

    onUpdate({
      ...widget,
      config: CONFIGS_TO_CONVERT_TO_UNDEFINED.reduce((acc, key) => {
        return match({ key, acc })
          .with({ key: 'unit', acc: { unit: '' } }, () => ({ ...acc, unit: undefined, unitLocation: undefined }))
          .otherwise(() => acc);
      }, config as WidgetSettings<FieldType>['config']),

      // Using FieldType instead of the generic for better pattern matching
      // https://github.com/gvergnaud/ts-pattern/issues/64
      constraints: CONSTRAINTS_TO_CONVERT_TO_UNDEFINED.reduce((acc, key) => {
        return match({ key, acc })
          .with({ key: 'restriction', acc: { restriction: '' } }, () => ({ ...acc, restriction: undefined }))
          .with({ key: 'decimalPlaces', acc: { decimalPlaces: '' } }, () => ({ ...acc, decimalPlaces: undefined }))
          .otherwise(() => acc);
      }, constraints as WidgetSettings<FieldType>['constraints']),
    });
    onClose();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
      {() => (
        <VStack as={Form} w="full" spacing={4}>
          {children}
        </VStack>
      )}
    </Formik>
  );
}) as <Type extends FieldType>(props: SettingsInlineContentProps<Type>) => JSX.Element;
