import { components, GroupBase, SelectComponentsConfig } from 'react-select';
import { HStack, ThemeTypings } from '@chakra-ui/react';
import { Checkbox, Icon, Text } from 'components/design/next';
import * as React from 'react';
import { BlvdSelect, BlvdSelectHelpers } from 'components/design/BlvdSelect';
import { InboxItemType } from '@process-street/subgrade/inbox';
import { match } from 'ts-pattern';
import { useInboxDropdownStyles } from '../use-inbox-dropdown-styles';
import { DropdownIndicator } from 'pages/tasks/components/filter-bar/selector-common-components';
import { useFeatureFlag } from 'features/feature-flags';
import { CustomIconName } from 'components/design/next/icon/icon-name';

interface OptionData {
  leftIcon: CustomIconName;
  value: InboxItemType;
  label: string;
  primaryLayer?: {
    opacity?: number;
    color?: ThemeTypings['colors'];
  };
  secondaryLayer?: {
    opacity?: number;
    color?: ThemeTypings['colors'];
  };
}

const selectOptions: OptionData[] = [
  {
    value: InboxItemType.StandardTask,
    label: 'Workflow Tasks',
    leftIcon: 'square-check',
  },
  {
    value: InboxItemType.Checklist,
    label: 'Workflow Runs',
    leftIcon: 'table-list',
  },
  {
    value: InboxItemType.ApprovalTask,
    label: 'Approvals',
    leftIcon: 'thumbs-up',
  },
  {
    value: InboxItemType.OneOffTask,
    label: 'Tasks',
    leftIcon: 'circle-check',
  },
];

const selectOptionsUpdated: OptionData[] = [
  {
    value: InboxItemType.StandardTask,
    label: 'Workflow Tasks',
    leftIcon: 'square-check',
    primaryLayer: { color: 'purple.500' },
    secondaryLayer: { color: 'purple.300' },
  },
  {
    value: InboxItemType.Checklist,
    label: 'Workflow Runs',
    leftIcon: 'circle-play',
    primaryLayer: { color: 'purple.500' },
    secondaryLayer: { color: 'purple.300' },
  },
  {
    value: InboxItemType.ApprovalTask,
    label: 'Approvals',
    leftIcon: 'thumbs-up',
    primaryLayer: { color: 'purple.500' },
    secondaryLayer: { color: 'purple.300' },
  },
  {
    value: InboxItemType.OneOffTask,
    label: 'Tasks',
    leftIcon: 'circle-check',
    primaryLayer: {
      opacity: 1,
      color: 'teal.500',
    },
    secondaryLayer: {
      opacity: 0.4,
      color: 'teal.300',
    },
  },
];

export interface ItemTypeSelectorProps {
  itemTypes?: InboxItemType[];
  onChange: (value?: InboxItemType[]) => void;
}

export const ItemTypeSelector = (props: ItemTypeSelectorProps) => {
  const styles = useInboxDropdownStyles<OptionData>();
  const isMyWorkGAEnabled = useFeatureFlag('myWorkGA');

  return (
    <BlvdSelect
      placeholder="All types"
      options={isMyWorkGAEnabled ? selectOptionsUpdated : selectOptions}
      isMulti
      menuControls={true}
      components={COMPONENTS}
      styles={styles}
      value={selectOptions.filter(v => props.itemTypes?.includes(v.value))}
      onChange={value => {
        if (BlvdSelectHelpers.isOptionsType<OptionData>(value)) {
          props.onChange(value.map(v => v.value));
        } else {
          props.onChange();
        }
      }}
    />
  );
};

const COMPONENTS: SelectComponentsConfig<OptionData, true, GroupBase<OptionData>> = {
  Option: props => {
    const { data } = props;
    const isMyWorkGAEnabled = useFeatureFlag('myWorkGA');
    return (
      <components.Option {...props}>
        <HStack px={2} py={1} alignItems="center">
          <Checkbox isChecked={props.isSelected} color="unset" pointerEvents="none" mr={2} />
          {isMyWorkGAEnabled ? (
            <Icon
              icon={data.leftIcon}
              variant="fad"
              size="4"
              ml="var(--ps-space-1) !important"
              mr="var(--ps-space-2) !important"
              primaryLayer={data.primaryLayer}
              secondaryLayer={data.secondaryLayer}
            />
          ) : (
            <Icon icon={data.leftIcon} variant="far" color="gray.500" size="4" mr={1} />
          )}

          <Text color="gray.700">{props.data.label}</Text>
        </HStack>
      </components.Option>
    );
  },
  // don't display default multi value selection at all
  MultiValue: () => null,
  ValueContainer: props => {
    const value = props.getValue();
    const selectedValuesCount = value.length;

    const contents = match(selectedValuesCount)
      // Show placeholder
      .with(0, () => null)
      .with(1, () => <Text color="gray.600">{value[0].label}</Text>)
      .with(selectOptions.length, () => <Text color="gray.600">All types</Text>)
      .otherwise(() => {
        const icons = value.map(v => <Icon color="gray.500" icon={v.leftIcon} variant="far" size="4" />);
        return (
          <HStack spacing={2}>
            {icons}
            <Text color="gray.600">{selectedValuesCount} types</Text>
          </HStack>
        );
      });

    return (
      <components.ValueContainer {...props}>
        {contents}
        {props.children}
      </components.ValueContainer>
    );
  },
  DropdownIndicator,
};
