import { SolutionTypeTag } from '@process-street/subgrade/automation';
import { useFeatureFlag } from 'app/features/feature-flags';
import { useAutomationSelector } from 'app/pages/templates/_id/automation/components/selector/context';
import { Icon, IconButton, IconButtonProps, Tooltip } from 'components/design/next';
import * as React from 'react';

export type TaskActionsAutomationsButtonProps = {
  isEditable: boolean;
  iconButtonProps?: Partial<IconButtonProps>;
};

export const TaskActionsAutomationsButton = ({ isEditable, iconButtonProps }: TaskActionsAutomationsButtonProps) => {
  const [_, send] = useAutomationSelector();

  const handleClick = () => {
    send({
      type: 'SOLUTION_TYPE_SELECTED',
      payload: { solutionTypeTag: SolutionTypeTag.WhenTaskCheckedThen, modalView: 'task' },
    });
  };
  const isReactWFEditorEnabled = useFeatureFlag('reactWorkflowEditor');
  return (
    <Tooltip hasArrow shouldWrapChildren label="Automations">
      <IconButton
        {...{
          'size': 'sm',
          'aria-label': 'Automations',
          'colorScheme': 'gray',
          'onClick': handleClick,
          ...iconButtonProps,
          ...(isEditable
            ? {
                variant: 'outline',
                icon: (
                  <Icon
                    icon={isReactWFEditorEnabled ? 'bolt-lightning' : 'bolt'}
                    variant="far"
                    size="4"
                    color="gray.400"
                  />
                ),
              }
            : {
                variant: 'ghost',
                isDisabled: true,
                pointerEvents: 'none',
              }),
        }}
      />
    </Tooltip>
  );
};
