import { comparator, sortWith } from '@process-street/subgrade/core';
import { InboxItem, InboxItemType, InboxItemUtils } from '@process-street/subgrade/inbox';
import { OrderTreeUtils } from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';

export function sortInboxItems(items: InboxItem[]): InboxItem[] {
  return sortWith(items, [byDueDate, byCreatedDate, byId, byOrderTree]);
}

const byDueDate = comparator<InboxItem>((a, b) => {
  const aDueDate = getDueDate(a);
  const bDueDate = getDueDate(b);
  return Boolean(aDueDate) && (aDueDate < bDueDate || !bDueDate);
});

function getDueDate(item: InboxItem): number {
  return match(item)
    .with(
      { itemType: InboxItemType.Checklist, checklist: { dueDate: P.number } },
      ({ checklist: { dueDate } }) => dueDate,
    )
    .with({ task: { dueDate: P.number } }, ({ task: { dueDate } }) => dueDate)
    .otherwise(() => 0);
}

const byCreatedDate = comparator<InboxItem>((a, b) => {
  return getCreatedDate(a) < getCreatedDate(b);
});

function getCreatedDate(item: InboxItem): number {
  return match(item)
    .with({ checklist: { audit: { createdDate: P.number } } }, ({ checklist }) => checklist.audit.createdDate)
    .otherwise(() => 0);
}

const byId = comparator<InboxItem>((a, b) => getId(a) < getId(b));

function getId(item: InboxItem): string {
  return match(item)
    .with({ checklist: { id: P.string } }, ({ checklist }) => checklist.id)
    .otherwise(() => '');
}

const byOrderTree = comparator<InboxItem>((a, b) => {
  if (!InboxItemUtils.isTask(a) || !InboxItemUtils.isTask(b)) return false;

  const orderTreeA = getOrderTree(a);
  const orderTreeB = getOrderTree(b);
  const result = OrderTreeUtils.compare(orderTreeA, orderTreeB);

  return result < 0;
});

function getOrderTree(item: InboxItem): string {
  return match(item)
    .with({ task: { taskTemplate: { orderTree: P.string } } }, ({ task }) => task.taskTemplate.orderTree)
    .otherwise(() => '');
}
