import angular from 'angular';
import { SessionSelector } from 'reducers/session/session.selectors';
import { TemplateSelector } from 'reducers/template/template.selectors';
import { connectController } from 'reducers/util';
import { FeatureFlagSelector } from 'services/features/feature-flags/store/feature-flags.selectors';
import { highlightUnknownMergeTags } from 'services/merge-tags/utils/inject-merge-tag-labels';
import templateUrl from './checklist.component.html';
import './checklist.scss';

angular.module('frontStreetApp.directives').component('psChecklistInboxItem', {
  bindings: {
    user: '<',
    item: '<',
    permissionMap: '<',
  },
  require: {
    inboxListCtrl: '^psInboxList',
  },
  templateUrl,
  controller(InboxService, $ngRedux, FolderActions) {
    const ctrl = this;

    ctrl.$onInit = () => {
      const mapStateToThis = () => state => ({
        currentOrganization: SessionSelector.getSelectedOrganization(state),
        templateIsPrivate: TemplateSelector.isTemplatePrivate(state)(ctrl.item.template?.id),
        isMergeTagImprovementsEnabled: FeatureFlagSelector.getFeatureFlags(state).mergeTagImprovements,
      });

      const mapDispatchToThis = () => ({
        getAllFolders: FolderActions.getAllByOrganizationId,
      });

      connectController($ngRedux, mapStateToThis, mapDispatchToThis)(ctrl);

      // if user is in inbox, folders may not have been fetched
      ctrl.actions.getAllFolders(ctrl.state.currentOrganization.id);
    };

    ctrl.resolveDueDate = () => InboxService.resolveDueDate(ctrl.item.checklist.dueDate);

    ctrl.isOverdue = () => InboxService.isOverdue(ctrl.item.checklist.dueDate);

    ctrl.getOverdueClassName = () => InboxService.getOverdueClassName(ctrl.item.checklist.dueDate);

    ctrl.shouldShowDoodads = function () {
      return !!ctrl.item.totalDoodads;
    };

    ctrl.isSelected = function () {
      return !!ctrl.item.selected;
    };

    ctrl.toggleSelect = function () {
      ctrl.inboxListCtrl.toggleItemSelection(ctrl.item);
    };

    ctrl.shouldOpenSelection = function () {
      return ctrl.inboxListCtrl.countSelected() > 0;
    };

    ctrl.getStateType = InboxService.convertItemTypeToPathStateType;

    ctrl.getChecklistName = () => {
      const checklistName = ctrl.item.checklist.name ?? 'Unnamed';

      if (ctrl.state.isMergeTagImprovementsEnabled) {
        return highlightUnknownMergeTags(checklistName);
      }

      return checklistName;
    };
  },
});
