// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("./images/icon-checklist.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"panel panel-default checklist-inbox-item\"> <div class=\"panel-body\"> <div class=\"item-body\"> <div class=\"item-icon hidden-xs\" data-ng-class=\"{'selection-open': $ctrl.shouldOpenSelection()}\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" class=\"icon\"/> <span data-ng-click=\"$ctrl.toggleSelect()\" data-ng-class=\"{ 'checked': $ctrl.isSelected() }\" class=\"item-checkbox\"> <i data-ng-if=\"$ctrl.isSelected()\" class=\"far fa-check item-checkbox-checkmark\"></i> </span> </div> <div class=\"item-info\"> <span class=\"checklist-name truncate\" data-ng-bind-html=\"$ctrl.getChecklistName()\"></span> <div class=\"template-name text-more-muted truncate\"> <span data-ng-bind=\"$ctrl.item.template.name\"></span> <span data-ng-if=\"$ctrl.state.templateIsPrivate\">(Private)</span> </div> </div> <div class=\"item-tools\"> <div class=\"badge badge-md badge-light\" data-ng-if=\"$ctrl.shouldShowDoodads()\"> <i class=\"far fa-comment\" aria-hidden=\"true\"></i> <span data-ng-bind=\"$ctrl.item.totalDoodads\"></span> </div> <div data-ng-if=\"$ctrl.item.checklist.dueDate\" class=\"due-date badge badge-md badge-light\" data-ng-class=\"$ctrl.getOverdueClassName()\"> <span data-ng-bind=\"$ctrl.resolveDueDate()\"></span> </div> <div class=\"assignees\"> <ps-inbox-item-assignments data-users=\"$ctrl.item.assignees\"></ps-inbox-item-assignments> </div> </div> <ps-inbox-item-controls data-user=\"$ctrl.user\" data-item=\"$ctrl.item\" data-permission-map=\"$ctrl.permissionMap\"></ps-inbox-item-controls> <a class=\"item-link\" data-ui-sref=\"inbox.item({ type: $ctrl.getStateType($ctrl.item.itemType), itemId: $ctrl.item.checklist.id })\"></a> </div> </div> </div> ";
// Exports
var _module_exports = code;;
var path = '/directives/inbox/item/checklist/checklist.component.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;