import * as React from 'react';
import {
  ModalContent,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  ModalCloseButton,
} from 'components/design/next';
import { useFormEditorPageActorRef } from './form-editor-page-machine';
import { TemplateConstants } from 'services/template-constants';

export const NewFormNameModalContent = () => {
  const actor = useFormEditorPageActorRef();
  const [formName, setFormName] = React.useState(TemplateConstants.BLANK_FORM_NAME);

  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalBody pt="8">
        <FormControl>
          <FormLabel fontSize="18px">Form Name</FormLabel>
          <Input
            value={formName}
            onChange={e => {
              setFormName(e.target.value);
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                actor.send({ type: 'CONTINUE', formName });
              }
            }}
            autoFocus
          />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => actor.send({ type: 'CONTINUE', formName })} size="lg" isDisabled={!formName}>
          Continue
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
