import angular from 'angular';
import { OrganizationMembershipRole } from '@process-street/subgrade/core/organization-model';
import { HttpStatus } from '@process-street/subgrade/util';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { industries } from 'app/industries';
import { trace } from 'components/trace';
import { LocalStorageService } from 'features/storage/local-storage-service';
import { SelectorHelper } from 'directives/rules/template/task-templates-selector/selector-helper';
import { ToastServiceImpl } from 'services/toast-service.impl';

angular
  .module('frontStreetApp.controllers')
  .controller(
    'CompleteCtrl',
    function (
      $q,
      $sanitize,
      $scope,
      $state,
      $timeout,
      $window,
      appBootService,
      Auth0Service,
      focusById,
      InvitationService,
      SessionService,
      SignUpService,
      ToastService,
    ) {
      const ctrl = this;
      const logger = trace({ name: 'CompleteCtrl' });
      logger.info('loading ctrl');

      ctrl.$onInit = () => {
        [, $scope.from] = $state.current.name.split('.');

        $scope.industries = industries;

        $scope.info = {};
        $scope.invitedAsGuest = false;
        $scope.showSteps = false;
        $scope.invitationOrganization = undefined;
        $scope.loading = true;
        $scope.newSignUpVisible = false;

        if ($scope.from === 'user' && $state.params.email) {
          $scope.info.email = $state.params.email;
          $scope.showSteps = true;
          $scope.loading = false;
          focusById('password');
        } else if ($scope.from === 'invitation' && $state.params.id) {
          InvitationService.getById($state.params.id)
            .then(invitation => {
              $scope.info.email = invitation.inviteeUser.email;
              $scope.info.username = invitation.inviteeUser.username;
              $scope.info.organizationName = invitation.organization.name;
              $scope.info.role = invitation.role;
              $scope.invitedAsGuest = invitation.role === OrganizationMembershipRole.Guest;
              $scope.showSteps = !$scope.invitedAsGuest;
              $scope.invitationOrganization = invitation.organization;
              $scope.newSignUpVisible = $scope.invitedAsGuest;
              $scope.loading = false;

              focusById('password');
            })
            .catch(response => {
              if (response.status === HttpStatus.FORBIDDEN) {
                ToastService.openToast({
                  status: 'error',
                  title: `The invitation has expired`,
                  description: 'Please reach out to the sender to request another invite.',
                });
              } else {
                logger.error('Failed to load the invitation.');
                ToastService.openToast({
                  status: 'error',
                  title: `We're having problems completing your sign up`,
                  description: DefaultErrorMessages.unexpectedErrorDescription,
                });
              }
              $state.go('login');
            });
        } else {
          $state.go('login');
        }
      };

      $scope.doCompleteAndLogin = info => {
        $scope.submitting = true;
        return SignUpService.complete(info)
          .then(() => {
            $scope.trackConversion();

            const url = $scope.invitedAsGuest ? $state.href('inbox') : $state.href('inviteMembers');
            LocalStorageService.setItem('url', url);

            Auth0Service.login(info.email, info.password, errorMessage => {
              $timeout(() => {
                ToastServiceImpl.openToast({
                  status: 'error',
                  title: errorMessage,
                });
                $scope.submitting = false;
              });
            });
          })
          .catch(response => {
            $scope.submitting = false;
            return $q.reject(response);
          });
      };

      $scope.sanitize = field => {
        $scope.info[field] = SelectorHelper.stripHtml($sanitize($scope.info[field]));
      };

      $scope.forms = {};
      $scope.completeAndLogin = function (info) {
        if (!$scope.forms.form.$invalid) {
          $scope.doCompleteAndLogin(info).catch(() => {
            // This is needed for AngularJS
            // We don't need to handle it because it deals with displaying errors within the function
          });
        } else {
          const messages = [];

          if ($scope.forms.form.password.$error.required) {
            messages.push('You must enter a password.');
          }

          if ($scope.forms.form.username.$error.required) {
            messages.push('You must enter your name.');
          }

          const message = messages.join('<br>');
          ToastServiceImpl.openToast({
            status: 'error',
            title: message,
          });
        }
      };

      $scope.validate = function (info) {
        return info.email;
      };

      $scope.conversionTracked = false;
      $scope.trackConversion = () => {
        if ($scope.conversionTracked) {
          return;
        }

        $scope.conversionTracked = true;

        $scope.trackingListener();
      };

      // capterra tracker
      $scope.trackingListener = () => {
        const capterraVkey = 'f051327558c024e566aee1646ca2ab5e';
        const capterraVid = '2095034';
        const capterraPrefix = 'https://ct.capterra.com';

        const ct = $window.document.createElement('script');
        ct.type = 'text/javascript';
        ct.async = true;
        ct.src = capterraPrefix + '/capterra_tracker.js?vid=' + capterraVid + '&vkey=' + capterraVkey;

        const s = $window.document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(ct, s);
      };
    },
  );
