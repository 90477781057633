import * as React from 'react';
import { useActor } from '@xstate/react';
import { SelectFormFieldActor } from './select-form-field-machine';
import { Box, HStack, IconButton, Link, Text, VStack } from 'components/design/next';
import { Icon } from 'components/design/next';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { BlvdSelect } from 'components/design/BlvdSelect';
import { Reorder } from 'framer-motion';
import {
  isMultiChoiceFormFieldWidget,
  SelectFormFieldConfigItem,
  SelectFormFieldWidget as SelectFormFieldWidgetModel,
  TaskTemplate,
  TemplateType,
} from '@process-street/subgrade/process';
import { SelectItem } from './select-item';
import { FormFieldLabel } from '../common/form-field-label';
import { WidgetActorProvider } from '../../../../shared/widget-context';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { FormFieldRecentlyMovedIndicator } from '../common/form-field-recently-moved-indicator';
import { useFeatureFlag } from 'app/features/feature-flags';
import { LinkedDataDisplay } from './linked-data-display';
import { useInjector } from 'app/components/injection-provider';
import { ViewModeInteractionWrapper } from '../../view-mode-interaction-wrapper/view-mode-interaction-wrapper';
import { AiTaskTemplateWidgetIndicatorV2 } from 'app/pages/templates/_id/components/ai-task-template-widget-indicator';

export type SelectFormFieldWidgetProps = {
  isFirst: boolean;
  isLast: boolean;
  actor: SelectFormFieldActor;
};

export const SelectFormFieldWidget: React.FC<React.PropsWithChildren<SelectFormFieldWidgetProps>> = ({
  actor,
  isFirst,
  isLast,
}) => {
  const [state, send] = useActor(actor);
  const { widget, template, labelActor, itemActorRefs, recentlyMovedFrom, isReadOnly } = state.context;
  const { $state } = useInjector('$state');
  const isMulti = isMultiChoiceFormFieldWidget(widget);
  const ref = React.useRef<HTMLDivElement | null>(null);
  const isDataSetsV2Enabled = useFeatureFlag('dataSetsV2');

  const isWorkflow = template.templateType === TemplateType.Playbook;

  const handleSelectTaskTemplate = (taskTemplate: TaskTemplate) => send({ type: 'SELECT_TASK_TEMPLATE', taskTemplate });

  if (!widget) return null;
  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer>
        <VStack
          alignItems="flex-start"
          flex="1"
          ref={node => {
            ref.current = node;
            if (node && !state.context.inputNode) {
              send({ type: 'SET_NODE', node });
            }
          }}
          scrollMarginBottom={17}
        >
          {recentlyMovedFrom && <FormFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
          {labelActor && <FormFieldLabel isReadOnly={isReadOnly} actor={labelActor} />}

          <ViewModeInteractionWrapper templateId={template.id}>
            <VStack
              shouldWrapChildren
              bg="gray.50"
              position="relative"
              spacing="0"
              w="full"
              alignItems="stretch"
              sx={{
                '.blvd-select__control--is-disabled': {
                  background: 'var(--ps-colors-gray-50)',
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  borderColor: 'var(--ps-colors-gray-200)',
                },
                '.blvd-select__indicator-separator': {
                  display: 'none',
                },
                '.blvd-select__indicator': {
                  transform: 'rotate(180deg)',
                },
                '.blvd-select__placeholder': {
                  color: 'var(--ps-colors-gray-400) !important',
                },
              }}
            >
              <BlvdSelect placeholder={isMulti ? 'Multi Choice' : 'Dropdown'} isDisabled />
              {widget.config.linkId ? (
                <VStack
                  spacing="3"
                  w="full"
                  alignItems="stretch"
                  border="1px solid"
                  borderColor="gray.200"
                  borderTopWidth="0"
                  borderBottomLeftRadius="lg"
                  borderBottomRightRadius="lg"
                  px="3"
                  py="4"
                  sx={{ listStyle: 'none' }}
                  maxH="248px" // 4 1/2 items
                  overflowY="auto"
                >
                  <LinkedDataDisplay widget={widget as SelectFormFieldWidgetModel} />
                </VStack>
              ) : (
                <VStack
                  as={Reorder.Group}
                  axis="y"
                  spacing="3"
                  w="full"
                  alignItems="stretch"
                  border="1px solid"
                  borderColor="gray.200"
                  borderTopWidth="0"
                  borderBottomLeftRadius="lg"
                  borderBottomRightRadius="lg"
                  px="3"
                  py="4"
                  sx={{ listStyle: 'none' }}
                  values={widget?.config.items ?? []}
                  onReorder={(items: SelectFormFieldConfigItem[]) => {
                    send({ type: 'ITEM_REORDER', items });
                  }}
                  maxH="248px" // 4 1/2 items
                  layoutScroll
                  overflowY="auto"
                >
                  {widget?.config.items.map((item, index) => (
                    <Box as={Reorder.Item} key={item.id} id={item.id} value={item}>
                      <SelectItem index={index} actor={itemActorRefs[item.id]} isReadOnly={isReadOnly} />
                    </Box>
                  ))}

                  {!isReadOnly && (
                    <HStack justifyContent="center">
                      <IconButton
                        mx="auto"
                        variant="outline"
                        borderWidth="thin"
                        borderColor="gray.300"
                        aria-label="Add"
                        size="sm"
                        onClick={() => send({ type: 'ITEM_APPEND' })}
                        bg="white"
                      >
                        <Icon icon="plus" size="3" color="gray.600" />
                      </IconButton>
                    </HStack>
                  )}
                </VStack>
              )}

              {!isReadOnly && (
                <>
                  <WidgetListItemDragIcon />

                  <FormsWidgetMenu>
                    <FormsWidgetMenuItems.Required widget={widget} />
                    <FormsWidgetMenuItems.ConditionalLogic
                      widget={widget}
                      templateRevisionId={widget.templateRevision.id}
                      templateType={template.templateType}
                    />
                    {isDataSetsV2Enabled && <FormsWidgetMenuItems.ConnectDataSet widgetHeaderId={widget.header.id} />}

                    <FormsWidgetMenuItems.Divider />

                    <FormsWidgetMenuItems.Duplicate />
                    <FormsWidgetMenuItems.MoveToStep widget={widget} />
                    <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
                    <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
                    <FormsWidgetMenuItems.Delete />
                  </FormsWidgetMenu>
                </>
              )}
            </VStack>
          </ViewModeInteractionWrapper>
          {widget.config.linkId && (
            <HStack spacing={4} mt={2}>
              <Box borderColor="gray.500" borderStyle="solid" borderWidth="1px" borderRadius="md" p={3}>
                <Icon icon="arrows-rotate" size="4" color="gray.500" />
              </Box>
              <VStack alignItems="flex-start">
                <Link
                  href={$state.href('dataSets.dataSet', { dataSetId: widget.config.linkedDataSetId })}
                  color="brand.500"
                  isExternal
                >
                  {widget.config.linkedSavedViewName}
                </Link>
                <HStack spacing={1} alignItems="flex-start" justifyContent="center" color="gray.500">
                  <Icon icon="database" size="4" mt={1} />
                  <Text as="span" fontSize="md">
                    {widget.config.linkedDataSetName}
                  </Text>
                </HStack>
              </VStack>
            </HStack>
          )}
          {isWorkflow && (
            <AiTaskTemplateWidgetIndicatorV2 widget={widget} handleOnSelectTaskTemplate={handleSelectTaskTemplate} />
          )}
        </VStack>
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};
