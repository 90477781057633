import * as React from 'react';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  Icon,
  IconButton,
  useBreakpointValue,
  useDisclosure,
} from 'app/components/design/next';
import { TOP_BAR_HEIGHT_CSS_VAR } from 'app/pages/forms/_id/shared';
import { TaskList } from '../task-list';
import { FormResponsePageMachineHooks } from 'app/pages/responses/_id/form-response-page-hooks';
import { ChecklistUtils } from '@process-street/subgrade/process';

const PROGRESS_BAR_HEIGHT_IN_PX = '5px';
const STATUS_BAR_HEIGHT_IN_PX = '64px';

export const TaskListSection = React.memo(() => {
  const isDesktop = useBreakpointValue({ base: false, md: true }, { ssr: false });
  const drawerDisclosure = useDisclosure();
  const checklist = FormResponsePageMachineHooks.useChecklist();

  const checklistTopBarHeightInPx = React.useMemo(() => {
    if (checklist && (ChecklistUtils.isArchived(checklist) || ChecklistUtils.isCompleted(checklist))) {
      return STATUS_BAR_HEIGHT_IN_PX;
    } else {
      return PROGRESS_BAR_HEIGHT_IN_PX;
    }
  }, [checklist]);

  React.useEffect(
    function toggleDisclosureBasedOnScreenSize() {
      if (isDesktop) {
        drawerDisclosure.onOpen();
      } else {
        drawerDisclosure.onClose();
      }
    },
    // We don't want this effect to run when the disclosure changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDesktop],
  );

  return (
    <>
      {!isDesktop && (
        <IconButton
          variant="tertiary"
          borderRightRadius={0}
          icon={<Icon icon="table-list" variant="far" size="4" />}
          aria-label="open task list menu"
          position="fixed"
          mt={1}
          left={-1}
          onClick={drawerDisclosure.onOpen}
          zIndex={1}
        />
      )}

      <Drawer
        variant="aside"
        placement="left"
        trapFocus={false}
        blockScrollOnMount={false}
        closeOnEsc={false}
        {...drawerDisclosure}
      >
        <DrawerContent
          maxW={{ base: 'full', md: '480px' }}
          bottom={0}
          bgColor={isDesktop ? 'rgba(255, 255, 255, 0.84)' : 'white'}
          borderRightWidth="thin"
          borderRightColor="gray.100"
          borderRightStyle="solid"
          boxShadow="0px 1px 2px 0px #0000000D"
          h={`calc(100vh - ${TOP_BAR_HEIGHT_CSS_VAR} - ${checklistTopBarHeightInPx})`}
          top={`${TOP_BAR_HEIGHT_CSS_VAR} - ${checklistTopBarHeightInPx} !important`}
        >
          {!isDesktop && (
            <DrawerCloseButton zIndex={1}>
              <Icon icon="times" size="4" color="gray.400" />
            </DrawerCloseButton>
          )}

          <DrawerBody px={0} pt={0} pb={4}>
            <ThemeProvider2024>
              <TaskList />
            </ThemeProvider2024>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
});
