import * as React from 'react';
import { match } from 'ts-pattern';
import { useDrop } from 'react-dnd';
import { DraggableType, NewWidgetItem } from '../../types';
import { Widget } from '@process-street/subgrade/process';
import { getCursorLocation, HoverLocation } from 'hooks/get-cursor-location';
import { useFeatureFlags } from 'app/features/feature-flags';
import { FormEditorPageActorRef } from '../../form-editor-page-machine/form-editor-page-machine-types';

export function useDropOnWidgetListItem({ actor, widget }: { actor: FormEditorPageActorRef; widget: Widget }) {
  const { send } = actor;

  const ref = React.useRef<HTMLDivElement>(null);

  const [hoverLocation, setHoverLocation] = React.useState<HoverLocation>(null);

  const featureFlags = useFeatureFlags('longTextFieldMarkdown');

  const [dropResult, drop] = useDrop(() => ({
    accept: [DraggableType.FormField, DraggableType.Content],
    drop: (item: NewWidgetItem, monitor) => {
      if (!ref.current) return;

      const locationRelativeToWidget = match(getCursorLocation<NewWidgetItem>({ monitor, element: ref.current }))
        .with('top', () => ({ before: widget }))
        .with('bottom', () => ({ after: widget }))
        .otherwise(() => ({}));

      send({ type: 'CREATE_WIDGET', payload: { featureFlags, ...item }, ...locationRelativeToWidget });
    },
    hover(_item, monitor) {
      if (!ref.current) return;
      setHoverLocation(getCursorLocation<NewWidgetItem>({ monitor, element: ref.current }));
    },
    collect: monitor => ({ isOver: monitor.isOver() }),
  }));

  React.useEffect(() => {
    drop(ref);
  }, [drop]);

  return React.useMemo(
    () => ({
      dropResult,
      ref,
      hoverLocation,
    }),
    [dropResult, hoverLocation],
  );
}
