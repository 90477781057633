import { ModelUpdatedEvent } from '@ag-grid-community/core';
import { Flex } from 'components/design/next';
import { Muid } from '@process-street/subgrade/core';
import { ColumnDto } from '@process-street/subgrade/dashboard';
import { AngularDependenciesContext, getRealContext } from 'components/common/context/angular-dependencies-context';
import { ChecklistDashboardGrid } from 'components/dashboard/components/checklist/ChecklistDashboardGrid';
import { gridDataService } from 'components/dashboard/services/grid-data.service.impl';
import { useInjector } from 'components/injection-provider';
import React from 'react';
import { useSelector } from 'react-redux';
import { OrganizationMembershipSelector } from 'reducers/organization-membership/organization-membership.selectors';
import { useChecklistScreenState } from 'components/dashboard/components/checklist/ChecklistDashboardScreen/checklist-screen-state';
import classNames from 'classnames';
import dashboardGridStyles from 'components/dashboard/components/checklist/ChecklistDashboardGrid/ChecklistDashboardGrid.module.scss';
import { useFeatureFlag } from 'features/feature-flags';

const MIN_ROW_COUNT_TO_VIRTUALIZE = 100;
// The height of all other elements above the reports. It's used to calculate the height of the wrapper of the table, to ensure it uses all the available vertical space.
const HEADER_HEIGHT_IN_PX = 254;
const MOBILE_HEADER_HEIGHT_IN_PX = 290;

export type ChecklistDashboardGridWrapperProps = { templateId: Muid };

export const ChecklistDashboardGridWrapper = ({ templateId }: ChecklistDashboardGridWrapperProps) => {
  const [columns, setColumns] = React.useState<ColumnDto[]>([]);
  const organizationMembership = useSelector(
    OrganizationMembershipSelector.getBySelectedOrganizationIdAndCurrentUserId,
  );

  const { $state, OrganizationMembershipActions, SessionService } = useInjector(
    '$state',
    'OrganizationMembershipActions',
    'SessionService',
  );

  const { editedSavedView: savedView } = useChecklistScreenState();

  const realContext = getRealContext($state, OrganizationMembershipActions, SessionService);

  const handleModelUpdated = React.useCallback((e: ModelUpdatedEvent) => {
    const rowCount = e.api.getDisplayedRowCount();

    if (rowCount < MIN_ROW_COUNT_TO_VIRTUALIZE) {
      e.api.setDomLayout('autoHeight');
    } else {
      e.api.setDomLayout('normal');
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore -- Stop IntelliJ from showing red underlines
  const dashboardGridClass = classNames(dashboardGridStyles.checklistDashboardGrid);
  const dashboardGridSelector = `.${dashboardGridClass}`;

  const isFormFieldSortingEnabled = useFeatureFlag('reportsFormFieldColumnSorting');
  const isWorkflowRunLabelEnabled = useFeatureFlag('customLabelsForWorkflowRuns');

  React.useEffect(() => {
    gridDataService.getColumns(templateId, isFormFieldSortingEnabled, isWorkflowRunLabelEnabled).then(cols => {
      setColumns(cols);
    });
  }, [savedView?.filters.selectedTemplates, templateId, isFormFieldSortingEnabled, isWorkflowRunLabelEnabled]);

  return (
    <React.Suspense fallback="">
      <AngularDependenciesContext.Provider value={realContext}>
        <Flex
          w="full"
          minH={{ base: `calc(100vh - ${MOBILE_HEADER_HEIGHT_IN_PX}px)`, sm: `calc(100vh - ${HEADER_HEIGHT_IN_PX}px)` }}
          h="full"
          sx={{
            [dashboardGridSelector]: {
              'height': 'unset',

              '.ag-row': {
                backgroundColor: 'transparent !important',
              },

              '.ag-overlay-wrapper.ag-layout-auto-height.ag-overlay-no-rows-wrapper': {
                background:
                  'linear-gradient(rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 40%, rgba(255,255,255,1) 100%)',
              },
              '.ag-overlay': {
                pointerEvents: 'unset',
              },
            },
            '.ag-root-wrapper-body.ag-focus-managed.ag-layout-auto-height': {
              minHeight: '500px',
            },
          }}
        >
          {organizationMembership && savedView && (
            <ChecklistDashboardGrid
              columns={columns}
              organizationMembership={organizationMembership}
              savedView={savedView}
              onModelUpdated={handleModelUpdated}
              disableBulkSelection
              showEnhancedEmptyState
            />
          )}
        </Flex>
      </AngularDependenciesContext.Provider>
    </React.Suspense>
  );
};
