import * as React from 'react';
import { IconButton } from '@chakra-ui/react';
import { Icon } from 'components/design/next';
import { useBackButtonContext } from './back-button-provider';

type TemplateBackButtonProps = {};

export const TemplateBackButton: React.FC<React.PropsWithChildren<TemplateBackButtonProps>> = () => {
  const { onGoBack } = useBackButtonContext();

  return (
    <IconButton
      variant="ghost"
      aria-label="back"
      icon={<Icon icon="arrow-left" size="4" color="gray.500" />}
      onClick={onGoBack}
    />
  );
};
